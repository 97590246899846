import React, { FunctionComponent, SyntheticEvent, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { red } from '@material-ui/core/colors';
import UserService from '../services/user/UserService';

interface Props {
  message: string;
  isError: boolean;
}

const SnackbarError: FunctionComponent<Props> = ({ message, isError }) => {
  const [open, setOpen] = React.useState(isError);

  const handleClose = (_event: SyntheticEvent<any>, _reason?: string) => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(isError);
  }, [isError]);

  const user = UserService.getCurrentUser();
  if (user && user.mcleod_customer_id && user.mcleod_customer_id.length === 0) {
    //supress the error if it's there isn't a Mcleod id
    return <></>;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={open}
      onClose={handleClose}
      className="snackbar-error"
    >
      <SnackbarContent
        message={message}
        action={[
          <IconButton key={1} aria-label="close" onClick={handleClose}>
            <CloseIcon style={{ color: red[900] }} />
          </IconButton>
        ]}
      ></SnackbarContent>
    </Snackbar>
  );
};

export default SnackbarError;
