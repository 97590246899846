import React, { useEffect, useState } from 'react';
import { Spinner } from 'shamrock-clover-ui/dist/clover/components/Spinner/Spinner';

const SpinnerWithWait = () => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSpinner(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  });

  return showSpinner ? <Spinner></Spinner> : null;
};

export default SpinnerWithWait;
