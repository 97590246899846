import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import ordersReducer, { ordersState } from './orders/ordersReducer';
import addressBookReducer, {
  addressBookState
} from './addressBook/addressBookReducer';
import accountingReducer, {
  accountingState
} from './accounting/accountingReducer';
import { crashReporter } from './crashReporter';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as Sentry from '@sentry/browser';

export interface AppState {
  orders: typeof ordersState;
  accounting: typeof accountingState;
  addressBook: typeof addressBookState;
}

export const rootReducer = combineReducers({
  orders: ordersReducer,
  accounting: accountingReducer,
  addressBook: addressBookReducer
});

const Config = require('Config');

Sentry.init(Config.sentry);

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      crashReporter,
      createSentryMiddleware(Sentry, {
        // Optionally pass some options here.
      }),
      ReduxThunk
    )
  )
);

export default store;
