import React, { FC } from 'react';
import { Icon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { MenuOption } from './SingleSelectMenu';
import { styled } from 'shamrock-clover-ui';

export interface BaseSelectProps {
  width?: number;
  variant?: string;
  disabled?: boolean;
  value?: MenuOption;
  hideIcon?: boolean;
  clean?: boolean;
  isActive?: boolean;
}

export const SelectContainer = styled.div<BaseSelectProps>`
  width: ${props => (props.width ? props.width - 20 + 'px' : 'auto')};
  background-color: ${props => props.theme.gray5};
  background: ${props =>
    props.variant === 'filled' ? props.theme.gray5 : 'transparent'};
  padding: 19px 10px 19px 10px;
  border: none;
  border-bottom: 1px solid
    ${props => (props.isActive ? props.theme.blue : props.theme.gray4)};
  outline: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: ${props => props.theme.font.family.proximaNova};
  font-size: 16px;
  color: ${props => props.theme.gray2};
  white-space: nowrap;
  &:hover {
    cursor: ${props =>
      props.disabled ||
      props.hideIcon ||
      (props.clean && props.value?.optionName !== '')
        ? 'not-allowed'
        : 'pointer'};
  }
`;

const SelectLabel = styled.div<{ hasValue?: boolean }>`
  font-family: ${props => props.theme.font.family.proximaNova};
  color: ${props => props.theme.gray2};
  ${({ hasValue }) =>
    hasValue &&
    `position: absolute;
  top: 6px;
  left: 9px;
  font-size: 12px;`}
`;

const SelectValue = styled.div`
  font-family: ${props => props.theme.font.family.proximaNova};
  font-size: 16px;
  position: relative;
  top: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectIconWrapper = styled.div`
  position: absolute;
  right: 9px;
  top: 22px;
`;

export const SelectValueLabel: FC<{ value?: any; label: string }> = ({
  value,
  label
}) => (
  <>
    {value && <SelectValue>{value}</SelectValue>}
    <SelectLabel hasValue={Boolean(value)}>{label}</SelectLabel>
  </>
);
interface SelectIconProps extends BaseSelectProps {
  variant?: string;
}

export const SelectIcon: FC<SelectIconProps> = ({ variant }) => (
  <SelectIconWrapper>
    {variant === 'up' ? (
      <Icon
        aria-label="Triangle Up"
        icon="chevronUp"
        size="12"
        onClick={() => {
          return;
        }}
      />
    ) : (
      <Icon
        aria-label="Triangle Down"
        icon="triangleDown"
        size="12"
        onClick={() => {
          return;
        }}
      />
    )}
  </SelectIconWrapper>
);

interface CleanIconProps extends BaseSelectProps {
  onClean: (ev: React.MouseEvent<Element, MouseEvent>) => void;
}

export const CleanIcon: FC<CleanIconProps> = ({ onClean }) => (
  <SelectIconWrapper>
    <Icon
      data-testid="clean-icon"
      aria-label="clean input"
      icon="close"
      size="12"
      onClick={(event: React.MouseEvent<Element, MouseEvent>) => onClean(event)}
    />
  </SelectIconWrapper>
);
