import React, { FunctionComponent } from 'react';
import './requestQuoteHeaderSection.scss';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import styled from 'styled-components';
// import UserService from '../../../../services/user/UserService';
// import * as FirebaseUtils from '../../../../utilities/firebaseUtils';

const StyledButton = styled(Button)`
  font-family: proxima-nova;
  text-transform: capitalize;
`;
interface Props {
  toggleCancelModal: Function;
  toggleRequestQuoteModal: Function;
  showModalOnCancel: boolean;
  isSubmitButtonDisabled: boolean;
  onSubmit: any;
}

const RequestQuoteHeaderSection: FunctionComponent<Props> = ({
  toggleCancelModal,
  toggleRequestQuoteModal,
  showModalOnCancel,
  isSubmitButtonDisabled,
  onSubmit
}) => {
  const handleOnCancel = () => {
    if (showModalOnCancel) {
      toggleCancelModal(true);
    } else {
      toggleRequestQuoteModal(false);
    }
  };

  return (
    <div className="requestQuoteHeader">
      <div className="requestQuoteTitle">Request a quote</div>
      <div className="cancelButton">
        <StyledButton
          buttonStyle="outlined"
          onClick={() => {
            handleOnCancel();
          }}
        >
          CANCEL
        </StyledButton>
      </div>
      <div className="submitButton">
        <StyledButton
          onClick={() => {
            onSubmit();
          }}
          color="green"
          disabled={isSubmitButtonDisabled}
        >
          SEND QUOTE
        </StyledButton>
      </div>
    </div>
  );
};

export default React.memo(RequestQuoteHeaderSection);
