import React from 'react';
import Card, { CardHeader, CardFooter, CardBody } from '../../components/Card';
import { useAccountingQueryParams } from '../../hooks/routeHooks';
import { useSelector } from 'react-redux';

import '../../components/Card.scss';
import { getCardData } from '../../store/accounting/accountingSelectors';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

const bodyStyle: React.CSSProperties = {
  fontSize: '30px',
  color: '#666666',
  padding: '10px 16px 8px 16px',
  fontWeight: 'bold'
};

type HeaderIcon = 'yellow' | 'orange' | 'red' | undefined;

const getQueryTextByIndex = (index: number) => {
  if (index === 2) {
    return '0-30';
  }
  if (index === 3) {
    return '31-61';
  }
  if (index === 4) {
    return '61+';
  }
  return '';
};

const AccountingCards = () => {
  const cardData = useSelector(getCardData);
  const { queryParams, addQueryParams } = useAccountingQueryParams();

  const filter =
    typeof queryParams.filter === 'string'
      ? [queryParams.filter]
      : queryParams.filter || [];

  const pushFilter = (filter: string[]) => {
    addQueryParams({ filter });
  };

  // first card has no active value
  const handleCardClick = (index: number) => (
    _event: React.MouseEvent<HTMLDivElement>
  ) => {
    if (index === 1) {
      pushFilter(['0-30', '31-61', '61+']);
      return;
    }
    const toggledValue = filter.includes(getQueryTextByIndex(index));
    pushFilter(
      toggledValue
        ? [...filter.filter(x => x !== getQueryTextByIndex(index))]
        : [...filter, getQueryTextByIndex(index)]
    );

    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.FILTER_CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.ACCOUNTING,
      {
        filterType: index == 1 ? 'Total Past Due' : getQueryTextByIndex(index)
      }
    );
  };

  const clearFilters = () => {
    pushFilter([]);
  };

  const getIsActive = (index: number) => {
    if (index === 1) {
      return (
        filter.includes('0-30') &&
        filter.includes('31-61') &&
        filter.includes('61+')
      );
    }
    return filter.includes(getQueryTextByIndex(index));
  };

  const isAnyFilterActive = () => {
    return filter.length > 0;
  };

  return (
    <div className="cardsContainer">
      {cardData.map((x, index, array) =>
        index === 0 ? (
          <Card
            key={index}
            onClick={clearFilters}
            style={{ flex: 1, margin: '1em 1em 1em 0em' }}
            data-testid={`CardFilter_${index}`}
            headerComponent={_props => (
              <CardHeader
                text={x.headerText}
                icon={x.headerIcon as HeaderIcon}
              />
            )}
            bodyComponent={_props => (
              <CardBody text={x.bodyText} style={bodyStyle} />
            )}
            footerComponent={_props => (
              <div className="cardFooterFilterContainer">
                <div className="cardText" style={{ paddingRight: '1em' }}>
                  {x.footerText}
                </div>
                <div
                  onClick={clearFilters}
                  style={{
                    width: '100px',
                    height: '32px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '16px',
                    color: isAnyFilterActive() ? '#0091EA' : '#DDDDDD',
                    border: isAnyFilterActive()
                      ? 'solid 1px #0091EA'
                      : 'solid 1px #DDDDDD'
                  }}
                >
                  Clear Filters
                </div>
              </div>
            )}
          />
        ) : (
          <Card
            key={index}
            data-testid={`CardFilter_${index}`}
            style={{
              flex: 1,
              margin:
                index === array.length - 1
                  ? '1em 0em 1em 1em'
                  : '1em 1em 1em 1em'
            }}
            // first card doesn't have an active value
            // so subtract one
            onClick={handleCardClick(index)}
            headerComponent={_props => (
              <CardHeader
                text={x.headerText}
                icon={x.headerIcon as HeaderIcon}
              />
            )}
            bodyComponent={_props => (
              <CardBody text={x.bodyText} style={bodyStyle} />
            )}
            footerComponent={_props => (
              <CardFooter
                filterChipText={x.footerText}
                // first card doesn't have an active value
                // so subtract one
                isActive={getIsActive(index)}
              />
            )}
          />
        )
      )}
    </div>
  );
};

export default AccountingCards;
