export class GoogleMapsService {
  getPlaceSuggestions(
    place: string
  ): Promise<google.maps.places.AutocompletePrediction[]> {
    const SOUTHWEST_BOUND = new google.maps.LatLng({
      lat: 5.49955,
      lng: -167.276413
    });
    const NORTHEAST_BOUND = new google.maps.LatLng({
      lat: 83.162102,
      lng: -52.23304
    });
    const MAP_BOUNDS = new google.maps.LatLngBounds(
      SOUTHWEST_BOUND,
      NORTHEAST_BOUND
    );
    return new Promise((resolve, reject) => {
      new google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: place,
          componentRestrictions: { country: ['us', 'ca'] },
          types: ['(regions)'],
          bounds: MAP_BOUNDS
        },
        (data, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            return resolve(data);
          }
          return reject(status);
        }
      );
    });
  }
}
export default new GoogleMapsService();
