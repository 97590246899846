// packages
import React, { FunctionComponent } from 'react';
import { styled } from 'shamrock-clover-ui';
import * as FirebaseUtils from '../../utilities/firebaseUtils';
import { Icon as CloverIcon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { Flex } from '../../components/Flex';

// props
interface Props {
  onClick: Function;
  visible?: boolean | undefined;
}

// styles
const StyledButton = styled.button`
  background-color: #0091ea;
  border-radius: 20px;
  border: unset;
  color: #ffffff;
  font-family: proxima-nova, arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  line-height: 17px;
  right: 70px;
  width: 158px;
`;

const StyledIcon = styled(CloverIcon)`
  filter: brightness(0) invert(1);
`;

export const RequestQuoteButton: FunctionComponent<Props> = ({
  onClick,
  visible
}) => {
  if (!visible) {
    return null;
  }
  return (
    <StyledButton
      data-testid="request-a-quote-button"
      onClick={() => {
        FirebaseUtils.logFirebaseEvent(
          FirebaseUtils.FirebaseEvents.CLICK,
          FirebaseUtils.FirebaseModules.SHIPPER,
          FirebaseUtils.FirebasePages.REQUEST_QUOTE_BUTTON,
          { description: 'Request Quote' }
        );
        onClick(true);
      }}
    >
      <Flex justifyContent="space-evenly">
        <StyledIcon icon="clipboard" size="16" />
        Request Quote
      </Flex>
    </StyledButton>
  );
};

export default RequestQuoteButton;
