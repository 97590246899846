// services
import Service from './Service';
import AuthService from '../services/auth/AuthService';

// config
const Config = require('Config');

const authService: AuthService = new AuthService();

interface SignUpPayload {
  company: string;
  company_type: string;
  customer_id?: string;
  device_type: string;
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  phone: string;
  roles: string[];
}

interface RegistrationPayload {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  company: string;
  phone: string;
  ooida_id?: string;
  mc_dot?: string;
  company_id?: string;
  company_type?: string;
  mcleod_customer_id?: string[];
  device_type?: string;
  roles?: string[];
  acceptedTos: boolean;
}

export class RegistrationService extends Service {
  constructor() {
    super('', false);
  }

  async sendVerificationCode(email: string) {
    try {
      const { status } = await this.axios.post<string>(Config.verificationUrl, {
        email,
        templateId: 'd-6faec22e9e5c4fe787c1b809f6589483'
      });

      if (status === 200) {
        return { success: true };
      }
    } catch (e) {
      return { success: false };
    }
  }

  async checkVerificationCode(email: string, verificationCode: string) {
    try {
      const { status } = await this.axios.get<string>(Config.verificationUrl, {
        params: { email, verification_code: verificationCode }
      });

      if (status === 200) {
        return { success: true };
      }
    } catch (e) {
      return { success: false };
    }
  }

  signupErrorHandling = (e: any) => {
    const response: {
      emailAlreadyExists?: boolean;
      success: boolean;
      msg?: string;
    } = {
      success: false
    };

    const { message }: any = e;
    const emailAlreadyExists = message.includes('409');
    const failedWithAnError =
      message.includes('502') || message.includes('500');

    if (failedWithAnError || emailAlreadyExists) {
      response.success = false;
      response.emailAlreadyExists = emailAlreadyExists;
      response.msg =
        'Error, unable to complete your request, please try again later.';
    }

    return response;
  }
 
  async signupNewUser({
    company,
    company_type,
    device_type,
    customer_id,
    email,
    firstname,
    lastname,
    password,
    phone,
    roles
  }: SignUpPayload) {
    try {
      const { data: registeredUser } = await this.axios.post(
        `${Config.sentinelServiceUrl}user/signup`,
        {
          user: {
            acceptedTos: true,
            company,
            company_type,
            mcleod_customer_id: customer_id,
            device_type,
            email,
            firstname,
            lastname,
            password,
            phone,
            roles
          },
          cpua1264Enabled: true
        }
      );

      if (registeredUser) {
        const tokenObj = {
          token: registeredUser.token,
          user: registeredUser.email
        };
        authService.setToken(tokenObj);

        return { success: true, emailAlreadyExists: false };
      }
    } catch (e) {
      return this.signupErrorHandling(e);
    }
  }

  async registerUser(user: RegistrationPayload) {
    try {
      await this.axios.post(`${Config.registerServiceUrl}`, user );
      return { success: true, emailAlreadyExists: false };
    } catch (e) {
      return this.signupErrorHandling(e);
    }
  }

}

export default new RegistrationService();
