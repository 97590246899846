import React, { ChangeEvent } from 'react';
import FilterChip from '../filterChip/FilterChip';
import FilterInput from 'shamrock-clover-ui/dist/clover/components/FilterInput/FilterInput';
import { FILTER_TYPES } from '../../utilities/constants';
import OriginDestinationFilter from '../originDestinationFilter/OriginDestinationFilter';
import NotificationsButton from '../../features/notifications/notificationsButton';
import LoadEntryButton from '../../features/loadEntry/button/loadEntryButton';
import {
  BOOKED,
  IN_TRANSIT,
  DELIVERED,
  ORDER_REFERENCE_PLACEHOLDER
} from '../../utilities/constants';
import './orderFilterBar.scss';
import * as FirebaseUtils from '../../utilities/firebaseUtils';
import styled from 'styled-components';
import {
  useOrdersServiceBookedCount,
  useOrdersServiceDeliveredCount,
  useOrdersServiceInTransitCount
} from '../../hooks/useOrdersServices';
import ExportIcon from '../../assets/images/Export.svg';
import ExportDisabledIcon from '../../assets/images/Export_Disabled.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrderCSV,
  getOrderCSVLoading
} from '../../store/orders/ordersSelectors';
import { getDateWithTimezone } from '../../utilities/dateHelper';
import { useExportCSVFlag } from '../../hooks/useFeatureFlag';
import { setOrdersCSVLoading } from '../../store/orders/ordersActions';

interface OrderFilterBarProps {
  filterBooked: boolean;
  filterDelivered: boolean;
  filterInTransit: boolean;
  isQuoteModalOpen: boolean;
  notificationsOn: boolean;
  referenceFilterString: string;
  referenceFilterStringOnChange: Function;
  toggleFunction: Function;
  toggleNotificationsModal: Function;
  toggleLoadEntryModal: Function;
}

const StyledInput = styled(FilterInput)`
  border: none;
  max-width: 256px;
`;

export const OrderFilterBar: React.FC<OrderFilterBarProps> = props => {
  const dispatch = useDispatch();
  const { bookedCount } = useOrdersServiceBookedCount();
  const { deliveredCount } = useOrdersServiceDeliveredCount();
  const { inTransitCount } = useOrdersServiceInTransitCount();
  const { isExportCSVEnabled } = useExportCSVFlag();
  const orderCsv = useSelector(getOrderCSV);
  const csvIsLoading = useSelector(getOrderCSVLoading);

  const convertToCSV = async () => {
    dispatch(setOrdersCSVLoading(true));
    if (orderCsv) {
      //converting string into blob
      const options = { type: 'text/plain', lastModified: Date.now() };
      const csvBlob = new Blob([orderCsv ? orderCsv : ''], options);

      //downloading blob as csv file
      const element = document.createElement('a');
      element.setAttribute('href', window.URL.createObjectURL(csvBlob));
      element.setAttribute(
        'download',
        `shipments_${getDateWithTimezone()}.csv`
      );
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    dispatch(setOrdersCSVLoading(false));
  };

  return (
    <div className="orderFilterBar">
      <div className="orderFilterBarContent">
        <div className="leftFilters">
          <div className="textInputsContainer">
            <div className="filterInput">
              <StyledInput
                value={props.referenceFilterString}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  props.referenceFilterStringOnChange(event.target.value);
                }}
                onBlur={(event: any) => {
                  if (event.currentTarget === event.target) {
                    FirebaseUtils.logFirebaseEvent(
                      FirebaseUtils.FirebaseEvents.REFERENCE_SEARCH,
                      FirebaseUtils.FirebaseModules.SHIPPER,
                      FirebaseUtils.FirebasePages.SHIPMENTS,
                      {
                        referenceValue: event.target.value
                      }
                    );
                  }
                }}
                data-testid={'SearchOrderReferenceInput'}
                placeholder={ORDER_REFERENCE_PLACEHOLDER}
                clearSearch={() => props.referenceFilterStringOnChange('')}
              />
            </div>
            <div className="originDestinationFilterContainer">
              <OriginDestinationFilter
                isQuoteModalOpen={props.isQuoteModalOpen}
              />
            </div>
          </div>

          <div className="filtersContainer" data-testid="FilterChips">
            <FilterChip
              isToggled={props.filterBooked}
              label={`${BOOKED} ${bookedCount}`}
              onClick={() => {
                props.toggleFunction(FILTER_TYPES.BOOKED);
                FirebaseUtils.logFirebaseEvent(
                  FirebaseUtils.FirebaseEvents.FILTER_CLICK,
                  FirebaseUtils.FirebaseModules.SHIPPER,
                  FirebaseUtils.FirebasePages.SHIPMENTS,
                  {
                    filterType: BOOKED
                  }
                );
              }}
            />
            <FilterChip
              isToggled={props.filterInTransit}
              label={`${IN_TRANSIT} ${inTransitCount}`}
              onClick={() => {
                props.toggleFunction(FILTER_TYPES.IN_TRANSIT);
                FirebaseUtils.logFirebaseEvent(
                  FirebaseUtils.FirebaseEvents.FILTER_CLICK,
                  FirebaseUtils.FirebaseModules.SHIPPER,
                  FirebaseUtils.FirebasePages.SHIPMENTS,
                  {
                    filterType: IN_TRANSIT
                  }
                );
              }}
            />
            <FilterChip
              isToggled={props.filterDelivered}
              label={`${DELIVERED} ${deliveredCount}`}
              onClick={() => {
                props.toggleFunction(FILTER_TYPES.DELIVERED);
                FirebaseUtils.logFirebaseEvent(
                  FirebaseUtils.FirebaseEvents.FILTER_CLICK,
                  FirebaseUtils.FirebaseModules.SHIPPER,
                  FirebaseUtils.FirebasePages.ACCOUNTING,
                  {
                    filterType: DELIVERED
                  }
                );
              }}
            />
            {isExportCSVEnabled ? (
              <Tooltip title="Download CSV" placement="right">
                <button className="exportIconButton">
                  <img
                    className="export"
                    src={csvIsLoading ? ExportDisabledIcon : ExportIcon}
                    onClick={csvIsLoading ? () => {} : convertToCSV}
                  ></img>
                </button>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="buttonsContainer">
          <div className="loadEntryButtonContainer">
            <LoadEntryButton
              toggleLoadEntryModal={props.toggleLoadEntryModal}
            />
          </div>
          <div className="notificationButtonContainer">
            <NotificationsButton
              toggleNotificationModal={props.toggleNotificationsModal}
              notificationsOn={props.notificationsOn}
              type="global"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderFilterBar;
