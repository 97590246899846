import React from 'react';
import { DateLocationFormData } from '../types/requestQuoteTypes';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';

// store
import {
  performGooglePlacesSearch,
  setDestinationFilterString,
  setOriginFilterString,
  setPlacesSuggestions
} from '../../../store/orders/ordersActions';
import { AppState } from '../../../store';

// styles
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import SuggestionSelectorField from '../../../components/suggestionSelectorField';
import { Icon as CloverIcon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { DatePickerStyled, GridContainer } from './requestQuoteStyles';

// props
interface Props {
  formData: DateLocationFormData;
  setFormData: Function;
  setIsMenuOpen: Function;
}

export const StyledSelectInputContainer = styled.div`
  width: 310px;
  margin-top: 28px;
  margin-left: 64px;
`;

const IconContainer = styled.div`
  margin-top: 40%;
  margin-left: 100%;
`;

export const DateLocationForm = ({
  formData,
  setFormData,
  setIsMenuOpen
}: Props) => {
  const dispatch = useDispatch();
  const { suggestions } = useSelector((state: AppState) => state.orders);

  const handleOnChangeForm = (name: string, value: string) => {
    setFormData({ ...formData, [name]: { value: value, errorMessage: '' } });
  };

  const handleOnLocationChange = (name: string, value: string) => {
    handleOnChangeForm(name, value);
    if (name === 'deliveryLocation') {
      dispatch(setDestinationFilterString(value));
      return;
    }

    dispatch(setOriginFilterString(value));
  };

  const handleOnSuggestionsFetchRequested = (type: string) => {
    dispatch(performGooglePlacesSearch(type));
  };
  const handleOnSuggestionsClearRequested = () => {
    dispatch(setPlacesSuggestions([]));
  };

  return (
    <GridContainer container>
      <Grid container direction="row">
        <Grid item sm={6}>
          <DatePickerStyled
            format="MM/DD/YYYY"
            onChange={(date: Moment) => {
              handleOnChangeForm('date', date.format('MM/DD/YYYY'));
            }}
            minDate={moment().subtract(1, 'hours')}
            placeholder="Date"
            value={formData.date.value ? moment(formData.date.value) : null}
            error={formData.date.errorMessage}
            readOnly
          />
        </Grid>
      </Grid>
      <Grid container direction="row">
        <Grid item lg={4} md={4}>
          <StyledSelectInputContainer>
            <SuggestionSelectorField
              helperText={
                formData.pickupLocation.errorMessage
                  ? 'City, State or Zip Required'
                  : 'City, State or Zip'
              }
              name="pickupLocation"
              onInputChange={handleOnLocationChange}
              onSuggestionsClearRequested={handleOnSuggestionsClearRequested}
              onSuggestionsFetchRequested={() =>
                handleOnSuggestionsFetchRequested('ORIGIN')
              }
              onFocus={() => {
                setIsMenuOpen(true);
              }}
              onBlur={() => {
                setIsMenuOpen(false);
              }}
              onSuggestionSelected={() => {
                setIsMenuOpen(false);
              }}
              placeholder="Pickup Location"
              suggestions={suggestions}
              value={formData.pickupLocation.value}
              error={!!formData.pickupLocation.errorMessage}
              visible
            />
          </StyledSelectInputContainer>
        </Grid>
        <Grid item lg={2} md={2}>
          <IconContainer>
            <CloverIcon icon="arrowRight" size="18" />
          </IconContainer>
        </Grid>
        <Grid item lg={4} md={4}>
          <StyledSelectInputContainer>
            <SuggestionSelectorField
              helperText={
                formData.deliveryLocation.errorMessage
                  ? 'City, State or Zip Required'
                  : 'City, State or Zip'
              }
              name="deliveryLocation"
              onInputChange={handleOnLocationChange}
              onSuggestionsClearRequested={handleOnSuggestionsClearRequested}
              onSuggestionsFetchRequested={() =>
                handleOnSuggestionsFetchRequested('DESTINATION')
              }
              onFocus={() => {
                setIsMenuOpen(true);
              }}
              onBlur={() => {
                setIsMenuOpen(false);
              }}
              onSuggestionSelected={() => {
                setIsMenuOpen(false);
              }}
              placeholder="Delivery Location"
              suggestions={suggestions}
              value={formData.deliveryLocation.value}
              error={!!formData.deliveryLocation.errorMessage}
              visible
            />
          </StyledSelectInputContainer>
        </Grid>
      </Grid>
    </GridContainer>
  );
};

export default DateLocationForm;
