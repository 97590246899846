import React, { FunctionComponent } from 'react';
import './loadEntryHeaderSection.scss';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import styled from 'styled-components';
import UserService from '../../../../services/user/UserService';
import * as FirebaseUtils from '../../../../utilities/firebaseUtils';
import AddressBookService from '../../../../services/AddressBookService';
import { useDispatch } from 'react-redux';

const StyledButton = styled(Button)`
  font-family: proxima-nova;
  text-transform: capitalize;
`;
interface Props {
  toggleCancelModal: Function;
  toggleLoadEntryModal: Function;
  showModalOnCancel: boolean;
  isSubmitButtonDisabled: boolean;
  onSubmit: any;
}

const LoadEntryHeaderSection: FunctionComponent<Props> = ({
  toggleCancelModal,
  toggleLoadEntryModal,
  showModalOnCancel,
  isSubmitButtonDisabled,
  onSubmit
}) => {
  const dispatch = useDispatch();
  const handleOnCancel = () => {
    const mcleod_customer_id = UserService.getMcleodCustomerId();
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.LOAD_ENTRY,
      {
        mcleodID: mcleod_customer_id,
        description: 'Initiate Cancel Load Request'
      }
    );

    if (showModalOnCancel) {
      toggleCancelModal(true);
    } else {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.SHIPPER,
        FirebaseUtils.FirebasePages.LOAD_ENTRY,
        {
          mcleodID: mcleod_customer_id,
          description: 'Confirm Cancel Load'
        }
      );
      toggleLoadEntryModal(false);
      AddressBookService.clearAddressBook(dispatch);
    }
  };

  return (
    <div className="loadEntryHeader">
      <div className="loadEntryTitle">Submit Load</div>
      <div className="cancelButton">
        <StyledButton
          buttonStyle="outlined"
          onClick={() => {
            handleOnCancel();
          }}
        >
          Cancel
        </StyledButton>
      </div>
      <div className="submitButton">
        <StyledButton
          onClick={() => {
            onSubmit();
          }}
          disabled={isSubmitButtonDisabled}
        >
          Submit Load
        </StyledButton>
      </div>
    </div>
  );
};

export default React.memo(LoadEntryHeaderSection);
