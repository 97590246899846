import Service from './Service';

const Config = require('Config');

class UserData extends Service {
  constructor() {
    super(Config.serviceUrl);
  }

  async getUserAddress() {
    const response = await this.axios.get('userAddress', {});
    return response.data;
  }
}

export default new UserData();
