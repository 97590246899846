import { styled } from 'shamrock-clover-ui';
import { Flex } from '../../components/Flex';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import FilterInput from 'shamrock-clover-ui/dist/clover/components/FilterInput/FilterInput';

export const DetailsContainer = styled(Flex as any)<{ isFullDetails: boolean }>`
  background-color: white;
  overflow-y: auto;
  max-height: calc(90vh - 28px);
  position: relative;
  padding-bottom: 24px;
`;

export const OrderIdText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const LastKnownText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const LoadStatusText = styled.div<{ color?: string }>`
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};
  font-size: ${({ theme }) => theme.font.size.xsmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const HeaderDetailContainer = styled(Flex as any)`
  border-bottom: 1px solid #dddddd;
`;

export const ReferenceContainer = styled(Flex as any)`
  border-bottom: 1px solid #dddddd;
`;

export const ReferenceHeaderText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.font.size.medium};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding-bottom: 10px;
`;

export const LeftText = styled.div`
  font-weight: 400;
  text-align: left;
  box-sizing: border-box;
  width: 175px;
  color: #424242;
  font-family: 'proxima-nova';
  font-size: 13px;
  letter-spacing: -0.06px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const RightText = styled.div`
  text-align: left;
  box-sizing: border-box;
  left: 220px;
  color: #424242;
  font-family: 'proxima-nova';
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.06px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Card = styled(Flex as any)`
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 2px 0px, rgb(0 0 0 / 24%) 0px 2px 2px 0px;
  margin-top: 28px;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const SearchButton = styled(Button as any)`
  border-radius: 24px;
  border: 0;
  background-color: #0091ea;
  color: white;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  :focus {
    outline: 0;
  }
  :disabled {
    background-color: #eeeeee;
  }
`;

export const ResetButton = styled.button`
  border: none;
  padding-left: 16px;
  background: none;
  color: #0091ea;
  height: 36px;
  border-left: #d8d8d8 1px solid;
  :focus {
    outline: 0;
  }
  :disabled {
    color: #d0d0d0;
  }
`;

export const LocationFilterContainer = styled.div`
  .react-autosuggest__container {
    max-width: 330px;
    min-width: 250px;
    height: 40px;
    margin-right: 16px;
  }

  .react-autosuggest__input {
    border-radius: 24px;
    border: none;
    outline: 0;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 330px;
    min-width: 250px;
    background-color: #eeeeee;
    ::-webkit-input-placeholder {
      color: #999999;
    }
  }
  .react-autosuggest__suggestions-container--open {
    background-color: white;
    position: absolute;
    z-index: 1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .react-autosuggest__suggestion {
    display: block;
    width: 200px;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    cursor: pointer;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #cccccc;
  }

  .react-autosuggest__suggestions-list {
    padding-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const FilterInputContainer = styled.div`
  & > :nth-of-type(1) {
    width: 175px;
    max-width: unset;
    margin-right: 16px;
  }
`;

export const StyledFilterInput = styled(FilterInput as any)`
  background-color: #eeeeee;
  border: none;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StatusText = styled(Flex as any)`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #888888;
`;

export const StatusTextBold = styled(StatusText as any)`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding-bottom: 20px;
`;

export const SmallIcon = styled.img`
  height: 70px;
  width: 70px;
`;

export const Icon = styled.img`
  height: 120px;
  width: 120px;
`;

export const SignUp = styled(Flex as any)`
  width: 100%;
  font-size: 18px;
  color: #888888;
  margin-top: auto;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const ParentContainer = styled(Flex as any)`
  height: 80%;
`;

export const InputContainer = styled.div`
  padding-top: 20px;
`;

export const StyledButton = styled(Button as any)`
  font-size: ${({ theme }) => theme.font.size.xsmall};
  margin-top: 24px;
`;

export const LastKnownLocationTextContainer = styled(Flex as any)`
  text-align: right;
`;
