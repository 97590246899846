import React, { Fragment, useEffect } from 'react';
import DetailContainer from '../../components/orderDetails/container/orderDetailsContainer';
import LoadEntryContainer from '../../features/loadEntry/container/loadEntryContainer';
import OrdersView from '../../components/orderTable/OrdersView';
import './orders.scss';
import OrderFilterBar from '../../components/orderFilterBar/OrderFilterBar';
import {
  setPageIndex,
  setNoOfRows,
  retrievePageOfData,
  toggleFilter,
  searchOrders,
  setReferenceFilterString,
  setReferenceToggleString,
  toggleOrderBy,
  toggleNotificationsModal,
  toggleLoadEntryModal,
  toggleCancelModal,
  setLoadEntryError,
  getNotificationSummary
} from '../../store/orders/ordersActions';
import { connect, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { Order } from 'types/orderTypes';
import { Slide } from '@material-ui/core';
import { useOrdersQueryParams } from '../../hooks/routeHooks';
import { AppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  OrderDetail,
  NotificationSummary
} from '../../store/orders/orderReduxTypes';
import SnackbarError from '../../components/SnackbarError';
import { Snackbar } from 'shamrock-clover-ui/dist/clover/components/Snackbar/Snackbar';
import GlobalNotificationsModal from '../../features/notifications/globalNotificationsModal';
import CancelModal from '../../features/loadEntry/cancelModal/cancelModal';
import UnmatchedUserModal from '../../features/blankState/unmatchedUserModal';

import {
  getShowLoadEntryModal,
  getIsLoadEntryError,
  getLoadEntryErrorText
} from '../../store/orders/ordersSelectors';

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) {
  return {
    retrievePageOfData: (page: number, rows?: number) =>
      dispatch(retrievePageOfData(page, rows)),
    setPageIndex: (page: number) => dispatch(setPageIndex(page)),
    setNoOfRows: (noOfRows: number) => dispatch(setNoOfRows(noOfRows)),
    toggleFilter: (filterToBeToggled: string) =>
      dispatch(toggleFilter(filterToBeToggled)),
    searchOrders: () => dispatch(searchOrders()),
    setReferenceFilterString: (referenceFilterString: string) =>
      dispatch(setReferenceFilterString(referenceFilterString)),
    setReferenceToggleString: (referenceToggleString: string) =>
      dispatch(setReferenceToggleString(referenceToggleString)),
    setOrderBy: (column: string, isAscending: boolean) =>
      dispatch(toggleOrderBy(column, isAscending)),
    toggleNotificationsModal: (isVisible: boolean) =>
      dispatch(toggleNotificationsModal(isVisible)),
    getNotificationSummary: () => dispatch(getNotificationSummary()),
    toggleLoadEntryModal: (isVisible: boolean) =>
      dispatch(toggleLoadEntryModal(isVisible)),
    toggleCancelModal: (isVisible: boolean) =>
      dispatch(toggleCancelModal(isVisible)),
    setLoadEntryError: (isVisible: boolean, isError: boolean, value: string) =>
      dispatch(setLoadEntryError(isVisible, isError, value))
  };
}

const mapStateToProps = ({ orders }: AppState) => {
  return {
    orders: orders.orders,
    isTableError: orders.isTableError,
    noOfResults: orders.noOfResults,
    pageChanging: orders.pageChanging,
    pageIndex: orders.pageIndex,
    noOfRows: orders.noOfRows,
    filterBooked: orders.filterBooked,
    filterDelivered: orders.filterDelivered,
    filterInTransit: orders.filterInTransit,
    referenceFilterString: orders.referenceFilterString,
    orderBy: orders.orderBy,
    detail: orders.detail,
    notificationSummary: orders.notificationSummary,
    showLoadEntryError: orders.showLoadEntryError,
    isLoadEntryError: orders.isLoadEntryError,
    loadEntryErrorText: orders.loadEntryErrorText
  };
};

export interface OrderProps {
  orders: Order[];
  isTableError: boolean;
  noOfResults: number;
  pageChanging: boolean;
  setPageIndex: (page: number) => void;
  pageIndex: number;
  noOfRows: number;
  setNoOfRows: (noOfRows: number) => void;
  filterBooked: boolean;
  filterInTransit: boolean;
  filterDelivered: boolean;
  referenceFilterString: string;
  retrievePageOfData: (page: number, rows?: number) => void;
  toggleFilter: (filterToBeToggled: string) => void;
  searchOrders: () => void;
  setReferenceFilterString: (referenceFilterString: string) => void;
  setReferenceToggleString: (referenceToggleString: string) => void;
  setOrderBy: (column: string, isAscending: boolean) => void;
  orderBy: {
    column: string;
    isAscending: boolean;
  };
  detail: OrderDetail;
  toggleNotificationsModal: (isVisible: boolean) => void;
  getNotificationSummary: () => void;
  notificationSummary: NotificationSummary | undefined | null;
  toggleLoadEntryModal: (isVisible: boolean) => void;
  toggleCancelModal: (isVisible: boolean) => void;
  showLoadEntryError: boolean;
  isLoadEntryError: boolean;
  loadEntryErrorText: string;
  setLoadEntryError: (
    isVisible: boolean,
    isError: boolean,
    value: string
  ) => void;
  isQuoteModalOpen: boolean;
}

var debounceSearch = debounce(async props => {
  await props.searchOrders(props);
}, 500);

var debounceToggle = debounce(async props => {
  await props.retrievePageOfData(0);
}, 500);

export const Orders: React.FC<OrderProps> = props => {
  const {
    queryParams: { orderId }
  } = useOrdersQueryParams();

  useEffect(() => {
    props.retrievePageOfData(props.pageIndex);
  }, []);

  useEffect(() => {
    props.getNotificationSummary();
  }, []);

  const rowChange = async (numberOfRows: number) => {
    props.setPageIndex(0);
    props.setNoOfRows(numberOfRows);
    await props.retrievePageOfData(0, numberOfRows);
  };

  function referenceFilterStringOnChange(referenceFilterString: string) {
    props.setReferenceFilterString(referenceFilterString);
    debounceSearch(props);
  }

  function referenceToggleChange(referenceToggleString: string) {
    if (props.pageChanging) {
      return;
    }
    props.setReferenceToggleString(referenceToggleString);
    props.toggleFilter(referenceToggleString);
    debounceToggle(props);
  }

  return (
    <Fragment>
      <UnmatchedUserModal />
      <SnackbarError
        isError={props.isTableError}
        message="Oops! Something went wrong. Please refresh."
      />
      <Snackbar
        open={props.showLoadEntryError}
        onClose={() => {
          props.setLoadEntryError(false, false, '');
        }}
        message={useSelector(getLoadEntryErrorText)}
        variant={useSelector(getIsLoadEntryError) ? 'success' : 'error'}
      />
      <div className="orderPageContainer">
        <OrderFilterBar
          filterBooked={props.filterBooked}
          filterDelivered={props.filterDelivered}
          filterInTransit={props.filterInTransit}
          isQuoteModalOpen={props.isQuoteModalOpen}
          notificationsOn={props.notificationSummary?.globalEnabled || false}
          referenceFilterString={props.referenceFilterString}
          referenceFilterStringOnChange={referenceFilterStringOnChange}
          toggleFunction={referenceToggleChange}
          toggleNotificationsModal={props.toggleNotificationsModal}
          toggleLoadEntryModal={props.toggleLoadEntryModal}
        />
        <div className="orderTableContainer container-fluid">
          <OrdersView
            pageChange={props.retrievePageOfData}
            orders={props.orders}
            noOfResults={props.noOfResults}
            pageIndex={props.pageIndex}
            noOfRows={props.noOfRows}
            pageChanging={props.pageChanging}
            rowChange={rowChange}
            setOrderBy={props.setOrderBy}
            orderBy={props.orderBy}
          />
        </div>
        <Slide
          direction="left"
          in={orderId !== undefined}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 400, exit: 400 }}
        >
          <DetailContainer orderId={orderId as string} />
        </Slide>
      </div>
      <GlobalNotificationsModal
        toggleNotificationModal={props.toggleNotificationsModal}
        orderId={orderId as string}
        notificationSummary={props.notificationSummary}
      />
      <Slide
        direction="left"
        in={useSelector(getShowLoadEntryModal)}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 400, exit: 400 }}
      >
        <LoadEntryContainer
          toggleCancelModal={props.toggleCancelModal}
          toggleLoadEntryModal={props.toggleLoadEntryModal}
          setLoadEntryError={props.setLoadEntryError}
          isLoadEntryVisible={useSelector(getShowLoadEntryModal)}
        />
      </Slide>
      <CancelModal
        toggleLoadEntryModal={props.toggleLoadEntryModal}
        toggleCancelModal={props.toggleCancelModal}
      />
    </Fragment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
