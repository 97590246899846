// packages
import styled from 'styled-components';
import { CheckBox } from 'shamrock-clover-ui/dist/clover/components/CheckBox/CheckBox';
import { TextInput } from 'shamrock-clover-ui/dist/clover/components/TextInput/TextInput';
import { Icon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import Grid from '@material-ui/core/Grid';
import { MultilineTextInput } from 'shamrock-clover-ui/dist/clover/components/MultilineTextInput/MultilineTextInput';
import DatePickerField from '../../../components/datePickerField';

import { TextField as MUITextInput } from '@material-ui/core';
export const LoadEntryContent = styled.div`
  background-color: #ffffff;
  width: 845px;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const Completed = styled.div`
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: flex-start;
`;

export const CompletedText = styled.div`
  margin-left: 4px;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 600px) {
    width: auto;
    padding: 10px;
    margin-bottom: 30px;
  }
`;

export const LinkContainer = styled.div<{ disabled: boolean }>`
  width: 250px;
  margin-left: 542px;
  margin-bottom: 13px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
// props for when its put back on header
// position: fixed;
// right: 24px;
// visibility: ${props => (props.disabled ? 'hidden' : 'visible')};

export const Link = styled.div<{ disabled: boolean }>`
  color: ${props => (props.disabled ? '#CCC' : '#0091ea')};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};
  font-size: 14px;

  &:hover {
    color: ${props => (props.disabled ? '#CCC' : '#005C95')};
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  }
`;

export const CheckBoxStyled = styled(CheckBox)`
  margin-left: 64px;
  margin-top: 20px;
  margin-bottom: 52px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const IconContainer = styled(Icon)<{ isValid?: boolean }>`
  margin-left: ${props => (props.isValid ? '-5px' : 'inherit')}
  width: ${props => (!props.isValid ? '15px' : 'inherit')};
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const GridContainer = styled(Grid)`
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const MultipleGrid = styled(Grid)`
  display: flex;
  margin-left: 30px;
  width: 296px;
  margin-bottom: 30px;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

// TODO: This must be deleted once shamrock-clover-ui has been upgraded.
export const PhoneTextInputStyles = styled.div<{ error?: boolean | string }>`
  .phoneTextInput {
    font-family: inherit;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    border: 0;
    outline: 0;
    font-size: 16px;
    color: #666666;
    cursor: inherit;
    padding-top: 10px;
    background: transparent;
    transition: border-color 0.2s;
  }
  .phoneTextInput::placeholder {
    color: transparent;
  }
  .phoneTextInput:placeholder-shown ~ .form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
  }
  .phoneTextInput:focus ~ .form__label {
    pointer-events: none;
    position: absolute;
    top: 5px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #666666;
  }
  .phoneTextInput:focus:focus ~ .form__label {
    color: ${props => (props.error ? '#EF3824' : '#0091ea')};
  }
`;

export const TextInputStyled = styled(TextInput)<{
  width?: string;
  className?: string;
  label?: string;
  marginBottom?: string;
}>`
  margin: auto;
  width: ${props => (!props.width ? '296px' : props.width)};
  margin-left: ${props => (props.className === 'leftSmallInput' ? '64px' : '')};
  margin-right: ${props =>
    props.className === 'rightSmallInput' ? '-64px' : ''};
  margin-bottom: ${props =>
    !props.marginBottom ? '30px' : props.marginBottom};
  padding-top: ${props => (props.label === 'Time' ? '3px' : '')};
  input {
    font-size: ${props =>
      props.label === 'Time' ? '14px !important' : 'inherit'};
  }
`;

export const DatePickerStyled = styled(DatePickerField)`
  font-family: proxima-nova, arial, sans-serif;
  font-size: 14px !important;
  margin-top: 28px !important;
  width: 140px;
  margin-left: 64px !important;
  > div > input {
    padding: 0px 0px 14px 10px !important;
    color: #666666;
  }
  > div:before {
    border-bottom: 1px solid #cccccc !important;
  }
`;

export const MultilineTextInputStyled = styled(MultilineTextInput)<{
  width?: string;
  className?: string;
}>`
  margin-left: 64px;
  margin-right: 64px;
`;

export const SelectInputContainer = styled.div<{
  width?: string;
  className?: string;
  error?: boolean | string;
}>`
  margin: auto;
  width: ${props => (!props.width ? '296px' : props.width)};
  margin-left: ${props => (props.className === 'leftSmallInput' ? '64px' : '')};
  margin-top: 6px;
  > div > div {
    border-bottom: ${props =>
      props.error ? '1px solid #EF3824' : '1px solid #CCCCCC'};
  }
  > div {
    border-bottom: none;
  }
`;

export const ErrorText = styled.div`
  margin: 5px 0px 0px 10px;
  color: #ef3824;
  font-size: 0.75em;
  background-color: transparent;
`;

export const InputLabel = styled.label<{
  fontSize?: string;
}>`
  font-family: Proxima Nova;
  font-size: ${props => props.fontSize};
  color: #333333;
  display: block;
  margin-left: 75px;
  margin-right: 75px;
  margin-bottom: 0px;
`;

export const AddressMenu = styled.div`
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 24%);
  max-height: 250px;
  overflow: auto;
  width: 296px;
  margin-left: 63px;
  margin-top: -30px;
`;

export const Summary = styled.div<{
  disabled: boolean;
}>`
  height: 52px;
  width: 100%;
  position: fixed;
  z-index: 9999;
  pointer-events: ${props =>
    props.disabled ? 'none !important' : 'all !important'};
`;

export const SummaryHeader = styled.b`
  width: 200px;
`;

export const Details = styled.div`
  pointer-events: all;
`;

export const AccordionContainer = styled.div`
  pointer-events: none;
`;

export const StyledSelect = styled(MUITextInput)<{
  width?: string;
  className?: string;
  error?: boolean | string;
}>`
  width: ${props => (!props.width ? '296px' : props.width)};
  margin-left: 64px !important;
  margin-top: 12.5px !important;

  > div > input {
    padding: 0px 0px 14px 10px !important;
    color: #666666;
  }
  > div:before {
    border-bottom: 1px solid #cccccc !important;
  }
}
`;

// > div > div {
//   border-bottom: ${props =>
//     props.error ? '1px solid #EF3824' : '1px solid #CCCCCC'};
// }
// > div {
//   border-bottom: none;
// }
