import Service from './Service';
import { Invoice } from '../store/accounting/accountingReduxTypes';

const Config = require('Config');

export interface InvoiceServiceAPIResponse {
  data?: Invoice[];
  count: number;
  csv?: string;
  summaryBilling?: boolean;
  pastDue?: number;
}
class InvoiceService extends Service {
  constructor() {
    super(Config.invoiceServiceUrl);
  }

  getInvoices = async (params: {
    id?: string;
    filter?: string;
    data?: boolean;
    limit?: number;
    page?: number;
    csv?: boolean;
    charges?: boolean;
  }) => {
    try {
      params.charges = true;
      const response = await this.axios.get<InvoiceServiceAPIResponse>(
        'summary',
        { params }
      );
      if (response.status != 200) {
        throw Error(response.statusText);
      }
      return Promise.resolve(response.data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };

  getInvoicesCsv = async () => {
    try {
      const data = await this.getInvoices({ csv: true });
      const csvString = data?.csv ? data.csv : '';
      const options = { type: 'text/plain', lastModified: Date.now() };
      const csvBlob = new Blob([csvString], options);
      return Promise.resolve(csvBlob);
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default new InvoiceService();
