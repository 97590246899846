import './requestQuoteContainer.scss';
import React, { useState, useRef } from 'react';
import RequestQuote from '../requestQuote/requestQuote';
import Spinner from '../../../components/Spinner';
import styled from 'styled-components';
import CancelModal from '../cancel/cancelModal';

interface Props {
  toggleRequestQuoteModal: Function;
  isRequestQuoteVisible: boolean;
  setRequestQuoteBanner: Function;
}

export const ErrorLabel = styled.label`
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  padding-top: 30px;
`;

const RequestQuoteContainer: React.FC<Props> = ({
  toggleRequestQuoteModal,
  isRequestQuoteVisible,
  setRequestQuoteBanner
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  if (isRequestQuoteVisible) {
    const underLay = wrapperRef.current;
    if (underLay) {
      setTimeout(() => {
        underLay.style.backgroundColor = '#00000080';
      }, 400);
    }
  } else {
    const underLay = wrapperRef.current;
    if (underLay) {
      underLay.style.backgroundColor = 'transparent';
    }
  }

  const [isLoading, toggleIsLoading] = useState<boolean>(false);
  const [showCancelModal, toggleCancelModal] = useState<boolean>(false);

  return (
    <>
      <div className="underlay" ref={wrapperRef}>
        <div className="requestQuoteContainer">
          <RequestQuote
            toggleCancelModal={toggleCancelModal}
            toggleRequestQuoteModal={toggleRequestQuoteModal}
            toggleIsLoading={toggleIsLoading}
            setRequestQuoteBanner={setRequestQuoteBanner}
          />
          {isLoading ? (
            <div className="requestQuoteWaiting">
              <Spinner />
              <ErrorLabel>Loading...</ErrorLabel>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <CancelModal
        toggleRequestQuoteModal={toggleRequestQuoteModal}
        toggleCancelModal={toggleCancelModal}
        showCancelModal={showCancelModal}
      />
    </>
  );
};

export default RequestQuoteContainer;
