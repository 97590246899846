// packages
import React from 'react';
import Autosuggest from 'react-autosuggest';
import styled from 'styled-components';

// util
import {
  getSuggestionValue,
  renderSuggestion
} from './SuggestionSelectorField.util';

// props
interface SuggestionSelectorFieldContainerProps {
  inputPadding?: string;
  highlightedSuggestionColor?: string;
  inputColor?: string;
  inputBorderBottom?: string;
  inputBorder?: string;
  error?: boolean;
}

interface Props extends SuggestionSelectorFieldContainerProps {
  helperText: string;
  name: string;
  onInputChange: (name: string, value: string) => void;
  onSuggestionSelected?: (suggestion: any) => void;
  onSuggestionsClearRequested: () => void;
  onSuggestionsFetchRequested: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder: string;
  suggestions: any;
  value: string;
  visible: boolean;
}

// styles
export const DEFAULT_THEME = {
  inputPadding: '19px 10px 6px 10px',
  highlightedSuggestionColor: '#cccccc',
  inputColor: '#666666',
  inputBorderBottom: '1px solid #cccccc',
  inputBorder: 'unset'
};

const SuggestionSelectorFieldContainer = styled.div<
  SuggestionSelectorFieldContainerProps
>`
  .react-autosuggest__container {
    width: 100%;
  }
  .react-autosuggest__input:focus-visible,
  .react-autosuggest__container:focus-visible {
    outline: unset;
  }
  .react-autosuggest__input {
    padding: ${props => props?.inputPadding || DEFAULT_THEME.inputPadding};
    color: ${props => props?.inputColor || DEFAULT_THEME.inputColor};
    border: ${props => props?.inputBorder || DEFAULT_THEME.inputBorder};
    border-bottom: ${props =>
      props?.inputBorderBottom || DEFAULT_THEME.inputBorderBottom};
    ${props => (props?.error ? 'border-bottom: 1px solid #ef3824;' : '')}
    width: 100%;
  }
  .react-autosuggest__suggestion {
    display: block;
    width: 100%;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    cursor: pointer;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .react-autosuggest__suggestions-container--open {
    background-color: white;
    position: absolute;
    z-index: 1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: ${props =>
      props?.highlightedSuggestionColor ||
      DEFAULT_THEME.highlightedSuggestionColor};
  }
  .react-autosuggest__suggestions-list {
    padding-inline-start: 0;
    margin-top: 10px;
    width: 220px;
    margin-bottom: 0;
  }
`;
const HelperText = styled.label<{ error?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  margin-left: 10px;
  color: ${props => (props?.error ? '#ef3824' : '#333333')};
`;

export const SuggestionSelectorField: React.FC<Props> = ({
  helperText,
  highlightedSuggestionColor,
  inputBorder,
  inputBorderBottom,
  inputColor,
  inputPadding,
  name,
  onInputChange,
  onSuggestionSelected = Function.prototype,
  onSuggestionsClearRequested,
  onSuggestionsFetchRequested,
  onFocus,
  onBlur,
  placeholder,
  suggestions,
  value,
  visible = true,
  error
}) => {
  if (!visible) {
    return null;
  }

  const handleOnInputChange = (value: string) => onInputChange(name, value);

  const handleOnSuggestionSelected = (
    _: React.ChangeEvent<HTMLInputElement>,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any
  ) =>
    onSuggestionSelected({
      suggestion,
      suggestionValue,
      suggestionIndex,
      sectionIndex,
      method
    });

  return (
    <SuggestionSelectorFieldContainer
      highlightedSuggestionColor={highlightedSuggestionColor}
      inputBorder={inputBorder}
      inputBorderBottom={inputBorderBottom}
      inputColor={inputColor}
      inputPadding={inputPadding}
      error={error}
    >
      <Autosuggest
        getSuggestionValue={getSuggestionValue}
        inputProps={{
          onChange: (_, { newValue }) => handleOnInputChange(newValue),
          placeholder,
          value: value || '',
          onBlur,
          onFocus
        }}
        onSuggestionSelected={handleOnSuggestionSelected as any}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        renderSuggestion={renderSuggestion}
        suggestions={suggestions}
      />
      <HelperText error={error}>{helperText}</HelperText>
    </SuggestionSelectorFieldContainer>
  );
};

export default SuggestionSelectorField;
