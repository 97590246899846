import * as React from 'react';
import { Flex } from '../../components/Flex';
import InTransit from '../../assets/images/InTransit.svg';
import DetailStop from '../../components/detail/DetailStop';
import { LoadTrackingDetails } from './loadTrackingTypes';
import LoadTrackingSignUpButton from './LoadTrackingSignUpButton';
import { monthDayTimeFormat } from '../../utilities/dateHelper';
import { useLoadTrackingQueryParams } from '../../hooks/routeHooks';
import {
  DetailsContainer,
  OrderIdText,
  LastKnownText,
  LoadStatusText,
  HeaderDetailContainer,
  ReferenceContainer,
  ReferenceHeaderText,
  LeftText,
  RightText,
  LastKnownLocationTextContainer
} from './LoadTrackingStyles';

interface Props {
  details: LoadTrackingDetails;
  loadStatus: string;
}

const getStatusColor = (value: string) => {
  return value.includes('Delayed') ? 'red' : '';
};

const LoadTrackingDetails: React.FC<Props> = ({ details, loadStatus }) => {
  const { queryParams } = useLoadTrackingQueryParams();

  return (
    <DetailsContainer
      width="50vw"
      flexDirection="column"
      isFullDetails={queryParams.trackingId}
    >
      <HeaderDetailContainer
        width="100%"
        alignItems="center"
        padding="24px 24px 24px 54px"
      >
        <img src={InTransit} style={{ marginRight: '18px' }} />
        <Flex flexDirection="column">
          <OrderIdText>Order {details.orderId}</OrderIdText>
          <LoadStatusText color={getStatusColor(loadStatus)}>
            {loadStatus}
          </LoadStatusText>
        </Flex>
        {queryParams.referenceNumber && details.rawCallin && (
          <LastKnownLocationTextContainer
            flexDirection="column"
            margin="0px 0px 0px auto"
          >
            <LastKnownText>Last Known Location:</LastKnownText>
            <LoadStatusText>
              {details.rawCallin.city_name}, {details.rawCallin.state},{' '}
              {monthDayTimeFormat(details.rawCallin.call_date_time)}
            </LoadStatusText>
          </LastKnownLocationTextContainer>
        )}
      </HeaderDetailContainer>
      <Flex padding="0px 26px 0px 54px">
        <ReferenceContainer
          flexDirection="column"
          width="100%"
          padding="18px 0px"
        >
          <ReferenceHeaderText>Reference & Contact</ReferenceHeaderText>
          <Flex padding="1.5px 0px">
            <LeftText>REFERENCE</LeftText>
            <RightText>{details.referenceId || '--'}</RightText>
          </Flex>
          <Flex padding="1.5px 0px">
            <LeftText>ADDITIONAL REFERENCE</LeftText>
            <RightText>{details.consigneeRefno || '--'}</RightText>
          </Flex>
          <Flex padding="1.5px 0px">
            <LeftText>EMAIL</LeftText>
            <RightText>{details.accountManagerEmail || '--'}</RightText>
          </Flex>
          <Flex padding="1.5px 0px">
            <LeftText>PHONE</LeftText>
            <RightText>{details.phone || '--'}</RightText>
          </Flex>
        </ReferenceContainer>
      </Flex>
      <Flex flexDirection="column" padding="0px 32px 0px 32px">
        {details.rawStops.map((stop, index) => {
          return (
            <DetailStop
              key={index}
              stop={stop}
              stops={details.rawStops}
              callIn={details.rawCallin}
            />
          );
        })}
      </Flex>
      <Flex
        height="125px"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <LoadTrackingSignUpButton details={true} text="" />
      </Flex>
    </DetailsContainer>
  );
};

export default LoadTrackingDetails;
