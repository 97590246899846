import ReactGA from 'react-ga';
import { UserService } from '../services';

const trackAnalyticsEvent = (
  category: string,
  action: string,
  label?: string
) => {
  const currentUser = UserService.getCurrentUser();

  if (currentUser) {
    ReactGA.set({
      dimension1: currentUser._id,
      dimension2: currentUser._id,
      dimension3: currentUser._id
    });
    ReactGA.event({
      category: category,
      action: action,
      label: label ? label : currentUser._id
    });
  }
};

export default trackAnalyticsEvent;
