import Service from '../Service';
import { buildURLQuery } from '../../utilities';
import { Order } from '../../types/orderTypes';

const Config = require('Config');

export interface OrdersPayload {
  orders: Order[];
  count: number;
}

export interface FilesPayload {
  id: string;
  companyId: string;
  appId: string;
  imageCount: number;
  description: string;
  scanData: {
    millis: number;
    storeData: boolean;
    storeTime: boolean;
  };
  documentTypeId: string;
}

interface SummarySearchObject {
  filterType?: string[];
  searchString?: string;
  originString?: string;
  destinationString?: string;
  orderBy?: any;
}

interface QueryParams {
  type: string;
  limit: number;
  page: number;
  criteria?: string;
  filterType?: string;
  origin?: string;
  destination?: string;
  orderBy?: string;
  isAscending?: boolean;
}

export class OrderService extends Service {
  constructor() {
    super(Config.orderServiceUrl);
  }

  getOrderDetails = async (orderId: string) => {
    try {
      const response = await this.axios.get<Order>(`${orderId}\?charges=true`);
      if (response.status != 200) {
        throw Error(response.statusText);
      }
      return Promise.resolve(response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getGeolocation = async (address: string) => {
    const {
      data: { latitude, longitude }
    } = await this.axios.get<{ latitude: number; longitude: number }>(
      `location`,
      {
        params: { address }
      }
    );
    return [latitude, longitude];
  };

  getFilesForOrder = async (orderId: string) => {
    const { data } = await this.axios.get<FilesPayload[]>(`${orderId}/files`);
    return data;
  };

  downloadFile = async (filePath: string, fileName: string) => {
    const encodedFilePath = encodeURI(filePath);
    const response = await this.axios.get(`files/${encodedFilePath}`);
    if (response.status != 200) {
      throw Error(response.statusText);
    }
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${response.data}`;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
  };

  async getOrderSummary(
    page: number,
    limit: number,
    searchObj: SummarySearchObject
  ) {
    const {
      filterType,
      searchString,
      originString,
      destinationString,
      orderBy
    } = searchObj;

    const params = {
      charges: true,
      type: 'ordersummary',
      limit: limit,
      page: page,
      ...(filterType &&
        filterType.length > 0 && { filterType: filterType.join(',') }),
      ...(searchString && { criteria: searchString }),
      ...(originString && { origin: originString }),
      ...(destinationString && { destination: destinationString }),
      ...(orderBy &&
        orderBy.column && {
          orderBy: orderBy.column,
          isAscending: orderBy.isAscending
        })
    };

    const queryParams = buildURLQuery(params);
    const urlString = `summary?${queryParams.toString()}`;

    const response = await this.axios.get<OrdersPayload>(urlString);

    if (response.status != 200) {
      throw Error(response.statusText);
    }
    return response.data;
  }

  async getOrdersCSV(searchObj: SummarySearchObject) {
    const {
      filterType,
      searchString,
      originString,
      destinationString,
      orderBy
    } = searchObj;

    const params = {
      charges: true,
      type: 'ordersummary',
      ...(filterType &&
        filterType.length > 0 && { filterType: filterType.join(',') }),
      ...(searchString && { criteria: searchString }),
      ...(originString && { origin: originString }),
      ...(destinationString && { destination: destinationString }),
      ...(orderBy &&
        orderBy.column && {
          orderBy: orderBy.column,
          isAscending: orderBy.isAscending
        }),
      csv: true
    };

    const queryParams = buildURLQuery(params);
    const urlString = `summary?${queryParams.toString()}`;

    const response = await this.axios.get<{ csv: string }>(urlString);

    if (response.status != 200) {
      throw Error(response.statusText);
    }
    return response.data;
  }
}

export default new OrderService();
