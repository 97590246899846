import Service from './Service';

const Config = require('Config');

class NotificationService extends Service {
  constructor() {
    super(Config.notificationServiceUrl);
  }

  async updateGlobalNotificationsEnabled(value: boolean) {
    const response = await this.axios.put(`globalEnabled`, {
      globalNotificationsEnabled: value
    });
    return response.data;
  }

  async updateGlobalUsers(users: string[]) {
    const response = await this.axios.put(`globalList`, {
      globalContacts: users
    });
    return response.data;
  }

  async updateOrderNotificationsEnabled(orderId: string, value: boolean) {
    const response = await this.axios.put(`order/${orderId}/orderEnabled`, {
      isOrderNotificationEnabled: value
    });
    return response.data;
  }

  async updateOrderUsers(orderId: string, users: string[]) {
    const response = await this.axios.put(`order/${orderId}/orderList`, {
      contacts: users
    });
    return response.data;
  }

  async getNotificationSummary() {
    const response = await this.axios.get(`summary`);
    return response.data;
  }

  async optOut(email: string, value: boolean) {
    const response = await this.axios.put(`contact/${email}`, {
      optOut: value
    });
    return response.data;
  }
}

export default new NotificationService();
