import {
  AccountingState,
  AccountingActionTypes,
  Invoice
} from './accountingReduxTypes';
import {
  ACCOUNTING_FILTER_COUNT_LOADING,
  ACCOUNTING_ERROR,
  INVOICE_DATA,
  FILTER_COUNT,
  ACCOUNTING_TABLE_LOADING,
  ACCOUNTING_DETAILS_LOADING,
  INVOICE_ORDER_DETAILS,
  INVOICE_ORDER_FILES,
  ACCOUNTING_SUMMARY_BILLING,
  ACCOUNTING_TABLE_ERROR,
  ACCOUNTING_DETAILS_ERROR
} from './accountingActions';

export const accountingState: AccountingState = {
  invoices: [],
  invoiceOrderDetails: null,
  invoiceOrderFiles: [],
  filterCounts: {
    '0-30': { count: 0, pastDue: 0 },
    '31-61': { count: 0, pastDue: 0 },
    '61': { count: 0, pastDue: 0 },
    total: { count: 0, pastDue: 0 }
  },
  totalInvoices: 0,
  page: 1,
  pageSize: 25,
  isLoading: false,
  isTableLoading: false,
  isTableError: false,
  isDetailsError: false,
  isDetailsLoading: false,
  error: '',
  summaryBilling: false
};

const accountingReducer = (
  state = accountingState,
  action: AccountingActionTypes
): AccountingState => {
  switch (action.type) {
    case ACCOUNTING_DETAILS_LOADING:
      return {
        ...state,
        isDetailsLoading: action.value
      };
    case ACCOUNTING_FILTER_COUNT_LOADING:
      return {
        ...state,
        isLoading: action.value
      };
    case ACCOUNTING_TABLE_LOADING:
      return {
        ...state,
        isTableLoading: action.value
      };
    case ACCOUNTING_TABLE_ERROR:
      return {
        ...state,
        isTableError: action.value
      };
    case ACCOUNTING_ERROR:
      return {
        ...state,
        error: action.value
      };
    case INVOICE_ORDER_DETAILS:
      return {
        ...state,
        invoiceOrderDetails: action.value
      };
    case ACCOUNTING_DETAILS_ERROR:
      return {
        ...state,
        isDetailsError: action.value
      };
    case INVOICE_ORDER_FILES:
      return {
        ...state,
        invoiceOrderFiles: action.value
      };
    case INVOICE_DATA:
      return {
        ...state,
        invoices: action.value.data as Invoice[],
        totalInvoices: action.value.count
      };
    case FILTER_COUNT:
      return {
        ...state,
        filterCounts: { ...state.filterCounts, ...action.value }
      };
    case ACCOUNTING_SUMMARY_BILLING:
      return {
        ...state,
        summaryBilling: action.value
      };
    default:
      return state;
  }
};

export default accountingReducer;
