// packages
import { TextInput } from 'shamrock-clover-ui/dist/clover/components/TextInput/TextInput';
import { PhoneFormatTextInput } from 'shamrock-clover-ui/dist/clover/components/PhoneFormatTextInput/PhoneFormatTextInput';
import styled from 'styled-components';
import { CheckBox } from 'shamrock-clover-ui/dist/clover/components/CheckBox/CheckBox';
import { FilterInput } from 'shamrock-clover-ui/dist/clover/components/FilterInput/FilterInput';

export const BackDrop = styled.div`
  align-items: center;
  background-color: #00000080;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  visibility: visible;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 99999;
`;

export const StyledDialog = styled.div<{
  minHeight: string;
  transitioning: boolean;
}>`
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  padding: 24px;
  width: 1156px;
  color: #333333;
  opacity: 1;
  box-sizing: border-box;

  min-height: ${props => props.minHeight};
  transition: min-height 0.5s;

  div {
    opacity: ${props => (props.transitioning ? '0' : '1')};
    transition: opacity 0.5s ease-in-out;
  }
`;

export const StyledFilterInput = styled(FilterInput)`
  width: 400px;
  max-width: 400px;

  div:first-child {
    width: 25px;
    height: 25px;
  }

  input {
    width: 350px;
    max-width: 350px;
  }
`;

export const Header = styled.div`
  color: #333;
  font-size: 36px;
  letter-spacing: -0.72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  > *:first-child {
    margin-right: 10px;
  }
`;

export const SubHeader = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Body = styled.div``;

export const PrimaryText = styled.span`
  color: #333333;
  font-weight: 900;
  font-size: 36px;
  line-height: 29.23px;
`;
export const EmptyViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
`;
export const EmptyViewText = styled.div<{
  fontSize: string;
  marginTop: string;
}>`
  color: #666666;
  font-size: ${({ fontSize }) => fontSize};
  margin-top: ${({ marginTop }) => marginTop};
  font-weight: 400;
  line-height: normal;
  padding: 5px;
`;

export const SpinnerContainer = styled.div`
  display: block;
  width: -webkit-fill-available;
  height: max-content;
  padding-top: 170px;
`;

// Address Form Styled
export const FormContainer = styled.div`
  margin-top: 24px;
  background-color: white;
`;

export const TextInputStyled = styled(TextInput)<{
  width?: string;
  className?: string;
  label?: string;
  marginBottom?: string;
}>`
  margin: 16px;
`;

export const AddressMenu = styled.div`
  position: absolute;
  z-index: 1;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 12%), 0px 2px 2px 0px rgb(0 0 0 / 24%);
  max-height: 250px;
  overflow: auto;
  width: 275px;
  margin-left: 17px;
  margin-top: -15px;
`;

export const StyledPhoneFormatTextInput = styled(PhoneFormatTextInput)<{
  width?: string;
  marginBottom?: string;
}>`
  margin: 16px;
`;

export const ErrorText = styled.div`
  margin: 5px 0px 0px 10px;
  color: #ef3824;
  font-size: 0.75em;
  background-color: transparent;
`;

export const SelectInputContainer = styled.div<{
  error?: boolean | string;
  isMenuSmall?: boolean;
}>`
  margin: 16px;
  margin-top: 22px;

  > div > div {
    border-bottom: ${props =>
      props.error ? '1px solid #EF3824' : '1px solid #CCCCCC'};
  }
  > div {
    border-bottom: none;
  }
  ${props =>
    props.isMenuSmall
      ? `.SingleSelectMenuContainer {
      width: 300px;
      height: 220px;
      left: 150px;
    }`
      : ''}
`;

export const CheckBoxStyled = styled(CheckBox)`
  margin: 16px;
  margin-bottom: 24px;
`;

//Modals

export const StyledModal = styled.div`
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 10px 0 rgba(0, 0, 0, 0.16);
  padding: 24px;
  width: 600px;
  color: #333333;
  opacity: 1;
  box-sizing: border-box;
  padding: 64px;
`;

export const ModalTitle = styled.div`
  color: #333333;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  margin-bottom: 32px;
`;

export const ModalText = styled.div`
  color: #333333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ButtonContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
`;
