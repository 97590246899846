import React, { FunctionComponent, useState, useEffect, useRef } from 'react';

// components
import RequestQuoteHeaderSection from './requestQuoteHeaderSection/requestQuoteHeaderSection';
import DateLocationForm from './DateLocationForm';
import LoadDetailsForm from './DetailsForm';
import { Icon as CloverIcon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { SmrAccordion } from 'shamrock-clover-ui/dist/clover/components/Accordion/Accordion';

import {
  DateLocationFormData,
  LoadDetailsFormData,
  QuoteEmailBody
} from '../types/requestQuoteTypes';
import * as FirebaseUtils from '../../../utilities/firebaseUtils';
import QuoteEmailService from '../../../services/QuoteEmailService';

import { useDispatch } from 'react-redux';
import {
  setDestinationFilterString,
  setOriginFilterString
} from '../../../store/orders/ordersActions';

// styles
import {
  RequestQuoteContent,
  AccordionContainer,
  Summary,
  SummaryHeader,
  MainText,
  Details,
  Completed,
  CompletedText
} from './requestQuoteStyles';

const emailService: QuoteEmailService = new QuoteEmailService();
interface Props {
  toggleCancelModal: Function;
  toggleRequestQuoteModal: Function;
  toggleIsLoading: (value: boolean) => void;
  setRequestQuoteBanner: Function;
}

interface DateLocationErrorFormData {
  date?: string;
  pickupLocation?: string;
  deliveryLocation?: string;
}
interface LoadDetailsErrorFormData {
  commodity?: string;
  equipment?: string;
  dollarValue?: string;
  weight?: string;
  dimensions?: string;
  flatbedType?: string;
  tarpDetails?: string;
  specifyFlatbed?: string;
  tempDetails?: string;
}

type DateLocationFormKeys = keyof DateLocationFormData;
type LoadDetailsFormKeys = keyof LoadDetailsFormData;
type DateLocationErrorFormDataKey = keyof DateLocationErrorFormData;
type LoadDetailsErrorFormDataKey = keyof LoadDetailsErrorFormData;

const RequestQuote: FunctionComponent<Props> = ({
  toggleCancelModal,
  toggleRequestQuoteModal,
  toggleIsLoading,
  setRequestQuoteBanner
}) => {
  const dispatch = useDispatch();

  const [pickupComplete, setPickupComplete] = useState<boolean>(false);
  const [loadDetailsComplete, setLoadDetailsComplete] = useState<boolean>(
    false
  );

  const [accordionIndex, setAccordionIndex] = useState<number>(0);
  const prevAccordionIndexRef = useRef<number | undefined>();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const [dateLocationFormData, setDateLocationFormData] = useState<
    DateLocationFormData
  >({
    date: { errorMessage: '', value: '' },
    pickupLocation: { errorMessage: '', value: '' },
    deliveryLocation: { errorMessage: '', value: '' }
  });

  const [loadDetailsFormData, setLoadDetailsFormData] = useState<
    LoadDetailsFormData
  >({
    commodity: { errorMessage: '', value: '' },
    hazmat: { errorMessage: '', value: false },
    equipment: { errorMessage: '', value: '' },
    dollarValue: { errorMessage: '', value: '' },
    weight: { errorMessage: '', value: '' },
    dimensions: { errorMessage: '', value: '' },
    comments: { errorMessage: '', value: '' },
    flatbedType: { errorMessage: '', value: '' },
    specifyFlatbed: { errorMessage: '', value: '' },
    tarpDetails: { errorMessage: '', value: '' },
    tempDetails: { errorMessage: '', value: '' }
  });

  const checkDateLocationRequiredError = () => {
    let fields: DateLocationFormKeys[] = [
      'date',
      'pickupLocation',
      'deliveryLocation'
    ];
    let errorMessages: DateLocationErrorFormData = {};

    fields.forEach((key: DateLocationFormKeys) => {
      if (dateLocationFormData[key].value === '') {
        errorMessages[key as DateLocationErrorFormDataKey] = 'Required field';
      }
    });

    setDateLocationFormData({
      ...dateLocationFormData,
      date: {
        value: dateLocationFormData.date.value,
        errorMessage: errorMessages.date
      },
      pickupLocation: {
        value: dateLocationFormData.pickupLocation.value,
        errorMessage: errorMessages.pickupLocation
      },
      deliveryLocation: {
        value: dateLocationFormData.deliveryLocation.value,
        errorMessage: errorMessages.deliveryLocation
      }
    });
  };

  const checkLoadDetailsRequiredError = () => {
    let fields: LoadDetailsFormKeys[] = [
      'commodity',
      'equipment',
      'dollarValue',
      'weight',
      'dimensions',
      'flatbedType',
      'tarpDetails',
      'specifyFlatbed',
      'tempDetails'
    ];
    let errorMessages: LoadDetailsErrorFormData = {};

    fields.forEach((key: LoadDetailsFormKeys) => {
      if (loadDetailsFormData[key].value === '') {
        errorMessages[key as LoadDetailsErrorFormDataKey] = 'Required field';
      }
    });

    setLoadDetailsFormData({
      ...loadDetailsFormData,
      commodity: {
        value: loadDetailsFormData.commodity.value,
        errorMessage: errorMessages.commodity
      },
      equipment: {
        value: loadDetailsFormData.equipment.value,
        errorMessage: errorMessages.equipment
      },
      dollarValue: {
        value: loadDetailsFormData.dollarValue.value,
        errorMessage: errorMessages.dollarValue
      },
      weight: {
        value: loadDetailsFormData.weight.value,
        errorMessage: errorMessages.weight
      },
      dimensions: {
        value: loadDetailsFormData.dimensions.value,
        errorMessage: errorMessages.dimensions
      },
      flatbedType: {
        value: loadDetailsFormData.flatbedType.value,
        errorMessage: errorMessages.flatbedType
      },
      tarpDetails: {
        value: loadDetailsFormData.tarpDetails.value,
        errorMessage: errorMessages.tarpDetails
      },
      specifyFlatbed: {
        value: loadDetailsFormData.specifyFlatbed.value,
        errorMessage: errorMessages.specifyFlatbed
      },
      tempDetails: {
        value: loadDetailsFormData.tempDetails.value,
        errorMessage: errorMessages.tempDetails
      }
    });
  };

  const checkDateLocationEmpty = () => {
    let isEmpty = true;
    const fields: DateLocationFormKeys[] = [
      'date',
      'pickupLocation',
      'deliveryLocation'
    ];

    fields.forEach((key: DateLocationFormKeys) => {
      if (dateLocationFormData[key].value !== '') {
        isEmpty = false;
      }
    });
    return isEmpty;
  };

  const checkLoadDetailsEmpty = () => {
    let isEmpty = true;
    const fields: LoadDetailsFormKeys[] = [
      'commodity',
      'equipment',
      'dollarValue',
      'weight',
      'dimensions',
      'flatbedType',
      'tarpDetails',
      'specifyFlatbed',
      'tempDetails'
    ];
    fields.forEach((key: LoadDetailsFormKeys) => {
      if (loadDetailsFormData[key].value !== '') {
        isEmpty = false;
      }
    });
    return isEmpty;
  };

  useEffect(() => {
    if (prevAccordionIndexRef.current === 0) {
      checkDateLocationRequiredError();
    }
    if (prevAccordionIndexRef.current === 1) {
      checkLoadDetailsRequiredError();
    }

    prevAccordionIndexRef.current = accordionIndex;
  }, [accordionIndex]);

  const checkDateLocationComplete = () => {
    if (dateLocationFormData.date.value === '') {
      return false;
    }
    if (dateLocationFormData.pickupLocation.value === '') {
      return false;
    }
    if (dateLocationFormData.deliveryLocation.value === '') {
      return false;
    }
    return true;
  };

  const checkLoadDetailsComplete = () => {
    if (loadDetailsFormData.commodity.value === '') {
      return false;
    }
    if (loadDetailsFormData.equipment.value === '') {
      return false;
    }
    if (loadDetailsFormData.dollarValue.value === '') {
      return false;
    }
    if (loadDetailsFormData.weight.value === '') {
      return false;
    }
    if (loadDetailsFormData.dimensions.value === '') {
      return false;
    }
    if (loadDetailsFormData.equipment.value === 'Flatbed') {
      if (loadDetailsFormData.flatbedType.value === '') return false;
      if (loadDetailsFormData.tarpDetails.value === '') return false;
      if (
        loadDetailsFormData.flatbedType.value === 'Other' &&
        loadDetailsFormData.specifyFlatbed.value === ''
      ) {
        return false;
      }
    }
    if (
      loadDetailsFormData.equipment.value === 'Refrigerated' &&
      loadDetailsFormData.tempDetails.value === ''
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setPickupComplete(checkDateLocationComplete());
  }, [dateLocationFormData]);

  useEffect(() => {
    setLoadDetailsComplete(checkLoadDetailsComplete());
  }, [loadDetailsFormData]);

  const disableSubmit = () => {
    if (pickupComplete && loadDetailsComplete) {
      return false;
    }
    return true;
  };

  const showModalOnCancel = () => {
    if (!checkLoadDetailsEmpty() || !checkDateLocationEmpty()) {
      return true;
    }
    return false;
  };

  const sendQuoteEmail = async () => {
    toggleIsLoading(true);
    const flatbedType =
      loadDetailsFormData.flatbedType.value === 'Other'
        ? `Other - ${loadDetailsFormData.specifyFlatbed.value}`
        : loadDetailsFormData.flatbedType.value;
    const emailRequestQuoteBody: QuoteEmailBody = {
      date: dateLocationFormData.date.value,
      pickupLocation: dateLocationFormData.pickupLocation.value,
      deliveryLocation: dateLocationFormData.deliveryLocation.value,
      commodity: loadDetailsFormData.commodity.value,
      hazmat: loadDetailsFormData.hazmat.value,
      value: loadDetailsFormData.dollarValue.value,
      equipment: loadDetailsFormData.equipment.value,
      weight: loadDetailsFormData.weight.value,
      dimensions: loadDetailsFormData.dimensions.value,
      temperature: loadDetailsFormData.tempDetails.value,
      flatbedType: flatbedType,
      tarp: loadDetailsFormData.tarpDetails.value,
      comment: loadDetailsFormData.comments.value
    };

    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.REQUEST_QUOTE_MODAL,
      {
        description: 'Send Quote to Rep',
        origin: dateLocationFormData.pickupLocation.value,
        destination: dateLocationFormData.deliveryLocation.value,
        equipment: loadDetailsFormData.equipment.value,
        value: loadDetailsFormData.dollarValue.value
      }
    );

    if (await emailService.sendRequestQuoteEmail(emailRequestQuoteBody)) {
      setRequestQuoteBanner({
        isVisible: true,
        isError: false,
        value: 'Quote submitted.'
      });
    } else {
      setRequestQuoteBanner({
        isVisible: true,
        isError: true,
        value: 'Quote submission failed. Please try again.'
      });
    }

    dispatch(setOriginFilterString(''));
    dispatch(setDestinationFilterString(''));
    toggleIsLoading(false);
    toggleRequestQuoteModal(false);
  };

  const handleOnSendQuote = async () => {
    sendQuoteEmail();
  };

  return (
    <>
      <RequestQuoteContent>
        <RequestQuoteHeaderSection
          toggleCancelModal={toggleCancelModal}
          toggleRequestQuoteModal={toggleRequestQuoteModal}
          showModalOnCancel={showModalOnCancel()}
          isSubmitButtonDisabled={disableSubmit()}
          onSubmit={handleOnSendQuote}
        />
        <AccordionContainer>
          <MainText>
            Provide your shipment details, and we’ll get back to you soon with a
            quote.
          </MainText>
          <SmrAccordion
            items={[
              {
                summary: (
                  <>
                    <Summary disabled={accordionIndex === 0 || isMenuOpen} />
                    <SummaryHeader>DATE & LOCATION</SummaryHeader>
                    {pickupComplete ? (
                      <Completed>
                        <CloverIcon
                          icon="checkGreen"
                          size="16"
                          margin-right="4px"
                        ></CloverIcon>
                        <CompletedText>Complete</CompletedText>
                      </Completed>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                details: (
                  <Details>
                    <DateLocationForm
                      formData={dateLocationFormData}
                      setFormData={setDateLocationFormData}
                      setIsMenuOpen={setIsMenuOpen}
                    />
                  </Details>
                ),
                width: '100%',
                detailsHeight: 'fit-content',
                onClick: () => {
                  setAccordionIndex(0);
                }
              },
              {
                summary: (
                  <>
                    <Summary disabled={accordionIndex === 1 || isMenuOpen} />
                    <SummaryHeader>LOAD DETAILS</SummaryHeader>
                    {loadDetailsComplete ? (
                      <Completed>
                        <CloverIcon
                          icon="checkGreen"
                          size="16"
                          margin-right="4px"
                        ></CloverIcon>
                        <CompletedText>Complete</CompletedText>
                      </Completed>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                details: (
                  <Details>
                    <LoadDetailsForm
                      formData={loadDetailsFormData}
                      setFormData={setLoadDetailsFormData}
                    />
                  </Details>
                ),
                width: '100%',
                detailsHeight: 'fit-content',
                onClick: () => {
                  setAccordionIndex(1);
                }
              }
            ]}
            noGutter
            hideCollapsedIcon
            initExpandedIndex={'0' as any}
          />
        </AccordionContainer>
      </RequestQuoteContent>
    </>
  );
};

export default RequestQuote;
