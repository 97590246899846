import React from 'react';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import {
  BackDrop,
  StyledModal,
  ModalTitle,
  ButtonContainer,
  ModalText
} from './AddressBookStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAddressesData,
  getDeleteModalOpen,
  getEditAddressIndex
} from '../../store/addressBook/addressBookSelectors';
import {
  postAddressBook,
  setAddressBookSnackbar,
  setDeleteModalOpen,
  setHoldAddress
} from '../../store/addressBook/addressBookActions';
import UserService from '../../services/user/UserService';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

const DeleteModal: React.FC = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(getDeleteModalOpen);
  const addressBook = useSelector(getAddressesData);
  const index = useSelector(getEditAddressIndex);

  const handleDelete = () => {
    dispatch(setDeleteModalOpen(false));
    //throw error if delete fails but still close the modal
    //dispatch(setAddressBookSnackbar(true, true, 'Error deleting address.'));

    let newAddressBook = addressBook;
    if (!newAddressBook.addresses) {
      return;
    }
    newAddressBook.addresses[index].isDeleted = true;

    dispatch(postAddressBook(newAddressBook));

    const address = {
      name: '',
      address1: '',
      address2: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      country: '',
      phoneNumber: '',
      appointmentRequired: false,
      isDeleted: false
    };
    dispatch(setHoldAddress(address));
    dispatch(setAddressBookSnackbar(true, false, 'Address deleted.'));

    const mcleod_customer_id = UserService.getMcleodCustomerId();
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.ADDRESS_BOOK,
      {
        mcleodID: mcleod_customer_id,
        description: 'Confirm Delete Request'
      }
    );
  };

  const continueEditing = () => {
    dispatch(setDeleteModalOpen(false));
  };

  if (!isOpen || !addressBook.addresses) return <></>;
  return (
    <BackDrop>
      <StyledModal>
        <ModalTitle>Delete this address?</ModalTitle>
        <ModalText>{addressBook.addresses[index].name}</ModalText>
        <ModalText>
          {addressBook.addresses[index].address1}
          {', '}
          {addressBook.addresses[index].address2
            ? addressBook.addresses[index].address2 + ', '
            : ''}
          {addressBook.addresses[index].city}
          {', '}
          {addressBook.addresses[index].stateProvince}{' '}
          {addressBook.addresses[index].postalCode}
        </ModalText>
        <ButtonContainer>
          <Button buttonStyle="outlined" onClick={continueEditing}>
            CANCEL
          </Button>
          <Button buttonStyle="solid" onClick={handleDelete}>
            DELETE
          </Button>
        </ButtonContainer>
      </StyledModal>
    </BackDrop>
  );
};

export default DeleteModal;
