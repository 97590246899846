exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".notificationButtonContainer{display:flex;align-items:center;margin-right:16px;margin-bottom:14px}.iconBlue{filter:invert(43%) sepia(73%) saturate(3323%) hue-rotate(179deg) brightness(97%) contrast(101%)}.iconWhite{filter:invert(100%) sepia(0%) saturate(0%) hue-rotate(218deg) brightness(101%) contrast(105%)}", ""]);

// Exports
exports.locals = {
	"ui-blue-01": "#0091ea",
	"ui-blue-02": "#0077b8",
	"ui-blue-03": "#005b8d",
	"ui-gray-01": "#333",
	"ui-gray-02": "#666",
	"ui-gray-03": "#999",
	"ui-gray-04": "#ccc",
	"ui-dropshadow": "rgba(0,0,0,.5)",
	"ui-background": "#f8f8f8",
	"ui-white": "#fff",
	"support-red": "#ef3824",
	"support-orange": "#ff9000",
	"support-green": "#72bf44",
	"support-yellow": "#ffc418"
};