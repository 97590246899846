// packages
import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

// components
import LoginLanding from '../loginLanding';

// services
import { AuthService, Token, UserService } from '../../services';

// utils
import * as FirebaseUtils from '../../utilities/firebaseUtils';

class Login extends Component<RouteComponentProps> {
  authService: AuthService;
  userService: UserService;

  constructor(props: RouteComponentProps) {
    super(props);

    this.authService = new AuthService();
    this.userService = new UserService();
  }

  componentDidUpdate() {
    if (this.authService.isAuthed) {
      this.props.history.replace('/shipments');
      return;
    }
  }

  async componentDidMount() {
    try {
      const tokenResp:
        | Token
        | undefined = await this.authService.checkSession();
      if (tokenResp) {
        this.authService.setToken(tokenResp);
      }

      await this.userService.isUserValid();
      const currentUser = UserService.getCurrentUser();

      if (currentUser) {
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Authenticated user ' + currentUser.email,
          level: Sentry.Severity.Info
        });
        ReactGA.set({
          dimension1: currentUser._id,
          dimension2: currentUser._id,
          dimension3: currentUser._id
        });
        ReactGA.event({
          category: 'User',
          action: 'Login',
          label: currentUser._id
        });

        FirebaseUtils.logFirebaseUser(currentUser._id, currentUser.user_type);
      }
      this.props.history.replace('/shipments');
    } catch (e) {
      if (e === 403) {
        this.authService.logoutRedirect();
      }
    }
  }

  render() {
    if (this.authService.isAuthed) {
      return null;
    }

    return <LoginLanding />;
  }
}

export default withRouter(Login);
