import React, { FC } from 'react';

import {
  StyledPaginationFooterContainer,
  StyledPaginationFooterRowsPerPageContainer,
  StyledPaginationFooterCurrentPageContainer,
  StyledPaginationFooterArrowsContainer,
  StyledPaginationFooterDropdown
} from './PaginationFooter.styles';
import { Icon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';

interface FooterProps {
  currentPage: number;
  disabled?: boolean;
  footerOptions?: {
    rowsPerPage?: number;
    rowsPerPageSelector?: boolean;
    totalRowsDisplay?: 'pages' | 'rows';
    height?: number;
  };
  rowsPerPage: number;
  customRowsPerPageOptions?: number[];
  totalRows: number;
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: number) => void;
  loading?: boolean;
}

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100, 1000];

export const StyledPaginationFooter: FC<FooterProps> = ({
  currentPage,
  disabled,
  footerOptions,
  rowsPerPage,
  customRowsPerPageOptions,
  totalRows,
  onChangePage,
  onChangeRowsPerPage,
  loading
}) => {
  //Once node upgrade happens, these clover files can be removed and instead imported from clover
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const currentPageStartPosition = currentPage * rowsPerPage - rowsPerPage + 1;
  const currentPageEndPosition =
    totalRows < currentPage * rowsPerPage
      ? totalRows
      : currentPage * rowsPerPage;
  const pageOptions = customRowsPerPageOptions ?? DEFAULT_ROWS_PER_PAGE_OPTIONS;
  const pageOptionsWithCurrent = [...new Set([...pageOptions, rowsPerPage])];

  return (
    <StyledPaginationFooterContainer height={footerOptions?.height}>
      {footerOptions?.rowsPerPageSelector && (
        <StyledPaginationFooterRowsPerPageContainer>
          <span>Rows per page:</span>
          <StyledPaginationFooterDropdown
            value={rowsPerPage}
            onChange={event => onChangeRowsPerPage(+event.target.value)}
            data-testid={`TableFooter_rows_per_page_dropdown`}
          >
            {pageOptionsWithCurrent.map(option => (
              <option
                key={`TableFooter_option_${option}`}
                data-testid={`TableFooter_option_${option}`}
                value={option}
              >
                {option}
              </option>
            ))}
          </StyledPaginationFooterDropdown>
        </StyledPaginationFooterRowsPerPageContainer>
      )}
      <StyledPaginationFooterCurrentPageContainer>
        {footerOptions?.totalRowsDisplay === 'rows' ? (
          <span>
            {loading ? 0 : currentPageStartPosition} - {currentPageEndPosition}{' '}
            of {totalRows}
          </span>
        ) : (
          <span>
            Page {currentPage} of {totalPages === 0 ? 1 : totalPages}
          </span>
        )}
      </StyledPaginationFooterCurrentPageContainer>
      <StyledPaginationFooterArrowsContainer>
        <Icon
          key={`TableFooter_arrow_left`}
          data-testid={`TableFooter_arrow_left`}
          icon={'chevronLeft'}
          size={'12'}
          color={currentPage === 1 ? 'gray3' : 'gray'}
          onClick={
            currentPage > 1 ? () => onChangePage(currentPage - 1) : undefined
          }
        />
        <Icon
          key={`TableFooter_arrow_right`}
          data-testid={`TableFooter_arrow_right`}
          icon={'chevronRight'}
          size={'12'}
          color={disabled || totalPages === currentPage ? 'gray3' : 'gray'}
          onClick={
            !disabled && (totalPages === 0 || currentPage < totalPages)
              ? () => onChangePage(currentPage + 1)
              : undefined
          }
        />
      </StyledPaginationFooterArrowsContainer>
    </StyledPaginationFooterContainer>
  );
};

export default StyledPaginationFooter;
