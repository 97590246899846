import Service from './Service';

const Config = require('Config');

export interface McleodCusmterData {
  id: string;
  parent_row_id: string;
  parent_row_type: string;
}

class UserMcleodService extends Service {
  constructor() {
    super(Config.userCheckpointServiceUrl);
  }

  async getMcleodId(email: string): Promise<McleodCusmterData> {
    try {
      const response = await this.axios.post(`registration/usercheckpoint`, {
        email
      });
      return response.data;
    } catch (e) {
      return { id: '', parent_row_id: '', parent_row_type: '' };
    }
  }
}

export default UserMcleodService;
