import Service from './Service';
import { OrderEmailBody } from '../types/orderTypes';

const Config = require('Config');

class EmailService extends Service {
  constructor() {
    super(Config.emailServiceTemplate);
  }

  async sendSuccessEmail(email: string) {
    try {
      const response = await this.axios.post(`success`, {
        email: email
      });
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async sendUnSuccessEmail(email: string) {
    try {
      const response = await this.axios.post(`unsuccess`, {
        email: email
      });
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async sendSupportEmail(
    email: string,
    companyName: string,
    firstName: string,
    lastName: string,
    phoneNumber: string,
    deviceType: string,
    mcleodID: string
  ) {
    try {
      const response = await this.axios.post(`support`, {
        email,
        companyName,
        firstName,
        lastName,
        phoneNumber,
        deviceType,
        mcleodID
      });
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async sendLoadEntryEmail(emailLoadEntryBody: OrderEmailBody) {
    try {
      const response = await this.axios.post(`sendLoadEntryEmail`, {
        emailLoadEntryBody
      });
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }
}

export default EmailService;
