import React, { FunctionComponent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
  REFERENCE,
  PIECES,
  CHARGES,
  ADDL_REF,
  COMMODITY,
  WEIGHT
} from '../../utilities/constants';
import { Order } from 'types/orderTypes';

import './DetailLoadInfoText.scss';

interface Props {
  order: Order | undefined;
}

const DetailLoadInfoText: FunctionComponent<Props> = props => {
  const createField = (text: string, className: string) => {
    if (text.length > 11) {
      return (
        <Tooltip title={text} placement="top">
          <div className={`${className} detailsValue loadInfoEllipsis`}>
            {text}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <div className={`${className} detailsValue loadInfoEllipsis`}>
          {text}
        </div>
      );
    }
  };


  let charge = "";
  if (props.order?.orderCharge != null) {
    charge = `$${props.order.orderCharge.finalCharge.toString()}`;
  } else {
    charge = props.order && props.order.totalCharge && props.order.totalCharge >= 1
      ? `$${props.order.totalCharge}`
      : 'Pending';
  }

  return (
    <div className="detailsLoadInfoText">
      <div className="detailsLoadInfoReference detailsTitle">{REFERENCE}</div>
      {createField(
        props.order && props.order.referenceId ? props.order.referenceId : '--',
        'detailsLoadInfoReferenceValue'
      )}
      <div className="detailsLoadInfoPieces detailsTitle">{PIECES}</div>
      {createField(
        props.order && props.order.pieces
          ? props.order.pieces.toString()
          : '--',
        'detailsLoadInfoPiecesValue'
      )}
      <div className="detailsLoadInfoTotalCharges detailsTitle">{CHARGES}</div>
      {createField(
        charge,
        'detailsLoadInfoTotalChargesValue'
      )}
      <div className="detailsLoadInfoAddl detailsTitle">{ADDL_REF}</div>
      {createField(
        props.order && props.order.consigneeRefno
          ? props.order.consigneeRefno
          : '--',
        'detailsLoadInfoAddlValue'
      )}
      <div className="detailsLoadInfoCommodity detailsTitle">{COMMODITY}</div>
      {createField(
        props.order && props.order.commodity ? props.order.commodity : '--',
        'detailsLoadInfoCommodityValue'
      )}
      <div className="detailsLoadInfoWeight detailsTitle">{WEIGHT}</div>
      {createField(
        props.order && props.order.weight
          ? props.order.weight.toString()
          : '--',
        'detailsLoadInfoWeightValue'
      )}
    </div>
  );
};

export default React.memo(DetailLoadInfoText);
