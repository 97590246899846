import React, { cloneElement, FC, useEffect, useRef, useState } from 'react';
import theme, { styled } from 'shamrock-clover-ui';

import { Icon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import {
  MenuComponentWrapper,
  MenuContainer,
  MenuItem,
  MenuLocation,
  SearchContainer,
  MenuItemsContainer,
  MenuItemNoResults
} from './MenuBaseComponents';

import { FilterInput } from './FilterInput';

interface MenuItemText {
  color?: Exclude<keyof typeof theme, 'font'> | string;
  fontSize?: string | '16' | '14';
}

export interface MenuOption {
  [key: string]: unknown;
  optionName: string;
}

export interface MenuOptions {
  [key: string]: unknown;
  sectionHeader: React.ReactElement;
  options: MenuOption[];
}

export const getOptionName = (option: MenuOption) =>
  typeof option === 'string' ? option : option.optionName;

type BaseMenuProps = MenuItemText & {
  sectionOptions?: MenuOptions[];
  hasSections?: boolean;
  menuOptions: MenuOption[];
  selectedOption?: MenuOption;
  menuLocation?: MenuLocation;
  width?: string;
  searcher?: boolean;
  setMenuOpen?: (_: boolean) => void;
  selectedOptionComparator?: (
    selectedOption: MenuOption | undefined,
    compareOption: MenuOption
  ) => boolean;
};
type MenuProps = BaseMenuProps & {
  onOptionSelected: (option?: MenuOption, index?: number) => void;
};

type SortMenuProps = BaseMenuProps & {
  onSortOptionSelected: (
    option: MenuOption,
    result?: 'ASC' | 'DESC',
    index?: number
  ) => void;
  sortDirection?: 'ASC' | 'DESC';
};

export type SingleSelectMenuProps = MenuProps & {
  VisualElement: React.ReactElement;
  disabled?: boolean;
};
export type SingleSelectSortMenuProps = SortMenuProps & {
  VisualElement: React.ReactElement;
  disabled?: boolean;
};

const processFontSize = (fontSize: string | '14' | '16' = '16px') =>
  fontSize === '14' || fontSize === '16' ? `${fontSize}px` : fontSize;

const MenuItemText = styled.span<MenuItemText>`
  color: ${props => props.theme['gray'] || props.color};
  padding-right: 6px;
  flex: none;
  font-family: ${props => props.theme.font.family.proximaNova};
  font-size: ${props => processFontSize(props.fontSize)};
`;

const returnIconSize = (fontSize?: string) => (fontSize === '14' ? '16' : '18');

const Menu: FC<MenuProps> = props => {
  const {
    color,
    fontSize,
    menuLocation,
    menuOptions,
    sectionOptions,
    hasSections,
    onOptionSelected,
    selectedOption,
    width,
    searcher,
    selectedOptionComparator
  } = props;
  const [currentOption, setCurrentOption] = useState(selectedOption);
  const [filterData, setFilterData] = useState(menuOptions);
  const [showSection] = useState(hasSections && sectionOptions != undefined);

  const handleSelectionChange = (option: MenuOption, index: number) => {
    const isSameOption = selectedOptionComparator
      ? selectedOptionComparator(currentOption, option)
      : option?.optionName === currentOption?.optionName;
    const newOption = isSameOption ? { optionName: '' } : option;
    setCurrentOption(newOption);
    onOptionSelected(newOption, newOption.optionName ? index : undefined);
  };

  const handleFilter = async ({
    target
  }: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const searchWord = target.value;
    const newFilter = menuOptions.filter(value => {
      return getOptionName(value)
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });
    setFilterData(newFilter);
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.nativeEvent.stopImmediatePropagation();
  };

  const MenuItemListDefault = () => {
    return (
      <>
        {!filterData.length && (
          <MenuItemNoResults>
            <MenuItemText color={color} fontSize={fontSize}>
              No Results
            </MenuItemText>
          </MenuItemNoResults>
        )}
        {filterData.map((option, index) => (
          <MenuItem
            onClick={() => handleSelectionChange(option, index)}
            key={`SingleSelectMenu_${option.optionName}`}
          >
            <MenuItemText
              color={color}
              fontSize={fontSize}
              key={`SingleSelectMenu_${option.optionName}_text`}
            >
              {option.optionName}
            </MenuItemText>
            {option.optionName === currentOption?.optionName && (
              <Icon
                key={`SingleSelectMenu_selected_icon`}
                icon={'check'}
                size={returnIconSize(fontSize)}
              />
            )}
          </MenuItem>
        ))}
      </>
    );
  };

  const renderSelectedIcon = (option: MenuOption) => {
    if (selectedOptionComparator) {
      return (
        selectedOptionComparator(currentOption, option) && (
          <Icon
            key={`SingleSelectMenu_selected_icon`}
            icon={'check'}
            size={returnIconSize(fontSize)}
          />
        )
      );
    } else if (option.optionName === currentOption?.optionName) {
      return (
        <Icon
          key={`SingleSelectMenu_selected_icon`}
          icon={'check'}
          size={returnIconSize(fontSize)}
        />
      );
    }
    return null;
  };

  const MenuItemListSectioned = () => {
    return (
      <>
        {sectionOptions?.map(section => {
          return (
            <div key={`SingleSelectMenu_${section.options[0].optionName}`}>
              <div className="sectionHeader">{section.sectionHeader}</div>
              {section.options.map((option, index) => (
                <MenuItem
                  onClick={() => handleSelectionChange(option, index)}
                  key={`SingleSelectMenu_${option.optionName}`}
                >
                  <MenuItemText
                    color={color}
                    fontSize={fontSize}
                    key={`SingleSelectMenu_${option.optionName}_text`}
                  >
                    {option.optionName}
                  </MenuItemText>
                  {renderSelectedIcon(option)}
                </MenuItem>
              ))}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <MenuContainer
      className="SingleSelectMenuContainer"
      menuLocation={menuLocation}
      width={width}
    >
      {searcher && (
        <SearchContainer onClick={handleClick}>
          <FilterInput
            icon="search"
            placeholder="Search"
            width="100%"
            onChange={handleFilter}
          />
        </SearchContainer>
      )}

      <MenuItemsContainer>
        {showSection ? <MenuItemListSectioned /> : <MenuItemListDefault />}
      </MenuItemsContainer>
    </MenuContainer>
  );
};

const SortMenu: FC<SortMenuProps> = (props: SortMenuProps) => {
  const {
    onSortOptionSelected,
    menuLocation,
    menuOptions,
    fontSize,
    selectedOption,
    sortDirection
  } = props;
  const defaultState = {
    internalSelectedOption: selectedOption || { optionName: '' },
    internalSortDirection: sortDirection
  };
  const [currentState, updateCurrentState] = useState(defaultState);
  const { internalSelectedOption, internalSortDirection } = currentState;
  const iconToDisplay =
    internalSortDirection?.toUpperCase() === 'ASC' ? 'arrowDown' : 'arrowUp';
  const handleSelectionChange = (option: MenuOption, index: number) => {
    const newState = { ...currentState };
    if (internalSelectedOption.optionName != option.optionName) {
      newState.internalSelectedOption = option;
      internalSortDirection ? null : (newState.internalSortDirection = 'ASC');
    } else {
      newState.internalSortDirection =
        internalSortDirection === 'ASC' ? 'DESC' : 'ASC';
      newState.internalSelectedOption = option;
    }
    updateCurrentState(newState);
    onSortOptionSelected(option, newState.internalSortDirection, index);
  };
  return (
    <MenuContainer
      className="SingleSelectMenuContainer"
      menuLocation={menuLocation}
    >
      {menuOptions.map((option, index) => (
        <MenuItem
          onClick={() => handleSelectionChange(option, index)}
          key={`SingleSelectMenu_${option.optionName}`}
        >
          <MenuItemText key={`SingleSelectMenu_${option.optionName}_text`}>
            {option.optionName}
          </MenuItemText>
          {option.optionName === internalSelectedOption.optionName && (
            <Icon
              key={`SingleSelectMenu_selected_icon`}
              icon={iconToDisplay}
              size={returnIconSize(fontSize)}
            />
          )}
        </MenuItem>
      ))}
    </MenuContainer>
  );
};

export const SingleSelectMenu: FC<
  SingleSelectMenuProps | SingleSelectSortMenuProps
> = (props: SingleSelectMenuProps | SingleSelectSortMenuProps) => {
  function isSortMenu(
    props: SingleSelectMenuProps | SingleSelectSortMenuProps
  ): props is SingleSelectSortMenuProps {
    return (
      (props as SingleSelectSortMenuProps).onSortOptionSelected !== undefined
    );
  }

  const [isMenuOpen, setMenuOpen] = useState(false);
  const parentRef = useRef<HTMLElement>(null);
  // Normal function to match document.addEventListener signature
  const handleClickOutside = function(ev: MouseEvent) {
    const children =
      parentRef.current && Array.from(parentRef.current?.children);
    const isChild = children?.some(child => child === ev.target);
    if (parentRef && (ev.target === parentRef.current || isChild)) {
      return;
    }
    if (isMenuOpen) {
      setMenuOpen(false);
      props.setMenuOpen?.(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]);

  const handleParentClick = (
    ev: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    ev.stopPropagation();
    if (!props.disabled) {
      setMenuOpen(!isMenuOpen);
      props.setMenuOpen?.(!isMenuOpen);
    }
  };

  const ClonedVisualEle = cloneElement(props.VisualElement, {
    onClick: handleParentClick,
    ref: parentRef
  });

  const menuClassName = isSortMenu(props)
    ? 'SingleSelectSortMenu'
    : 'SingleSelectMenu';
  const menuToRender = isSortMenu(props) ? (
    <SortMenu {...props} />
  ) : (
    <Menu {...props} />
  );

  return (
    <MenuComponentWrapper className={menuClassName} width={props.width}>
      {ClonedVisualEle}
      {isMenuOpen && menuToRender}
    </MenuComponentWrapper>
  );
};
