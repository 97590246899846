import { ThunkAction } from 'redux-thunk';
import { AppState } from 'store';
import { Action } from 'redux';
import { AccountingActionTypes, FilterCountType } from './accountingReduxTypes';
import InvoiceService, {
  InvoiceServiceAPIResponse
} from '../../services/InvoiceService';
import to from 'await-to-js';
import OrderService from '../../services/order/OrderService';

export const ACCOUNTING_FILTER_COUNT_LOADING =
  'ACCOUNTING_FILTER_COUNT_LOADING';
export const ACCOUNTING_TABLE_LOADING = 'ACCOUNTING_TABLE_LOADING';
export const ACCOUNTING_TABLE_ERROR = 'ACCOUNTING_TABLE_ERROR';
export const ACCOUNTING_DETAILS_LOADING = 'ACCOUNTING_DETAILS_LOADING';
export const ACCOUNTING_DETAILS_ERROR = 'ACCOUNTING_DETAILS_ERROR';
export const ACCOUNTING_ERROR = 'ACCOUNTING_ERROR';
export const INVOICE_DATA = 'INVOICE_DATA';
export const INVOICE_ORDER_DETAILS = 'INVOICE_ORDER_DETAILS';
export const INVOICE_ORDER_FILES = 'INVOICE_ORDER_FILES';
export const FILTER_COUNT = 'FILTER_COUNT';
export const ACCOUNTING_SUMMARY_BILLING = 'ACCOUNTING_SUMMARY_BILLING';

export function setInvoices(
  value: InvoiceServiceAPIResponse
): AccountingActionTypes {
  return {
    type: INVOICE_DATA,
    value
  };
}

export function setInvoiceOrderDetails(value: any): AccountingActionTypes {
  return {
    type: INVOICE_ORDER_DETAILS,
    value
  };
}

export function setInvoiceOrderFiles(value: any): AccountingActionTypes {
  return {
    type: INVOICE_ORDER_FILES,
    value
  };
}

export function setIsAccountingFilterCountLoading(
  value: boolean
): AccountingActionTypes {
  return {
    type: ACCOUNTING_FILTER_COUNT_LOADING,
    value
  };
}

export function setIsAccountingTableLoading(
  value: boolean
): AccountingActionTypes {
  return {
    type: ACCOUNTING_TABLE_LOADING,
    value
  };
}

export function setIsAccountingTableError(
  value: boolean
): AccountingActionTypes {
  return {
    type: ACCOUNTING_TABLE_ERROR,
    value
  };
}

export function setIsAccountingDetailsLoading(
  value: boolean
): AccountingActionTypes {
  return {
    type: ACCOUNTING_DETAILS_LOADING,
    value
  };
}

export function setIsAccountingDetailsError(
  value: boolean
): AccountingActionTypes {
  return {
    type: ACCOUNTING_DETAILS_ERROR,
    value
  };
}

export function setAccountingError(value: string): AccountingActionTypes {
  return {
    type: ACCOUNTING_ERROR,
    value
  };
}

export function setFilter(value: FilterCountType): AccountingActionTypes {
  return {
    type: FILTER_COUNT,
    value
  };
}

export function setSummaryBilling(value: boolean): AccountingActionTypes {
  return {
    type: ACCOUNTING_SUMMARY_BILLING,
    value
  };
}

export function fetchInvoiceDetails(
  orderId: string
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch) {
    dispatch(setIsAccountingDetailsLoading(true));

    const [orderDetailsError, orderDetails] = await to(
      OrderService.getOrderDetails(orderId)
    );
    if (orderDetailsError || !orderDetails) {
      dispatch(setIsAccountingDetailsError(true));
      dispatch(setIsAccountingDetailsLoading(false));
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [orderFileError, orderFiles] = await to(
      OrderService.getFilesForOrder(orderId)
    );

    dispatch(setInvoiceOrderDetails(orderDetails));
    dispatch(setInvoiceOrderFiles(orderFiles || []));
    dispatch(setIsAccountingDetailsLoading(false));
  };
}

export function fetchInvoices(query: {
  page: number;
  limit: number;
  filter: string;
  orderBy: string;
  order: string;
  search: string | undefined;
}): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch) {
    dispatch(setIsAccountingTableLoading(true));

    const [error, data] = await to(InvoiceService.getInvoices({ ...query }));
    if (error || !data) {
      dispatch(setIsAccountingTableError(true));
      dispatch(setIsAccountingTableLoading(false));
      return;
    }
    if (data.summaryBilling) {
      dispatch(setSummaryBilling(data.summaryBilling));
    }
    dispatch(setInvoices(data));
    dispatch(setIsAccountingTableLoading(false));
  };
}

export function fetchInvoiceFilterCounts(): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> {
  return async function(dispatch) {
    dispatch(setIsAccountingFilterCountLoading(true));

    const [error, promises] = await to(
      Promise.all([
        InvoiceService.getInvoices({ filter: '0-30', data: false }),
        InvoiceService.getInvoices({ filter: '31-61', data: false }),
        InvoiceService.getInvoices({ filter: '61+', data: false }),
        InvoiceService.getInvoices({ data: false })
      ])
    );
    if (error || promises === undefined) {
      console.log(error);
      dispatch(setAccountingError(error ? error.toString() : ''));
      return;
    }

    const [
      zeroToThirty,
      thirtyOneToSixtyOne,
      sixtyOnePlus,
      totalCount
    ] = promises;

    dispatch(
      setFilter({
        '0-30': {
          count: zeroToThirty.count,
          pastDue: zeroToThirty.pastDue
        }
      })
    );
    dispatch(
      setFilter({
        '31-61': {
          count: thirtyOneToSixtyOne.count,
          pastDue: thirtyOneToSixtyOne.pastDue
        }
      })
    );
    dispatch(
      setFilter({
        '61': {
          count: sixtyOnePlus.count,
          pastDue: sixtyOnePlus.pastDue
        }
      })
    );

    dispatch(
      setFilter({
        total: {
          count: totalCount.count,
          pastDue: totalCount.pastDue
        }
      })
    );

    dispatch(setIsAccountingFilterCountLoading(false));
  };
}
