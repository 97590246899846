import Service from './Service';
import { OrderEmailBody } from '../types/orderTypes';

const Config = require('Config');

class OrderEmailService extends Service {
  constructor() {
    super(Config.serviceUrl);
  }

  async sendLoadEntryEmail(emailLoadEntryBody: OrderEmailBody) {
    try {
      const response = await this.axios.post(`email/sendLoadEntryEmail`, {
        emailLoadEntryBody
      });
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }
}

export default OrderEmailService;
