import Service from './Service';

const Config = require('Config');

class FeatureService extends Service {
  constructor() {
    super(Config.featureServiceUrl);
  }

  async getFeature(feature: string, email: string) {
    const response = await this.axios.get(`${feature}/${email}`, {});
    return response.data.status === 'always-active';
  }
}

export default new FeatureService();
