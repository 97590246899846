import './loadEntryContainer.scss';
import React, { useState, useRef } from 'react';
import LoadEntry from '../loadEntry/loadEntry';
import Spinner from '../../../components/Spinner';
import styled from 'styled-components';
import AddressBookDialog from '../../../components/addressBook/AddressBookDialog';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from 'shamrock-clover-ui';
import {
  getAddressBookSnackbarError,
  getAddressBookSnackbarMessage,
  getAddressBookSnackbarOpen
} from '../../../store/addressBook/addressBookSelectors';
import { setAddressBookSnackbar } from '../../../store/addressBook/addressBookActions';

interface Props {
  toggleCancelModal: Function;
  toggleLoadEntryModal: Function;
  setLoadEntryError: Function;
  isLoadEntryVisible: boolean;
}

export const ErrorLabel = styled.label`
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #333333;
  padding-top: 30px;
`;

const SnackbarContainer = styled.div`
  div {
    z-index: 99999 !important;
  }
`;

const LoadEntryContainer: React.FC<Props> = ({
  toggleCancelModal,
  toggleLoadEntryModal,
  setLoadEntryError,
  isLoadEntryVisible
}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);

  if (isLoadEntryVisible) {
    const underLay = wrapperRef.current;
    if (underLay) {
      setTimeout(() => {
        underLay.style.backgroundColor = '#00000080';
      }, 400);
    }
  } else {
    const underLay = wrapperRef.current;
    if (underLay) {
      underLay.style.backgroundColor = 'transparent';
    }
  }

  const [isLoading, toggleIsLoading] = useState<boolean>(false);

  return (
    <div className="underlay" ref={wrapperRef}>
      <div className="loadEntryContainer">
        <AddressBookDialog />
        <SnackbarContainer>
          <Snackbar
            open={useSelector(getAddressBookSnackbarOpen)}
            onClose={() => {
              dispatch(setAddressBookSnackbar(false, false, ''));
            }}
            message={useSelector(getAddressBookSnackbarMessage)}
            variant={
              useSelector(getAddressBookSnackbarError) ? 'error' : 'success'
            }
          />
        </SnackbarContainer>
        <LoadEntry
          toggleCancelModal={toggleCancelModal}
          toggleLoadEntryModal={toggleLoadEntryModal}
          toggleIsLoading={toggleIsLoading}
          setLoadEntryError={setLoadEntryError}
        />
        {isLoading ? (
          <div className="loadEntryWaiting">
            <Spinner />
            <ErrorLabel>Submitting load...</ErrorLabel>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default LoadEntryContainer;
