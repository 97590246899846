import React, { FunctionComponent, Fragment, useEffect, useRef } from 'react';
import './AccountingDetails.scss';
import AccountingSummary from './AccountingSummary';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

function useOutsideAlerter(ref: React.MutableRefObject<any>) {
  const { push } = useHistory();
  const { search } = useLocation();

  const isRow = (el: HTMLElement) => {
    if (el.classList.contains('tableRow')) {
      return true;
    }
    let parent = el.parentElement;
    while (parent) {
      if (parent.classList.contains('tableRow')) {
        return true;
      }
      parent = parent.parentElement;
    }
    return false;
  };

  function handleClickOutside(event: { target: any }) {
    if (!isRow(event.target)) {
      if (ref.current && !ref.current.contains(event.target)) {
        const { invoiceId, ...rest } = queryString.parse(search);
        push({
          pathname: '/accounting',
          search: queryString.stringify({ ...rest })
        });
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const AccountingDetails: FunctionComponent = () => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div
      className="accountingPageContainer"
      ref={wrapperRef}
      data-testid="accountingDetailContainer"
    >
      <Fragment>
        <AccountingSummary />
      </Fragment>
    </div>
  );
};

export default React.memo(AccountingDetails);
