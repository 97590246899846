import React, { forwardRef } from 'react';
import { DetailsFormData } from '../types/loadTypes';

// styles
import Grid from '@material-ui/core/Grid';
import { SelectInput } from 'shamrock-clover-ui/dist/clover/components/SelectInput/SelectInput';
import {
  TextInputStyled,
  MultilineTextInputStyled,
  GridContainer,
  SelectInputContainer,
  ErrorText,
  InputLabel,
  MultipleGrid
} from './loadEntryStyles';

// props
interface Props {
  setFormData: Function;
  formData: DetailsFormData;
}

// eslint-disable-next-line react/display-name
export const SelectInputStyled = forwardRef<
  HTMLInputElement,
  {
    label: string;
    error?: string;
    onOptionSelected: (value: string) => void;
    options: string[];
    value: string;
    width?: string;
    className?: string;
  }
>(({ label, error, onOptionSelected, options, value, width, className }) => {
  return (
    <SelectInputContainer width={width} className={className} error={!!error}>
      <SelectInput
        label={label}
        onOptionSelected={(option: any) => onOptionSelected(option)}
        options={options}
        value={value}
      />
      <ErrorText>{error}</ErrorText>
    </SelectInputContainer>
  );
});

export const DetailsForm = ({ formData, setFormData }: Props) => {
  var equipmentOptions = ['Van', 'Flatbed', 'Refrigerated'];

  const handleOnChangeForm = (name: string, value: string) => {
    setFormData({ ...formData, [name]: { value: value, errorMessage: '' } });
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setFormData({
        ...formData,
        [name]: { value: '', errorMessage: 'Required field' }
      });
    }
  };

  return (
    <GridContainer container>
      <Grid item sm={6}>
        <Grid item sm={12}>
          <TextInputStyled
            label="Commodity"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('commodity', value);
            }}
            onBlur={() => {
              handleOnBlurForm('commodity', formData.commodity.value);
            }}
            error={formData.commodity.errorMessage}
            value={formData.commodity.value}
          />
        </Grid>
        <Grid item sm={12}>
          <SelectInputStyled
            label="Equipment"
            onOptionSelected={(option: string) =>
              handleOnChangeForm('equipment', option)
            }
            options={equipmentOptions}
            value={formData.equipment.value}
            error={formData.equipment.errorMessage}
          />
        </Grid>
        <Grid item sm={12}>
          {formData.equipment.value === 'Flatbed' ? (
            <TextInputStyled
              label="Tarp Details"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => {
                handleOnChangeForm('tarpDetails', value);
              }}
              value={formData.tarpDetails.value}
              helperText="Enter the size and number needed"
            />
          ) : (
            <></>
          )}
          {formData.equipment.value === 'Refrigerated' ? (
            <TextInputStyled
              label="Temperature"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => {
                handleOnChangeForm('tempDetails', value);
              }}
              onBlur={() => {
                handleOnBlurForm('tempDetails', formData.tempDetails.value);
              }}
              error={formData.tempDetails.errorMessage}
              value={formData.tempDetails.value}
              helperText="Enter the required temperature"
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid item sm={6}>
        <Grid item sm={12}>
          <TextInputStyled
            label="Handling Units"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('handlingUnits', value);
            }}
            onBlur={() => {
              handleOnBlurForm('handlingUnits', formData.handlingUnits.value);
            }}
            error={formData.handlingUnits.errorMessage}
            value={formData.handlingUnits.value}
          />
        </Grid>
        <Grid item sm={12}>
          <TextInputStyled
            label="Total Weight"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('totalWeight', value);
            }}
            onBlur={() => {
              handleOnBlurForm('totalWeight', formData.totalWeight.value);
            }}
            error={formData.totalWeight.errorMessage}
            value={formData.totalWeight.value}
          />
        </Grid>
        <MultipleGrid item sm={12}>
          <TextInputStyled
            label="Pickup #"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('pickupNum', value);
            }}
            value={formData.pickupNum.value}
            width="140px"
            className="leftSmallInput"
          />
          <TextInputStyled
            label="PO/Reference #"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('poReference', value);
            }}
            value={formData.poReference.value}
            width="140px"
            className="rightSmallInput"
          />
        </MultipleGrid>
      </Grid>
      <Grid item sm={12}>
        <InputLabel fontSize="16px">Special Instructions</InputLabel>
        <InputLabel fontSize="13px">
          Enter anything special about the load (e.g., hazmat, high-value cargo,
          dimensions, unloading instructions, etc.)
        </InputLabel>
        <MultilineTextInputStyled
          variant="filled"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('specialInstructions', value);
          }}
          value={formData.specialInstructions.value}
        />
      </Grid>
    </GridContainer>
  );
};

export default DetailsForm;
