import React, { FunctionComponent, Fragment } from 'react';
import CloseIcon from '../../../assets/images/Close.svg';
import './AccountingDetailHeaderSection.scss';
import { useHistory } from 'react-router';
import InvoiceStatusIcon from '../InvoiceStatusIcon';

interface Props {
  id?: string;
  balance?: number;
  daysPastDue?: number;
  paymentTermDays?: number;
}

const AccountingDetailHeaderSection: FunctionComponent<Props> = ({
  id,
  balance,
  daysPastDue,
  paymentTermDays
}) => {
  const { push } = useHistory();
  const closeDetails = () => {
    push({
      pathname: '/accounting',
      search: ''
    });
  };
  return (
    <div
      className="accountingDetailHeader"
      data-testid="accountingDetailHeader"
    >
      <div className="accountingDetailIcon">
        <InvoiceStatusIcon daysOverdue={daysPastDue} />
      </div>
      <div className="detailTitle">Invoice {id}</div>
      <div className="accountingDetailStatus">
        {balance && (
          <Fragment>
            <div className="balance" data-testid="AccountingDetailBalance">
              ${balance.toLocaleString()}
            </div>
            <div className="divider">|</div>
          </Fragment>
        )}
        {daysPastDue && (
          <Fragment>
            <div className="status" data-testid="AccountingDetailStatus">
              {daysPastDue < 0
                ? `Due in ${Math.abs(daysPastDue)} Days`
                : `${daysPastDue} Days Past Due`}
            </div>
            <div className="divider">|</div>
          </Fragment>
        )}
        {paymentTermDays && (
          <div className="terms" data-testid="AccountingDetailTerms">
            Payment Terms: Net {paymentTermDays} Days
          </div>
        )}
      </div>
      <div className="accountingDetailCloseIcon">
        <img src={CloseIcon} onClick={closeDetails} />
      </div>
    </div>
  );
};

export default React.memo(AccountingDetailHeaderSection);
