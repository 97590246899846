import React, { FunctionComponent } from 'react';
import OrderDetailsHeaderSection from './orderDetailsHeaderSection/orderDetailsHeaderSection';
import DetailDocumentSection from '../../detail/DetailDocumentSection';
import DetailLoadInfo from '../../detail/DetailLoadInfo';
import DetailLoadInfoText from '../../detail/DetailLoadInfoText';
import DetailStop from '../../detail/DetailStop';
import DetailScrollableArea from '../../detail/DetailScrollableArea';
import { useSelector } from 'react-redux';
import {
  getOrderStops,
  getOrderCallIn,
  getDetailOrderFileList,
  getDetailOrderId,
  getDetailOrder
} from '../../../store/orders/ordersSelectors';

import './orderDetails.scss';

const OrderSummary: FunctionComponent = () => {
  const stops = useSelector(getOrderStops);
  const callIn = useSelector(getOrderCallIn);
  const fileList = useSelector(getDetailOrderFileList);
  const detailOrderId = useSelector(getDetailOrderId);
  const order = useSelector(getDetailOrder);

  return (
    <div className="orderSummary">
      <OrderDetailsHeaderSection />
      <DetailScrollableArea>
        <DetailDocumentSection fileList={fileList} id={detailOrderId} />
        <DetailLoadInfo>
          <DetailLoadInfoText order={order} />
        </DetailLoadInfo>
        {stops.map((stop, index) => {
          return (
            <DetailStop key={index} stop={stop} stops={stops} callIn={callIn} />
          );
        })}
      </DetailScrollableArea>
    </div>
  );
};

export default OrderSummary;
