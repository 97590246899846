import React from 'react';
import {
  BackDrop,
  Body,
  Header,
  SubHeader,
  StyledDialog,
  StyledFilterInput
} from './AddressBookStyles';
import { Icon as CloverIcon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import AddressBookTable from './AddressBookTable';
import {
  fetchAddressBook,
  setAddressBookOpen,
  setUnsavedModalOpen
} from '../../store/addressBook/addressBookActions';
import { useDispatch, useSelector } from 'react-redux';
import NewAddressButton from './NewAddressButton';
import {
  getAddressBookOpen,
  getAddressEntryOpen,
  getAddressBookLoading,
  getEditAddressIndex
} from '../../store/addressBook/addressBookSelectors';
import DeleteModal from './DeleteModal';
import UnsavedChangesModal from './UnsavedChangesModal';
import AddressEntryForm from './AddressEntryForm';

export const AddressBookDialog = () => {
  const dispatch = useDispatch();
  const isAddressBookOpen = useSelector(getAddressBookOpen);
  const isAddressEntryOpen = useSelector(getAddressEntryOpen);
  const editAddressIndex = useSelector(getEditAddressIndex);
  const isAddressBookLoading = useSelector(getAddressBookLoading);

  const [isTransitioning, setIsTransitioning] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<string>('');

  const [
    entryFormBlankOrNoChange,
    setEntryFormBlankOrNoChange
  ] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsTransitioning(true);

    setTimeout(() => {
      setIsTransitioning(false);
    }, 100);
  }, [isAddressBookOpen, isAddressEntryOpen]);

  const closeModal = () => {
    if (isAddressEntryOpen) dispatch(setUnsavedModalOpen(true));
    else dispatch(setAddressBookOpen(false));
  };

  React.useEffect(() => {
    dispatch(fetchAddressBook());
  }, []);

  if (!isAddressBookOpen && !isAddressEntryOpen) return <></>;
  return (
    <>
      <BackDrop>
        <StyledDialog
          minHeight={isAddressEntryOpen ? '450px' : '700px'}
          transitioning={isTransitioning}
        >
          <Header>
            {isAddressEntryOpen ? (
              editAddressIndex !== -1 ? (
                <>Edit Address</>
              ) : (
                <>Add New Address</>
              )
            ) : (
              <>Address Book</>
            )}
            <CloverIcon
              onClick={() => {
                closeModal();
              }}
              icon="close"
              size="32"
            />
          </Header>
          {isAddressEntryOpen ? (
            <></>
          ) : (
            <>
              <SubHeader>
                <StyledFilterInput
                  placeholder="Company Name or Address"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    setSearchInput(e.currentTarget.value);
                  }}
                  clearSearch={() => {
                    setSearchInput('');
                  }}
                  value={searchInput}
                  icon="searchBlack"
                  width="400px"
                  height="40px"
                  disabled={isAddressBookLoading}
                  className="filterInput"
                />
                <NewAddressButton />
              </SubHeader>
            </>
          )}
          <Body>
            {isAddressEntryOpen ? (
              <AddressEntryForm
                closeModal={closeModal}
                setEntryFormBlankOrNoChange={setEntryFormBlankOrNoChange}
              />
            ) : (
              <AddressBookTable searchInput={searchInput} />
            )}
          </Body>
        </StyledDialog>
        <DeleteModal />
        <UnsavedChangesModal
          entryFormBlankOrNoChange={entryFormBlankOrNoChange}
        />
      </BackDrop>
    </>
  );
};

export default AddressBookDialog;
