import React from 'react';
import LoadTrackingInputs from './LoadTrackingInputs';
import AppHeader from '../../components/appHeader/AppHeader';
import {
  useFullDetailsLoadTrackingData,
  usePartialDetailsLoadTrackingData
} from './useLoadTrackingData';

interface Props {
  referenceNumber?: string;
  trackingId?: string;
  city?: string;
  zip?: string;
}

export const DetailHeader: React.FC<Props> = ({
  referenceNumber,
  trackingId,
  city,
  zip
}) => {
  const { fullDetailsError } = useFullDetailsLoadTrackingData(
    trackingId as string
  );
  const {
    partialDetailsError,
    isLoadTrackingDetails,
    partialDetails
  } = usePartialDetailsLoadTrackingData(referenceNumber as string, city, zip);

  const shouldShowInputsInHeader = () => {
    // search page
    if (!referenceNumber && !trackingId && !city && !zip) {
      return false;
    }

    // partial details error page
    if (referenceNumber && partialDetailsError) {
      return false;
    }

    // partial details multiple loads
    if (referenceNumber && !isLoadTrackingDetails(partialDetails)) {
      return false;
    }

    // full details error page
    if (trackingId && fullDetailsError) {
      return false;
    }

    return true;
  };

  return (
    <AppHeader>
      {shouldShowInputsInHeader() && <LoadTrackingInputs />}
    </AppHeader>
  );
};

export default DetailHeader;
