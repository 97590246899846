import React, { Fragment, ChangeEvent, useState } from 'react';
import './Toolbar.scss';
import FilterInput from 'shamrock-clover-ui/dist/clover/components/FilterInput/FilterInput';
import { ACCOUNTING_SEARCH_PLACEHOLDER } from '../../utilities/constants';
import ExportIcon from '../../assets/images/Export.svg';
import ExportDisabledIcon from '../../assets/images/Export_Disabled.svg';
import { useAccountingQueryParams } from '../../hooks/routeHooks';
import InvoiceService from '../../services/InvoiceService';
import { getDateWithTimezone } from '../../utilities/dateHelper';
import Tooltip from '@material-ui/core/Tooltip';
import trackAnalyticsEvent from '../../utilities/googleAnalyticsUtilities';
import * as FirebaseUtils from '../../utilities/firebaseUtils';
import { styled } from 'shamrock-clover-ui';

const StyledFilterInput = styled(FilterInput)`
  max-width: 500px;
`;

export const Toolbar = () => {
  const [csvIsLoading, setCsvIsLoading] = useState(false);
  const { queryParams, addQueryParams } = useAccountingQueryParams();

  const convertArrayOfObjectsToCSV = async () => {
    trackAnalyticsEvent('Accounting', 'CSV Download');
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CSV_DOWNLOAD,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.ACCOUNTING,
      {}
    );
    setCsvIsLoading(true);
    const csvData = await InvoiceService.getInvoicesCsv();
    var element = document.createElement('a');
    element.setAttribute('href', window.URL.createObjectURL(csvData));
    element.setAttribute('download', `account_${getDateWithTimezone()}.csv`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    setCsvIsLoading(false);
  };

  const onSearchValueChange = (value: string) => {
    addQueryParams({ search: value });
  };

  return (
    <Fragment>
      <div data-testid="AccountingToolbar" className="accountingToolbar">
        <div className="filterInput">
          {' '}
          <StyledFilterInput
            width="500px"
            value={queryParams.search as string}
            data-testid="AccountingSearch"
            placeholder={ACCOUNTING_SEARCH_PLACEHOLDER}
            clearSearch={() => onSearchValueChange('')}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onSearchValueChange(event.target.value);
            }}
            onBlur={(event: any) => {
              if (event.currentTarget === event.target) {
                FirebaseUtils.logFirebaseEvent(
                  FirebaseUtils.FirebaseEvents.INVOICE_SEARCH,
                  FirebaseUtils.FirebaseModules.SHIPPER,
                  FirebaseUtils.FirebasePages.ACCOUNTING,
                  {
                    searchValue: event.target.value
                  }
                );
              }
            }}
          />
        </div>
        <Tooltip title="Download Aging" placement="top">
          <button className="exportIconButton">
            <img
              className="export"
              src={csvIsLoading ? ExportDisabledIcon : ExportIcon}
              onClick={csvIsLoading ? () => {} : convertArrayOfObjectsToCSV}
            ></img>
          </button>
        </Tooltip>
      </div>
    </Fragment>
  );
};

export default Toolbar;
