import React, { FunctionComponent } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import {
  ORDER,
  REFERENCE,
  PIECES,
  ADDL_REF,
  COMMODITY,
  WEIGHT,
  STOP_REFERENCES
} from '../../../utilities/constants';
import './AccountingLoadInfoText.scss';
import { Order } from 'types/orderTypes';

interface Props {
  order: Order | undefined;
}

const AccountingLoadInfoText: FunctionComponent<Props> = props => {
  const createField = (
    text: string,
    className: string,
    overflowEnabled: boolean = true
  ) => {
    if (text.length > 11) {
      return (
        <Tooltip title={text} placement="top">
          <div
            data-testid="LoadInfoValue"
            className={`${className} detailsValue ${
              overflowEnabled ? 'loadInfoEllipsis' : ''
            }`}
          >
            {text}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <div
          data-testid="LoadInfoValue"
          className={`${className} detailsValue ${
            overflowEnabled ? 'loadInfoEllipsis' : ''
          }`}
        >
          {text}
        </div>
      );
    }
  };

  return (
    <div className="accountingDetailsLoadInfoText">
      <div className="order detailsTitle">{ORDER}</div>
      {createField(
        props.order && props.order.orderId ? props.order.orderId : '--',
        'orderValue'
      )}
      <div className="reference detailsTitle">{REFERENCE}</div>
      {createField(
        props.order && props.order.referenceId ? props.order.referenceId : '--',
        'referenceValue'
      )}
      <div className="addlReference detailsTitle">{ADDL_REF}</div>
      {createField(
        props.order && props.order.consigneeRefno
          ? props.order.consigneeRefno
          : '--',
        'addlReferenceValue'
      )}
      <div className="commodity detailsTitle">{COMMODITY}</div>
      {createField(
        props.order && props.order.commodity ? props.order.commodity : '--',
        'commodityValue'
      )}
      <div className="pieces detailsTitle">{PIECES}</div>
      {createField(
        props.order && props.order.pieces
          ? props.order.pieces.toString()
          : '--',
        'piecesValue'
      )}
      <div className="weight detailsTitle">{WEIGHT}</div>
      {createField(
        props.order && props.order.weight
          ? `${props.order.weight.toString()} lbs`
          : '--',
        'weightValue'
      )}
      <div className="stopReferences detailsTitle">{STOP_REFERENCES}</div>
      {createField(
        props.order && props.order.rawStops && props.order.rawStops.length > 0
          ? props.order.rawStops
              .map(({ reference_number }) => reference_number)
              .toString()
              .split(',')
              .filter((value, index, self) => self.indexOf(value) === index)
              .join(', ')
          : '--',
        'stopReferencesValue',
        false
      )}
    </div>
  );
};

export default React.memo(AccountingLoadInfoText);
