import './orderDetailsContainer.scss';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import OrderSummary from '../orderDetails/orderDetails';
import OrderDetailsMapInfo from '../orderDetailsMapInfo/orderDetailsMapInfo';
import { useSelector, useDispatch } from 'react-redux';
import { retrieveOrderData } from '../../../store/orders/ordersActions';
import Spinner from '../../Spinner';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router';
import {
  getDetailIsLoading,
  getDetailMarkersLoading,
  getDetailOrder,
  getShowNotificationsModal,
  getMarkersForOrderDetail,
  getMapsError
} from '../../../store/orders/ordersSelectors';

function useOutsideAlerter(ref: React.MutableRefObject<any>) {
  const isLoading = useSelector(getDetailIsLoading);
  const showModalDialog = useSelector(getShowNotificationsModal);
  const { search } = useLocation();
  const { push } = useHistory();

  const isRow = (el: HTMLElement) => {
    if (el.classList.contains('tableRow')) {
      return true;
    }
    let parent = el.parentElement;
    while (parent) {
      if (parent.classList.contains('tableRow')) {
        return true;
      }
      parent = parent.parentElement;
    }
    return false;
  };

  function handleClickOutside(event: { target: any }) {
    if (isLoading || showModalDialog) return;

    if (!isRow(event.target)) {
      if (ref.current && !ref.current.contains(event.target)) {
        const { orderId, ...rest } = queryString.parse(search);
        push({
          pathname: '/shipments',
          search: queryString.stringify({ ...rest })
        });
      }
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

interface Props {
  orderId: string;
}

const OrderDetailsContainer: FunctionComponent<Props> = ({ orderId }) => {
  const wrapperRef = useRef(null);
  const order = useSelector(getDetailOrder);
  const isLoading = useSelector(getDetailIsLoading);
  const isMarkersLoading = useSelector(getDetailMarkersLoading);
  const markers = useSelector(getMarkersForOrderDetail);
  const isMapsError = useSelector(getMapsError);
  const dispatch = useDispatch();

  useEffect(() => {
    if (orderId) {
      dispatch(retrieveOrderData(orderId));
    }
  }, [dispatch, orderId]);

  useOutsideAlerter(wrapperRef);

  return (
    <div className="detailPageContainer" ref={wrapperRef}>
      {(order || isLoading) && (
        <React.Fragment>
          {isLoading ? (
            <div className="orderSummaryWaiting">
              <Spinner />
            </div>
          ) : (
            <OrderSummary />
          )}
          {isMarkersLoading || isLoading ? (
            <div className="mapMarkersWaiting">
              <Spinner />
            </div>
          ) : (
            <div className="orderDetailsMap">
              <OrderDetailsMapInfo
                markers={markers}
                isMapsError={isMapsError}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default React.memo<Props>(OrderDetailsContainer);
