exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".modal{background-color:#f8f8f8;height:500px;width:500px}.modalTitle{display:flex;justify-content:space-between}.modalTitle .titleText{width:75%}.modalTitle .closeButton{width:20%;margin-top:-15px;margin-right:-100px;cursor:pointer}.mainText{line-height:normal;margin-bottom:15px}.notifyAllContainer{display:flex;justify-content:flex-start;align-items:center}.addContainer{display:flex;justify-content:space-between;align-items:center}.addContainer .icon{width:5%}.addContainer .disabledIcon{filter:grayscale(1);cursor:default}.addContainer .input{width:90%}.contactsContainer{background-color:#fff;margin:-30px -60px -60px -60px;padding:5px 0px;max-height:300px;min-height:300px;height:300px;overflow-y:scroll}.contactsContainer .contact{background-color:#fff;margin:15px 10px;padding:15px;box-shadow:0 2px 4px 0 rgba(0,0,0,.5);display:flex;justify-content:space-between}.contactsContainer .contact .actions .actionIcon{padding:0px 15px;cursor:pointer;opacity:.8}.contactsContainer .contact .actions .deleteIcon{height:18px}", ""]);

// Exports
exports.locals = {
	"ui-blue-01": "#0091ea",
	"ui-blue-02": "#0077b8",
	"ui-blue-03": "#005b8d",
	"ui-gray-01": "#333",
	"ui-gray-02": "#666",
	"ui-gray-03": "#999",
	"ui-gray-04": "#ccc",
	"ui-dropshadow": "rgba(0,0,0,.5)",
	"ui-background": "#f8f8f8",
	"ui-white": "#fff",
	"support-red": "#ef3824",
	"support-orange": "#ff9000",
	"support-green": "#72bf44",
	"support-yellow": "#ffc418"
};