export const FILTER_TYPES = {
  BOOKED: 'booked',
  IN_TRANSIT: 'intransit',
  DELIVERED: 'delivered'
};

export const ACTUAL_ARRIVAL = 'Actual Arrival';
export const ACTUAL_DEPARTURE = 'Actual Departure';
export const ADDL_REF = 'ADDL. REF';
export const ADDRESS = 'Address';
export const LOCATION = 'location';
export const BOOKED = 'Booked';
export const CHARGES = 'Charges';
export const COPYRIGHT_TEXT =
  '© Ryan Transportation Service, Inc. All rights reserved';
export const COMMODITY = 'Commodity';
export const DELIVERED = 'Delivered';
export const DELIVERY = 'Delivery';
export const DELIVERY_DATE = 'Delivery Date';
export const DELIVERY_LOCATION_PLACEHOLDER = 'Delivery City, State, or Zip';
export const IN_TRANSIT = 'In Transit';
export const LOAD_INFORMATION = 'Load Information';
export const LOG_OUT = 'Log Out';
export const NAME = 'Name';
export const ORDER = 'Order';
export const ORDER_REFERENCE_PLACEHOLDER = 'Order or Reference Number';
export const PENDING = 'Pending';
export const PICKUP = 'Pickup';
export const PIECES = 'Pieces';
export const PICKUP_DATE = 'Pickup Date';
export const PICKUP_LOCATION_PLACEHOLDER = 'Pickup City, State, or Zip';
export const PRIVACY_POLICY = 'Privacy Policy';
export const REFERENCE = 'Reference';
export const WEIGHT = 'Weight';
export const SCHEDULED_ARRIVAL = 'Scheduled Arrival';
export const STATUS = 'Status';
export const TERMS_OF_USE = 'Terms of Use';
export const TERMS_AND_CONDITIONS = 'Terms and Conditions';
export const TOKEN_CACHE = 'token';
export const CURRENT_USER = 'currentUser';
export const ACCOUNTING_SEARCH_PLACEHOLDER =
  'Search by Invoice, Order, or Reference';
export const BILLING_EMAIL_LINK = 'mailto:billing@ryanrts.com';
export const BILLING_EMAIL_LINK_TEXT = 'billing@ryanrts.com';
export const ACCOUNTING_PHONE_NUMBER_LINK = 'tel:877-519-1984';
export const ACCOUNTING_PHONE_NUMBER_TEXT = '877-519-1984';
'Search Invoices, Orders, or Reference Numbers';
export const STOP_REFERENCES = 'Stop References';

export const CSV_COLUMNS = [
  {
    key: 'invoice_id',
    label: 'Invoice Number'
  },
  {
    key: 'order_billDate',
    label: 'Bill Date'
  },
  {
    key: 'order_id',
    label: 'Order Number'
  },
  {
    key: 'order_arrival',
    label: 'Ship Date'
  },
  {
    key: 'invoice_age',
    label: 'Invoice Age'
  },
  {
    key: 'invoice_amount',
    label: 'Invoice Amount'
  },
  {
    key: 'invoice_balance',
    label: 'Invoice Balance'
  }
];
