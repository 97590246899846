import React from 'react';
import { FileItem } from '../../store/orders/orderReduxTypes';
import to from 'await-to-js';
import OrderService from '../../services/order/OrderService';
import FileLogo from '../../assets/images/Document_Dark.svg';
import './DetailDocumentSection.scss';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

const FILE_CATEGORIES: { [key: string]: string } = {
  '1': 'Bill of Lading',
  '2': 'Unloading Receipt',
  '4': 'Permit',
  '5': 'Scale Ticket',
  '6': 'Loading Receipt',
  '7': 'Invoice',
  '9': 'Rate Confirmation'
};

const getFileCategoriesText = (imageCategory: string) => {
  return FILE_CATEGORIES[imageCategory] || '';
};

interface Props {
  fileList: FileItem[];
  id: string | null;
}

const DetailDocumentSection = (props: Props) => {
  const onClick = (fileId: string, imageCategory: string) => async () => {
    const fileCategoriesText = getFileCategoriesText(imageCategory);
    const fileCategoriesTextWithUnderscores = fileCategoriesText.replace(
      / /g,
      '_'
    );
    await to(
      OrderService.downloadFile(
        fileId,
        `${props.id}_${fileCategoriesTextWithUnderscores}`
      )
    );
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.DOCUMENT_DOWNLOAD,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.SHIPMENTS,
      { orderNumber: fileId, documentType: fileCategoriesTextWithUnderscores }
    );
  };

  return props.fileList.length > 0 ? (
    <div className="detailsDocument">
      <div>
        <div className="detailsDocumentTitle">Documents</div>
        {props.fileList.map(({ documentTypeId, id }) => (
          <div key={id} className="documentWrapper">
            <img
              className="img"
              onClick={onClick(id, documentTypeId)}
              src={FileLogo}
            ></img>
            <div className="fileName">
              {getFileCategoriesText(documentTypeId)}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default DetailDocumentSection;
