import {
  Order,
  StopInformation,
  LastCallInInformation
} from '../types/orderTypes';
import { isEmpty } from './stringHelper';
import moment from 'moment-timezone';
import {
  isDateFiveMinutesAfterDate,
  isDateAfterDate,
  getDateWithTimezone
} from './dateHelper';
import * as Sentry from '@sentry/browser';

export const isOrder = (value: any): value is Order => {
  return value.orderId !== undefined;
};

export const isStop = (value: any): value is StopInformation => {
  return value.movement_sequence;
};

export const isStopComplete = (stop: StopInformation) => {
  return !isEmpty(stop.actual_arrival) && !isEmpty(stop.actual_departure);
};

export const isOrderComplete = (order: Order | undefined) => {
  if (!order) {
    return false;
  }

  const { rawStops } = order;

  const largestSequence = rawStops.reduce(
    (max, p) => (p.movement_sequence > max ? p.movement_sequence : max),
    rawStops[0].movement_sequence
  );

  const lastStop = rawStops.find(x => x.movement_sequence == largestSequence);

  return (lastStop && isStopComplete(lastStop)) || false;
};

export const isBooked = (value: Order | StopInformation | undefined) => {
  if (!value) {
    return false;
  }

  if (isOrder(value)) {
    const firstStop = value.rawStops.find(stop => stop.movement_sequence === 1);
    return firstStop
      ? firstStop.movement_sequence === 1 &&
          firstStop.stop_type === 'PU' &&
          !isStopComplete(firstStop)
      : false;
  }

  if (isStop(value)) {
    return (
      value.movement_sequence === 1 &&
      value.stop_type === 'PU' &&
      !isStopComplete(value)
    );
  }

  return false;
};

export const isStopCurrentStop = (
  stop: StopInformation,
  stops: StopInformation[]
) => {
  const lastCompletedStop = stops.reduce(
    (completedStop: StopInformation | null, stop) => {
      if (stop.actual_arrival && stop.actual_departure) {
        if (!completedStop) {
          return stop;
        }
        return stop.movement_sequence > completedStop.movement_sequence
          ? stop
          : completedStop;
      }
      return completedStop;
    },
    null
  );

  // no completed stops
  if (!lastCompletedStop) {
    return stop.movement_sequence === 1;
  }

  // all stops complete
  if (lastCompletedStop.movement_sequence === stops.length) {
    return true;
  }

  return lastCompletedStop.movement_sequence + 1 === stop.movement_sequence;
};

export const getCurrentStop = (stops: StopInformation[]) => {
  return stops.find(stop => isStopCurrentStop(stop, stops));
};

export const getPreviousStop = (
  stop: StopInformation,
  stops: StopInformation[]
) => {
  if (stop.movement_sequence === 1) {
    return false;
  }

  return stops.find(x => x.movement_sequence === stop.movement_sequence - 1);
};

export const isCallInValid = (
  stop: StopInformation,
  stops: StopInformation[],
  callIn?: LastCallInInformation
) => {
  if (!callIn) {
    return false;
  }

  const isCurrentStop = isStopCurrentStop(stop, stops);
  if (isCurrentStop) {
    if (isStopComplete(stop)) {
      return false;
    }

    // all call date times are in central time
    // eventually we will move all the timezone code to the server
    // right now all dates have z appended from the database so we need to
    // remove the z indicating utc and switch it to the correct timezone
    const callDateTimeCentralTimezone = moment
      .tz(callIn.call_date_time.replace('Z', ''), 'America/Chicago')
      .format();
    const previousStop = getPreviousStop(stop, stops);

    if (
      previousStop &&
      isDateAfterDate(
        getDateWithTimezone(
          previousStop.timezone_id,
          callDateTimeCentralTimezone
        ),
        moment(previousStop.actual_departure)
          .utc()
          .format('YYYY-MM-DD HH:mm')
      )
    ) {
      return true;
    }
  }

  return false;
};

export const isDelayed = (
  stop: StopInformation,
  stops: StopInformation[],
  callIn?: LastCallInInformation
) => {
  if (!isEmpty(stop.actual_arrival) && !isEmpty(stop.actual_departure)) {
    return false;
  }

  if (!isEmpty(stop.actual_arrival)) {
    return false;
  }

  const currentDate = getDateWithTimezone(stop.timezone_id);

  const dateToUse = stop.sched_arrive_late
    ? stop.sched_arrive_late
    : stop.sched_arrive_early;

  const comparisionTime = moment(dateToUse).utc();

  if (isDateFiveMinutesAfterDate(currentDate, comparisionTime)) {
    return true;
  }

  // Booked we only want to check against the current date not the callin
  if (isBooked(stop)) {
    return false;
  }

  if (
    isStopCurrentStop(stop, stops) &&
    callIn &&
    isCallInValid(stop, stops, callIn)
  ) {
    const dateToUse = stop.sched_arrive_late
      ? stop.sched_arrive_late
      : stop.sched_arrive_early;

    return isDateFiveMinutesAfterDate(callIn.new_eta, dateToUse);
  }

  return false;
};

export const isOrderDelayed = (order: Order | undefined) => {
  if (!order) {
    return false;
  }

  const { rawStops, rawCallin } = order;
  const currentStop = getCurrentStop(order.rawStops);

  return currentStop ? isDelayed(currentStop, rawStops, rawCallin) : false;
};

export const getStopStatusText = (
  stop: StopInformation | undefined,
  stops: StopInformation[],
  callIn?: LastCallInInformation
) => {
  if (stop === undefined) {
    return '';
  }

  const { actual_arrival, actual_departure } = stop;
  if (!isEmpty(actual_arrival) && !isEmpty(actual_departure)) {
    return 'Complete';
  }

  if (!isEmpty(actual_arrival)) {
    return 'Pending';
  }

  const isStopBooked = isBooked(stop);
  const isCurrentStop = isStopCurrentStop(stop, stops);
  const isStopDelayed = isDelayed(stop, stops, callIn);
  const isCallInValidForStop = isCallInValid(stop, stops, callIn);

  if (isStopBooked) {
    return isStopDelayed ? 'Delayed' : 'Pending';
  }

  if (!isCurrentStop) {
    return isStopDelayed ? 'Delayed' : 'Pending';
  }

  if (isStopDelayed && !isCallInValidForStop) {
    return 'Delayed En Route';
  }

  if (isStopDelayed) {
    return `Delayed En Route`;
  }

  return `En Route`;
};

export const getSecondaryText = (stops: StopInformation[]) => {
  const firstStop = stops.find(stop => stop.movement_sequence === 1);
  const largestSequence = stops.reduce(
    (max, p) => (p.movement_sequence > max ? p.movement_sequence : max),
    stops[0].movement_sequence
  );
  const lastStop = stops.find(x => x.movement_sequence == largestSequence);
  if (
    firstStop &&
    lastStop &&
    (isBooked(firstStop) || isStopComplete(lastStop))
  ) {
    return '';
  }

  const currentStop = getCurrentStop(stops);
  // haven't left the current stop
  if (
    currentStop &&
    currentStop.actual_arrival &&
    !currentStop.actual_departure
  ) {
    return 'Arrived at Stop';
  }

  // one pickup, one delivery
  if (stops.length === 2) {
    return 'En Route to Delivery';
  }

  const isCurrentStopLastStop =
    currentStop &&
    lastStop &&
    currentStop.movement_sequence === lastStop.movement_sequence;

  if (isCurrentStopLastStop) {
    return 'En Route to Final Delivery';
  }

  return 'En Route to Next Stop';
};

export const getPrimaryText = (
  stops: StopInformation[],
  callIn?: LastCallInInformation
) => {
  const currentStop = getCurrentStop(stops);
  if (currentStop && isBooked(currentStop)) {
    if (currentStop && isDelayed(currentStop, stops, callIn)) {
      return 'Delayed';
    }
    return 'Booked';
  }
  const largestSequence = stops.reduce(
    (max, p) => (p.movement_sequence > max ? p.movement_sequence : max),
    stops[0].movement_sequence
  );
  const lastStop = stops.find(x => x.movement_sequence == largestSequence);
  if (lastStop && isStopComplete(lastStop)) {
    return 'Delivered';
  }

  if (currentStop && isDelayed(currentStop, stops, callIn)) {
    return 'Delayed In Transit';
  }

  return 'In Transit';
};

export const isValidStops = (stops: StopInformation[], orderId: string) => {
  if (stops.length < 2) {
    console.log(`Stops are not defined for load id: ${orderId}`);
  }
  const firstStop = stops.find(stop => stop.movement_sequence === 1);
  const largestSequence = stops.reduce(
    (max, p) => (p.movement_sequence > max ? p.movement_sequence : max),
    stops[0].movement_sequence
  );
  const lastStop = stops.find(x => x.movement_sequence == largestSequence);
  if (!firstStop || !lastStop) {
    Sentry.captureException(`Invalid stop data for ${orderId}`);
    return false;
  }
  return true;
};
