import React, { FunctionComponent } from 'react';
import './InvoiceInfo.scss';
import { Invoice } from 'store/accounting/accountingReduxTypes';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { Order } from 'types/orderTypes';

interface Props {
  invoice: Invoice | undefined;
  order: Order | undefined;
}

const InvoiceInfo: FunctionComponent<Props> = ({
  invoice = {
    totalAmount: null,
    paidAmount: null,
    remainingBalance: null,
    dueDate: '',
    billDueDays: null,
    billDate: ''
  },
  order
}) => {
  const createField = (text: string, className: string) => {
    if (text.length > 11) {
      return (
        <Tooltip title={text} placement="top">
          <div
            data-testid="InvoiceInfoValue"
            className={`${className} detailsValue loadInfoEllipsis`}
          >
            {text}
          </div>
        </Tooltip>
      );
    } else {
      return (
        <div
          data-testid="InvoiceInfoValue"
          className={`${className} detailsValue loadInfoEllipsis`}
        >
          {text}
        </div>
      );
    }
  };

  return (
    <div className="invoiceInfo">
      <div className="invoiceContainer">
        <div className="invoiceInfoTitle">Invoice Information</div>
        <div className="amountName detailsTitle">Invoice Amount</div>
        <div className="amountValue detailsValue overflowEllipsis">
          {createField(
            order?.orderCharge?.finalCharge
              ? `$${order?.orderCharge?.finalCharge.toLocaleString()}`
              : '--',
            'orderValue'
          )}
        </div>
        <div className="dateName detailsTitle">Bill Date</div>
        <div className="dateValue detailsValue overflowEllipsis">
          {createField(
            invoice.dueDate
              ? `${moment(invoice.billDate).format('MM/DD/YYYY')}`
              : '--',
            'orderValue'
          )}
        </div>
        <div className="paidName detailsTitle">Paid</div>
        <div className="paidValue detailsValue overflowEllipsis">
          {createField(
            invoice.paidAmount
              ? invoice.paidAmount < 0
                ? `$${Math.abs(invoice.paidAmount).toLocaleString()}`
                : `$${invoice.paidAmount.toLocaleString()}`
              : '--',
            'orderValue'
          )}
        </div>
        <div className="termName detailsTitle">Term</div>
        <div className="termValue detailsValue overflowEllipsis">
          {createField(
            invoice.billDueDays ? `Net ${invoice.billDueDays} Days` : '--',
            'orderValue'
          )}
        </div>
        <div className="balanceName detailsTitle">Balance</div>
        <div className="balanceValue detailsValue overflowEllipsis">
          {createField(
            invoice.remainingBalance
              ? `$${invoice.remainingBalance.toLocaleString()}`
              : '--',
            'orderValue'
          )}
        </div>
      </div>
    </div>
  );
};

export default InvoiceInfo;
