import React from 'react';
import InTransitIcon from '../../assets/images/InTransit.svg';
import NoTrackingIcon from '../../assets/images/NoTracking.svg';
import DelayedInTransitIcon from '../../assets/images/DelayedInTransit.svg';
import BookedIcon from '../../assets/images/Booked.svg';
import DeliveredIcon from '../../assets/images/Delivered.svg';
import './truckStatusIcon.scss';
import { Tooltip, withStyles } from '@material-ui/core';
interface TruckStatusIconProps {
  isTracking: boolean;
  isDelayed: boolean;
  isBooked: boolean;
  isDelivered: boolean;
  tooltipBackgroundColor?: string;
}

const TruckStatusIcon: React.FunctionComponent<TruckStatusIconProps> = ({
  isBooked,
  isDelayed,
  isDelivered,
  isTracking,
  tooltipBackgroundColor
}) => {
  const getTruckingIcon = () => {
    if (isBooked) {
      return BookedIcon;
    }

    if (isDelivered) {
      return DeliveredIcon;
    }

    if (isTracking) {
      return isDelayed ? DelayedInTransitIcon : InTransitIcon;
    }
    return NoTrackingIcon;
  };

  const truckingIcon = getTruckingIcon();

  const StyledTooltip = withStyles(() => {
    if (tooltipBackgroundColor) {
      return {
        tooltip: {
          backgroundColor: tooltipBackgroundColor
        }
      };
    }
    return { tooltip: {} };
  })(Tooltip);

  return (
    <>
      {truckingIcon ? (
        truckingIcon === DelayedInTransitIcon ||
        truckingIcon === InTransitIcon ||
        truckingIcon === NoTrackingIcon ? (
          <StyledTooltip
            title={isTracking ? 'Tracking Available' : 'Tracking Unavailable'}
            placement="top"
          >
            <img className="icon" src={truckingIcon} />
          </StyledTooltip>
        ) : (
          <img className="icon" src={truckingIcon} />
        )
      ) : (
        <div className="noIcon" />
      )}
    </>
  );
};

export default TruckStatusIcon;
