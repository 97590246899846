// packages
import React from 'react';

// styles
import { ActionButtonsContainer, StyledButton } from './RegistrationStyles';

// props
interface Props {
  isLeftButtonDisabled?: boolean;
  isRightButtonDisabled: boolean;
  leftButtonLabel?: string;
  onBack: () => void;
  onContinue: any;
  rightButtonLabel?: string;
  visible: boolean;
}

export const StepNavigation = ({
  isLeftButtonDisabled = false,
  isRightButtonDisabled = false,
  leftButtonLabel = 'Back',
  onBack,
  onContinue,
  rightButtonLabel = 'Continue',
  visible
}: Props) => {
  if (!visible) {
    return null;
  }

  return (
    <ActionButtonsContainer>
      <StyledButton
        buttonStyle="outlined"
        disabled={isLeftButtonDisabled}
        onClick={onBack}
      >
        {leftButtonLabel}
      </StyledButton>
      <StyledButton disabled={isRightButtonDisabled} onClick={onContinue}>
        {rightButtonLabel}
      </StyledButton>
    </ActionButtonsContainer>
  );
};

export default StepNavigation;
