import React from 'react';
import QuestionMark from '../../assets/images/QuestionMark.svg';
import LoadTrackingInputs from './LoadTrackingInputs';
import LoadTrackingSignUpButton from './LoadTrackingSignUpButton';
import {
  SearchContainer,
  StatusText,
  Icon,
  SignUp,
  ParentContainer,
  InputContainer
} from './LoadTrackingStyles';

export const LoadTrackingNoLoadsFound = () => {
  return (
    <SearchContainer>
      <ParentContainer flexDirection="column">
        <StatusText>
          <Icon src={QuestionMark} />
        </StatusText>
        <StatusText>No loads found, please contact</StatusText>
        <StatusText>
          <a href={`mailto:shipperportal@ryantrans.com`}>
            shipperportal@ryantrans.com
          </a>
          &nbsp;or call (866)-977-9877
        </StatusText>
        <InputContainer>
          <LoadTrackingInputs />
        </InputContainer>
        <SignUp flexDirection="column" alignItems="center">
          <LoadTrackingSignUpButton
            text={`Are you a Ryan Transportation customer, or want to view all your loads?`}
          />
        </SignUp>
      </ParentContainer>
    </SearchContainer>
  );
};

export default LoadTrackingNoLoadsFound;
