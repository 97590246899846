import React, { forwardRef, useEffect, useState } from 'react';
import {
  FormContainer,
  TextInputStyled,
  AddressMenu,
  StyledPhoneFormatTextInput,
  ErrorText,
  SelectInputContainer,
  CheckBoxStyled,
  Footer
} from './AddressBookStyles';
import Grid from '@material-ui/core/Grid';
import {
  Address,
  AddressFormData
} from '../../store/addressBook/addressBookReduxTypes';
import { SelectInput } from '../cloverSelect/SelectInput';
import { useSelector } from 'react-redux';
import usStates from '../../utilities/locations/usStates.json';
import canadaStates from '../../utilities/locations/canadaStates.json';
import mexicoStates from '../../utilities/locations/mexicoStates.json';
import countries from '../../utilities/locations/countries.json';
import { getHoldAddress } from '../../store/addressBook/addressBookSelectors';
import { MenuOption } from '../../components/cloverSelect/SingleSelectMenu';
import {
  getAddressesData,
  getEditAddressIndex
} from '../../store/addressBook/addressBookSelectors';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import { useDispatch } from 'react-redux';
import {
  postAddressBook,
  setAddressBookSnackbar,
  setAddressEntryOpen
} from '../../store/addressBook/addressBookActions';
import UserService from '../../services/user/UserService';
import * as FirebaseUtils from '../../utilities/firebaseUtils';
import { MenuItem } from 'shamrock-clover-ui/dist/clover/components/MenuItem/MenuItem';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

// eslint-disable-next-line react/display-name
export const SelectInputWithError = forwardRef<
  HTMLInputElement,
  {
    label: string;
    error?: string;
    onOptionSelected: (value: MenuOption) => void;
    options: MenuOption[];
    value?: MenuOption;
    isMenuSmall?: boolean;
  }
>(({ label, error, onOptionSelected, options, value, isMenuSmall }) => {
  return (
    <SelectInputContainer error={!!error} isMenuSmall={isMenuSmall}>
      <SelectInput
        label={label}
        onOptionSelected={(option: any) => onOptionSelected(option)}
        options={options}
        value={value}
      />
      <ErrorText>{error}</ErrorText>
    </SelectInputContainer>
  );
});

interface ErrorFormData {
  name?: string;
  address1?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  phoneNumber?: string;
}

interface Props {
  closeModal: Function;
  setEntryFormBlankOrNoChange: Function;
}

export const AddressForm = ({
  closeModal,
  setEntryFormBlankOrNoChange
}: Props) => {
  const dispatch = useDispatch();

  const holdAddress = useSelector(getHoldAddress);
  const addressBook = useSelector(getAddressesData);
  const editAddressIndex = useSelector(getEditAddressIndex);

  const [addressFormData, setAddressFormData] = useState<AddressFormData>({
    name: { errorMessage: '', value: '' },
    address1: { errorMessage: '', value: '' },
    address2: { errorMessage: '', value: '' },
    city: { errorMessage: '', value: '' },
    stateProvince: { errorMessage: '', value: '' },
    postalCode: { errorMessage: '', value: '' },
    country: { errorMessage: '', value: 'United States' },
    phoneNumber: { errorMessage: '', value: '' },
    appointmentRequired: { errorMessage: '', value: false }
  });

  const {
    suggestions: { data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete();

  const [addressFocus, setAddressFocus] = useState<boolean>(true);
  const [stateDisplayName, setStateDisplayName] = useState<string>('');

  const [stateOptions, setStateOptions] = useState<MenuOption[]>(
    usStates.map(state => ({
      optionName: state.name,
      value: state.abbreviation
    }))
  );

  var countryOptions = countries
    .map(country => country.name)
    .map(optionName => ({ optionName }));

  const handleAddressSelect = ({ description }: { description: any }) => () => {
    clearSuggestions();

    getGeocode({ address: description })
      .then(results => {
        let addressBody = {
          address1: { value: '' },
          city: { value: '' },
          stateProvince: { value: '' },
          country: { value: '' },
          postalCode: { value: '' }
        };

        results[0].address_components.map(address => {
          if (address.types[0] === 'street_number') {
            addressBody.address1.value = address.long_name;
          }
          if (address.types[0] === 'route') {
            addressBody.address1.value =
              addressBody.address1.value + ' ' + address.long_name;
          }
          if (address.types[0] === 'locality') {
            addressBody.city.value = address.long_name;
          }
          if (address.types[0] === 'country') {
            addressBody.country.value = address.long_name;
          }
          if (address.types[0] === 'administrative_area_level_1') {
            setStateDisplayName(address.short_name);
            addressBody.stateProvince.value = address.long_name;
          }
          if (address.types[0] === 'postal_code') {
            addressBody.postalCode.value = address.long_name;
          }
        });

        if (addressBody.country.value === 'Mexico') {
          addressBody.stateProvince.value = addressBody.stateProvince.value.split(
            ' - '
          )[0];
        }

        setAddressFormData({
          ...addressFormData,
          ...addressBody
        });
      })
      .catch(error => {
        console.log('Error: ', error);
      });
  };

  const renderSuggestionsMenu = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      if (
        suggestion.terms[suggestion.terms.length - 1].value === 'USA' ||
        suggestion.terms[suggestion.terms.length - 1].value === 'Canada' ||
        suggestion.terms[suggestion.terms.length - 1].value === 'Mexico'
      ) {
        return (
          <MenuItem key={place_id} onClick={handleAddressSelect(suggestion)}>
            <p>
              <b>{main_text}</b>&nbsp;
              {secondary_text}
            </p>
          </MenuItem>
        );
      }
      return;
    });

  useEffect(() => {
    if (addressFormData.country.value === 'United States') {
      setStateOptions(
        usStates.map(state => ({
          optionName: state.name,
          value: state.abbreviation
        }))
      );
      setStateDisplayName('');
    }
    if (addressFormData.country.value === 'Canada') {
      setStateOptions(
        canadaStates.map(state => ({
          optionName: state.name,
          value: state.abbreviation
        }))
      );
      setStateDisplayName('');
    }
    if (addressFormData.country.value === 'Mexico') {
      setStateOptions(
        mexicoStates.map(state => ({
          optionName: state.name,
          value: state.abbreviation
        }))
      );
      setStateDisplayName('');
    }
  }, [addressFormData.country.value]);

  useEffect(() => {
    if (editAddressIndex === -1) {
      // if its a new address, check if the form is blank
      if (
        addressFormData?.name.value !== '' ||
        addressFormData?.address1.value !== '' ||
        addressFormData?.address2.value !== '' ||
        addressFormData?.city.value !== '' ||
        addressFormData?.stateProvince.value !== '' ||
        addressFormData?.postalCode.value !== '' ||
        addressFormData?.phoneNumber.value !== '' ||
        addressFormData?.appointmentRequired.value !== false
      )
        setEntryFormBlankOrNoChange(false);
      else setEntryFormBlankOrNoChange(true);
    } else {
      // if its an edit address, check form compared to original address
      if (!addressBook.addresses) return;

      const originalAddress = addressBook.addresses[editAddressIndex];
      if (
        addressFormData.name.value === originalAddress.name &&
        addressFormData.address1.value === originalAddress.address1 &&
        addressFormData.address2.value === originalAddress.address2 &&
        addressFormData.city.value === originalAddress.city &&
        addressFormData.stateProvince.value === originalAddress.stateProvince &&
        addressFormData.postalCode.value === originalAddress.postalCode &&
        addressFormData.phoneNumber.value === originalAddress.phoneNumber &&
        addressFormData.appointmentRequired.value ===
          originalAddress.appointmentRequired
      )
        setEntryFormBlankOrNoChange(true);
      else setEntryFormBlankOrNoChange(false);
    }
  }, [addressFormData]);

  useEffect(() => {
    if (
      editAddressIndex === -1 &&
      !(
        holdAddress?.name !== '' ||
        holdAddress?.address1 !== '' ||
        holdAddress?.address2 !== '' ||
        holdAddress?.city !== '' ||
        holdAddress?.stateProvince !== '' ||
        holdAddress?.postalCode !== '' ||
        holdAddress?.phoneNumber !== '' ||
        holdAddress?.appointmentRequired !== false
      )
    ) {
      return;
    }

    let addressBeingEdited: Address | undefined;
    if (editAddressIndex !== -1)
      addressBeingEdited = addressBook.addresses?.[editAddressIndex];
    else addressBeingEdited = holdAddress;

    if (!addressBeingEdited) {
      return;
    }

    const savedState = stateOptions.find(
      state => state.optionName === addressBeingEdited?.stateProvince
    );

    setStateDisplayName(savedState?.value as string);
    setAddressFormData({
      name: { errorMessage: '', value: addressBeingEdited.name },
      address1: { errorMessage: '', value: addressBeingEdited.address1 },
      address2: { errorMessage: '', value: addressBeingEdited.address2 },
      city: { errorMessage: '', value: addressBeingEdited.city },
      stateProvince: {
        errorMessage: '',
        value: savedState?.optionName as string
      },
      postalCode: { errorMessage: '', value: addressBeingEdited.postalCode },
      country: { errorMessage: '', value: addressBeingEdited.country },
      phoneNumber: {
        errorMessage: '',
        value: addressBeingEdited.phoneNumber
      },
      appointmentRequired: {
        errorMessage: '',
        value: addressBeingEdited.appointmentRequired
      }
    });
  }, [editAddressIndex, holdAddress]);

  const handleOnChangeForm = (name: string, value: string) => {
    setAddressFormData({
      ...addressFormData,
      [name]: { value: value, errorMessage: '' }
    });

    if (name === 'phoneNumber' && value && value.length < 10) {
      setAddressFormData({
        ...addressFormData,
        [name]: {
          value: value,
          errorMessage: 'Enter a valid phone number'
        }
      });
      return;
    }
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setAddressFormData({
        ...addressFormData,
        [name]: { value: '', errorMessage: 'Required field' }
      });
    }
  };

  interface ErrorFormData {
    name?: string;
    address1?: string;
    city?: string;
    stateProvince?: string;
    postalCode?: string;
    phoneNumber?: string;
  }
  type RequiredFormDataKeys = keyof ErrorFormData;

  const setRequiredError = () => {
    let fields: RequiredFormDataKeys[] = [
      'name',
      'address1',
      'city',
      'stateProvince',
      'postalCode',
      'phoneNumber'
    ];
    let errorMessages: ErrorFormData = {};
    let isError = false;

    fields.forEach(key => {
      if (
        addressFormData[key].value === '' ||
        addressFormData[key].value === undefined
      ) {
        errorMessages[key as RequiredFormDataKeys] = 'Required field';
        isError = true;
      }
      if (
        key === 'phoneNumber' &&
        addressFormData[key].value &&
        addressFormData[key].value.length < 10
      ) {
        errorMessages[key as RequiredFormDataKeys] =
          'Enter a valid phone number';
        isError = true;
      }
    });
    setAddressFormData({
      ...addressFormData,
      name: {
        errorMessage: errorMessages.name,
        value: addressFormData.name.value
      },
      address1: {
        errorMessage: errorMessages.address1,
        value: addressFormData.address1.value
      },
      city: {
        errorMessage: errorMessages.city,
        value: addressFormData.city.value
      },
      stateProvince: {
        errorMessage: errorMessages.stateProvince,
        value: addressFormData.stateProvince.value
      },
      postalCode: {
        errorMessage: errorMessages.postalCode,
        value: addressFormData.postalCode.value
      },
      phoneNumber: {
        errorMessage: errorMessages.phoneNumber,
        value: addressFormData.phoneNumber.value
      }
    });
    return isError;
  };

  const postAddress = () => {
    if (setRequiredError()) {
      return;
    }

    const newAddress: Address = {
      name: addressFormData.name.value,
      address1: addressFormData.address1.value,
      address2: addressFormData.address2.value,
      city: addressFormData.city.value,
      stateProvince: addressFormData.stateProvince.value,
      postalCode: addressFormData.postalCode.value,
      country: addressFormData.country.value,
      phoneNumber: addressFormData.phoneNumber.value,
      appointmentRequired: addressFormData.appointmentRequired.value,
      isDeleted: false
    };

    if (!addressBook.addresses) {
      addressBook.addresses = [];
    }

    if (editAddressIndex !== -1) {
      addressBook.addresses[editAddressIndex] = newAddress;
    } else {
      addressBook?.addresses.push(newAddress);
    }

    const mcleod_customer_id = UserService.getMcleodCustomerId();
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      editAddressIndex !== -1
        ? FirebaseUtils.FirebasePages.EDIT_ADDRESS
        : FirebaseUtils.FirebasePages.ADD_NEW_ADDRESS,
      {
        mcleodID: mcleod_customer_id,
        companyName: newAddress.name,
        address: newAddress.address1,
        address2: newAddress.address2,
        phoneNumber: newAddress.phoneNumber,
        city: newAddress.city,
        state: newAddress.stateProvince,
        zipCode: newAddress.postalCode,
        country: newAddress.country,
        appointmentRequired: newAddress.appointmentRequired,
        description:
          editAddressIndex !== -1
            ? 'Edit Address Details'
            : 'New Address Details'
      }
    );

    dispatch(postAddressBook(addressBook));
    dispatch(setAddressBookSnackbar(true, false, 'Address saved.'));
    dispatch(setAddressEntryOpen(false));
  };

  return (
    <>
      <FormContainer>
        <Grid container>
          <Grid item sm={3}>
            <TextInputStyled
              label="Company Name"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => {
                handleOnChangeForm('name', value);
              }}
              value={addressFormData.name.value}
              onBlur={() => {
                handleOnBlurForm('name', addressFormData.name.value);
              }}
              error={addressFormData.name.errorMessage}
            />
          </Grid>
          <Grid item sm={3}>
            <TextInputStyled
              label="Address 1"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => {
                handleOnChangeForm('address1', value);
                setValue(value);
              }}
              value={addressFormData.address1.value}
              onBlur={() => {
                handleOnBlurForm('address1', addressFormData.address1.value);
                setValue('');
              }}
              error={addressFormData.address1.errorMessage}
              onFocus={() => setAddressFocus(true)}
              autoComplete={false}
            />
            {addressFocus && (
              <AddressMenu>{renderSuggestionsMenu()}</AddressMenu>
            )}
          </Grid>
          <Grid item sm={3}>
            <TextInputStyled
              label="Address 2"
              helperText="Optional"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => {
                handleOnChangeForm('address2', value);
              }}
              value={addressFormData.address2.value}
            />
          </Grid>
          <Grid item sm={3}>
            <StyledPhoneFormatTextInput
              label="Phone Number"
              changeHandler={(value: string) => {
                handleOnChangeForm('phoneNumber', value);
              }}
              value={addressFormData.phoneNumber.value}
              onBlur={() => {
                handleOnBlurForm(
                  'phoneNumber',
                  addressFormData.phoneNumber.value
                );
              }}
              error={addressFormData.phoneNumber.errorMessage}
            />
          </Grid>

          <Grid item sm={4}>
            <TextInputStyled
              label="City"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => {
                handleOnChangeForm('city', value);
              }}
              value={addressFormData.city.value}
              onBlur={() => {
                handleOnBlurForm('city', addressFormData.city.value);
              }}
              error={addressFormData.city.errorMessage}
            />
          </Grid>
          <Grid item sm={2}>
            <SelectInputWithError
              label="State"
              onOptionSelected={(option: MenuOption) => {
                //set full name as value
                handleOnChangeForm('stateProvince', option.optionName);
                // set display name from abbreviation
                setStateDisplayName(option.value as string);
              }}
              options={stateOptions}
              value={{ optionName: stateDisplayName }}
              error={addressFormData.stateProvince.errorMessage}
              isMenuSmall={true}
            />
          </Grid>
          <Grid item sm={2}>
            <TextInputStyled
              label="Zip Code"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => {
                handleOnChangeForm('postalCode', value);
              }}
              value={addressFormData.postalCode.value}
              onBlur={() => {
                handleOnBlurForm(
                  'postalCode',
                  addressFormData.postalCode.value
                );
              }}
              error={addressFormData.postalCode.errorMessage}
            />
          </Grid>
          <Grid item sm={4}>
            <SelectInputWithError
              label="Country"
              onOptionSelected={option => {
                setAddressFormData({
                  ...addressFormData,
                  stateProvince: { value: '', errorMessage: '' },
                  postalCode: { value: '', errorMessage: '' },
                  country: { value: option?.optionName, errorMessage: '' }
                });
              }}
              options={countryOptions}
              value={{ optionName: addressFormData.country.value }}
              error={addressFormData.country.errorMessage}
            />
          </Grid>

          <Grid item sm={12}>
            <CheckBoxStyled
              fontSize="14"
              text="Appointment required"
              onClick={() =>
                setAddressFormData({
                  ...addressFormData,
                  appointmentRequired: {
                    value: !addressFormData.appointmentRequired.value,
                    errorMessage: ''
                  }
                })
              }
              checked={addressFormData.appointmentRequired.value}
            />
          </Grid>
        </Grid>
      </FormContainer>
      <Footer>
        <Button
          buttonStyle="outlined"
          isRounded={false}
          onClick={() => {
            const mcleod_customer_id = UserService.getMcleodCustomerId();
            FirebaseUtils.logFirebaseEvent(
              FirebaseUtils.FirebaseEvents.CLICK,
              FirebaseUtils.FirebaseModules.SHIPPER,
              editAddressIndex !== -1
                ? FirebaseUtils.FirebasePages.EDIT_ADDRESS
                : FirebaseUtils.FirebasePages.ADD_NEW_ADDRESS,
              {
                mcleodID: mcleod_customer_id,

                description:
                  editAddressIndex !== -1
                    ? 'Cancel Edit Address'
                    : 'Cancel Add New Address'
              }
            );
            closeModal();
          }}
        >
          CANCEL
        </Button>
        <Button buttonStyle="solid" isRounded={false} onClick={postAddress}>
          SAVE
        </Button>
      </Footer>
    </>
  );
};

export default AddressForm;
