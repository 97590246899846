import React, { Fragment, useState } from 'react';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import NoShipmentView from '../../features/blankState/noShipmentView';
import './Table.scss';

export interface TableJson {
  [x: string]: any;
}

interface Props {
  style?: React.CSSProperties;
}

export interface HeaderDataProps {
  label: string;
  key: string;
  width?: string;
  style?: React.CSSProperties;
}

interface TableHeaderProps extends Props {
  columns: HeaderDataProps[];
  cellStyles: TableCellStyles[];
  isSortable?: boolean;
  orderByClick?: (columnName: string, order: 'asc' | 'desc') => void;
  singleSortColumn?: string;
}

export const TableHeader: React.FunctionComponent<TableHeaderProps> = ({
  style,
  columns,
  cellStyles,
  isSortable,
  orderByClick,
  singleSortColumn
}) => {
  const [activeColumn, setActiveColumn] = useState();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const sortHeader = (evt: any) => {
    if (singleSortColumn && singleSortColumn !== evt.currentTarget.innerText)
      return;
    const newDirection = direction === 'asc' ? 'desc' : 'asc';
    setDirection(newDirection);
    setActiveColumn(evt.currentTarget.innerText);
    if (orderByClick) orderByClick(evt.currentTarget.innerText, direction);
  };

  const getStyles = (currentKey: string): React.CSSProperties => {
    const currentCell = cellStyles.find(x => x.columnKey === currentKey);
    if (currentCell) return currentCell.currentStyle;

    return {};
  };

  const getColumn = (currentLabel: string) => {
    if (isSortable) {
      return (
        <TableSortLabel
          active={currentLabel === activeColumn}
          direction={direction}
          onClick={sortHeader}
        >
          <span>{currentLabel}</span>
        </TableSortLabel>
      );
    } else {
      return <span>{currentLabel}</span>;
    }
  };

  return (
    <thead
      className="tableHeader"
      style={{
        ...style
      }}
    >
      <tr>
        {columns &&
          columns.map((headerData: HeaderDataProps, index: number) => {
            return (
              <th key={index} style={getStyles(headerData.key)}>
                {getColumn(headerData.label)}
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

interface TableRowProps extends Props {
  items: TableJson;
  cellStyles?: TableCellStyles[];
  notificationsOn?: boolean;
}

const TableRow: React.FunctionComponent<TableRowProps> = ({
  items,
  cellStyles
}) => {
  const generateValue = (value: string | JSX.Element) => {
    if (value && typeof value == 'string' && value.startsWith('data:image')) {
      return <img className="icon" src={value} />;
    } else if (value && typeof value == 'string') {
      return <span className="column">{value ? value : ''}</span>;
    } else {
      return value;
    }
  };

  const getStyles = (currentKey: string): React.CSSProperties => {
    if (cellStyles) {
      const currentCell = cellStyles.find(x => x.columnKey === currentKey);
      if (currentCell) return currentCell.currentStyle;
    }
    return {};
  };
  return (
    <Fragment>
      {items &&
        Object.keys(items).map((key, index: number) => {
          if (key == 'identifier') return;
          return (
            <td
              key={`${index}${key}`}
              className="tableColumn"
              style={getStyles(key)}
            >
              {generateValue(items[key])}
            </td>
          );
        })}
    </Fragment>
  );
};

interface TableBodyProps extends Props {
  tableRowData: TableJson[];
  rowStyle?: React.CSSProperties;
  columnStyle?: React.CSSProperties;
  onRowClick?: (rowData: TableJson, index: number) => void;
  currentlySelectedIndex: number;
  cellStyles?: TableCellStyles[];
}

export const TableBody: React.FunctionComponent<TableBodyProps> = ({
  style,
  tableRowData,
  rowStyle,
  columnStyle,
  onRowClick,
  currentlySelectedIndex,
  cellStyles
}) => {
  const onClick = (rowData: TableJson, index: number) => {
    // add click row color
    if (onRowClick) onRowClick(rowData, index);
  };

  return (
    <tbody
      className="tableBody"
      style={{
        ...style
      }}
    >
      {tableRowData &&
        tableRowData.length > 0 &&
        tableRowData.map((rowData, index: number) => {
          return (
            <tr
              onClick={() => onClick(rowData, index)}
              data-testid={
                rowData.identifier
                  ? `OrderRow ${rowData.identifier}`
                  : `TableRow_${index}`
              }
              key={index}
              className={`tableRow ${
                currentlySelectedIndex === index ? 'selected' : 'deselected'
              } ${rowData['notificationsOn'] ? 'notificationsOn' : ''}`}
              style={{
                ...rowStyle
              }}
            >
              <TableRow
                cellStyles={cellStyles}
                style={columnStyle}
                items={rowData}
              />
            </tr>
          );
        })}
      {!tableRowData || (tableRowData.length === 0 && <NoShipmentView />)}
    </tbody>
  );
};

export interface TableCellStyles {
  currentStyle: React.CSSProperties;
  columnKey: string;
}

interface TableProps extends Props {
  headerComponent: (
    cellClasses: TableCellStyles[],
    isSortable: boolean | undefined
  ) => React.ReactElement;
  bodyComponent: (cellClasses: TableCellStyles[]) => React.ReactElement;
  cellStyles: TableCellStyles[];
  isSortable?: boolean;
}

const Table: React.FunctionComponent<TableProps> = ({
  style,
  headerComponent,
  bodyComponent,
  cellStyles,
  isSortable
}) => {
  return (
    <React.Fragment>
      <table
        className="tableView"
        style={{
          ...style
        }}
      >
        {headerComponent(cellStyles, isSortable)}
        {bodyComponent(cellStyles)}
      </table>
    </React.Fragment>
  );
};

export default Table;
