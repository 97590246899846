import React, { Fragment } from 'react';
import { Dialog } from 'shamrock-clover-ui/dist/clover/components/Dialog/Dialog';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import './cancelModal.scss';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import {
  setDestinationFilterString,
  setOriginFilterString
} from '../../../store/orders/ordersActions';

interface Props {
  toggleCancelModal: Function;
  toggleRequestQuoteModal: Function;
  showCancelModal: boolean;
}

const StyledModal = styled(Dialog)`
  padding: 0px !important;
`;

const CancelModal: React.FC<Props> = ({
  toggleCancelModal,
  toggleRequestQuoteModal,
  showCancelModal
}) => {
  const dispatch = useDispatch();

  const closeModals = () => {
    dispatch(setOriginFilterString(''));
    dispatch(setDestinationFilterString(''));

    toggleCancelModal(false);
    toggleRequestQuoteModal(false);
  };

  const continueEditing = () => {
    toggleCancelModal(false);
  };

  const getModalContent = () => {
    return (
      <>
        <div className="mainText">
          You will lose all of this quote’s details.
        </div>
        <div className="buttonContainer">
          <Button buttonStyle="outlined" onClick={continueEditing}>
            Continue Editing
          </Button>
          <Button buttonStyle="solid" onClick={closeModals}>
            Cancel Quote
          </Button>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <StyledModal
        open={showCancelModal}
        title="Are you sure you want to cancel?"
        content={getModalContent()}
      ></StyledModal>
    </Fragment>
  );
};

export default CancelModal;
