// packages
import MomentUtils from '@date-io/moment';
import React from 'react';
import { Moment } from 'moment';
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers';

// styles
import './datepickerfield.scss';

// props
interface Props {
  className?: string;
  disablePast?: boolean;
  format?: string;
  label?: string;
  maxDate?: Moment;
  minDate?: Moment;
  onChange: (date: Moment) => void;
  placeholder?: string;
  value: Moment | null;
  visible?: boolean;
  readOnly?: boolean;
  error?: string;
}

const DatePickerField: React.FunctionComponent<Props> = ({
  className,
  disablePast,
  format,
  label,
  maxDate,
  placeholder,
  minDate,
  onChange,
  value,
  visible = true,
  readOnly,
  error
}) => {
  if (!visible) {
    return null;
  }

  const handleOnChange = (date: Moment) => onChange(date);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div id="picker-container">
        <InlineDatePicker
          className={`${className} datepickerfield`}
          disablePast={disablePast}
          format={format}
          keyboard
          label={label}
          margin="none"
          maxDate={maxDate}
          minDate={minDate}
          placeholder={placeholder}
          onChange={handleOnChange}
          openTo="day"
          value={value}
          InputProps={{
            readOnly: readOnly,
            disabled: readOnly
          }}
          error={!!error}
          helperText={error}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DatePickerField;
