import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dialog } from 'shamrock-clover-ui/dist/clover/components/Dialog/Dialog';
import './unmatchedUserModal.scss';
import { UserService } from '../../services/';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

const Config = require('Config');

const StyledModal = styled(Dialog)`
  padding: 0px !important;
`;

const UnmatchedUserModal: FunctionComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const showModal = () => {
    const user = UserService.getCurrentUser();
    if (user?.mcleod_customer_id && user.mcleod_customer_id.length > 0) {
      setIsOpen(false);
      return;
    }
    setIsOpen(true);
  };

  useEffect(() => {
    showModal();
  }, []);

  const getModalContent = () => {
    return (
      <div className="modalContent">
        We&lsquo;ll contact you soon when it&lsquo;s ready
        <br />
        <br />
        Until then, use our <span className="bolded">Track Shipments</span>{' '}
        tool. It&lsquo;s open to everyone.
      </div>
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const trackShipmentButton = () => {
    return (
      <div
        onClick={() => {
          FirebaseUtils.logFirebaseEvent(
            FirebaseUtils.FirebaseEvents.CLICK,
            FirebaseUtils.FirebaseModules.SHIPPER,
            FirebaseUtils.FirebasePages.VERIFYACCOUNT,
            { description: 'Verifying Acct Track Shipments' }
          );
          window.location.assign(`${Config.shipperPortalUrl}loadtracking`);
        }}
        className="trackShipmentButton"
      >
        <p className="trackShipmentText">GO TO TRACK SHIPMENTS</p>
      </div>
    );
  };

  return (
    <Fragment>
      <StyledModal
        open={isOpen}
        title={`We're verifying your account.`}
        content={getModalContent()}
        actions={trackShipmentButton()}
        onClose={closeModal}
      ></StyledModal>
    </Fragment>
  );
};

export default UnmatchedUserModal;
