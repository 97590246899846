import React from 'react';
import TruckStatusIcon from '../../components/truckStatusIcon/TruckStatusIcon';
import { Order } from '../../types/orderTypes';
import {
  isBooked,
  isOrderDelayed,
  isOrderComplete,
  getSecondaryText,
  getPrimaryText
} from '../../utilities/orderUtilities';
import { getDate } from '../../utilities/dateHelper';
import { AppState } from 'store';
// import { Tooltip, withStyles } from '@material-ui/core';
import './ordersTableSelectors.scss';

// Commented out the Tooltip component until we have time to come back and
// conditionally apply it to text that is overflowing
// const BlackBackgroundTooltip = withStyles(() => {
//   return {
//     tooltip: {
//       backgroundColor: '#000000'
//     }
//   };
// })(Tooltip);

const getIcon = (order: Order) => {
  return (
    <div className="statusIcon">
      <TruckStatusIcon
        isTracking={order.rawCallin !== null}
        isBooked={isBooked(order)}
        isDelayed={isOrderDelayed(order)}
        isDelivered={isOrderComplete(order)}
        tooltipBackgroundColor="#000000"
      />
    </div>
  );
};

const getStatusInfoClass = (primaryText: string) => {
  return primaryText.includes('Delayed') ? 'lateColumn' : '';
};

const getStatus = (order: Order) => {
  const secondaryText = getSecondaryText(order.rawStops);
  const primaryText = getPrimaryText(order.rawStops, order.rawCallin);
  // const tooltipContent = (
  //   <div>
  //     <div> {primaryText}</div>
  //     <div> {secondaryText}</div>
  //   </div>
  // );
  return (
    <div className="tableCellContainer">
      {/* <BlackBackgroundTooltip title={tooltipContent} placement="top-start"> */}
      <div className={`statusText ${getStatusInfoClass(primaryText)}`}>
        <div className="statusValue ellipsis">{primaryText}</div>
        <div className="statusInfo ellipsis">{secondaryText}</div>
      </div>
      {/* </BlackBackgroundTooltip> */}
    </div>
  );
};

const getPickup = (order: Order) => {
  const initialPickup = order.rawStops[0];
  // const tooltipContent = (
  //   <div>
  //     <div>{initialPickup.locationName}</div>
  //     <div>
  //       {initialPickup.city}, {initialPickup.state} {initialPickup.zip_code}
  //     </div>
  //   </div>
  // );
  return (
    <div className="tableCellContainer">
      {/* <BlackBackgroundTooltip title={tooltipContent} placement="top-start"> */}
      <div className="basicData">
        <div className="ellipsis">{initialPickup.locationName}</div>
        <div className="ellipsis">
          {initialPickup.city}, {initialPickup.state} {initialPickup.zip_code}
        </div>
      </div>
      {/* </BlackBackgroundTooltip> */}
    </div>
  );
};

const getReference = (order: Order) => {
  // const tooltipContent = <div>{order.referenceId}</div>;
  return (
    <div className="tableCellContainer">
      {/* <BlackBackgroundTooltip title={tooltipContent} placement="top-start"> */}
      <div className="basicData">{order.referenceId}</div>
      {/* </BlackBackgroundTooltip> */}
    </div>
  );
};
const getDateString = (type: string, order: Order) => {
  const { rawStops } = order;

  const stop =
    type === 'Delivery' ? rawStops[rawStops.length - 1] : rawStops[0];

  return getDate(stop);
};

const getPickupDate = (order: Order) => {
  // const tooltipContent = <div>{getDateString('Pickup', order)}</div>;
  return (
    <div className="tableCellContainer">
      {/* <BlackBackgroundTooltip title={tooltipContent} placement="top-start"> */}
      <div className="pickup basicData dateField">
        {getDateString('Pickup', order)}
      </div>
      {/* </BlackBackgroundTooltip> */}
    </div>
  );
};

const getDelivery = (order: Order) => {
  const finalDelivery = order.rawStops.slice(-1)[0];
  // const tooltipContent = (
  //   <div>
  //     <div>{finalDelivery.locationName}</div>
  //     <div>
  //       {finalDelivery.city}, {finalDelivery.state}, {finalDelivery.zip_code}
  //     </div>
  //   </div>
  // );
  return (
    <div className="tableCellContainer">
      {/* <BlackBackgroundTooltip title={tooltipContent} placement="top-start"> */}
      <div className="basicData">
        <div className="ellipsis">{finalDelivery.locationName}</div>
        <div className="ellipsis">
          {finalDelivery.city}, {finalDelivery.state} {finalDelivery.zip_code}
        </div>
      </div>
      {/* </BlackBackgroundTooltip> */}
    </div>
  );
};

const getDeliveryDate = (order: Order) => {
  // const tooltipContent = <div>{getDateString('Delivery', order)}</div>;
  return (
    <div className="tableCellContainer">
      {/* <BlackBackgroundTooltip title={tooltipContent} placement="top-start"> */}
      <div className="delivery basicData dateField">
        {getDateString('Delivery', order)}
      </div>
      {/* </BlackBackgroundTooltip> */}
    </div>
  );
};

const getOrderId = (order: Order) => {
  // const tooltipContent = <div>{order.orderId}</div>;
  return (
    <div className="tableCellContainer">
      {/* <BlackBackgroundTooltip title={tooltipContent} placement="top-start"> */}
      <div className="basicData">{order.orderId}</div>
      {/* </BlackBackgroundTooltip> */}
    </div>
  );
};

export interface TableData {
  icon: JSX.Element;
  orderId: JSX.Element;
  status: JSX.Element;
  reference: JSX.Element;
  pickup: JSX.Element;
  pickupDate: JSX.Element;
  delivery: JSX.Element;
  deliveryDate: JSX.Element;
  identifier: string;
  notificationsOn?: boolean;
}

export const getOrdersTableData = (state: AppState): TableData[] => {
  let orders = state.orders.orders;
  let tableData: TableData[] = [];

  orders.forEach((order: Order) => {
    tableData.push({
      icon: getIcon(order),
      orderId: getOrderId(order),
      status: getStatus(order),
      reference: getReference(order),
      pickup: getPickup(order),
      pickupDate: getPickupDate(order),
      delivery: getDelivery(order),
      deliveryDate: getDeliveryDate(order),
      identifier: order.orderId
    });
  });

  return tableData;
};
