import React, { forwardRef } from 'react';
import { LoadDetailsFormData } from '../types/requestQuoteTypes';

// styles
import Grid from '@material-ui/core/Grid';
import { SelectInput } from 'shamrock-clover-ui/dist/clover/components/SelectInput/SelectInput';
import {
  TextInputStyled,
  MultilineTextInputStyled,
  GridContainer,
  SelectInputContainer,
  ErrorText,
  InputLabel,
  EmptyGrid,
  CheckBoxStyled
} from './requestQuoteStyles';

// props
interface Props {
  setFormData: Function;
  formData: LoadDetailsFormData;
}

// eslint-disable-next-line react/display-name
export const SelectInputStyled = forwardRef<
  HTMLInputElement,
  {
    label: string;
    error?: string;
    onOptionSelected: (value: string) => void;
    options: string[];
    value: string;
    width?: string;
    className?: string;
  }
>(({ label, error, onOptionSelected, options, value, width, className }) => {
  return (
    <SelectInputContainer width={width} className={className} error={!!error}>
      <SelectInput
        label={label}
        onOptionSelected={(option: any) => onOptionSelected(option)}
        options={options}
        value={value}
      />
      <ErrorText>{error}</ErrorText>
    </SelectInputContainer>
  );
});

export const DetailsForm = ({ formData, setFormData }: Props) => {
  const equipmentOptions = ['Van', 'Flatbed', 'Refrigerated'];
  const dollarValueOptions = [
    '$0 – $50,000',
    '$50,000 – $100,000',
    '$100,000+'
  ];
  const flatbedTypeOptions = [
    'Conestoga',
    'Double Drop',
    'Flatbed',
    'Hotshot',
    'RGN',
    'Step Deck',
    'Other'
  ];
  const tarpDetailsOptions = ['None', '4 feet', '6 feet', '8 feet'];

  const handleOnChangeForm = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: { value: value || '', errorMessage: '' }
    });
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setFormData({
        ...formData,
        [name]: { value: '', errorMessage: 'Required field' }
      });
    }
  };

  return (
    <GridContainer container>
      <Grid item sm={6}>
        <Grid item sm={12}>
          <TextInputStyled
            label="Commodity"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('commodity', value);
            }}
            onBlur={() => {
              handleOnBlurForm('commodity', formData.commodity.value);
            }}
            error={formData.commodity.errorMessage}
            value={formData.commodity.value}
          />
        </Grid>
        <Grid item sm={12}>
          <CheckBoxStyled
            fontSize="14"
            text="Hazmat"
            onClick={() =>
              setFormData({
                ...formData,
                hazmat: {
                  value: !formData.hazmat.value
                }
              })
            }
            checked={formData.hazmat.value}
          />
        </Grid>
        <Grid item sm={12}>
          <SelectInputStyled
            label="Equipment"
            onOptionSelected={(option: string) =>
              handleOnChangeForm('equipment', option)
            }
            options={equipmentOptions}
            value={formData.equipment.value}
            error={formData.equipment.errorMessage}
          />
        </Grid>
        {formData.equipment.value === 'Flatbed' ? (
          <>
            <Grid item sm={12}>
              <SelectInputStyled
                label="Flatbed Type"
                onOptionSelected={(option: string) =>
                  handleOnChangeForm('flatbedType', option)
                }
                options={flatbedTypeOptions}
                value={formData.flatbedType.value}
                error={formData.flatbedType.errorMessage}
              />
            </Grid>
            {formData.flatbedType.value === 'Other' ? (
              <Grid item sm={12}>
                <TextInputStyled
                  label="Specify Flatbed"
                  onChange={({
                    target: { value }
                  }: {
                    target: { value: string };
                  }) => {
                    handleOnChangeForm('specifyFlatbed', value);
                  }}
                  onBlur={() => {
                    handleOnBlurForm(
                      'specifyFlatbed',
                      formData.specifyFlatbed.value
                    );
                  }}
                  error={formData.specifyFlatbed.errorMessage}
                  value={formData.specifyFlatbed.value}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item sm={12}>
              <SelectInputStyled
                label="Tarp Details"
                onOptionSelected={(option: string) =>
                  handleOnChangeForm('tarpDetails', option)
                }
                options={tarpDetailsOptions}
                value={formData.tarpDetails.value}
                error={formData.tarpDetails.errorMessage}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
        {formData.equipment.value === 'Refrigerated' ? (
          <Grid item sm={12}>
            <TextInputStyled
              label="Temperature"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => {
                handleOnChangeForm('tempDetails', value);
              }}
              onBlur={() => {
                handleOnBlurForm('tempDetails', formData.tempDetails.value);
              }}
              error={formData.tempDetails.errorMessage}
              value={formData.tempDetails.value}
              helperText="Enter the required temperature"
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>

      <Grid item sm={6}>
        <Grid item sm={12}>
          <SelectInputStyled
            label="Value"
            onOptionSelected={(option: string) =>
              handleOnChangeForm('dollarValue', option)
            }
            options={dollarValueOptions}
            value={formData.dollarValue.value}
            error={formData.dollarValue.errorMessage}
          />
        </Grid>
        <EmptyGrid item sm={12} />
        <Grid item sm={12}>
          <TextInputStyled
            label="Weight"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('weight', value);
            }}
            onBlur={() => {
              handleOnBlurForm('weight', formData.weight.value);
            }}
            error={formData.weight.errorMessage}
            value={formData.weight.value}
          />
        </Grid>
        <Grid item sm={12}>
          <TextInputStyled
            label="Dimensions"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('dimensions', value);
            }}
            onBlur={() => {
              handleOnBlurForm('dimensions', formData.dimensions.value);
            }}
            error={formData.dimensions.errorMessage}
            value={formData.dimensions.value}
          />
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <InputLabel fontSize="16px">Comments</InputLabel>
        <InputLabel fontSize="13px">
          Enter anything special about the load (e.g., fragile materials,
          unloading instructions, etc.)
        </InputLabel>
        <MultilineTextInputStyled
          variant="filled"
          onChange={({ target: { value } }: { target: { value: string } }) => {
            handleOnChangeForm('comments', value);
          }}
          value={formData.comments.value}
        />
      </Grid>
    </GridContainer>
  );
};

export default DetailsForm;
