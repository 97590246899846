// packages
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// components
import Registration from '../../components/registration';
import ContactUs from './ContactUs';

// services
import { AuthService } from '../../services';

// styles
import {
  BoldLabel,
  ContactUsLabel,
  CreateAccountButton,
  LoginButton,
  LoginLandingContainer,
  LoginLandingPaper,
  NoAccountNeededLabel,
  QuestionsAndContactUsContainer,
  QuestionsLabel,
  SeparatorLine,
  TitleLabel,
  TrackShipmentsLabel
} from './LoginLandingStyles';

// images
import ShipperPortalBlueLogo from '../../assets/images/shipper-portal-blue.svg';
import LocationIcon from '../../assets/images/Location.svg';
import { useAuth0Flag } from '../../hooks/useFeatureFlag';
import { useQueryParams } from '../../hooks/routeHooks';
import { AUTH0_ERRORS } from '../../utilities/authUtils';
import { Snackbar } from 'shamrock-clover-ui';

const Config = require('Config');

// constants
export const TRACK_SHIPMENTS_URL = Config.loadTrackingUrl;
const authService: AuthService = new AuthService();

export const LoginLanding: React.FC = () => {
  const { isAuth0Enabled } = useAuth0Flag();
  const { loginWithRedirect } = useAuth0();
  const [isRegistrationVisible, setIsRegistrationVisible] = useState(false);
  const [isContactUsVisible, setIsContactUsVisible] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const { queryParams } = useQueryParams<{
    error: string;
    error_description: string;
  }>();

  const handleRedirectToLoadTracking = () =>
    window.location.assign(TRACK_SHIPMENTS_URL);

  const toggleRegistrationVisibility = () =>
    setIsRegistrationVisible(!isRegistrationVisible);

  const handleOnLogin = () =>
    isAuth0Enabled ? loginWithRedirect() : authService.redirectToLogin();

  const toggleOnOpenContactUs = () =>
    setIsContactUsVisible(!isContactUsVisible);

  const showSnackbarError = (msg: string) => {
    setSnackbarMessage(msg);
    setShowSnackbar(true);
  };
  useEffect(() => {
    if (
      queryParams &&
      queryParams?.error_description === AUTH0_ERRORS.USER_BLOCKED
    ) {
      showSnackbarError('Account locked, please contact support.');
    }
  }, []);
  return (
    <>
      <ContactUs
        handleOnGoBack={toggleOnOpenContactUs}
        visible={isContactUsVisible}
      />
      <Registration
        onCancel={toggleRegistrationVisibility}
        visible={isRegistrationVisible}
      />
      <LoginLandingContainer visible={!isRegistrationVisible}>
        <LoginLandingPaper elevation={3}>
          <img
            alt="shipper-portal-blue-logo"
            src={ShipperPortalBlueLogo}
            style={{ height: 48, marginBottom: 32, width: 282 }}
          />
          <TitleLabel>
            Track shipments in real-time, follow invoices, view important
            shipping documents, and more.
          </TitleLabel>
          <BoldLabel>New user?</BoldLabel>
          <CreateAccountButton
            onClick={toggleRegistrationVisibility}
            variant="contained"
          >
            CREATE ACCOUNT
          </CreateAccountButton>
          <LoginButton onClick={handleOnLogin} variant="contained">
            LOG IN
          </LoginButton>
          <SeparatorLine />
          <TrackShipmentsLabel onClick={handleRedirectToLoadTracking}>
            <img
              alt="location-icon"
              src={LocationIcon}
              style={{ marginRight: 4 }}
            />
            TRACK SHIPMENTS
          </TrackShipmentsLabel>
          <NoAccountNeededLabel>No account needed</NoAccountNeededLabel>
          <QuestionsAndContactUsContainer>
            <QuestionsLabel>Questions?&nbsp;</QuestionsLabel>
            <ContactUsLabel href="#" onClick={toggleOnOpenContactUs}>
              Contact Us
            </ContactUsLabel>
          </QuestionsAndContactUsContainer>
        </LoginLandingPaper>
        <Snackbar
          open={showSnackbar}
          duration={5000}
          message={snackbarMessage}
          variant={'error'}
          position="bottomCenter"
          onClose={() => setShowSnackbar(false)}
        />
      </LoginLandingContainer>
    </>
  );
};

export default LoginLanding;
