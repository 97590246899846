import React, { Fragment, SyntheticEvent, useState, useEffect } from 'react';
import { Dialog } from 'shamrock-clover-ui/dist/clover/components/Dialog/Dialog';
import { Icon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { TextInput } from 'shamrock-clover-ui/dist/clover/components/TextInput/TextInput';
import CloseIcon from '../../assets/images/Close.svg';
import EditIcon from '../../assets/images/Edit.svg';
import Switch from '@material-ui/core/Switch';
import './globalNotificationsModal.scss';
import {
  getShowNotificationsModal,
  getNotificationModalInPendingState
} from '../../store/orders/ordersSelectors';
import {
  toggleOrderNotificationsEnabled,
  toggleGlobalNotificationsEnabled,
  updateGlobalNotificationUsers,
  updateOrderNotificationUsers
} from '../../store/orders/ordersActions';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { NotificationSummary } from '../../store/orders/orderReduxTypes';
import * as FirebaseUtils from '../../utilities/firebaseUtils';
import { UserService } from '../../services';
import { Spinner } from 'shamrock-clover-ui';

interface Props {
  toggleNotificationModal: Function;
  orderId: string;
  notificationSummary: NotificationSummary | undefined | null;
}

const StyledModal = styled(Dialog)`
  padding: 0px !important;
`;

const Theme = {
  colors: {
    white: '#FFFFFF',
    blue: '#0091EA',
    gray: '#999999'
  }
};

const StyledSwitch = withStyles({
  switchBase: {
    color: Theme.colors.white,
    '&$checked': {
      color: Theme.colors.white
    },
    '&$checked + $bar': {
      backgroundColor: Theme.colors.blue,
      opacity: 1
    }
  },
  checked: {},
  bar: {
    backgroundColor: Theme.colors.gray
  }
})(Switch);

const GlobalNotificationsModal: React.FC<Props> = ({
  toggleNotificationModal,
  orderId,
  notificationSummary
}) => {
  const dispatch = useDispatch();
  const showModal = useSelector(getShowNotificationsModal);
  const [contacts, setContacts] = useState<string[]>([]);
  const modalInPendingState = useSelector(getNotificationModalInPendingState)
  const [errorText, setErrorText] = useState('');
  const [newContact, setNewContact] = useState('');
  const setOrderNotificationsEnabled = (orderId: string, on: boolean) =>
    dispatch(toggleOrderNotificationsEnabled(orderId, on));
  const setGlobalNotificationsEnabled = (on: boolean) => {
    dispatch(toggleGlobalNotificationsEnabled(on));
  };
  const updateOrderUsers = (orderId: string, contacts: string[]) =>
    dispatch(updateOrderNotificationUsers(orderId, contacts));
  const updateGlobalUsers = (contacts: string[]) => {
    dispatch(updateGlobalNotificationUsers(contacts));
  };

  const getContacts = (): string[] => {

    if (notificationSummary === null) {
      updateGlobalUsers([UserService.getUserEmail()]);
      return [];
    }

    if (orderId && notificationSummary != undefined) {
      if (notificationSummary.orders) {
        let order = notificationSummary.orders.find(o => o.orderId === orderId);
        if (order) {
          return order.contacts || [];
        } else {
          updateOrderUsers(orderId, [UserService.getUserEmail()]);
          return [];
        }
      }
    }

    //if there is no globalContacts and global notificaiton is enabled; disable it.
    if (notificationSummary?.globalEnabled &&
      notificationSummary?.globalContacts.length == 0) {
      setGlobalNotificationsEnabled(false);
    }

    return notificationSummary?.globalContacts || [];
  };

  useEffect(() => {
    const contacts = getContacts();
    setContacts(contacts);
  }, [showModal, notificationSummary])

  const getNotificationsOn = (): boolean => {
    if (notificationSummary) {
      if (orderId && !notificationSummary.globalEnabled) {
        if (notificationSummary.orders) {
          let order = notificationSummary.orders.find(o => o.orderId === orderId);
          if (order) {
            return order.enabled;
          } else return false;
        } else return false;
      } else return notificationSummary.globalEnabled;
    } return false;
  };

  const notificationsOn = getNotificationsOn();

  const isValidEmail = (contact: string) => {
    const emailRegex = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return emailRegex.test(contact);
  };

  const isValidPhone = (contact: string) => {
    const phoneRegex = new RegExp(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    );
    return phoneRegex.test(contact);
  };

  const saveNotificationContacts = (contacts: string[]) => {
    setNewContact('');
    setErrorText('');
    orderId ? updateOrderUsers(orderId, contacts) : updateGlobalUsers(contacts);
    if (orderId && contacts.length == 0 && !notificationSummary?.globalEnabled) {
      setOrderNotificationsEnabled(orderId, false)
    }
  };

  const logFirebaseChangeContacts = (
    type: 'ADD' | 'REMOVE',
    emailText: string | null,
    phoneText: string | null
  ) => {
    if (orderId) {
      FirebaseUtils.logFirebaseEvent(
        type === 'ADD'
          ? FirebaseUtils.FirebaseEvents.NOTIFICATION_ADD
          : FirebaseUtils.FirebaseEvents.NOTIFICATION_REMOVE,
        FirebaseUtils.FirebaseModules.SHIPPER,
        FirebaseUtils.FirebasePages.NOTIFICATIONSETTINGS,
        {
          email: emailText ? emailText : '',
          phoneNumber: phoneText ? phoneText : '',
          orderNumber: orderId
        }
      );
    } else {
      FirebaseUtils.logFirebaseEvent(
        type === 'ADD'
          ? FirebaseUtils.FirebaseEvents.GLOBAL_NOTIFICATION_ADD
          : FirebaseUtils.FirebaseEvents.GLOBAL_NOTIFICATION_REMOVE,
        FirebaseUtils.FirebaseModules.SHIPPER,
        FirebaseUtils.FirebasePages.NOTIFICATIONSETTINGS,
        {
          email: emailText ? emailText : '',
          phoneNumber: phoneText ? phoneText : ''
        }
      );
    }
  };

  const addNotificationContact = (contact: string) => {
    if (contacts && contacts.length === 5) {
      setErrorText('Only 5 notifications contacts are allowed at this time');
      return;
    }
    if (isValidEmail(contact)) {
      logFirebaseChangeContacts('ADD', contact, null);
      saveNotificationContacts(contacts.concat(contact));
    } else if (isValidPhone(contact)) {
      logFirebaseChangeContacts('ADD', null, contact);
      saveNotificationContacts(contacts.concat(contact));
    } else setErrorText('Please enter a valid email or phone number');
  };

  useEffect(() => {
    window.addEventListener('keydown', (event: KeyboardEvent) => {
      if (!(event.key === 'Enter')) return;
      if (event.target) {
        let t = event.target as HTMLInputElement;
        if (
          t.placeholder &&
          t.placeholder === 'Add an email or phone number' &&
          t.value &&
          notificationSummary?.userId
        ) {
          addNotificationContact(t.value);
        }
      }
    });
  }, [notificationSummary, orderId]);

  const onContactChange = (event: SyntheticEvent) => {
    let t = event.nativeEvent.target as HTMLInputElement;
    if (t && t.value) {
      setNewContact(t.value);
    } else setNewContact('');
  };

  const deleteNotificationContact = (contact: string) => {
    if (!contact) return;
    var i = contacts.findIndex(c => c === contact);
    if (!(i > -1)) return;
    contacts.splice(i, 1);
    logFirebaseChangeContacts(
      'REMOVE',
      isValidEmail(contact) ? contact : null,
      isValidPhone(contact) ? contact : null
    );
    saveNotificationContacts(contacts);
  };

  const updateNotificationContact = (contact: string) => {
    if (!contact) return;
    setNewContact(contact);
    var i = contacts.findIndex(c => c === contact);
    if (!(i > -1)) return;
    contacts.splice(i, 1);
    orderId ? updateOrderUsers(orderId, contacts) : updateGlobalUsers(contacts);
  };

  const notificationsToggled = (on: boolean) => {
    if (on && contacts.length < 1) {
      setErrorText('Please enter a valid email or phone number');
      return;
    }
    orderId
      ? setOrderNotificationsEnabled(orderId, !notificationsOn)
      : setGlobalNotificationsEnabled(on);
  };

  const closeModal = () => {
    setErrorText('');
    toggleNotificationModal(false);
  };

  const getModalContent = () => {
    return (
      <div>
        <div className="mainText">
          Send status update notifications{' '}
          {orderId
            ? 'to contacts for this order.'
            : `on all orders to your contacts. You
          can also send update notifications on individual orders by selecting
          that shipment.`}
        </div>
        <div className="notifyAllContainer">
          <StyledSwitch
            checked={notificationsOn}
            onChange={() => notificationsToggled(!notificationsOn)}
          />
          <div>{orderId ? 'Notifications on' : 'Notify for all Shipments'}</div>
        </div>
        <div className="addContainer">
          <Icon
            icon="add"
            className={
              contacts && contacts.length === 5 ? 'icon disabledIcon' : 'icon'
            }
            onClick={() => addNotificationContact(newContact)}
          />
          <TextInput
            label="Add an email or phone number"
            className="input"
            onChange={onContactChange}
            error={errorText}
            value={newContact}
          ></TextInput>
        </div>
      </div>
    );
  };

  const getContactsContainer = () => {
    return (
      <div className="contactsContainer">
        {modalInPendingState && <Spinner size='20' />}
        {contacts &&
          contacts.map((x, index) => (
            <div className="contact" key={index}>
              <div className="text">{x}</div>
              <div className="actions">
                <img
                  className="actionIcon"
                  src={EditIcon}
                  onClick={() => updateNotificationContact(x)}
                />
                <img
                  className="actionIcon deleteIcon"
                  src={CloseIcon}
                  onClick={() => deleteNotificationContact(x)}
                />
              </div>
            </div>
          ))}
      </div>
    );
  };

  return (
    <Fragment>
      <StyledModal
        open={showModal}
        title={
          orderId ? `Order ${orderId} Notifications` : 'Global Notifications'
        }
        content={getModalContent()}
        actions={getContactsContainer()}
        onClose={closeModal}
      ></StyledModal>
    </Fragment>
  );
};

export default GlobalNotificationsModal;
