import React, { FunctionComponent } from 'react';
import Chip from 'shamrock-clover-ui/dist/clover/components/Chip/Chip';
import { Check } from '@material-ui/icons';
import styled from 'styled-components';

const StyledChip = styled(Chip)`
  cursor: pointer;
`;

interface Props {
  isToggled: boolean;
  label: string;
  onClick: () => void;
}

export const FilterChip: FunctionComponent<Props> = props => {
  return (
    <StyledChip
      state={props.isToggled ? 'selected' : 'disabled'}
      variant="base"
      leftIcon={
        props.isToggled ? <Check style={{ fontSize: '20px' }} /> : undefined
      }
      label={props.label}
      onClick={props.onClick}
    />
  );
};

export default FilterChip;
