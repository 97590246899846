import * as Sentry from '@sentry/browser';

const crashReporter = (store: { getState: () => any }) => (
  next: (arg0: any) => any
) => (action: any) => {
  try {
    return next(action); // dispatch
  } catch (err) {
    console.error('Caught an exception!', err);
    Sentry.withScope(scope => {
      scope.setExtras({
        action,
        state: store
      });
    });
    throw err; // re-throw error
  }
};

export { crashReporter };
