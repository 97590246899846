import React, { FunctionComponent } from 'react';
import { LOAD_INFORMATION } from '../../utilities/constants';
// import DetailLoadInfoText from '../detailLoadInfo/detailLoadInfoText/detailLoadInfoText';
import './DetailLoadInfo.scss';
// import { Order } from 'types/orderTypes';

interface Props {
  children?: React.ReactNode;
  large?: boolean;
}

const detailLoadInfo: FunctionComponent<Props> = props => {
  return (
    <div
      className={
        props.large
          ? 'detailsLoadInfoContainer large'
          : 'detailsLoadInfoContainer'
      }
    >
      <div className="detailsLoadInfo">
        <div className="detailsLoadInfoTitle">{LOAD_INFORMATION}</div>
        {/* <DetailLoadInfoText order={props.order} /> */}
        {props.children}
      </div>
    </div>
  );
};

export default React.memo(detailLoadInfo);
