import * as React from 'react';
import { StyledButton } from './LoadTrackingStyles';
import UserService from '../../services/user/UserService';
import {
  FirebaseEvents,
  FirebaseModules,
  FirebasePages,
  logFirebaseEvent
} from '../../utilities/firebaseUtils';

const Config = require('Config');

interface Props {
  details?: boolean;
  text: string;
}

const LoadTrackingSignUpButton: React.FC<Props> = ({
  details = false,
  text
}) => {
  const onClick = () => {
    logFirebaseEvent(
      FirebaseEvents.SIGN_UP,
      FirebaseModules.EASYTRACKFULL,
      FirebasePages.EASYTRACKFULL,
      {}
    );
    window.location.href = Config.shipperPortalUrl;
  };

  if (UserService.getCurrentUser() !== undefined) {
    return null;
  }

  return (
    <>
      {text}
      <StyledButton width="300px" onClick={onClick}>
        {details ? 'Sign Up For More Info' : 'SIGN UP'}
      </StyledButton>
    </>
  );
};

export default LoadTrackingSignUpButton;
