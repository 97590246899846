import { AddressState, AddressBookActionTypes } from './addressBookReduxTypes';
import {
  SET_ADDRESS_BOOK,
  SET_ADDRESS_BOOK_ERROR,
  SET_HOLD_ADDRESS,
  SET_EDIT_ADDRESS_INDEX,
  SET_PICKUP_ADDRESS,
  SET_DELIVERY_ADDRESS,
  SET_ADDRESS_BOOK_LOADING,
  SET_ADDRESS_ENTRY_OPEN,
  SET_ADDRESS_BOOK_OPEN,
  SET_CURRENT_FORM,
  SET_DELETE_MODAL_OPEN,
  SET_UNSAVED_MODAL_OPEN,
  SET_ADDRESS_BOOK_SNACKBAR
} from './addressBookActions';

export const addressBookState: AddressState = {
  addressBook: {},
  holdAddress: undefined,
  editAddressIndex: -1,
  pickupAddress: undefined,
  deliveryAddress: undefined,
  isAddressEntryOpen: false,
  isAddressBookOpen: false,
  isDeleteModalOpen: false,
  isUnsavedModalOpen: false,
  currentForm: 'pickup',
  isLoading: false,
  isError: false,
  showCancelModal: false,
  isSnackbarVisible: false,
  isSnackbarError: false,
  snackbarMessage: ''
};

export default function addressBook(
  state = addressBookState,
  action: AddressBookActionTypes
): AddressState {
  switch (action.type) {
    case SET_ADDRESS_BOOK:
      return Object.assign({}, state, {
        addressBook: Object.assign({}, action.addressBook),
        isError: false
      });
    case SET_ADDRESS_BOOK_ERROR:
      return Object.assign({}, state, {
        isError: action.isError
      });
    case SET_HOLD_ADDRESS:
      return Object.assign({}, state, {
        holdAddress: Object.assign({}, action.holdAddress),
        isError: false
      });
    case SET_EDIT_ADDRESS_INDEX:
      return Object.assign({}, state, {
        editAddressIndex: action.editAddressIndex
      });
    case SET_PICKUP_ADDRESS:
      return Object.assign({}, state, {
        pickupAddress: Object.assign({}, action.pickupAddress)
      });
    case SET_DELIVERY_ADDRESS:
      return Object.assign({}, state, {
        deliveryAddress: Object.assign({}, action.deliveryAddress)
      });
    case SET_ADDRESS_BOOK_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading
      });
    case SET_ADDRESS_ENTRY_OPEN:
      return Object.assign({}, state, {
        isAddressEntryOpen: action.isAddressEntryOpen
      });
    case SET_ADDRESS_BOOK_OPEN:
      return Object.assign({}, state, {
        isAddressBookOpen: action.isAddressBookOpen
      });
    case SET_CURRENT_FORM:
      return Object.assign({}, state, {
        currentForm: action.currentForm
      });
    case SET_DELETE_MODAL_OPEN:
      return Object.assign({}, state, {
        isDeleteModalOpen: action.isDeleteModalOpen
      });
    case SET_UNSAVED_MODAL_OPEN:
      return Object.assign({}, state, {
        isUnsavedModalOpen: action.isUnsavedModalOpen
      });
    case SET_ADDRESS_BOOK_SNACKBAR:
      return Object.assign({}, state, {
        isSnackbarVisible: action.isSnackbarVisible,
        isSnackbarError: action.isSnackbarError,
        snackbarMessage: action.snackbarMessage
      });
    default:
      return state;
  }
}
