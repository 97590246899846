import React from 'react';
import InTransitGray from '../../assets/images/InTransitGray.svg';
import LoadTrackingInputs from './LoadTrackingInputs';
import LoadTrackingSignUpButton from './LoadTrackingSignUpButton';
import {
  SearchContainer,
  StatusTextBold,
  Icon,
  SignUp,
  ParentContainer
} from './LoadTrackingStyles';

export const LoadTrackingSearch = () => {
  return (
    <SearchContainer>
      <ParentContainer flexDirection="column">
        <StatusTextBold>
          <Icon src={InTransitGray} />
        </StatusTextBold>
        <StatusTextBold>
          Enter a Reference Number and City, or ZIP to start tracking
        </StatusTextBold>
        <LoadTrackingInputs />
        <SignUp flexDirection="column" alignItems="center">
          <LoadTrackingSignUpButton
            text={`Are you a Ryan Transportation customer, or don't know what to search by?`}
          />
        </SignUp>
      </ParentContainer>
    </SearchContainer>
  );
};

export default LoadTrackingSearch;
