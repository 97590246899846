import { LateInformation } from 'types/orderTypes';
import moment from 'moment-timezone';

const monthDayTimeFormat = (value: string | moment.Moment) => {
  return moment(value)
    .utc()
    .format('MM/DD HH:mm');
};

const monthDayFormat = (value: string | moment.Moment) => {
  return moment(value)
    .utc()
    .format('MM/DD');
};

const etaFormat = (value: string | moment.Moment) => {
  const dateUTC = moment.isMoment(value) ? value.utc() : moment(value).utc();

  // check for same day
  if (
    moment()
      .utc()
      .isSame(dateUTC)
  ) {
    return dateUTC.format('HH:mm');
  }

  return monthDayTimeFormat(dateUTC);
};

const isDateAfterDate = (
  dateOne: string | moment.Moment,
  dateTwo: string | moment.Moment
) => {
  const dateOneMoment = moment.isMoment(dateOne)
    ? dateOne.utc()
    : moment(dateOne).utc();

  const dateTwoMoment = moment.isMoment(dateTwo)
    ? dateTwo.utc()
    : moment(dateTwo).utc();

  return dateOneMoment.isAfter(dateTwoMoment);
};

const isDateFiveMinutesAfterDate = (
  dateOne: string | moment.Moment,
  dateTwo: string | moment.Moment
) => {
  const dateOneMoment = moment.isMoment(dateOne)
    ? dateOne.utc()
    : moment(dateOne).utc();
  const dateTwoMoment = moment.isMoment(dateTwo)
    ? dateTwo.utc()
    : moment(dateTwo).utc();

  const lateDeliveryDate = moment(dateTwoMoment)
    .add(5, 'minutes')
    .format('YYYY-MM-DD HH:mm');

  return moment(dateOneMoment).isAfter(moment(lateDeliveryDate));
};

const getDate = (stop: {
  actual_arrival?: string;
  actual_departure?: string;
  sched_arrive_late?: string;
  sched_arrive_early?: string;
}) => {
  let {
    actual_arrival: actualArrival,
    actual_departure: actualDeparture,
    sched_arrive_late: late,
    sched_arrive_early: early
  } = stop;

  if (actualArrival) {
    let actualString: string[] = [];
    const actualArrivalMoment = moment(actualArrival).utc();

    if (actualArrivalMoment.isSame(new Date(), 'd')) {
      actualString.push(`Today ${actualArrivalMoment.format('HH:mm')}`);
    } else {
      actualString.push(`${actualArrivalMoment.format('MM/DD HH:mm')}`);
    }

    if (actualDeparture) {
      const actualDepartureMoment = moment(actualDeparture).utc();
      if (!actualDepartureMoment.isSame(actualArrival)) {
        actualString.push('-');
        !actualDepartureMoment.isSame(actualArrival, 'd')
          ? actualString.push(`${actualDepartureMoment.format('MM/DD HH:mm')}`)
          : actualString.push(`${actualDepartureMoment.format('HH:mm')}`);
      }
    }
    return actualString.join(' ');
  } else {
    const earlyMoment = moment(early).utc();
    const lateMoment = moment(late).utc();
    let dateString = earlyMoment.isSame(new Date(), 'd')
      ? `
        Today ${earlyMoment.format('HH:mm')}`
      : `${earlyMoment.format('MM/DD HH:mm')}`;
    if (lateMoment.isValid() && !lateMoment.isSame(earlyMoment)) {
      dateString +=
        ' - ' +
        (lateMoment.isSame(earlyMoment, 'd')
          ? `${lateMoment.format('HH:mm')}`
          : `${lateMoment.format('MM/DD HH:mm')}`);
    }
    return dateString;
  }
};

const isDateDelayed = (
  schedule_arrive_late: string,
  actual_departure: string,
  late: LateInformation | false,
  type: string
) => {
  const currentDate = moment(new Date());

  if (isDateFiveMinutesAfterDate(actual_departure, schedule_arrive_late)) {
    return true;
  }

  if (isDateFiveMinutesAfterDate(currentDate, schedule_arrive_late)) {
    return true;
  }

  if (late && late.type && late.type === type) {
    return true;
  }

  return false;
};

const getMomentTimezone = (orderTimeZone: string) => {
  if (!orderTimeZone || orderTimeZone.length == 0) return '';
  switch (orderTimeZone.toUpperCase()) {
    case 'EST':
      return 'America/New_York';
    case 'EDT':
      return 'America/New_York';
    case 'PST':
      return 'America/Los_Angeles';
    case 'PDT':
      return 'America/Los_Angeles';
    case 'CST':
      return 'America/Chicago';
    case 'CDT':
      return 'America/Chicago';
    case 'MST':
      return 'America/Phoenix';
    case 'MDT':
      return 'America/Phoenix';
    default:
      return 'America/Chicago';
  }
};

const getDateWithTimezone = (timezone = moment.tz.guess(), date?: string) => {
  if (date) {
    return moment(date)
      .tz(getMomentTimezone(timezone))
      .format('YYYY-MM-DD HH:mm');
  }
  return moment()
    .tz(getMomentTimezone(timezone))
    .format('YYYY-MM-DD HH:mm');
};

export {
  getDate,
  isDateDelayed,
  monthDayTimeFormat,
  isDateFiveMinutesAfterDate,
  isDateAfterDate,
  etaFormat,
  getMomentTimezone,
  getDateWithTimezone,
  monthDayFormat
};
