// packages
import React, { forwardRef, useEffect, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import Grid from '@material-ui/core/Grid';

// components
import StepNavigation from './StepNavigation';

// services
import {
  AuthService,
  RegistrationService,
  EmailService,
  UserMcleodService
} from '../../services';

// utilities
import { isValidEmail } from '../../utilities/stringHelper';

// styles
import {
  CheckBoxStyled,
  ColumnContainer,
  IconContainer,
  LinkStyled,
  PasswordHelperTextContainer,
  PasswordValidationText,
  PhoneTextInputStyles,
  StepContainerPaper,
  StepContentLabel,
  StepTitleLabel,
  TextInputStyled,
  TitleLabel,
  GridContainer,
  GridPasswordValidationItem
} from './RegistrationStyles';

// props
interface Props {
  onChangeStep: any;
  setCustomCardMsg: (value: string) => void;
  step: number;
  toggleCardVisibility: (value: boolean) => void;
  toggleError: (value: boolean) => void;
  toggleIsLoading: (value: boolean) => void;
  userEmail: string;
  visible: boolean;
}

interface FormDataField {
  error: boolean | string;
  value: string;
}
interface FormData {
  companyName: FormDataField;
  customerId: FormDataField;
  emailAddress: FormDataField;
  fullName: FormDataField;
  password: FormDataField;
  phoneNumber: FormDataField;
}

// services
const registrationService = new RegistrationService();
const authService: AuthService = new AuthService();
const emailService: EmailService = new EmailService();
const userMcleodService: UserMcleodService = new UserMcleodService();

// constants
export const TERMS_USE = 'https://www.rtsinc.com/page/terms-use';
export const SHIPPER_POPRTAL_ROLE_FOR_PRODUCTION = '5ddedd667c213e56251a9238';

// eslint-disable-next-line react/display-name
export const PhoneTextInputStyled = forwardRef<
  HTMLInputElement,
  {
    error: string;
    onChange: (value: string) => void;
    onBlur: (value: string) => void;
    value: string;
  }
>(({ error, onChange, onBlur, value }, ref) => {
  // TODO: Please keep in mind this implementation is already done in last shamrock-clover-ui version >= 6.2.0
  // we did not upgrade the current shamrock-clover-ui version due some components, styles, pages, etc might break

  return (
    <PhoneTextInputStyles error={!!error}>
      <TextInputStyled
        error={error}
        inputComponent={
          <NumberFormat
            allowNegative={false}
            className="phoneTextInput"
            format={'(###) - ### - ####'}
            getInputRef={ref}
            onValueChange={(valuePayload: NumberFormatValues) =>
              onChange(valuePayload.value)
            }
            onBlur={({ target: { value } }: { target: { value: string } }) => {
              onBlur(value);
            }}
            placeholder="Phone Number"
            type="tel"
            value={value}
          />
        }
        label="Phone Number"
        marginBottom="28px"
        maxLength={10}
        onChange={({ target: { value } }: { target: { value: string } }) =>
          onChange(value)
        }
        onBlur={({ target: { value } }: { target: { value: string } }) => {
          onBlur(value);
        }}
        width="240px"
        value={value}
      />
    </PhoneTextInputStyles>
  );
});

export const Step3 = ({
  onChangeStep,
  setCustomCardMsg,
  step,
  toggleCardVisibility,
  toggleError,
  toggleIsLoading,
  userEmail,
  visible
}: Props) => {
  const [formData, setFormData] = useState<FormData>({
    companyName: { error: false, value: '' },
    customerId: { error: false, value: '' },
    emailAddress: { error: false, value: '' },
    fullName: { error: false, value: '' },
    password: { error: false, value: '' },
    phoneNumber: { error: false, value: '' }
  });
  const [errorData, setErrorData] = useState({
    companyName: { errorMessage: '' },
    emailAddress: { errorMessage: '' },
    fullName: { errorMessage: '' },
    password: { errorMessage: '' },
    phoneNumber: { errorMessage: '' }
  });
  const [termsAndConditions, setTermsAndConditions] = useState<boolean>(false);

  useEffect(() => {
    setFormData({
      ...formData,
      emailAddress: { error: false, value: userEmail }
    });
  }, [userEmail]);

  const handleOnChangeForm = (name: string, value: string) => {
    setFormData({ ...formData, [name]: { value } });

    if (
      (name !== 'phoneNumber' && value && value.length < 3) ||
      (name === 'phoneNumber' && value && value.length < 10) ||
      (name === 'emailAddress' && value && !isValidEmail(value))
    ) {
      setErrorData({
        ...errorData,
        [name]: { errorMessage: 'Invalid field' }
      });
      return;
    }
    setErrorData({
      ...errorData,
      [name]: { errorMessage: '' }
    });
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (!value) {
      setErrorData({
        ...errorData,
        [name]: {
          errorMessage: 'Please enter a valid field'
        }
      });
    }
  };

  const validatePassword = (pwd: string) => {
    const capitalRe = /[A-Z]/;
    const specialRe = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~0-9]/;

    return {
      capital: capitalRe.test(pwd),
      length: pwd.length >= 8,
      special: specialRe.test(pwd)
    };
  };

  const isPasswordLengthValid = validatePassword(formData.password.value)
    .length;
  const hasPasswordCapitalLetter = validatePassword(formData.password.value)
    .capital;
  const hasPasswordSpecialChar = validatePassword(formData.password.value)
    .special;

  const handleOnCreateAccount = async () => {
    toggleIsLoading(true);

    const {
      companyName: { value: company },
      customerId: { value: customer_id },
      emailAddress: { value: email },
      fullName: { value: fullname },
      password: { value: password },
      phoneNumber: { value: phone }
    } = formData;

    const name = fullname.split(' ');
    const firstname = name.length >= 3 ? name[0] + ' ' + name[1] : name[0];
    const lastname = name.length >= 3 ? name[2] : name[1];

    let mcloedId: string = customer_id;

    const {
      parent_row_id: mcloedCustomerId
    } = await userMcleodService.getMcleodId(email);

    let sendSuccessEmail = true;
    if (mcloedCustomerId && mcloedCustomerId.length > 0) {
      if (mcloedCustomerId !== customer_id) {
        // user entered the wrong customer id
        mcloedId = mcloedCustomerId;
      }
    } else {
      sendSuccessEmail = false;
      await emailService.sendUnSuccessEmail(email);
      await emailService.sendSupportEmail(
        email,
        company,
        firstname,
        lastname,
        phone,
        'Shipper Portal',
        customer_id
      );
      mcloedId = customer_id;
    }

    const response:
      | { emailAlreadyExists?: boolean; success: boolean }
      | undefined = await registrationService.signupNewUser({
      company,
      company_type: 'shipper',
      customer_id: mcloedId,
      device_type: 'web',
      roles: [SHIPPER_POPRTAL_ROLE_FOR_PRODUCTION],
      email,
      firstname,
      lastname,
      password,
      phone
    });

    if (response?.success) {
      authService.redirectToLoginRegistered();
    }

    if (!response?.success || response?.emailAlreadyExists) {
      if (sendSuccessEmail) {
        await emailService.sendUnSuccessEmail(email);
      }
      toggleIsLoading(false);
      toggleError(true);
      toggleCardVisibility(true);
      setCustomCardMsg(
        `${formData.emailAddress.value} already exists, please log in.`
      );
    }
  };

  const disableSubmit = () => {
    if (!termsAndConditions) {
      return true;
    }
    if (
      errorData.emailAddress.errorMessage ||
      formData.emailAddress.value === ''
    ) {
      return true;
    }
    if (
      !isPasswordLengthValid ||
      !hasPasswordCapitalLetter ||
      !hasPasswordSpecialChar
    ) {
      return true;
    }
    if (errorData.fullName.errorMessage || formData.fullName.value === '') {
      return true;
    }
    if (
      errorData.companyName.errorMessage ||
      formData.companyName.value === ''
    ) {
      return true;
    }
    if (
      errorData.phoneNumber.errorMessage ||
      formData.phoneNumber.value === ''
    ) {
      return true;
    }
    return false;
  };

  if (!visible) {
    return null;
  }

  return (
    <StepContainerPaper height="">
      <GridContainer container>
        <Grid item xs={12}>
          <TitleLabel>Enter Account Details</TitleLabel>
        </Grid>
        <Grid item xs={12}>
          <StepTitleLabel>Step 3 of 3</StepTitleLabel>
        </Grid>
        <Grid item xs={12}>
          <StepContentLabel marginBottom="18px" noPadding width="541px">
            Provide a few details and create a password to finish setting up
            your account.
          </StepContentLabel>
        </Grid>
        <Grid item xs={12}>
          <StepTitleLabel fontSize="14px">
            All fields are required unless otherwise noted.
          </StepTitleLabel>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInputStyled
            error={errorData.fullName.errorMessage.replace('field', 'name')}
            label="Full Name"
            marginBottom="28px"
            marginRight="0px"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              if (formData.fullName.error) {
                setFormData({
                  ...formData,
                  fullName: { error: false, value: formData.fullName.value }
                });
              }

              handleOnChangeForm(
                'fullName',
                value.replace(/[^a-zA-Z\-\'\ ]/, '')
              );
            }}
            onBlur={() => handleOnBlurForm('fullName', formData.fullName.value)}
            width="240px"
            value={formData.fullName.value}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInputStyled
            label="Customer ID"
            helperText="Optional - ID speeds account approval."
            marginBottom="28px"
            marginRight="0px"
            maxLength={8}
            onChange={({ target: { value } }: { target: { value: string } }) =>
              handleOnChangeForm('customerId', value)
            }
            inputType="text"
            width="240px"
            value={formData.customerId.value}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInputStyled
            error={errorData.companyName.errorMessage.replace(
              'field',
              'company name'
            )}
            marginBottom="28px"
            marginRight="35px"
            label="Company Name"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              if (formData.companyName.error) {
                setFormData({
                  ...formData,
                  companyName: {
                    error: false,
                    value: formData.companyName.value
                  }
                });
              }
              handleOnChangeForm('companyName', value);
            }}
            onBlur={() =>
              handleOnBlurForm('companyName', formData.companyName.value)
            }
            width="240px"
            value={formData.companyName.value}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <PhoneTextInputStyled
            error={errorData.phoneNumber.errorMessage.replace(
              'field',
              'phone number'
            )}
            onBlur={value => {
              handleOnBlurForm('phoneNumber', value);
            }}
            onChange={value => {
              if (formData.phoneNumber.error) {
                setFormData({
                  ...formData,
                  phoneNumber: {
                    error: false,
                    value: formData.phoneNumber.value
                  }
                });
              }
              handleOnChangeForm('phoneNumber', value);
            }}
            value={formData.phoneNumber.value}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextInputStyled
            helperText={
              !Boolean(formData.emailAddress.error)
                ? 'If you have already worked with RTS, use that email address.'
                : ''
            }
            label="Email Address"
            error={errorData.emailAddress.errorMessage.replace(
              'field',
              'email'
            )}
            marginBottom="40px"
            marginRight="35px"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('emailAddress', value);
            }}
            onBlur={() => {
              handleOnBlurForm('emailAddress', formData.emailAddress.value);
            }}
            width="240px"
            value={formData.emailAddress.value}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ColumnContainer>
            <TextInputStyled
              helperText="Password must contain:"
              inputType="password"
              label="Password"
              marginBottom="3px"
              onChange={({
                target: { value }
              }: {
                target: { value: string };
              }) => handleOnChangeForm('password', value)}
              width="240px"
              value={formData.password.value}
            />
            <PasswordHelperTextContainer>
              <GridPasswordValidationItem>
                <IconContainer
                  icon={isPasswordLengthValid ? 'check' : 'close'}
                  isValid={isPasswordLengthValid}
                />
                <PasswordValidationText>
                  At least 8 characters
                </PasswordValidationText>
              </GridPasswordValidationItem>
              <GridPasswordValidationItem>
                <IconContainer
                  icon={hasPasswordCapitalLetter ? 'check' : 'close'}
                  isValid={hasPasswordCapitalLetter}
                />
                <PasswordValidationText>
                  A capital letter
                </PasswordValidationText>
              </GridPasswordValidationItem>
              <GridPasswordValidationItem>
                <IconContainer
                  icon={hasPasswordSpecialChar ? 'check' : 'close'}
                  isValid={hasPasswordSpecialChar}
                />
                <PasswordValidationText>
                  A number or special character
                </PasswordValidationText>
              </GridPasswordValidationItem>
            </PasswordHelperTextContainer>
          </ColumnContainer>
        </Grid>
        <Grid item xs={12}>
          <CheckBoxStyled
            fontSize="14"
            text={
              (
                <>
                  I have read and agree to the Ryan Transportation{' '}
                  <LinkStyled href={TERMS_USE} target="_blank">
                    Terms of Use and Privacy Policy.
                  </LinkStyled>
                </>
              ) as any
            }
            onClick={() => setTermsAndConditions(!termsAndConditions)}
            checked={termsAndConditions}
          ></CheckBoxStyled>
        </Grid>
        <Grid item xs={12}>
          <StepNavigation
            leftButtonLabel="CANCEL"
            onBack={() => onChangeStep(step - 1)}
            onContinue={handleOnCreateAccount}
            isRightButtonDisabled={disableSubmit()}
            rightButtonLabel="CREATE ACCOUNT"
            visible
          />
        </Grid>
      </GridContainer>
    </StepContainerPaper>
  );
};

export default Step3;
