import React, { forwardRef, useState, useEffect } from 'react';
import { AddressFormData } from '../types/loadTypes';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import moment, { Moment } from 'moment';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

//utilities
import usStates from '../../../utilities/locations/usStates.json';
import canadaStates from '../../../utilities/locations/canadaStates.json';
import mexicoStates from '../../../utilities/locations/mexicoStates.json';
import countries from '../../../utilities/locations/countries.json';

// styles
import Grid from '@material-ui/core/Grid';
import { MenuItem as MUIMenuItem } from '@material-ui/core';
import { MenuItem } from 'shamrock-clover-ui/dist/clover/components/MenuItem/MenuItem';
import {
  CheckBoxStyled,
  PhoneTextInputStyles,
  TextInputStyled,
  DatePickerStyled,
  GridContainer,
  MultipleGrid,
  AddressMenu,
  StyledSelect,
  LinkContainer,
  Link
} from './loadEntryStyles';
import {
  setAddressBookOpen,
  setAddressEntryOpen,
  setCurrentForm,
  setEditAddressIndex,
  setHoldAddress
} from '../../../store/addressBook/addressBookActions';
import { Address } from '../../../store/addressBook/addressBookReduxTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDeliveryAddress,
  getPickupAddress
} from '../../../store/addressBook/addressBookSelectors';
import UserService from '../../../services/user/UserService';
import * as FirebaseUtils from '../../../utilities/firebaseUtils';

// props
interface Props {
  formData: AddressFormData;
  setFormData: Function;
  formType: 'pickup' | 'delivery';
  minDate?: string;
  maxDate?: string;
}

// eslint-disable-next-line react/display-name
export const PhoneTextInputStyled = forwardRef<
  HTMLInputElement,
  {
    error?: string;
    onChange: (value: string) => void;
    onBlur: (value: string) => void;
    value: string;
  }
>(({ error, onChange, onBlur, value }, ref) => {
  // TODO: Please keep in mind this implementation is already done in last shamrock-clover-ui version >= 6.2.0
  // we did not upgrade the current shamrock-clover-ui version due some components, styles, pages, etc might break

  return (
    <PhoneTextInputStyles error={!!error}>
      <TextInputStyled
        error={error}
        inputComponent={
          <NumberFormat
            allowNegative={false}
            className="phoneTextInput"
            format={'(###) - ### - ####'}
            getInputRef={ref}
            onValueChange={(valuePayload: NumberFormatValues) =>
              onChange(valuePayload.value)
            }
            onBlur={({ target: { value } }: { target: { value: string } }) => {
              onBlur(value);
            }}
            placeholder="Phone Number"
            type="tel"
            value={value}
          />
        }
        label="Phone Number"
        maxLength={10}
        onChange={({ target: { value } }: { target: { value: string } }) =>
          onChange(value)
        }
        onBlur={({ target: { value } }: { target: { value: string } }) => {
          onBlur(value);
        }}
        value={value}
      />
    </PhoneTextInputStyles>
  );
});

export const AddressForm = ({
  formData,
  setFormData,
  formType,
  minDate,
  maxDate
}: Props) => {
  const {
    suggestions: { data },
    setValue,
    clearSuggestions
  } = usePlacesAutocomplete();
  const dispatch = useDispatch();

  const autoFillAddress = useSelector(
    formType === 'pickup' ? getPickupAddress : getDeliveryAddress
  );
  const [addressFocus, setAddressFocus] = useState<boolean>(true);
  const [stateOptions, setStateOptions] = useState<string[]>(
    usStates.map(state => state.name + ' - ' + state.abbreviation)
  );

  var countryOptions = countries.map(country => country.name);

  const handleOnChangeForm = (name: string, value: string) => {
    setFormData({ ...formData, [name]: { value: value, errorMessage: '' } });

    if (name === 'phoneNumber' && value && value.length < 10) {
      setFormData({
        ...formData,
        [name]: {
          value: value,
          errorMessage: 'Enter a valid phone number'
        }
      });
      return;
    }
  };

  const handleOnBlurForm = (name: string, value: string) => {
    if (value === '') {
      setFormData({
        ...formData,
        [name]: { value: '', errorMessage: 'Required field' }
      });
    }
  };

  useEffect(() => {
    if (autoFillAddress) {
      const savedState =
        autoFillAddress.stateProvince === ''
          ? ''
          : stateOptions.find(
              state => state.split(' - ')[0] === autoFillAddress.stateProvince
            );

      setFormData({
        ...formData,
        companyName: { errorMessage: '', value: autoFillAddress.name },
        addressLine1: {
          errorMessage: '',
          value: autoFillAddress.address1
        },
        addressLine2: {
          errorMessage: '',
          value: autoFillAddress.address2
        },
        city: { errorMessage: '', value: autoFillAddress.city },
        state: {
          errorMessage: '',
          value: savedState
        },
        zip: { errorMessage: '', value: autoFillAddress.postalCode },
        country: { errorMessage: '', value: autoFillAddress.country },
        phoneNumber: {
          errorMessage: '',
          value: autoFillAddress.phoneNumber
        },
        appointmentRequired: {
          errorMessage: '',
          value: autoFillAddress.appointmentRequired
        }
      });
    }
  }, [autoFillAddress]);

  useEffect(() => {
    if (formData.country.value === 'United States') {
      setStateOptions(
        usStates.map(state => state.name + ' - ' + state.abbreviation)
      );
    }
    if (formData.country.value === 'Canada') {
      setStateOptions(
        canadaStates.map(state => state.name + ' - ' + state.abbreviation)
      );
    }
    //Check later to see if its possible to add abbreviations back
    if (formData.country.value === 'Mexico') {
      setStateOptions(
        mexicoStates.map(state => state.name) // + ' - ' + state.abbreviation)
      );
    }
  }, [formData.country.value]);

  const handleAddressSelect = ({ description }: { description: any }) => () => {
    clearSuggestions();

    getGeocode({ address: description })
      .then(results => {
        let addressBody = {
          addressLine1: { value: '' },
          city: { value: '' },
          state: { value: '' },
          country: { value: '' },
          zip: { value: '' }
        };

        results[0].address_components.map(address => {
          if (address.types[0] === 'street_number') {
            addressBody.addressLine1.value = address.long_name;
          }
          if (address.types[0] === 'route') {
            addressBody.addressLine1.value =
              addressBody.addressLine1.value + ' ' + address.long_name;
          }
          if (address.types[0] === 'locality') {
            addressBody.city.value = address.long_name;
          }
          if (address.types[0] === 'country') {
            addressBody.country.value = address.long_name;
          }
          if (address.types[0] === 'administrative_area_level_1') {
            addressBody.state.value =
              address.long_name + ' - ' + address.short_name;
          }
          if (address.types[0] === 'postal_code') {
            addressBody.zip.value = address.long_name;
          }
        });

        if (addressBody.country.value === 'Mexico') {
          addressBody.state.value = addressBody.state.value.split(' - ')[0];
        }

        setFormData({
          ...formData,
          ...addressBody
        });
      })
      .catch(error => {
        console.log('Error: ', error);
      });
  };

  const renderSuggestionsMenu = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text }
      } = suggestion;

      if (
        suggestion.terms[suggestion.terms.length - 1].value === 'USA' ||
        suggestion.terms[suggestion.terms.length - 1].value === 'Canada' ||
        suggestion.terms[suggestion.terms.length - 1].value === 'Mexico'
      ) {
        return (
          <MenuItem key={place_id} onClick={handleAddressSelect(suggestion)}>
            <p>
              <b>{main_text}</b>&nbsp;
              {secondary_text}
            </p>
          </MenuItem>
        );
      }
      return;
    });

  return (
    <GridContainer container>
      <Grid item sm={12}>
        <LinkContainer disabled={false}>
          <Link
            disabled={false}
            onClick={() => {
              const mcleod_customer_id = UserService.getMcleodCustomerId();
              FirebaseUtils.logFirebaseEvent(
                FirebaseUtils.FirebaseEvents.CLICK,
                FirebaseUtils.FirebaseModules.SHIPPER,
                FirebaseUtils.FirebasePages.LOAD_ENTRY,
                {
                  mcleodID: mcleod_customer_id,
                  description: `Open Address Book-${
                    formType === 'pickup' ? 'Pickup' : 'Delivery'
                  }`
                }
              );

              dispatch(setAddressBookOpen(true));
            }}
          >
            ADDRESS BOOK
          </Link>
          |
          <Link
            disabled={
              !(
                formData.companyName.value !== '' ||
                formData.addressLine1.value !== '' ||
                formData.addressLine2.value !== '' ||
                formData.city.value !== '' ||
                formData.state.value !== '' ||
                formData.zip.value !== '' ||
                formData.phoneNumber.value !== '' ||
                formData.appointmentRequired.value !== false
              )
            }
            onClick={() => {
              const address: Address = {
                name: formData.companyName.value,
                address1: formData.addressLine1.value,
                address2: formData.addressLine2.value,
                city: formData.city.value,
                stateProvince:
                  formData.state.value === ''
                    ? ''
                    : formData.state.value.split(' - ')[0],
                postalCode: formData.zip.value,
                country: formData.country.value,
                phoneNumber: formData.phoneNumber.value,
                appointmentRequired: formData.appointmentRequired.value,
                isDeleted: false
              };

              const mcleod_customer_id = UserService.getMcleodCustomerId();
              FirebaseUtils.logFirebaseEvent(
                FirebaseUtils.FirebaseEvents.CLICK,
                FirebaseUtils.FirebaseModules.SHIPPER,
                FirebaseUtils.FirebasePages.LOAD_ENTRY,
                {
                  mcleodID: mcleod_customer_id,
                  description: `Save Address-${
                    formType === 'pickup' ? 'Pickup' : 'Delivery'
                  }`
                }
              );

              dispatch(setAddressEntryOpen(true));
              dispatch(setEditAddressIndex(-1));
              dispatch(setHoldAddress(address));
              dispatch(setCurrentForm(formType));
            }}
          >
            SAVE ADDRESS
          </Link>
        </LinkContainer>
      </Grid>
      <Grid item sm={6}>
        <Grid item sm={12}>
          <CheckBoxStyled
            fontSize="14"
            text="Appointment required"
            onClick={() =>
              setFormData({
                ...formData,
                appointmentRequired: {
                  value: !formData.appointmentRequired.value
                }
              })
            }
            checked={formData.appointmentRequired.value}
          />
        </Grid>
        <MultipleGrid item sm={12}>
          <DatePickerStyled
            format="MM/DD/YYYY"
            onChange={(date: Moment) => {
              handleOnChangeForm('date', date.format('MM/DD/YYYY'));
            }}
            minDate={minDate ? moment(minDate) : moment().subtract(1, 'hours')}
            maxDate={maxDate ? moment(maxDate) : moment().add(1, 'years')}
            placeholder="Date"
            value={formData.date.value ? moment(formData.date.value) : null}
            readOnly
            error={formData.date.errorMessage}
          />
          <TextInputStyled
            label="Time"
            helperText="AM/PM & time zone"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('time', value);
            }}
            value={formData.time.value}
            width="140px"
            className="rightSmallInput"
          />
        </MultipleGrid>
        <Grid item sm={12}>
          <TextInputStyled
            label="Company Name"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('companyName', value);
            }}
            value={formData.companyName.value}
            onBlur={() => {
              handleOnBlurForm('companyName', formData.companyName.value);
            }}
            error={formData.companyName.errorMessage}
          />
        </Grid>
        <Grid item sm={12}>
          <PhoneTextInputStyled
            onChange={value => {
              handleOnChangeForm('phoneNumber', value);
            }}
            value={formData.phoneNumber.value}
            onBlur={() => {
              handleOnBlurForm('phoneNumber', formData.phoneNumber.value);
            }}
            error={formData.phoneNumber.errorMessage}
          />
        </Grid>
      </Grid>
      <Grid item sm={6}>
        <Grid item sm={12}>
          <TextInputStyled
            label="Address 1"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('addressLine1', value);
              setValue(value);
            }}
            value={formData.addressLine1.value}
            onBlur={() => {
              handleOnBlurForm('addressLine1', formData.addressLine1.value);
              setValue('');
            }}
            error={formData.addressLine1.errorMessage}
            onFocus={() => setAddressFocus(true)}
            autoComplete={false}
          />
          {addressFocus && <AddressMenu>{renderSuggestionsMenu()}</AddressMenu>}
        </Grid>
        <Grid item sm={12}>
          <TextInputStyled
            label="Address 2"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('addressLine2', value);
            }}
            value={formData.addressLine2.value}
            marginBottom="52px"
          />
        </Grid>
        <Grid item sm={12}>
          <TextInputStyled
            label="City"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('city', value);
            }}
            value={formData.city.value}
            onBlur={() => {
              handleOnBlurForm('city', formData.city.value);
            }}
            error={formData.city.errorMessage}
          />
        </Grid>
        <MultipleGrid item sm={12}>
          <StyledSelect
            value={formData.state.value}
            onChange={event => {
              handleOnChangeForm('state', event.target.value);
            }}
            label="State"
            error={!!formData.state.errorMessage}
            helperText={formData.state.errorMessage}
            width="140px"
            select
            SelectProps={{
              MenuProps: {
                MenuListProps: {
                  style: { height: '220px', width: '300px' }
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              },
              SelectDisplayProps: {
                style: {
                  color: '#666666',
                  fontFamily: 'Proxima Nova'
                }
              }
            }}
          >
            {stateOptions.map((option, index) => {
              return (
                <MUIMenuItem
                  style={{
                    color: '#666666',
                    fontFamily: 'Proxima Nova'
                  }}
                  key={index}
                  value={option}
                >
                  {option}
                </MUIMenuItem>
              );
            })}
          </StyledSelect>
          <TextInputStyled
            label="Zip Code"
            onChange={({
              target: { value }
            }: {
              target: { value: string };
            }) => {
              handleOnChangeForm('zip', value);
            }}
            value={formData.zip.value}
            onBlur={() => {
              handleOnBlurForm('zip', formData.zip.value);
            }}
            error={formData.zip.errorMessage}
            width="140px"
            className="rightSmallInput"
          />
        </MultipleGrid>
        <Grid item sm={12}>
          <StyledSelect
            value={formData.country.value}
            onChange={event => {
              setFormData({
                ...formData,
                state: { value: '', errorMessage: '' },
                zip: { value: '', errorMessage: '' },
                country: { value: event.target.value, errorMessage: '' }
              });
            }}
            label="Country"
            error={!!formData.country.errorMessage}
            helperText={formData.country.errorMessage}
            select
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              },
              SelectDisplayProps: {
                style: {
                  color: '#666666',
                  fontFamily: 'Proxima Nova'
                }
              }
            }}
          >
            {countryOptions.map((option, index) => {
              return (
                <MUIMenuItem
                  style={{
                    color: '#666666',
                    fontFamily: 'Proxima Nova',
                    margin: 5
                  }}
                  key={index}
                  value={option}
                >
                  {option}
                </MUIMenuItem>
              );
            })}
          </StyledSelect>
        </Grid>
      </Grid>
    </GridContainer>
  );
};

export default AddressForm;
