exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".statusIcon{display:flex;justify-content:center}.tableCellContainer{height:100%;display:flex;flex-direction:column;justify-content:center}.statusText{font-size:.875rem;font-weight:600;vertical-align:top}.statusText div{display:block}.statusText .statusInfo{font-size:.75rem}.orderColumn{min-width:105px}.basicData{vertical-align:top;font-size:.875rem;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;display:block}.statusText .ellipsis,.basicData .ellipsis{text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.lateColumn{color:red}", ""]);

// Exports
exports.locals = {
	"ui-blue-01": "#0091ea",
	"ui-blue-02": "#0077b8",
	"ui-blue-03": "#005b8d",
	"ui-gray-01": "#333",
	"ui-gray-02": "#666",
	"ui-gray-03": "#999",
	"ui-gray-04": "#ccc",
	"ui-dropshadow": "rgba(0,0,0,.5)",
	"ui-background": "#f8f8f8",
	"ui-white": "#fff",
	"support-red": "#ef3824",
	"support-orange": "#ff9000",
	"support-green": "#72bf44",
	"support-yellow": "#ffc418"
};