import { Order } from '../../types/orderTypes';
import {
  Marker,
  OrderState,
  FileItem,
  OrderActionTypes
} from './orderReduxTypes';
import {
  SET_ORDERS,
  SET_NUMBER_OF_ORDER_RESULTS,
  TOGGLE_PAGE_CHANGING,
  SET_ORDERS_CSV,
  SET_ORDERS_CSV_LOADING,
  SET_PAGE_INDEX,
  SET_NO_OF_ROWS,
  TOGGLE_FILTER_BOOKED,
  TOGGLE_FILTER_IN_TRANSIT,
  TOGGLE_FILTER_DELIVERED,
  SET_REFERENCE_FILTER_STRING,
  SET_ORIGIN_FILTER_STRING,
  SET_DESTINATION_FILTER_STRING,
  SET_PLACES_SUGGESTIONS,
  SET_ORDER_BY,
  SET_DETAIL_PAGE,
  TOGGLE_DETAIL_LOADING,
  TOGGLE_MARKERS_LOADING,
  SET_DETAIL_ERROR,
  SET_DETAIL_MARKERS,
  TOGGLE_MAPS_ERROR,
  SET_ORDERS_ERROR,
  TOGGLE_NOTIFICATIONS_MODAL,
  SET_NOTIFICATION_SUMMARY,
  TOGGLE_LOAD_ENTRY_MODAL,
  TOGGLE_CANCEL_MODAL,
  SET_LOAD_ENTRY_ERROR,
  TOGGLE_NOTIFICATIONS_MODAL_PENDING_STATE
} from './ordersActions';

export const ordersState: OrderState = {
  orders: [] as Order[],
  noOfResults: 0,
  pageIndex: 0,
  noOfRows: 25,
  ordersCSV: '',
  ordersCSVLoading: false,
  pageChanging: false,
  filterBooked: false,
  filterInTransit: true,
  filterDelivered: false,
  referenceFilterString: '',
  originFilterString: '',
  destinationFilterString: '',
  isTableError: false,
  suggestions: [],
  orderBy: {
    column: 'Order',
    isAscending: false
  },
  detail: {
    isVisible: false,
    isLoading: false,
    markersLoading: false,
    markers: [] as Marker[],
    files: [] as FileItem[],
    isMapsError: false,
    order: undefined
  },
  showNotificationsModal: false,
  notificationModalInPendingState: false,
  notificationSummary: undefined,
  showLoadEntryModal: false,
  showCancelModal: false,
  showLoadEntryError: false,
  isLoadEntryError: false,
  loadEntryErrorText: ''
};

export default function orders(
  state = ordersState,
  action: OrderActionTypes
): OrderState {
  switch (action.type) {
    case SET_ORDERS:
      return Object.assign({}, state, {
        orders: Object.assign([], action.orders),
        isTableError: false
      });
    case SET_ORDERS_ERROR:
      return Object.assign({}, state, {
        isTableError: action.isError
      });
    case SET_NUMBER_OF_ORDER_RESULTS:
      return Object.assign({}, state, {
        noOfResults: action.orderCount
      });
    case TOGGLE_PAGE_CHANGING:
      return Object.assign({}, state, {
        pageChanging: action.pageChanging
      });
    case SET_ORDERS_CSV:
      return Object.assign({}, state, {
        ordersCSV: action.ordersCSV
      });
    case SET_ORDERS_CSV_LOADING:
      return Object.assign({}, state, {
        ordersCSVLoading: action.ordersCSVLoading
      });
    case SET_PAGE_INDEX:
      return Object.assign({}, state, { pageIndex: action.page });
    case SET_NO_OF_ROWS:
      return Object.assign({}, state, { noOfRows: action.noOfRows });
    case TOGGLE_FILTER_BOOKED:
      return Object.assign({}, state, { filterBooked: action.filterBooked });
    case TOGGLE_FILTER_IN_TRANSIT:
      return Object.assign({}, state, {
        filterInTransit: action.filterInTransit
      });
    case TOGGLE_FILTER_DELIVERED:
      return Object.assign({}, state, {
        filterDelivered: action.filterDelivered
      });
    case SET_REFERENCE_FILTER_STRING:
      return Object.assign({}, state, {
        referenceFilterString: action.referenceFilterString
      });
    case SET_ORIGIN_FILTER_STRING:
      return Object.assign({}, state, {
        originFilterString: action.originFilterString
      });
    case SET_DESTINATION_FILTER_STRING:
      return Object.assign({}, state, {
        destinationFilterString: action.destinationFilterString
      });
    case SET_PLACES_SUGGESTIONS:
      return Object.assign({}, state, {
        suggestions: Object.assign([], action.placesSuggestions)
      });
    case SET_ORDER_BY:
      return Object.assign({}, state, {
        orderBy: action.orderBy
      });
    case SET_DETAIL_MARKERS: {
      const { value } = action;
      return {
        ...state,
        detail: {
          ...state.detail,
          markers: value
        }
      };
    }
    case SET_DETAIL_PAGE: {
      const { order, error, fileItems } = action.detail;

      return {
        ...state,
        detail: {
          ...state.detail,
          order,
          error,
          files: fileItems
        }
      };
    }
    case SET_DETAIL_ERROR: {
      const { value } = action;

      return {
        ...state,
        detail: {
          ...state.detail,
          error: value
        }
      };
    }
    case TOGGLE_DETAIL_LOADING: {
      const { value } = action;

      return {
        ...state,
        detail: {
          ...state.detail,
          isLoading: value
        }
      };
    }
    case TOGGLE_MARKERS_LOADING: {
      const { value } = action;

      return {
        ...state,
        detail: {
          ...state.detail,
          markersLoading: value
        }
      };
    }
    case TOGGLE_MAPS_ERROR: {
      const { value } = action;

      return {
        ...state,
        detail: {
          ...state.detail,
          isMapsError: value
        }
      };
    }
    case TOGGLE_NOTIFICATIONS_MODAL: {
      return Object.assign({}, state, {
        showNotificationsModal: action.isVisible
      });
    }
    case TOGGLE_NOTIFICATIONS_MODAL_PENDING_STATE: {
      const { value } = action;
      return Object.assign({}, state, {
        notificationModalInPendingState: value
      });
    }
    case SET_NOTIFICATION_SUMMARY: {
      return Object.assign({}, state, {
        notificationSummary: action.notificationSummary
      });
    }
    case TOGGLE_LOAD_ENTRY_MODAL: {
      return Object.assign({}, state, {
        showLoadEntryModal: action.isVisible
      });
    }
    case TOGGLE_CANCEL_MODAL: {
      return Object.assign({}, state, {
        showCancelModal: action.isVisible
      });
    }
    case SET_LOAD_ENTRY_ERROR: {
      const { isVisible, isError, value } = action;

      return {
        ...state,
        showLoadEntryError: isVisible,
        isLoadEntryError: isError,
        loadEntryErrorText: value
      };
    }
    default:
      return state;
  }
}
