import * as React from 'react';
import { ChildComponentProps } from 'google-map-react';
import InTransitIcon from '../../../assets/images/InTransit.svg';
import DelayedInTransitIcon from '../../../assets/images/DelayedInTransit.svg';
import CompletedIcon from '../../../assets/images/BlueCheckMark.svg';
import DeliveredIcon from '../../../assets/images/Delivered.svg';
import PickupIcon from '../../../assets/images/Booked.svg';
import { MarkerTypes } from '../../../store/orders/orderReduxTypes';

import './mapMarker.scss';
import { Tooltip, withStyles } from '@material-ui/core';

const getIcon = (type: MarkerTypes) => {
  switch (type) {
    case 'IN_TRANSIT':
      return InTransitIcon;
    case 'IN_TRANSIT_DELAYED':
      return DelayedInTransitIcon;
    case 'COMPLETED':
      return CompletedIcon;
    case 'DELIVERY':
      return DeliveredIcon;
    case 'PICKUP':
      return PickupIcon;
    default:
      return '';
  }
};

const MapTooltip = withStyles(() => {
  return {
    tooltip: {
      backgroundColor: 'white',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)'
    }
  };
})(Tooltip);

const MapMarker: React.FunctionComponent<ChildComponentProps & {
  type: MarkerTypes;
  primaryText?: string;
  secondaryText?: string;
}> = ({ type, primaryText, secondaryText }) => {
  return primaryText || secondaryText ? (
    <MapTooltip
      placement="top-end"
      title={
        <div className="mapToolTip">
          {primaryText && <span className="primaryText">{primaryText}</span>}
          {secondaryText && (
            <span
              className="secondaryText"
              style={
                secondaryText.includes('Delayed') ? { color: '#FF0000' } : {}
              }
            >
              {secondaryText}
            </span>
          )}
        </div>
      }
    >
      <img className="mapMarker" src={getIcon(type)}></img>
    </MapTooltip>
  ) : (
    <img className="mapMarker" src={getIcon(type)}></img>
  );
};

export default MapMarker;
