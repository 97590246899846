import React from 'react';
import { AppState } from 'store';
import { Address } from './addressBookReduxTypes';
import {
  setAddressEntryOpen,
  setEditAddressIndex,
  setDeleteModalOpen
} from './addressBookActions';
import './addressBookSelectors.scss';
import UserService from '../../services/user/UserService';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

import usStates from '../../utilities/locations/usStates.json';
import canadaStates from '../../utilities/locations/canadaStates.json';
import mexicoStates from '../../utilities/locations/mexicoStates.json';

export interface TableData {
  index: number;
  companyName: JSX.Element;
  address: JSX.Element;
  phone: JSX.Element;
  event: JSX.Element;
}

export const getCompanyNameCell = (address: Address) => {
  return <div className="nameLink">{address.name}</div>;
};

export const getAddressCell = (address: Address) => {
  let stateList = usStates;
  if (address.country === 'Canada') stateList = canadaStates;
  if (address.country === 'Mexico') stateList = mexicoStates;

  const stateAbbreviated = stateList.find(
    state => state.name === address.stateProvince
  )?.abbreviation;

  return (
    <div className="basicData">
      {address.address1}
      {', '}
      {address.address2 ? address.address2 + ', ' : ''}
      {address.city}
      {', '}
      {stateAbbreviated ? stateAbbreviated : address.stateProvince}
      {', '}
      {address.postalCode}
    </div>
  );
};

export const getPhoneCell = (address: Address) => {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return (
    <div className="basicData">
      {address.phoneNumber.replace(phoneRegex, '($1) $2-$3')}
    </div>
  );
};

export const getActionCell = (index: number, dispatch: any) => {
  return (
    <div
      className="actionLinkContainer"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <span
        className="actionLink"
        onClick={() => {
          const mcleod_customer_id = UserService.getMcleodCustomerId();
          FirebaseUtils.logFirebaseEvent(
            FirebaseUtils.FirebaseEvents.CLICK,
            FirebaseUtils.FirebaseModules.SHIPPER,
            FirebaseUtils.FirebasePages.ADDRESS_BOOK,
            {
              mcleodID: mcleod_customer_id,
              description: 'Open Edit Address Form'
            }
          );

          dispatch(setAddressEntryOpen(true));
          dispatch(setEditAddressIndex(index));
        }}
      >
        Edit
      </span>
      {' | '}
      <span
        className="actionLink"
        onClick={() => {
          const mcleod_customer_id = UserService.getMcleodCustomerId();
          FirebaseUtils.logFirebaseEvent(
            FirebaseUtils.FirebaseEvents.CLICK,
            FirebaseUtils.FirebaseModules.SHIPPER,
            FirebaseUtils.FirebasePages.ADDRESS_BOOK,
            {
              mcleodID: mcleod_customer_id,
              description: 'Initiate Delete Request'
            }
          );

          dispatch(setEditAddressIndex(index));
          dispatch(setDeleteModalOpen(true));
        }}
      >
        Delete
      </span>
    </div>
  );
};

export const getAddressesData = (state: AppState) => {
  return state.addressBook.addressBook;
};
export const getAddressBookLoading = (state: AppState) => {
  return state.addressBook.isLoading;
};
export const getAddressBookError = (state: AppState) => {
  return state.addressBook.isError;
};
export const getHoldAddress = (state: AppState) => {
  return state.addressBook.holdAddress;
};
export const getEditAddressIndex = (state: AppState) => {
  return state.addressBook.editAddressIndex;
};
export const getPickupAddress = (state: AppState) => {
  return state.addressBook.pickupAddress;
};
export const getDeliveryAddress = (state: AppState) => {
  return state.addressBook.deliveryAddress;
};
export const getAddressEntryOpen = (state: AppState) => {
  return state.addressBook.isAddressEntryOpen;
};
export const getAddressBookOpen = (state: AppState) => {
  return state.addressBook.isAddressBookOpen;
};
export const getCurrentForm = (state: AppState) => {
  return state.addressBook.currentForm;
};
export const getDeleteModalOpen = (state: AppState) => {
  return state.addressBook.isDeleteModalOpen;
};
export const getUnsavedModalOpen = (state: AppState) => {
  return state.addressBook.isUnsavedModalOpen;
};
export const getAddressBookSnackbarOpen = (state: AppState) => {
  return state.addressBook.isSnackbarVisible;
};
export const getAddressBookSnackbarError = (state: AppState) => {
  return state.addressBook.isSnackbarError;
};
export const getAddressBookSnackbarMessage = (state: AppState) => {
  return state.addressBook.snackbarMessage;
};
