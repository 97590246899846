// services
import Service from './Service';
import AuthService from '../services/auth/AuthService';

// config
const Config = require('Config');

const authService: AuthService = new AuthService();

interface SignUpPayload {
  company: string;
  company_type: string;
  customer_id?: string;
  device_type: string;
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  phone: string;
  roles: string[];
}

export class RegistrationService extends Service {
  constructor() {
    super('', false);
  }

  async sendVerificationCode(email: string) {
    try {
      const { status } = await this.axios.post<string>(Config.verificationUrl, {
        email,
        templateId: 'd-6faec22e9e5c4fe787c1b809f6589483'
      });

      if (status === 200) {
        return { success: true };
      }
    } catch (e) {
      return { success: false };
    }
  }

  async checkVerificationCode(email: string, verificationCode: string) {
    try {
      const { status } = await this.axios.get<string>(Config.verificationUrl, {
        params: { email, verification_code: verificationCode }
      });

      if (status === 200) {
        return { success: true };
      }
    } catch (e) {
      return { success: false };
    }
  }

  async signupNewUser({
    company,
    company_type,
    device_type,
    customer_id,
    email,
    firstname,
    lastname,
    password,
    phone,
    roles
  }: SignUpPayload) {
    try {
      const { data: registeredUser } = await this.axios.post(
        `${Config.sentinelServiceUrl}user/signup`,
        {
          user: {
            acceptedTos: true,
            company,
            company_type,
            mcleod_customer_id: customer_id,
            device_type,
            email,
            firstname,
            lastname,
            password,
            phone,
            roles
          },
          cpua1264Enabled: true
        }
      );

      if (registeredUser) {
        const tokenObj = {
          token: registeredUser.token,
          user: registeredUser.email
        };
        authService.setToken(tokenObj);

        return { success: true, emailAlreadyExists: false };
      }
    } catch (e) {
      const response: {
        emailAlreadyExists?: boolean;
        success: boolean;
        msg?: string;
      } = {
        success: false
      };

      const { message }: any = e as any;
      const emailAlreadyExists = message.includes('409');
      const createdSuccessfully =
        message.includes('502') || message.includes('500');

      if (emailAlreadyExists) {
        response.emailAlreadyExists = emailAlreadyExists;
        response.msg =
          'Error, unable to complete your request, please try again later.';
      }

      if (createdSuccessfully) {
        response.success = true;
      }

      return response;
    }
  }
}

export default RegistrationService;
