// packages
import React from 'react';

export const formatPlaceSuggestion = (
  suggestion: google.maps.places.AutocompletePrediction
) => {
  const { terms } = suggestion;
  const termsArray = terms.map(({ value }) => value);
  termsArray.pop();
  return termsArray.join(', ');
};

export const getSuggestionValue = (suggestion: any) =>
  formatPlaceSuggestion(suggestion);

export const renderSuggestion = (suggestion: any) => (
  <div>{formatPlaceSuggestion(suggestion)}</div>
);
