import React, { Fragment } from 'react';
import UserService from '../../../services/user/UserService';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import styled from 'styled-components';
import * as FirebaseUtils from '../../../utilities/firebaseUtils';

interface Props {
  toggleLoadEntryModal: Function;
}

const StyledButton = styled(Button)`
  font-family: proxima-nova;
  border-radius: 20px;
  text-transform: capitalize;
  svg {
    fill: currentColor;
  }
  margin-right: 25px;
`;

const LoadEntryButton: React.FC<Props> = props => {
  return (
    <Fragment>
      <StyledButton
        height={'36px'}
        buttonStyle="solid"
        onClick={() => {
          const mcleod_customer_id = UserService.getMcleodCustomerId();
          FirebaseUtils.logFirebaseEvent(
            FirebaseUtils.FirebaseEvents.CLICK,
            FirebaseUtils.FirebaseModules.SHIPPER,
            FirebaseUtils.FirebasePages.SHIPMENTS,
            {
              mcleodID: mcleod_customer_id,
              description: 'Open Submit Load Panel'
            }
          );
          props.toggleLoadEntryModal(true);
        }}
      >
        Submit Load
      </StyledButton>
    </Fragment>
  );
};

export default LoadEntryButton;
