import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Logo from '../../assets/images/shipper-portal-blue.svg';
import RTSProLogo from '../../assets/images/rtspro-logo-blue.svg';
import { UserService, AuthService } from '../../services';
import { styled } from 'shamrock-clover-ui';
import {
  FirebaseEvents,
  FirebaseModules,
  FirebasePages,
  logFirebaseEvent
} from '../../utilities/firebaseUtils';
import './appHeader.scss';
import { redirectoToWebCore } from '../../utilities';

export const LoginLink = styled.a`
  font-size: ${({ theme }) => theme.font.size.small};
  color: #666666;
  align-self: center;
  margin-left: auto;
`;

interface Props {
  useRTSProLogo?: boolean;
  ignoreClick?: boolean;
}

const authService = new AuthService();

const Config = require('Config');

const Header: React.FC<Props> = ({ children, useRTSProLogo, ignoreClick }) => {
  const [anchorEl, setAnchorEl] = React.useState<
    HTMLDivElement | null | undefined
  >(undefined);

  const user = UserService.getCurrentUser();
  const firstName = user?.firstname || '';
  const lastName = user?.lastname ? ` ${user?.lastname}` : '';

  const token = authService.getToken;
  const email = user?.email;

  const handleSettingsClicked = () => {
    if (token) {
      setAnchorEl(null);
      redirectoToWebCore(token.token, email, 'settings');
    }
  };

  const handleContactClicked = () => {
    setAnchorEl(null);
    window.open(Config.contactUsUrl, '_blank');
  };

  const handleLogoutClicked = () => {
    setAnchorEl(null);
    authService.logoutRedirect();
  };

  const loginClick = () => {
    logFirebaseEvent(
      FirebaseEvents.LOGIN,
      FirebaseModules.EASYTRACK,
      FirebasePages.EASYTRACK,
      {}
    );
  };

  const logoClick = () => {
    if (ignoreClick) return;
    window.open(Config.shipperPortalUrl, '_self');
  };

  return (
    <div className="header">
      <div className="appBarTopMenu">
        <div className="toolBar">
          <div className="rightSideHeader">
            {user ? (
              <div
                className="menuItem"
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                {firstName + lastName}
                <div
                  className={
                    anchorEl === undefined ? '' : anchorEl ? 'open' : 'closed'
                  }
                >
                  <ArrowDropDownIcon />
                </div>
              </div>
            ) : (
              <LoginLink onClick={loginClick} href={AuthService.getLoginUrl()}>
                Log In
              </LoginLink>
            )}
          </div>
          <Menu
            classes={{ paper: 'menuRoot' }}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={handleSettingsClicked}>
              <div className="menuItem">Settings</div>
            </MenuItem>
            <MenuItem onClick={handleContactClicked}>
              <div className="menuItem">Contact Us</div>
            </MenuItem>
            <MenuItem onClick={handleLogoutClicked}>
              <div className="menuItem">Log Out</div>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <div className="appBarPrimaryNavigation">
        <div className="toolBar">
          <img
            alt="rts-logo"
            src={useRTSProLogo ? RTSProLogo : Logo}
            className="logo"
            onClick={() => logoClick()}
          />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Header;
