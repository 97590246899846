import React, { useState, ChangeEvent } from 'react';
import Autosuggest from 'react-autosuggest';
import { Search } from '@material-ui/icons';
import GoogleMapsService from '../../services/googleMaps/GoogleMapsService';
import { useLoadTrackingQueryParams } from '../../hooks/routeHooks';
import {
  formatStringOrStringArray,
  isZipCode
} from '../../utilities/stringHelper';
import {
  FilterContainer,
  FilterInputContainer,
  Filters,
  SearchButton,
  ResetButton,
  LocationFilterContainer,
  StyledFilterInput
} from './LoadTrackingStyles';
import {
  FirebaseEvents,
  FirebaseModules,
  FirebasePages,
  logFirebaseEvent
} from '../../utilities/firebaseUtils';

export const LoadTrackingInputs: React.FC<{}> = ({}) => {
  const { setQueryParams, queryParams } = useLoadTrackingQueryParams();

  const [refNo, setRefNo] = useState(queryParams.referenceNumber || '');
  const [cityZip, setCityZip] = useState(
    formatStringOrStringArray(queryParams.city) ||
      formatStringOrStringArray(queryParams.zip) ||
      ''
  );
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const removeStateFromCity = (cityState: string) => {
    return cityState.substring(0, cityState.lastIndexOf(','));
  };

  const searchForTracking = () => {
    logFirebaseEvent(
      FirebaseEvents.SEARCH,
      FirebaseModules.EASYTRACK,
      FirebasePages.EASYTRACK,
      {
        referenceNumber: refNo,
        cityZip
      }
    );
    if (isZipCode(cityZip)) {
      setQueryParams({ referenceNumber: refNo, zip: cityZip });
      return;
    }
    setQueryParams({
      referenceNumber: refNo,
      city: removeStateFromCity(cityZip)
    });
  };

  const getGooglePlaceSuggestions = async (filter: string) => {
    let places = await GoogleMapsService.getPlaceSuggestions(filter);
    const cities = places
      .filter(x => x.types[0] === 'locality')
      .map(x => `${x.terms[0].value}, ${x.terms[1].value}`);

    const zips = places
      .filter(x => x.types[0] === 'postal_code')
      .map(x => x.terms[2].value);

    // remove duplicates
    setSuggestions([...new Set([...cities, ...zips])]);
  };

  const onInputEnter = (event: any) => {
    if (event.key === 'Enter') {
      searchForTracking();
    }
  };

  const renderSuggestion = (suggestion: string) => {
    return <div>{suggestion}</div>;
  };

  const getSuggestionValue = (suggestion: string) => {
    return suggestion;
  };

  const isDisabled = !refNo || !cityZip;

  return (
    <FilterContainer>
      <Filters>
        <FilterInputContainer>
          <StyledFilterInput
            placeholder="Reference Number"
            value={refNo}
            clearSearch={() => setRefNo('')}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setRefNo(event.target.value);
            }}
          />
        </FilterInputContainer>
        <LocationFilterContainer>
          <Autosuggest
            inputProps={{
              value: cityZip,
              onChange: (event, { newValue }) => {
                setCityZip(newValue);
              },
              placeholder: 'Delivery City or Zip Code',
              onKeyDown: onInputEnter,
              autoComplete: 'new-password'
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={() =>
              getGooglePlaceSuggestions(cityZip)
            }
            onSuggestionsClearRequested={() => setSuggestions([])}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
          />
        </LocationFilterContainer>
        <SearchButton
          type="button"
          data-testid="SearchButton"
          onClick={() => searchForTracking()}
          disabled={isDisabled}
        >
          <Search color={isDisabled ? 'disabled' : 'inherit'} />
        </SearchButton>
        <ResetButton
          onClick={() => {
            setRefNo('');
            setCityZip('');
          }}
          disabled={isDisabled}
        >
          Reset Search
        </ResetButton>
      </Filters>
    </FilterContainer>
  );
};

export default LoadTrackingInputs;
