import { TOKEN_CACHE, CURRENT_USER } from '../../utilities/constants';

const Config = require('Config');

export interface Token {
  token: string;
  user: string;
}
export interface Permission {
  name: string;
  status: string;
}
export interface UserResponce {
  _id: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  user_type: string;
  company_type: string;
  createdOn: Date;
  accounts?: any;
  supervisor_email?: any;
  company: string;
  roles: string[];
  mc_number: string;
  bundle_status: boolean;
  mcleod_carrier_id: string;
  pfjMyRewardsNumber: string;
  user_id: string;
  permissions: Permission[];
  token: string;
}

class AuthService {
  async checkSession(): Promise<Token | undefined> {
    if (!this.isAuthed) {
      const token = this.incomingSearch;
      if (token.token && token.user) {
        return token as Token;
      } else {
        throw new Error('Not Logged In');
      }
    }
  }

  redirectToLogin() {
    this.deleteToken();
    this.deleteUser();
    const origin = encodeURIComponent(Config.shipperPortalUrl);
    window.location.assign(
      `${Config.coreWebUrl}auth?finaldestination=${origin}`
    );
  }

  async redirectToLoginRegistered() {
    window.location.assign(`${Config.shipperPortalUrl}`);
  }

  logoutRedirect(): void {
    this.deleteToken();
    this.deleteUser();
    this.redirectToLogout();
  }

  deleteToken() {
    localStorage.removeItem(TOKEN_CACHE);
  }

  deleteUser() {
    localStorage.removeItem(CURRENT_USER);
  }

  setToken(token: Token) {
    localStorage.setItem(TOKEN_CACHE, JSON.stringify(token));
  }

  get incomingSearch() {
    const params = new URLSearchParams(location.search);
    return {
      user: params.get('email'),
      token: params.get('token')
    };
  }

  get isAuthed() {
    return !!localStorage.getItem(TOKEN_CACHE);
  }

  get getToken() {
    const token = localStorage.getItem(TOKEN_CACHE);
    return token ? (JSON.parse(token) as Token) : null;
  }

  static getLoginUrl() {
    const origin = encodeURIComponent(Config.shipperPortalUrl);
    return `${Config.coreWebUrl}auth?finaldestination=${origin}`;
  }

  private redirectToLogout() {
    const redirect = encodeURIComponent(Config.shipperPortalUrl);
    window.location.assign(
      `${Config.coreWebUrl}logout?finaldestination=${redirect}`
    );
  }
}

export default AuthService;
