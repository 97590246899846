import * as React from 'react';
import { styled } from 'shamrock-clover-ui';

interface MenuItemProps {
  className?: string;
  onClick?: () => void;
  width?: string;
}

function returnMenuLocationCss(location?: string) {
  switch (location) {
    case 'top-right':
      return `
      position: absolute; top:0; left:100%; margin-left: 5px;
      `;
    case 'top-left':
      return `
      position: absolute; top:0; right:100%; margin-right: 5px;
      `;
    case 'above':
      return `
      position: absolute; bottom: 100%; left: 50%; transform: translateX(-50%); margin-bottom: 5px;
      `;
    case 'on-top-of':
      return `
      position: absolute; top: 0%; left: 50%; transform: translateX(-50%);
      `;
    case 'middle-right':
      return `
      position: absolute; top:50%; left:100%; margin-left: 5px;
      `;
    case 'middle-left':
      return `
      position: absolute; top:50%; right:100%; margin-right: 5px;
      `;
    case 'bottom-right':
      return `
      position: absolute; left:100%; margin-left: 5px;
      `;
    case 'bottom-left':
      return `
      position: absolute; right:100%; margin-right: 5px;
      `;
    case 'below':
      return `
      position: absolute; left: 50%; transform: translateX(-50%); margin-top: 5px;
      `;
    case 'below-block':
      return `
      position: unset; margin-left: 50%; transform: translateX(-50%); margin-top: 5px;
      `;
    default:
      return `
      position: absolute; top:0; left:100%; margin-left: 5px;
      `;
  }
}

const MenuComponentWrapper = styled.div<{ width?: string }>`
  position: relative;
  display: inline-block;
  height: min-content;
  line-height: 1;
  ${props => `width: ${props.width}`}
`;

export type MenuLocation =
  | 'top-right'
  | 'top-left'
  | 'above'
  | 'on-top-of'
  | 'middle-right'
  | 'middle-left'
  | 'bottom-right'
  | 'bottom-left'
  | 'below'
  | 'below-block';

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 8px 16px 8px;
`;

const MenuItemsContainer = styled.div`
  overflow-y: auto;
  max-height: 200px;
  min-width: 230px;
  overflow-x: hidden;
  scrollbar-color: ${props => props.theme.gray4} transparent;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: ${props => props.theme.gray4};
    :hover {
      background-color: ${props => props.theme.gray3};
    }
  }
`;

const MenuContainer = styled.div<{
  menuLocation?: MenuLocation;
  width?: string;
}>`
  overflow: auto;
  background-color: ${props => props.theme.white};
  padding: 2px 0px;
  width: ${props => (props.width ? props.width : 'max-content')};
  max-height: 300px;
  min-width: 112px;
  height: max-content;
  border-radius: 4px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
  z-index: 3;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  ${props => returnMenuLocationCss(props.menuLocation || 'top-right')}
`;

const MenuItemContainer = styled.li<MenuItemProps>`
  background-color: ${props => props.theme.white};
  display: flex;
  height: 48px;
  justify-content: space-between;
  min-width: 112px;
  align-items: center;
  padding: 0px 16px;
  width: auto;
  :hover {
    background-color: ${props => props.theme.gray5};
    cursor: pointer;
  }
`;

const MenuItemNoResults = styled.li`
  background-color: ${props => props.theme.white};
  display: flex;
  height: 48px;
  justify-content: space-between;
  min-width: 112px;
  align-items: center;
  padding: 0px 16px;
  width: auto;
`;

const MenuItem: React.FC<MenuItemProps> = (
  props: React.PropsWithChildren<MenuItemProps>
) => {
  const { className, onClick, width } = props;
  return (
    <MenuItemContainer
      className={className || 'CloverMenuItem'}
      onClick={() => onClick && onClick()}
      width={width}
    >
      {props.children}
    </MenuItemContainer>
  );
};

export {
  MenuComponentWrapper,
  MenuItem,
  MenuContainer,
  SearchContainer,
  MenuItemsContainer,
  MenuItemNoResults
};
