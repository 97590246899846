import Service from './Service';
import { AddressBook } from '../store/addressBook/addressBookReduxTypes';
// import { useDispatch } from 'react-redux';
import {
  setAddressBook,
  setAddressBookError,
  setAddressBookLoading,
  setAddressBookOpen,
  setAddressEntryOpen,
  setCurrentForm,
  setDeleteModalOpen,
  setDeliveryAddress,
  setEditAddressIndex,
  setHoldAddress,
  setPickupAddress,
  setUnsavedModalOpen
} from '../store/addressBook/addressBookActions';

const Config = require('Config');

export interface AddressBookServiceAPIResponse {
  data: AddressBook;
  status: number;
}
class AddressBookService extends Service {
  constructor() {
    super(Config.apiBaseUrl);
  }

  getAddressBook = async () => {
    try {
      const response = await this.axios.get<AddressBook>('/addressBook');
      if (response.status != 200) {
        throw Error(response.statusText);
      }
      return Promise.resolve(response.data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };

  postAddressBook = async (addressBook: AddressBook) => {
    try {
      const response = await this.axios.post<AddressBook>(
        '/addressBook',
        addressBook
      );
      if (response.status != 200) {
        throw Error(response.statusText);
      }
      return Promise.resolve(response.data);
    } catch (e) {
      console.log(e);
      return Promise.reject(e);
    }
  };

  clearAddressBook = async (dispatch: any) => {
    const emptyAddress = {
      name: '',
      address1: '',
      address2: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      country: 'United States',
      phoneNumber: '',
      appointmentRequired: false,
      isDeleted: false
    };

    dispatch(setAddressBook({}));
    dispatch(setHoldAddress(emptyAddress));
    dispatch(setPickupAddress(emptyAddress));
    dispatch(setDeliveryAddress(emptyAddress));
    dispatch(setEditAddressIndex(-1));

    dispatch(setAddressBookOpen(false));
    dispatch(setAddressEntryOpen(false));
    dispatch(setDeleteModalOpen(false));
    dispatch(setUnsavedModalOpen(false));

    dispatch(setCurrentForm('pickup'));
    dispatch(setAddressBookLoading(false));
    dispatch(setAddressBookError(false));
    dispatch(setEditAddressIndex(-1));
  };
}

export default new AddressBookService();
