import React from 'react';
import './AddressBookTable.scss';
import { useSelector } from 'react-redux';
import Table, {
  TableHeader,
  TableBody,
  TableJson,
  TableCellStyles
} from '../table/Table';
import { Spinner } from 'shamrock-clover-ui/dist/clover/components/Spinner/Spinner';
import SnackbarError from '../SnackbarError';
import {
  EmptyViewContainer,
  EmptyViewText,
  SpinnerContainer
} from './AddressBookStyles';
import { Icon as CloverIcon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import {
  getAddressesData,
  getAddressBookError,
  getAddressBookLoading,
  getCurrentForm,
  TableData,
  getCompanyNameCell,
  getAddressCell,
  getPhoneCell,
  getActionCell
} from '../../store/addressBook/addressBookSelectors';
import {
  setPickupAddress,
  setDeliveryAddress,
  sortAddressBook,
  setAddressBookOpen
} from '../../store/addressBook/addressBookActions';
import { useDispatch } from 'react-redux';
import { StyledPaginationFooter } from '../cloverPagination/PaginationFooter';
import { Address } from '../../store/addressBook/addressBookReduxTypes';

const headerData = [
  {
    label: 'COMPANY NAME',
    key: 'company_name'
  },
  {
    label: 'ADDRESS',
    key: 'address'
  },
  {
    label: 'PHONE',
    key: 'phone'
  },
  {
    label: 'ACTIONS',
    key: 'actions'
  }
];

const cellStyles = [
  {
    currentStyle: {
      width: '15%',
      color: 'black',
      fontSize: '12px',
      letterSpacing: '0.1px'
    },
    columnKey: 'company_name'
  },
  {
    currentStyle: {
      width: '55%',
      color: 'black',
      fontSize: '12px',
      letterSpacing: '0.1px'
    },
    columnKey: 'address'
  },
  {
    currentStyle: {
      width: '15%',
      color: 'black',
      fontSize: '12px',
      letterSpacing: '0.1px'
    },
    columnKey: 'phone'
  },
  {
    currentStyle: {
      width: '15%',
      color: 'black',
      fontSize: '12px',
      letterSpacing: '0.1px'
    },
    columnKey: 'actions'
  },
  {
    currentStyle: {
      width: '0%'
    },
    columnKey: 'index'
  }
];

interface AddressBookTableProps {
  searchInput: string;
}

const AddressBookTable = (props: AddressBookTableProps) => {
  const dispatch = useDispatch();

  const rawAddressData = useSelector(getAddressesData);
  const isTableLoading = useSelector(getAddressBookLoading);
  const currentForm = useSelector(getCurrentForm);
  const isTableError = useSelector(getAddressBookError);

  const [addressBookTableData, setAddressBookTableData] = React.useState<
    TableData[]
  >([]);

  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [limit, setLimit] = React.useState(30);

  React.useEffect(() => {
    if (rawAddressData) {
      let tableData: TableData[] = [];

      rawAddressData.addresses?.forEach((address: Address, index: number) => {
        if (address.isDeleted) {
          return;
        }

        if (
          props.searchInput &&
          address.name
            .toLowerCase()
            .indexOf(props.searchInput.toLowerCase()) === -1 &&
          address.address1
            .toLowerCase()
            .indexOf(props.searchInput.toLowerCase()) === -1 &&
          address.address2
            .toLowerCase()
            .indexOf(props.searchInput.toLowerCase()) === -1 &&
          address.city
            .toLowerCase()
            .indexOf(props.searchInput.toLowerCase()) === -1 &&
          address.stateProvince
            .toLowerCase()
            .indexOf(props.searchInput.toLowerCase()) === -1 &&
          address.country
            .toLowerCase()
            .indexOf(props.searchInput.toLowerCase()) === -1
        ) {
          return;
        }

        tableData.push({
          companyName: getCompanyNameCell(address),
          address: getAddressCell(address),
          phone: getPhoneCell(address),
          event: getActionCell(index, dispatch),
          index: index
        });
      });
      setAddressBookTableData(tableData);
    }
  }, [rawAddressData, props.searchInput]);

  const onRowClick = (rowData: TableJson, index: number): void => {
    setSelectedIndex(index);
    if (currentForm === 'pickup') {
      rawAddressData?.addresses &&
        dispatch(setPickupAddress(rawAddressData.addresses[rowData.index]));
    } else {
      rawAddressData?.addresses &&
        dispatch(setDeliveryAddress(rawAddressData.addresses[rowData.index]));
    }
    dispatch(setAddressBookOpen(false));
  };

  const onChangePage = (page: number): void => {
    setCurrentPage(page);
  };

  const onChangeRowsPerPage = (rowsPerPage: number): void => {
    setLimit(rowsPerPage);
    setCurrentPage(1);
  };

  const orderByClick = (columnTitle: string, order: 'asc' | 'desc') => {
    rawAddressData.addresses &&
      dispatch(sortAddressBook(rawAddressData, order === 'asc'));
  };

  return (
    <>
      <div className="addressBookTableContainer">
        {isTableLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
        ) : (
          <>
            {addressBookTableData.length === 0 && (
              <EmptyViewContainer>
                <CloverIcon icon="stationNote" size="36" color="gray3" />
                <EmptyViewText fontSize="20px" marginTop="8px">
                  You don’t have any addresses saved.
                </EmptyViewText>
                <EmptyViewText fontSize="16px" marginTop="0px">
                  To begin, click on Add New Address.
                </EmptyViewText>
              </EmptyViewContainer>
            )}

            {addressBookTableData && addressBookTableData.length > 0 && (
              <>
                <Table
                  cellStyles={cellStyles}
                  isSortable={true}
                  headerComponent={(
                    cellStyles: TableCellStyles[],
                    isSortable: boolean | undefined
                  ) => {
                    return (
                      <div className="tableHeaderContainer">
                        <TableHeader
                          orderByClick={orderByClick}
                          isSortable={isSortable}
                          columns={headerData}
                          cellStyles={cellStyles}
                          singleSortColumn="COMPANY NAME"
                        />
                      </div>
                    );
                  }}
                  bodyComponent={(cellStyles: TableCellStyles[]) => {
                    return (
                      <TableBody
                        currentlySelectedIndex={selectedIndex}
                        tableRowData={addressBookTableData.slice(
                          currentPage === 1 ? 0 : currentPage * limit - limit,
                          addressBookTableData.length >= currentPage * limit - 1
                            ? currentPage === 1
                              ? limit
                              : currentPage * limit - 1
                            : addressBookTableData.length
                        )}
                        onRowClick={onRowClick}
                        rowStyle={{
                          marginBottom: '0px'
                        }}
                        cellStyles={cellStyles}
                      />
                    );
                  }}
                />
              </>
            )}
          </>
        )}
      </div>

      {addressBookTableData && addressBookTableData.length > 0 && (
        <div className="paginationContainer">
          <StyledPaginationFooter
            currentPage={currentPage}
            rowsPerPage={limit}
            totalRows={addressBookTableData.length}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            footerOptions={{
              rowsPerPageSelector: true
            }}
          />
        </div>
      )}

      <SnackbarError
        isError={isTableError}
        message="Oops! Something went wrong. Please refresh."
      />
    </>
  );
};

export default AddressBookTable;
