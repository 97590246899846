import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TruckStatusIcon from '../../../truckStatusIcon/TruckStatusIcon';
import CloseIcon from '../../../../assets/images/Close.svg';
import { statusColorClass } from '../../../../utilities/stringHelper';
import {
  getDetailOrderStatusText,
  getDetailOrder,
  getNotificationSummary
} from '../../../../store/orders/ordersSelectors';
import {
  isBooked,
  isOrderDelayed,
  isOrderComplete
} from '../../../../utilities/orderUtilities';
import { toggleNotificationsModal } from '../../../../store/orders/ordersActions';
import './orderDetailsHeaderSection.scss';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import NotificationsButton from '../../../../features/notifications/notificationsButton';

const OrderDetailsHeaderSection: FunctionComponent = () => {
  const orderStatusText = useSelector(getDetailOrderStatusText);
  const order = useSelector(getDetailOrder);
  const notificationSummary = useSelector(getNotificationSummary);
  const { push } = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const toggleNotificationModal = (bool: boolean) =>
    dispatch(toggleNotificationsModal(bool));
  const getStatusColorClass = () => {
    return statusColorClass(orderStatusText);
  };

  const closeOrderDetails = () => {
    const { orderId, ...rest } = queryString.parse(search);
    push({
      pathname: '/shipments',
      search: queryString.stringify({ ...rest })
    });
  };

  const orderNotificationsOn = (): boolean => {
    if (notificationSummary && notificationSummary.globalEnabled) {
      return true;
    } else if (notificationSummary && notificationSummary.orders && order) {
      let orderNotifications = notificationSummary.orders.find(
        o => o.orderId === order.orderId
      );
      if (orderNotifications) {
        return orderNotifications.enabled;
      } else return false;
    } else return false;
  };
  return (
    <div className="orderDetailsHeader">
      <div className="orderDetailsIcon">
        <TruckStatusIcon
          isTracking={(order && order.rawCallin !== null) || false}
          isBooked={isBooked(order)}
          isDelayed={isOrderDelayed(order)}
          isDelivered={isOrderComplete(order)}
        />
      </div>
      <div className="orderTitle">{`Order ${
        order ? order.orderId : 'Unknown Order Id'
      } `}</div>
      <div className={`orderDetailsStatus ${getStatusColorClass()}`}>
        {`${orderStatusText}`}
      </div>
      <div className="notificationsButton">
        <NotificationsButton
          toggleNotificationModal={toggleNotificationModal}
          notificationsOn={orderNotificationsOn()}
          type="order"
          orderId={order ? order.orderId : undefined}
        />
      </div>
      <div
        className="orderDetailsCloseIcon"
        onClick={() => closeOrderDetails()}
      >
        <img src={CloseIcon} />
      </div>
    </div>
  );
};

export default React.memo(OrderDetailsHeaderSection);
