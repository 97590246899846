exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".basicData{line-height:20px;letter-spacing:.25px;display:contents}.nameLink{color:#0091ea;letter-spacing:.25px}.actionLinkContainer{width:100%;height:100%;display:flex;align-items:center;cursor:initial}.actionLink{color:#0091ea;letter-spacing:.25px;margin:8px;cursor:pointer}", ""]);

// Exports
exports.locals = {
	"ui-blue-01": "#0091ea",
	"ui-blue-02": "#0077b8",
	"ui-blue-03": "#005b8d",
	"ui-gray-01": "#333",
	"ui-gray-02": "#666",
	"ui-gray-03": "#999",
	"ui-gray-04": "#ccc",
	"ui-dropshadow": "rgba(0,0,0,.5)",
	"ui-background": "#f8f8f8",
	"ui-white": "#fff",
	"support-red": "#ef3824",
	"support-orange": "#ff9000",
	"support-green": "#72bf44",
	"support-yellow": "#ffc418"
};