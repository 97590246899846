exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".addressBookTableContainer{width:100%;height:calc(100vh - 500px);overflow:hidden}.addressBookTableContainer thead{display:table;width:100%}.addressBookTableContainer tbody{overflow-x:hidden;overflow-y:scroll}.addressBookTableContainer .tableHeaderContainer{border-bottom:1px solid var(--gray-gray-30, #CCC);background:var(--global-white, #FFF);width:98.25%;margin-left:1.15%}.addressBookTableContainer .tableRow{box-shadow:none;border-bottom:1px solid var(--gray-gray-20, #EEE)}.addressBookTableContainer .tableRow:hover{background-color:#eee}.addressBookTableContainer .emptyState{display:flex;justify-content:center;align-items:center;font-size:1.5rem;height:100%;color:#ccc}.addressBookTableContainer .summaryBilling{color:#666;text-align:center}.paginationContainer{margin-top:24px}", ""]);

// Exports
exports.locals = {
	"ui-blue-01": "#0091ea",
	"ui-blue-02": "#0077b8",
	"ui-blue-03": "#005b8d",
	"ui-gray-01": "#333",
	"ui-gray-02": "#666",
	"ui-gray-03": "#999",
	"ui-gray-04": "#ccc",
	"ui-dropshadow": "rgba(0,0,0,.5)",
	"ui-background": "#f8f8f8",
	"ui-white": "#fff",
	"support-red": "#ef3824",
	"support-orange": "#ff9000",
	"support-green": "#72bf44",
	"support-yellow": "#ffc418"
};