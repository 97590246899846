import React, { useEffect } from 'react';
import AccountingCards from './AccountingCards';
import AccountingTable from './AccountingTable';
import Toolbar from './Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceCount } from '../../store/accounting/accountingSelectors';
import TermsFooter from '../../components/termsFooter/termsFooter';
import Paginator from '../../components/paginator/Paginator';
import './Accounting.scss';
import AccountingDetails from './details/AccountingDetails';
import { Slide } from '@material-ui/core';
import { useAccountingQueryParams } from '../../hooks/routeHooks';
import {
  fetchInvoiceFilterCounts,
  fetchInvoices
} from '../../store/accounting/accountingActions';
import UnmatchedUserModal from '../../features/blankState/unmatchedUserModal';

const Accounting = () => {
  const dispatch = useDispatch();
  const count = useSelector(getInvoiceCount);

  const { queryParams, addQueryParams } = useAccountingQueryParams();
  const {
    page,
    limit,
    filter,
    orderBy,
    order,
    search,
    invoiceId
  } = queryParams;

  const pageNumber = page ? parseInt(page.toString()) : 1;
  const pageIndex = pageNumber - 1;
  const limitNumber = limit ? parseInt(limit.toString()) : 25;

  useEffect(() => {
    dispatch(fetchInvoiceFilterCounts());
  }, []);

  useEffect(() => {
    const { filter, orderBy, order, search } = queryParams;

    dispatch(
      fetchInvoices({
        filter: filter ? filter.toString() : '',
        orderBy: orderBy ? orderBy : 'invoice_due_date',
        order: order ? order : 'asc',
        page: pageNumber,
        limit: limitNumber,
        search: search
      })
    );
  }, [filter, orderBy, order, page, limit, search]);

  const pageChange = (newPage: number): void => {
    addQueryParams({ page: (newPage + 1).toString() });
  };

  const limitChange = (newLimit: number): void => {
    addQueryParams({ limit: newLimit.toString() });
  };

  return (
    <div style={{ minWidth: '1075px' }}>
      <UnmatchedUserModal />
      <AccountingCards />
      <Toolbar />
      <AccountingTable />
      <hr className="divider" />
      <div className="paginationContainer">
        <Paginator
          noOfRows={limitNumber}
          noOfResults={count}
          onPageChange={pageChange}
          onRowChange={limitChange}
          pageIndex={pageIndex}
        />
      </div>
      <TermsFooter showBillingEmail={true} />
      <Slide
        direction="left"
        in={invoiceId !== undefined}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 400, exit: 400 }}
      >
        <AccountingDetails />
      </Slide>
    </div>
  );
};

export default Accounting;
