import React, { Fragment } from 'react';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import styled from 'styled-components';
import { Icon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { useDispatch } from 'react-redux';
import {
  setAddressEntryOpen,
  setEditAddressIndex
} from '../../store/addressBook/addressBookActions';
import UserService from '../../services/user/UserService';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

const StyledButton = styled(Button)`
  font-family: proxima-nova;
  border-radius: 20px;
  text-transform: capitalize;
  svg {
    fill: currentColor;
  }
  margin-right: 6px;
`;

export const NewAddressButton = () => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    const mcleod_customer_id = UserService.getMcleodCustomerId();
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.ADDRESS_BOOK,
      {
        mcleodID: mcleod_customer_id,
        description: 'Open Add New Address Form'
      }
    );

    dispatch(setAddressEntryOpen(true));
    dispatch(setEditAddressIndex(-1));
  };

  return (
    <Fragment>
      <StyledButton
        height={'40px'}
        buttonStyle="outlined"
        onClick={handleOnClick}
        icon={<Icon icon="plusBlue" size="16" />}
      >
        ADD NEW ADDRESS
      </StyledButton>
    </Fragment>
  );
};

export default NewAddressButton;
