import {
  FirebaseEvents,
  FirebaseModules,
  FirebasePages,
  logFirebaseEvent
} from '../../utilities/firebaseUtils';

export const logFullDetailsView = (orderId: string) => {
  logFirebaseEvent(
    FirebaseEvents.VIEW_FULL,
    FirebaseModules.EASYTRACKFULL,
    FirebasePages.EASYTRACKFULL,
    {
      orderNumber: orderId
    }
  );
};

export const logPartialDetailsView = (referenceNumber: string) => {
  logFirebaseEvent(
    FirebaseEvents.VIEW_BASIC,
    FirebaseModules.EASYTRACK,
    FirebasePages.EASYTRACK,
    {
      orderNumber: referenceNumber
    }
  );
};
