import React, { FunctionComponent } from 'react';
import {
  COPYRIGHT_TEXT,
  TERMS_OF_USE,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
  BILLING_EMAIL_LINK,
  BILLING_EMAIL_LINK_TEXT,
  ACCOUNTING_PHONE_NUMBER_LINK,
  ACCOUNTING_PHONE_NUMBER_TEXT
} from '../../utilities/constants';
import '../termsFooter/termsFooter.scss';

interface Props {
  showBillingEmail: boolean;
  snowAccountingPhone?: boolean;
}

const TermsFooter: FunctionComponent<Props> = props => {
  const getAnchor = (link: string, text: string) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  return (
    <div className="copyright" data-testid="TermsFooter">
      <p>
        {COPYRIGHT_TEXT} &nbsp;|&nbsp;
        {getAnchor(
          'https://www.ryantrans.com/page/terms-use',
          TERMS_OF_USE
        )}{' '}
        &nbsp;|&nbsp;
        {getAnchor(
          'https://www.ryantrans.com/page/privacy-policy',
          PRIVACY_POLICY
        )}{' '}
        &nbsp;|&nbsp;
        {getAnchor(
          'https://www.ryantrans.com/page/customer-portal-terms-of-use',
          TERMS_AND_CONDITIONS
        )}
        {props.showBillingEmail && (
          <>
            {' '}
            &nbsp;|&nbsp;{' '}
            <a
              data-testid="TermsFooterEmailLink"
              href={BILLING_EMAIL_LINK}
              className="mailText"
            >
              {BILLING_EMAIL_LINK_TEXT}
            </a>
          </>
        )}
        {props.snowAccountingPhone && (
          <>
            {' '}
            &nbsp;|&nbsp;{' '}
            <a
              data-testid="TermsFooterEmailLink"
              href={ACCOUNTING_PHONE_NUMBER_LINK}
              className="mailText"
            >
              {ACCOUNTING_PHONE_NUMBER_TEXT}
            </a>
          </>
        )}
        {}
      </p>
    </div>
  );
};

export default TermsFooter;
