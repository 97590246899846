import Service from './Service';
import {
  LoadTrackingDetails,
  LoadTrackingDetailsMultiple
} from '../features/loadTracking/loadTrackingTypes';

const Config = require('Config');

class LoadTrackingService extends Service {
  constructor() {
    super(Config.loadTrackingServiceUrl, false);
  }

  async getFullDetails(id: string) {
    const response = await this.axios.get<LoadTrackingDetails>(`/${id}`);
    return response.data;
  }

  async getPartialDetails(
    referenceNumber: string,
    city?: string,
    zip?: string
  ) {
    try {
      const response = await this.axios.get<
        LoadTrackingDetails | LoadTrackingDetailsMultiple
      >('', {
        params: { referenceNumber, city, zip }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default new LoadTrackingService();
