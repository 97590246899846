export const getWidthValue = (pixels?: number): string => {
  return pixels
    ? pixels + 'px'
    : '-moz-available; width: -webkit-fill-available; width: fill-available; width:100%';
};

export const getMultiSelectValue = (
  options: { text: string; checked: boolean }[]
): string => {
  const selectedOptionsText = options.reduce((selectedOptionsText, option) => {
    if (option.checked) {
      selectedOptionsText.push(option.text);
    }

    return selectedOptionsText;
  }, [] as string[]);

  return selectedOptionsText.join(', ');
};
