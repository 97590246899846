import React, { FunctionComponent } from 'react';
import { getDate } from '../../utilities/dateHelper';
import './DetailStop.scss';
import { getStopStatusText } from '../../utilities/orderUtilities';
import {
  NAME,
  ADDRESS,
  LOCATION,
  SCHEDULED_ARRIVAL,
  ACTUAL_ARRIVAL,
  ACTUAL_DEPARTURE,
  PENDING
} from '../../utilities/constants';
import { StopInformation, LastCallInInformation } from 'types/orderTypes';

interface Props {
  stop: StopInformation;
  stops: StopInformation[];
  callIn?: LastCallInInformation;
}

const DetailStop: FunctionComponent<Props> = ({ stop, stops, callIn }) => {
  const {
    stop_type,
    locationName,
    address,
    city,
    state,
    zip_code,
    sched_arrive_early,
    sched_arrive_late,
    actual_arrival,
    actual_departure
  } = stop;
  const stopStatusText = getStopStatusText(stop, stops, callIn);
  const stopType = stop_type == 'PU' ? 'Pickup' : 'Delivery';

  const getStatusColorClass = (statusText: string) => {
    return statusText.includes('Delayed') ? 'delayed' : '';
  };

  return (
    <div className="detailsPickup">
      <div className="detailsPickupContainer">
        <div className="detailsPickupTitle">
          {stopType}:
          <span
            data-testid="DetailsPickupTitle"
            className={`detailsPickupTitleBold ${getStatusColorClass(
              stopStatusText
            )}`}
          >{` ${stopStatusText}`}</span>
        </div>
        <div className="detailsInfoContainer">
          {locationName && (
            <div className="detailsInfo">
              <div className="detailInfoName">{NAME}</div>
              <div
                data-testid="DetailsPickupNameValue"
                className="detailInfoValue overflowEllipsis"
              >
                {locationName}
              </div>
            </div>
          )}
          <div className="detailsInfo">
            <div className="detailInfoName">{address ? ADDRESS : LOCATION}</div>
            <div
              data-testid="DetailsPickupAddressValue"
              className="detailInfoValue overflowEllipsis"
            >
              {address
                ? `${address}, ${city}, ${state}, ${zip_code}`
                : `${city}, ${state}, ${zip_code}`}
            </div>
          </div>
          <div className="detailsInfo">
            <div className="detailInfoName">{SCHEDULED_ARRIVAL}</div>
            <div
              data-testid="DetailsPickupScheduleValue"
              className="detailInfoValue overflowEllipsis"
            >
              {getDate({
                sched_arrive_early,
                sched_arrive_late
              })}
            </div>
          </div>
          <div className="detailsInfo">
            <div className="detailInfoName">{ACTUAL_ARRIVAL}</div>
            <div
              data-testid="DetailsPickupArrivalValue"
              className="detailInfoValue overflowEllipsis"
            >
              {actual_arrival ? getDate({ actual_arrival }) : PENDING}
            </div>
          </div>
          <div className="detailsInfo">
            <div className="detailInfoName">{ACTUAL_DEPARTURE}</div>
            <div
              data-testid="DetailsPickupDepartureValue"
              className="detailInfoValue overflowEllipsis"
            >
              {actual_departure
                ? getDate({
                    actual_departure: actual_departure,
                    actual_arrival: actual_departure
                  })
                : PENDING}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailStop;
