import * as React from 'react';
import { usePartialDetailsLoadTrackingData } from './useLoadTrackingData';
import SpinnerWithWait from '../../components/Spinner';
import { Flex } from '../../components/Flex';
import LoadTrackingCard from './LoadTrackingDetails';
import LoadTrackingMultipleLoadsFound from './LoadTrackingMultipleLoadsFound';
import LoadTrackingNoLoadsFound from './LoadTrackingNoLoadsFound';
import { Card } from './LoadTrackingStyles';
import { logPartialDetailsView } from './loadTrackingAnalytics';

interface Props {
  referenceNumber: string;
  city?: string;
  zip?: string;
}

const LoadTrackingPartialDetails: React.FC<Props> = ({
  referenceNumber,
  city,
  zip
}) => {
  const {
    partialDetails,
    partialDetailsError,
    isLoadingPartialDetails,
    loadStatus,
    isLoadTrackingDetails
  } = usePartialDetailsLoadTrackingData(referenceNumber, city, zip);

  React.useEffect(() => {
    if (!isLoadingPartialDetails && !partialDetailsError && partialDetails)
      logPartialDetailsView(referenceNumber);
  }, [isLoadingPartialDetails]);

  if (isLoadingPartialDetails) {
    return (
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <SpinnerWithWait />
      </Flex>
    );
  }

  if (partialDetailsError || !partialDetails) {
    return <LoadTrackingNoLoadsFound />;
  }

  return (
    <Flex height="100%" justifyContent="center">
      {isLoadTrackingDetails(partialDetails) ? (
        <Card height="max-content">
          <LoadTrackingCard details={partialDetails} loadStatus={loadStatus} />
        </Card>
      ) : (
        <LoadTrackingMultipleLoadsFound
          phone={partialDetails?.phone || ''}
          email={partialDetails?.email || ''}
        />
      )}
    </Flex>
  );
};

export default LoadTrackingPartialDetails;
