import { useQuery } from 'react-query';
import FeatureService from '../services/FeatureService';
import UserService from '../services/user/UserService';

const useFeatureFlag = (id: string) => {
  return useQuery(
    ['featureFlag', id],
    () => FeatureService.getFeature(id, UserService.getUserEmail()),
    {
      staleTime: Infinity
    }
  );
};

// RTD-2185 Feature Flag for Export CSV
export const useExportCSVFlag = () => {
  const result = useFeatureFlag('RTD-2185');
  return { isExportCSVEnabled: result.data };
};

export const useAuth0Flag = () => {
  const result = useFeatureFlag('4bef49cfa41b32fc825919da63a95f4dd8506d7a29f71524ab67516737d1b586');
  return { isAuth0Enabled: result.data };
};

export const useNewRegisterServiceFlag = () => {
  const result = useFeatureFlag('aed29cfbd2bfde7bec77403a2dbf4de3706aed49bb41479b9145b963b3480c3c');
  return { isNewRegisterEnabled: result.data };
};
