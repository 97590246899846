import React from 'react';
import { styled } from 'shamrock-clover-ui';
import { Icon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';

interface FilterInputProps {
  width?: string;
  height?: string;
  value?: string;
  placeholder?: string;
  icon?: string;
  clearSearch?: () => void;
  className?: string;
}

const Container = styled.div<FilterInputProps>`
  max-width: ${props =>
    props.width ? props.width.replace(/px$/, '') + 'px' : '156px'};
  height: ${props =>
    props.height ? props.height.replace(/px$/, '') + 'px' : '40px'};
  width: ${props =>
    props.width ? props.width.replace(/px$/, '') + 'px' : '156px'};
  background: #fff;
  display: inline-flex;
  flex: 1;
  border: 1px solid #cccccc;
  border-radius: 20px;
  padding-right: 10px;
  padding-left: 19px;
  align-items: center;
  justify-content: center;
` as any;

const RightIcon = styled.div`
  cursor: pointer;
  width: 30px;
  flex: 0 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;

  & > div {
    width: 12px;
    height: 12px;
  }
`;

const LeftIcon = styled.div`
  width: 16px;
  flex: 0 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  padding-right: 10px;

  & > div {
    width: 100%;
    height: 100%;
  }
`;

const InputContainer = styled.div`
  flex: 1;
`;

const Input = styled.input<FilterInputProps>`
  font-family: ${props => props.theme.font.family.proximaNova};
  width: 100%;
  color: ${props => props.theme.gray};
  font-size: ${props =>
    props.height
      ? Number(props.height.replace(/px$/, '')) * 0.4 + 'px'
      : '16px'};
  padding: 0;
  border: 0;
  :focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    color: ${props => props.theme.gray3};
  }
`;

export const FilterInput: React.FC<FilterInputProps &
  React.HTMLProps<HTMLInputElement>> = (
  props: FilterInputProps & React.HTMLProps<HTMLInputElement>
) => {
  const containerPropsValues = { height: props.height, width: props.width };
  return (
    <Container className={props.className} {...containerPropsValues}>
      {props.icon && !props.value && (
        <LeftIcon>
          <Icon icon="searchBlack" />
        </LeftIcon>
      )}
      <InputContainer>
        <Input {...(props as FilterInputProps)} />
      </InputContainer>
      {props.value && (
        <RightIcon onClick={props.clearSearch}>
          <Icon
            icon="close"
            onClick={props.clearSearch}
            data-testid="clear-button"
          />
        </RightIcon>
      )}
    </Container>
  );
};

export default FilterInput;
