exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".accountingDetailHeader{width:100%;border-bottom-color:#eee;border-bottom-width:1px;border-bottom-style:solid;height:81px}.accountingDetailHeader .detailTitle{position:absolute;height:24px;width:auto;color:#424242;font-family:\"proxima-nova\";font-size:20px;font-weight:bold;letter-spacing:-0.06px;line-height:24px;left:72px;top:20.5px}.accountingDetailHeader .accountingDetailIcon{position:absolute;top:24px;left:24px;height:32px;width:32px}.accountingDetailHeader .accountingDetailCloseIcon{position:absolute;top:20px;right:20px;font-size:30px;cursor:pointer}.accountingDetailHeader .accountingDetailCloseIcon img{vertical-align:top}.accountingDetailHeader .accountingDetailStatus{position:absolute;display:flex;justify-content:space-between;height:17px;color:#666;font-family:\"proxima-nova\";font-weight:bold;font-size:14px;letter-spacing:-0.06px;line-height:17px;top:45px;left:72px}.accountingDetailHeader .delayed{color:red}.accountingDetailHeader .divider{margin-left:10px;margin-right:10px}", ""]);

// Exports
exports.locals = {
	"ui-blue-01": "#0091ea",
	"ui-blue-02": "#0077b8",
	"ui-blue-03": "#005b8d",
	"ui-gray-01": "#333",
	"ui-gray-02": "#666",
	"ui-gray-03": "#999",
	"ui-gray-04": "#ccc",
	"ui-dropshadow": "rgba(0,0,0,.5)",
	"ui-background": "#f8f8f8",
	"ui-white": "#fff",
	"support-red": "#ef3824",
	"support-orange": "#ff9000",
	"support-green": "#72bf44",
	"support-yellow": "#ffc418"
};