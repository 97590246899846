import React, { Fragment } from 'react';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import styled from 'styled-components';
import Email from '../../assets/images/Email.svg';
import * as FirebaseUtils from '../../utilities/firebaseUtils';
import './notificationsButton.scss';

interface Props {
  toggleNotificationModal: Function;
  notificationsOn: boolean;
  type: 'global' | 'order';
  orderId?: string;
}

const StyledButton = styled(Button)`
  font-family: proxima-nova;
  border-radius: 20px;
  text-transform: capitalize;
  svg {
    fill: currentColor;
  }
  margin-right: 25px;
`;

const NotificationsButton: React.FC<Props> = props => {
  return (
    <Fragment>
      <StyledButton
        height={'36px'}
        buttonStyle={props.notificationsOn ? 'solid' : 'outlined'}
        icon={
          <img
            src={Email}
            className={props.notificationsOn ? 'iconWhite' : 'iconBlue'}
          />
        }
        onClick={() => {
          props.toggleNotificationModal(true);
          if (props.type === 'global') {
            FirebaseUtils.logFirebaseEvent(
              FirebaseUtils.FirebaseEvents.GLOBAL_NOTIFICATION_CLICK,
              FirebaseUtils.FirebaseModules.SHIPPER,
              FirebaseUtils.FirebasePages.SHIPMENTS,
              {}
            );
          } else {
            FirebaseUtils.logFirebaseEvent(
              FirebaseUtils.FirebaseEvents.NOTIFICATION_CLICK,
              FirebaseUtils.FirebaseModules.SHIPPER,
              FirebaseUtils.FirebasePages.SHIPMENTSDETAILS,
              { orderNumber: props.orderId }
            );
          }
        }}
      >
        {props.type === 'global' ? 'Global' : 'Order'} Notifications:{' '}
        {props.notificationsOn ? 'On' : 'Off'}
      </StyledButton>
    </Fragment>
  );
};

export default NotificationsButton;
