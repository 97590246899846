import React, { useState } from 'react';
import { getWidthValue } from './utils';
import { MenuOption, MenuOptions, SingleSelectMenu } from './SingleSelectMenu';
import {
  SelectContainer,
  SelectIcon,
  SelectValueLabel,
  CleanIcon,
  BaseSelectProps
} from './BaseComponents';
interface SelectProps extends BaseSelectProps {
  label: string;
  value?: MenuOption;
  onOptionSelected: (option?: MenuOption, index?: number) => void;
  options: MenuOption[];
  clean?: boolean;
  hideIcon?: boolean;
  disabled?: boolean;
  sectionOptions?: MenuOptions[];
  hasSections?: boolean;
  displayValue?: string;
  selectedOptionComparator?: (
    selectedOption: MenuOption | undefined,
    compareOption: MenuOption
  ) => boolean;
}

export const SelectInput: React.FC<SelectProps> = (props: SelectProps) => {
  const {
    width,
    value,
    options,
    label,
    onOptionSelected,
    clean = false,
    hideIcon = false,
    disabled = false,
    sectionOptions,
    hasSections = false,
    displayValue,
    selectedOptionComparator
  } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const iconToRender = () => {
    if (clean && value && value.optionName) {
      return (
        <CleanIcon
          onClean={(event: React.MouseEvent<Element, MouseEvent>) => {
            onOptionSelected({ optionName: '' });
            event.stopPropagation();
          }}
        />
      );
    }
    return <SelectIcon variant={isMenuOpen ? 'up' : 'down'} />;
  };

  return (
    <SingleSelectMenu
      width={getWidthValue(width)}
      VisualElement={
        <SelectContainer
          {...props}
          isActive={isMenuOpen}
          className="inputContainer"
        >
          <SelectValueLabel
            value={displayValue || value?.optionName}
            label={label}
          />
          {!hideIcon && iconToRender()}
        </SelectContainer>
      }
      onOptionSelected={onOptionSelected}
      selectedOption={value}
      menuOptions={options ?? []}
      sectionOptions={sectionOptions}
      hasSections={hasSections}
      menuLocation="below"
      setMenuOpen={setMenuOpen}
      selectedOptionComparator={selectedOptionComparator}
      disabled={disabled || hideIcon || (clean && value?.optionName !== '')}
    />
  );
};

export default SelectInput;
