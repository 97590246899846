import { AppState } from '../';
import {
  getPrimaryText,
  getSecondaryText
} from '../../utilities/orderUtilities';

export const getMarkersForOrderDetail = (state: AppState) => {
  return state.orders.detail.markers;
};

export const getDetail = (state: AppState) => {
  return state.orders.detail;
};

export const getDetailOrder = (state: AppState) => {
  const detail = getDetail(state);
  return detail.order;
};

export const getDetailIsLoading = (state: AppState) => {
  const detail = getDetail(state);
  return detail.isLoading;
};

export const getDetailMarkersLoading = (state: AppState) => {
  const detail = getDetail(state);
  return detail.markersLoading;
};

export const getDetailIsVisible = (state: AppState) => {
  const detail = getDetail(state);
  return detail.isVisible;
};

export const getOrderStops = (state: AppState) => {
  const order = getDetailOrder(state);
  if (!order) {
    return [];
  }

  const stops = order.rawStops;

  return [...stops];
};

export const getOrderCallIn = (state: AppState) => {
  const order = getDetailOrder(state);
  if (!order) {
    return undefined;
  }

  return order.rawCallin;
};

export const getDetailOrderId = (state: AppState) => {
  const order = getDetailOrder(state);

  return order ? order.orderId : null;
};

export const getDetailOrderStatusText = (state: AppState) => {
  const order = getDetailOrder(state);
  if (!order) {
    return '';
  }

  return `${getPrimaryText(
    order.rawStops,
    order.rawCallin
  )}. ${getSecondaryText(order.rawStops)}`;
};

export const getDetailOrderFileList = (state: AppState) => {
  const detail = getDetail(state);
  return detail.files;
};

export const getMapsError = (state: AppState) => {
  const detail = getDetail(state);
  return detail.isMapsError || false;
};

export const getShowNotificationsModal = (state: AppState) => {
  return state.orders.showNotificationsModal;
};

export const getNotificationModalInPendingState = (state: AppState) => {
  return state.orders.notificationModalInPendingState;
};

export const getNotificationSummary = (state: AppState) => {
  return state.orders.notificationSummary;
};

export const getShowLoadEntryModal = (state: AppState) => {
  return state.orders.showLoadEntryModal;
};

export const getShowCancelModal = (state: AppState) => {
  return state.orders.showCancelModal;
};

export const getIsLoadEntryError = (state: AppState) => {
  return state.orders.isLoadEntryError;
};

export const getLoadEntryErrorText = (state: AppState) => {
  return state.orders.loadEntryErrorText;
};

export const getShowLoadEntryError = (state: AppState) => {
  return state.orders.showLoadEntryError;
};

export const getOrderCSV = (state: AppState) => {
  return state.orders.ordersCSV;
};
export const getOrderCSVLoading = (state: AppState) => {
  return state.orders.ordersCSVLoading;
};
