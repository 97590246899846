// packages
import styled from 'styled-components';
import { CheckBox } from 'shamrock-clover-ui/dist/clover/components/CheckBox/CheckBox';
import { TextInput } from 'shamrock-clover-ui/dist/clover/components/TextInput/TextInput';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import Grid from '@material-ui/core/Grid';
import { MultilineTextInput } from 'shamrock-clover-ui/dist/clover/components/MultilineTextInput/MultilineTextInput';
import DatePickerField from '../../../components/datePickerField';

export const RequestQuoteContent = styled.div`
  background-color: #ffffff;
  width: 845px;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  display: flex;
  flex-direction: column;
`;

export const Completed = styled.div`
  font-size: 14px;
  font-weight: normal;
  display: flex;
  align-items: flex-start;
`;

export const CompletedText = styled.div`
  margin-left: 4px;
`;

export const MainText = styled.div`
  padding: 18px;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 600px) {
    width: auto;
    padding: 10px;
    margin-bottom: 30px;
  }
`;

export const CheckBoxStyled = styled(CheckBox)`
  margin-left: 64px;
  margin-top: 20px;
  margin-bottom: 52px;
`;

export const GridContainer = styled(Grid)`
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const EmptyGrid = styled(Grid)`
  height: 64px;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

export const TextInputStyled = styled(TextInput)<{
  width?: string;
  className?: string;
  label?: string;
  marginBottom?: string;
}>`
  margin: auto;
  width: ${props => (!props.width ? '296px' : props.width)};
  margin-left: ${props => (props.className === 'leftSmallInput' ? '64px' : '')};
  margin-left: ${props =>
    props.className === 'rightSmallInput' ? '15px' : ''};
  margin-bottom: ${props =>
    !props.marginBottom ? '30px' : props.marginBottom};
  padding-top: ${props => (props.label === 'Time' ? '3px' : '')};
  input {
    font-size: ${props =>
      props.label === 'Time' ? '14px !important' : 'inherit'};
  }
`;

export const DatePickerStyled = styled(DatePickerField)`
  font-family: proxima-nova, arial, sans-serif;
  font-size: 14px !important;
  margin-top: 28px !important;
  margin-left: 64px !important;
  > div > input {
    padding: 0px 0px 14px 10px !important;
    color: #666666;
  }
  > div:before {
    border-bottom: 1px solid #cccccc !important;
  }
`;

export const MultilineTextInputStyled = styled(MultilineTextInput)<{
  width?: string;
  className?: string;
}>`
  margin-left: 64px;
  margin-right: 64px;
`;

export const SelectInputContainer = styled.div<{
  width?: string;
  className?: string;
  error?: boolean | string;
}>`
  margin: auto;
  width: ${props => (!props.width ? '296px' : props.width)};
  margin-left: ${props => (props.className === 'leftSmallInput' ? '64px' : '')};
  margin-top: 6px;
  margin-bottom: 30px;
  > div > div {
    border-bottom: ${props =>
      props.error ? '1px solid #EF3824' : '1px solid #CCCCCC'};
  }
  > div {
    border-bottom: none;
  }
`;

export const ErrorText = styled.div`
  margin: 5px 0px 0px 10px;
  color: #ef3824;
  font-size: 0.75em;
  background-color: transparent;
`;

export const InputLabel = styled.label<{
  fontSize?: string;
}>`
  font-family: Proxima Nova;
  font-size: ${props => props.fontSize};
  color: #333333;
  display: block;
  margin-left: 75px;
  margin-right: 75px;
  margin-bottom: 0px;
`;

export const Summary = styled.div<{
  disabled: boolean;
}>`
  height: 52px;
  width: 100%;
  position: fixed;
  z-index: 9999;
  pointer-events: ${props =>
    props.disabled ? 'none !important' : 'all !important'};
`;

export const SummaryHeader = styled.b`
  width: 200px;
`;

export const Details = styled.div`
  pointer-events: all;
`;

export const AccordionContainer = styled.div`
  pointer-events: none;
`;
