import { ThunkAction } from 'redux-thunk';
import {
  AddressBookActionTypes,
  Address,
  AddressBook
} from './addressBookReduxTypes';
import to from 'await-to-js';
import { Action } from 'redux';
import { AppState } from 'store';
import AddressBookService from '../../services/AddressBookService';

export const SET_ADDRESS_BOOK = 'SET_ADDRESS_BOOK';
export const SET_ADDRESS_BOOK_ERROR = 'SET_ADDRESS_BOOK_ERROR';
export const SET_HOLD_ADDRESS = 'SET_HOLD_ADDRESS';
export const SET_EDIT_ADDRESS_INDEX = 'SET_EDIT_ADDRESS_INDEX';
export const SET_PICKUP_ADDRESS = 'SET_PiCKUP_ADDRESS';
export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const SET_ADDRESS_BOOK_LOADING = 'SET_ADDRESS_BOOK_LOADING';
export const SET_ADDRESS_ENTRY_OPEN = 'SET_ADDRESS_ENTRY_OPEN';
export const SET_ADDRESS_BOOK_OPEN = 'SET_ADDRESS_BOOK_OPEN';
export const SET_DELETE_MODAL_OPEN = 'SET_DELETE_MODAL_OPEN';
export const SET_UNSAVED_MODAL_OPEN = 'SET_UNSAVED_MODAL_OPEN';
export const SET_CURRENT_FORM = 'SET_CURRENT_FORM';
export const SET_ADDRESS_BOOK_SNACKBAR = 'SET_ADDRESS_BOOK_SNACKBAR';

export function setAddressBook(
  addressBook: AddressBook
): AddressBookActionTypes {
  return { type: SET_ADDRESS_BOOK, addressBook };
}

export function setAddressBookError(isError: boolean): AddressBookActionTypes {
  return { type: SET_ADDRESS_BOOK_ERROR, isError };
}

export function setAddressBookLoading(
  isLoading: boolean
): AddressBookActionTypes {
  return { type: SET_ADDRESS_BOOK_LOADING, isLoading };
}

export function setHoldAddress(holdAddress: Address): AddressBookActionTypes {
  return { type: SET_HOLD_ADDRESS, holdAddress };
}

export function setEditAddressIndex(
  editAddressIndex: number
): AddressBookActionTypes {
  return { type: SET_EDIT_ADDRESS_INDEX, editAddressIndex };
}

export function setPickupAddress(
  pickupAddress: Address
): AddressBookActionTypes {
  return { type: SET_PICKUP_ADDRESS, pickupAddress };
}

export function setDeliveryAddress(
  deliveryAddress: Address
): AddressBookActionTypes {
  return { type: SET_DELIVERY_ADDRESS, deliveryAddress };
}

export function setAddressEntryOpen(
  isAddressEntryOpen: boolean
): AddressBookActionTypes {
  return { type: SET_ADDRESS_ENTRY_OPEN, isAddressEntryOpen };
}

export function setAddressBookOpen(
  isAddressBookOpen: boolean
): AddressBookActionTypes {
  return { type: SET_ADDRESS_BOOK_OPEN, isAddressBookOpen };
}

export function setCurrentForm(
  currentForm: 'pickup' | 'delivery'
): AddressBookActionTypes {
  return { type: SET_CURRENT_FORM, currentForm };
}

export function setDeleteModalOpen(
  isDeleteModalOpen: boolean
): AddressBookActionTypes {
  return { type: SET_DELETE_MODAL_OPEN, isDeleteModalOpen };
}

export function setUnsavedModalOpen(
  isUnsavedModalOpen: boolean
): AddressBookActionTypes {
  return { type: SET_UNSAVED_MODAL_OPEN, isUnsavedModalOpen };
}

export function setAddressBookSnackbar(
  isSnackbarVisible: boolean,
  isSnackbarError: boolean,
  snackbarMessage: string
): AddressBookActionTypes {
  return {
    type: SET_ADDRESS_BOOK_SNACKBAR,
    isSnackbarVisible,
    isSnackbarError,
    snackbarMessage
  };
}

export function sortAddressBook(
  data: AddressBook,
  isAsc: boolean
): ThunkAction<
  // data: Address[]
  void,
  AppState,
  null,
  Action<string>
> {
  return async function(dispatch) {
    dispatch(setAddressBookLoading(true));

    let sortedData;
    if (isAsc && data?.addresses) {
      sortedData = [...data.addresses].sort((a, b) =>
        a.name > b.name ? 1 : -1
      );
    } else if (data?.addresses) {
      sortedData = [...data.addresses].sort((a, b) =>
        a.name > b.name ? -1 : 1
      );
    }
    data.addresses = sortedData;

    dispatch(setAddressBook(data));
    dispatch(setAddressBookLoading(false));
  };
}

export function fetchAddressBook(): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> {
  return async function(dispatch) {
    dispatch(setAddressBookLoading(true));

    const [error, data] = await to(AddressBookService.getAddressBook());

    if (error || !data) {
      dispatch(setAddressBookError(true));
      dispatch(setAddressBookLoading(false));
      return;
    }
    dispatch(setAddressBook(data || { addresses: [] }));
    dispatch(setAddressBookLoading(false));
  };
}

export function postAddressBook(
  addressBook: AddressBook
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch) {
    dispatch(setAddressBookLoading(true));
    const [error, data] = await to(
      AddressBookService.postAddressBook(addressBook)
    );

    if (error || !data) {
      dispatch(setAddressBookError(true));
      dispatch(setAddressBookLoading(false));
      dispatch(setAddressBookSnackbar(true, true, 'Error saving address.'));
      return;
    }
    dispatch(setAddressBook(data || { addresses: [] }));
    dispatch(setAddressBookLoading(false));
  };
}
