import React, { FunctionComponent, useState, useEffect, useRef } from 'react';

// components
import LoadEntryHeaderSection from './loadEntryHeaderSection/loadEntryHeaderSection';
import AddressForm from './AddressForm';
import DetailsForm from './DetailsForm';
import { AddressFormData, DetailsFormData } from '../types/loadTypes';
import UserService from '../../../services/user/UserService';
import * as FirebaseUtils from '../../../utilities/firebaseUtils';

// services
import OrderEmailService from '../../../services/OrderEmailService';

// styles
import {
  LoadEntryContent,
  AccordionContainer,
  Summary,
  SummaryHeader,
  Details,
  Completed,
  CompletedText
} from './loadEntryStyles';
import { Icon as CloverIcon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { SmrAccordion } from 'shamrock-clover-ui/dist/clover/components/Accordion/Accordion';
import { setCurrentForm } from '../../../store/addressBook/addressBookActions';
import { useDispatch } from 'react-redux';
import AddressBookService from '../../../services/AddressBookService';

interface Props {
  toggleCancelModal: Function;
  toggleLoadEntryModal: Function;
  toggleIsLoading: (value: boolean) => void;
  setLoadEntryError: Function;
}

interface AddressErrorFormData {
  date?: string;
  companyName?: string;
  phoneNumber?: string;
  addressLine1?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
}
interface DetailsErrorFormData {
  commodity?: string;
  handlingUnits?: string;
  equipment?: string;
  totalWeight?: string;
  tempDetails?: string;
}

type AddressFormKeys = keyof AddressFormData;
type DetailsFormKeys = keyof DetailsFormData;
type AddressErrorFormDataKey = keyof AddressErrorFormData;
type DetailsErrorFormDataKey = keyof DetailsErrorFormData;

//service
const orderEmailService: OrderEmailService = new OrderEmailService();

const LoadEntry: FunctionComponent<Props> = ({
  toggleCancelModal,
  toggleLoadEntryModal,
  toggleIsLoading,
  setLoadEntryError
}) => {
  const dispatch = useDispatch();

  const [pickupComplete, setPickupComplete] = useState<boolean>(false);
  const [deliveryComplete, setDeliveryComplete] = useState<boolean>(false);
  const [detailsComplete, setDetailsComplete] = useState<boolean>(false);

  const [accordionIndex, setAccordionIndex] = useState<number>(0);
  const prevAccordionIndexRef = useRef<number | undefined>();

  const [pickupFormData, setPickupFormData] = useState<AddressFormData>({
    appointmentRequired: { errorMessage: '', value: false },
    addressLine1: { errorMessage: '', value: '' },
    addressLine2: { errorMessage: '', value: '' },
    city: { errorMessage: '', value: '' },
    state: { errorMessage: '', value: '' },
    zip: { errorMessage: '', value: '' },
    country: { errorMessage: '', value: 'United States' },
    date: { errorMessage: '', value: '' },
    time: { errorMessage: '', value: '' },
    companyName: { errorMessage: '', value: '' },
    phoneNumber: { errorMessage: '', value: '' }
  });

  const [deliveryFormData, setDeliveryFormData] = useState<AddressFormData>({
    appointmentRequired: { errorMessage: '', value: false },
    addressLine1: { errorMessage: '', value: '' },
    addressLine2: { errorMessage: '', value: '' },
    city: { errorMessage: '', value: '' },
    state: { errorMessage: '', value: '' },
    zip: { errorMessage: '', value: '' },
    country: { errorMessage: '', value: 'United States' },
    date: { errorMessage: '', value: '' },
    time: { errorMessage: '', value: '' },
    companyName: { errorMessage: '', value: '' },
    phoneNumber: { errorMessage: '', value: '' }
  });

  const [detailsFormData, setDetailsFormData] = useState<DetailsFormData>({
    commodity: { errorMessage: '', value: '' },
    handlingUnits: { errorMessage: '', value: '' },
    equipment: { errorMessage: '', value: '' },
    tarpDetails: { errorMessage: '', value: '' },
    tempDetails: { errorMessage: '', value: '' },
    totalWeight: { errorMessage: '', value: '' },
    pickupNum: { errorMessage: '', value: '' },
    poReference: { errorMessage: '', value: '' },
    specialInstructions: { errorMessage: '', value: '' }
  });

  const checkAddressRequiredError = (
    type: 'pickup' | 'delivery',
    data: AddressFormData,
    setFormData: Function
  ) => {
    let fields: AddressFormKeys[] = [
      'date',
      'companyName',
      'phoneNumber',
      'addressLine1',
      'city',
      'state',
      'zip',
      'country'
    ];

    let errorMessages: AddressErrorFormData = {};

    const formData = type === 'delivery' ? deliveryFormData : pickupFormData;

    fields.forEach((key: AddressFormKeys) => {
      if (formData[key].value === '') {
        errorMessages[key as AddressErrorFormDataKey] = 'Required field';
      }
    });

    setFormData({
      ...data,
      date: {
        value: data.date.value,
        errorMessage: errorMessages.date
      },
      companyName: {
        value: data.companyName.value,
        errorMessage: errorMessages.companyName
      },
      phoneNumber: {
        value: data.phoneNumber.value,
        errorMessage: errorMessages.phoneNumber
      },
      addressLine1: {
        value: data.addressLine1.value,
        errorMessage: errorMessages.addressLine1
      },
      city: {
        value: data.city.value,
        errorMessage: errorMessages.city
      },
      state: {
        value: data.state.value,
        errorMessage: errorMessages.state
      },
      zip: {
        value: data.zip.value,
        errorMessage: errorMessages.zip
      },
      country: {
        value: data.country.value,
        errorMessage: errorMessages.country
      }
    });
  };

  const checkDetailsRequiredError = () => {
    let fields: DetailsFormKeys[] = [
      'commodity',
      'handlingUnits',
      'equipment',
      'totalWeight',
      'tempDetails'
    ];
    let errorMessages: DetailsErrorFormData = {};

    fields.forEach((key: DetailsFormKeys) => {
      if (detailsFormData[key].value === '') {
        errorMessages[key as DetailsErrorFormDataKey] = 'Required field';
      }
    });

    setDetailsFormData({
      ...detailsFormData,
      commodity: {
        value: detailsFormData.commodity.value,
        errorMessage: errorMessages.commodity
      },
      handlingUnits: {
        value: detailsFormData.handlingUnits.value,
        errorMessage: errorMessages.handlingUnits
      },
      equipment: {
        value: detailsFormData.equipment.value,
        errorMessage: errorMessages.equipment
      },
      totalWeight: {
        value: detailsFormData.totalWeight.value,
        errorMessage: errorMessages.totalWeight
      },
      tempDetails: {
        value: detailsFormData.tempDetails.value,
        errorMessage: errorMessages.tempDetails
      }
    });
  };

  useEffect(() => {
    if (prevAccordionIndexRef.current === 0) {
      checkAddressRequiredError('pickup', pickupFormData, setPickupFormData);
    }
    if (prevAccordionIndexRef.current === 1) {
      checkAddressRequiredError(
        'delivery',
        deliveryFormData,
        setDeliveryFormData
      );
    }
    if (prevAccordionIndexRef.current === 2) {
      checkDetailsRequiredError();
    }

    prevAccordionIndexRef.current = accordionIndex;
  }, [accordionIndex]);

  const checkPickupComplete = () => {
    if (pickupFormData.date.value === '') {
      return false;
    }
    if (pickupFormData.companyName.value === '') {
      return false;
    }
    if (
      pickupFormData.phoneNumber.value === '' ||
      (pickupFormData.phoneNumber.errorMessage !== '' &&
        pickupFormData.phoneNumber.errorMessage !== undefined)
    ) {
      return false;
    }
    if (pickupFormData.addressLine1.value === '') {
      return false;
    }
    if (pickupFormData.city.value === '') {
      return false;
    }
    if (pickupFormData.state.value === '') {
      return false;
    }
    if (pickupFormData.zip.value === '') {
      return false;
    }
    if (pickupFormData.country.value === '') {
      return false;
    }
    return true;
  };

  const checkDeliveryComplete = () => {
    if (deliveryFormData.date.value === '') {
      return false;
    }
    if (deliveryFormData.companyName.value === '') {
      return false;
    }
    if (
      deliveryFormData.phoneNumber.value === '' ||
      (deliveryFormData.phoneNumber.errorMessage !== '' &&
        deliveryFormData.phoneNumber.errorMessage !== undefined)
    ) {
      return false;
    }
    if (deliveryFormData.addressLine1.value === '') {
      return false;
    }
    if (deliveryFormData.city.value === '') {
      return false;
    }
    if (deliveryFormData.state.value === '') {
      return false;
    }
    if (deliveryFormData.zip.value === '') {
      return false;
    }
    if (deliveryFormData.country.value === '') {
      return false;
    }
    return true;
  };

  const checkDetailsComplete = () => {
    if (detailsFormData.commodity.value === '') {
      return false;
    }
    if (detailsFormData.handlingUnits.value === '') {
      return false;
    }
    if (detailsFormData.equipment.value === '') {
      return false;
    }
    if (detailsFormData.totalWeight.value === '') {
      return false;
    }
    if (
      detailsFormData.equipment.value === 'Refrigerated' &&
      detailsFormData.tempDetails.value === ''
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setPickupComplete(checkPickupComplete());
  }, [pickupFormData]);

  useEffect(() => {
    setDeliveryComplete(checkDeliveryComplete());
  }, [deliveryFormData]);

  useEffect(() => {
    setDetailsComplete(checkDetailsComplete());
  }, [detailsFormData]);

  const disableSubmit = () => {
    if (pickupComplete && deliveryComplete && detailsComplete) {
      return false;
    }
    return true;
  };

  const showModalOnCancel = () => {
    if (pickupComplete || deliveryComplete || detailsComplete) {
      return true;
    }
    return false;
  };

  const sendLoadEmail = async () => {
    toggleIsLoading(true);
    const emailLoadEntryBody = {
      pAppointment: pickupFormData.appointmentRequired.value,
      pDate: pickupFormData.date.value,
      pTime: pickupFormData.time.value,
      pCompanyName: pickupFormData.companyName.value,
      pPhoneNumber: pickupFormData.phoneNumber.value,
      pAddress1: pickupFormData.addressLine1.value,
      pAddress2: pickupFormData.addressLine2.value,
      pCity: pickupFormData.city.value,
      pState: pickupFormData.state.value,
      pZipCode: pickupFormData.zip.value,
      pCountry: pickupFormData.country.value,
      dAppointment: deliveryFormData.appointmentRequired.value,
      dDate: deliveryFormData.date.value,
      dTime: deliveryFormData.time.value,
      dCompanyName: deliveryFormData.companyName.value,
      dPhoneNumber: deliveryFormData.phoneNumber.value,
      dAddress1: deliveryFormData.addressLine1.value,
      dAddress2: deliveryFormData.addressLine2.value,
      dCity: deliveryFormData.city.value,
      dState: deliveryFormData.state.value,
      dZipCode: deliveryFormData.zip.value,
      dCountry: deliveryFormData.country.value,
      Commodity: detailsFormData.commodity.value,
      Equipment: detailsFormData.equipment.value,
      TarpDetails: detailsFormData.tarpDetails.value,
      TempDetails: detailsFormData.tempDetails.value,
      PalletCount: detailsFormData.handlingUnits.value,
      TotalWeight: detailsFormData.totalWeight.value,
      PickupNum: detailsFormData.pickupNum.value,
      POReference: detailsFormData.poReference.value,
      SpecialInstructions: detailsFormData.specialInstructions.value
    };

    const mcleod_customer_id = UserService.getMcleodCustomerId();
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.LOAD_ENTRY,
      {
        ...emailLoadEntryBody,
        mcleodID: mcleod_customer_id,
        description: 'Load Entry Details'
      }
    );

    if (await orderEmailService.sendLoadEntryEmail(emailLoadEntryBody)) {
      setLoadEntryError(true, true, 'Load submitted.');
    } else {
      setLoadEntryError(
        true,
        false,
        'Load submission failed. Please try again.'
      );
    }

    toggleIsLoading(false);
    toggleLoadEntryModal(false);
    AddressBookService.clearAddressBook(dispatch);
  };

  const handleOnCreateLoad = async () => {
    sendLoadEmail();
  };

  return (
    <>
      <LoadEntryContent>
        <LoadEntryHeaderSection
          toggleCancelModal={toggleCancelModal}
          toggleLoadEntryModal={toggleLoadEntryModal}
          showModalOnCancel={showModalOnCancel()}
          isSubmitButtonDisabled={disableSubmit()}
          onSubmit={handleOnCreateLoad}
        />
        <AccordionContainer>
          <SmrAccordion
            items={[
              {
                summary: (
                  <>
                    <Summary disabled={accordionIndex === 0} />
                    <SummaryHeader>PICKUP ADDRESS</SummaryHeader>
                    {pickupComplete ? (
                      <Completed>
                        <CloverIcon
                          icon="checkGreen"
                          size="16"
                          margin-right="4px"
                        ></CloverIcon>
                        <CompletedText>Complete</CompletedText>
                      </Completed>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                details: (
                  <Details>
                    <AddressForm
                      formData={pickupFormData}
                      setFormData={setPickupFormData}
                      formType="pickup"
                      maxDate={deliveryFormData.date.value}
                    />
                  </Details>
                ),
                width: '100%',
                detailsHeight: 'fit-content',
                onClick: () => {
                  setAccordionIndex(0);
                  dispatch(setCurrentForm('pickup'));
                }
              },
              {
                summary: (
                  <>
                    <Summary disabled={accordionIndex === 1} />
                    <SummaryHeader>DELIVERY ADDRESS</SummaryHeader>
                    {deliveryComplete ? (
                      <Completed>
                        <CloverIcon
                          icon="checkGreen"
                          size="16"
                          margin-right="4px"
                        ></CloverIcon>
                        <CompletedText>Complete</CompletedText>
                      </Completed>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                details: (
                  <Details>
                    <AddressForm
                      formData={deliveryFormData}
                      setFormData={setDeliveryFormData}
                      formType="delivery"
                      minDate={pickupFormData.date.value}
                    />
                  </Details>
                ),
                width: '100%',
                detailsHeight: 'fit-content',
                onClick: () => {
                  setAccordionIndex(1);
                  dispatch(setCurrentForm('delivery'));
                }
              },
              {
                summary: (
                  <>
                    <Summary disabled={accordionIndex === 2} />
                    <SummaryHeader>LOAD DETAILS</SummaryHeader>
                    {detailsComplete ? (
                      <Completed>
                        <CloverIcon
                          icon="checkGreen"
                          size="16"
                          margin-right="4px"
                        ></CloverIcon>
                        <CompletedText>Complete</CompletedText>
                      </Completed>
                    ) : (
                      <></>
                    )}
                  </>
                ),
                details: (
                  <Details>
                    <DetailsForm
                      formData={detailsFormData}
                      setFormData={setDetailsFormData}
                    />
                  </Details>
                ),
                width: '100%',
                detailsHeight: 'fit-content',
                onClick: () => {
                  setAccordionIndex(2);
                }
              }
            ]}
            noGutter
            hideCollapsedIcon
            initExpandedIndex={'0' as any}
          />
        </AccordionContainer>
      </LoadEntryContent>
    </>
  );
};

export default LoadEntry;
