// packages
import React from 'react';

// styles
import {
  ContactUsContent,
  ContactUsLabel as MailTo,
  CreateAccountButton as GoBackButton,
  LoginLandingContainer as ContactUsContainer,
  LoginLandingPaper as ContactUsPaper,
  TitleLabel
} from './LoginLandingStyles';

// images
// images
import ShipperPortalBlueLogo from '../../assets/images/shipper-portal-blue.svg';

interface Props {
  handleOnGoBack: () => void;
  visible: boolean;
}

export const ContactUs: React.FC<Props> = ({ handleOnGoBack, visible }) => {
  if (!visible) {
    return null;
  }

  return (
    <ContactUsContainer visible={visible}>
      <ContactUsPaper alignItems="flex-start" elevation={3} isContactUs>
        <img
          alt="shipper-portal-blue-logo"
          src={ShipperPortalBlueLogo}
          style={{ height: 48, marginBottom: 32, marginLeft: -8, width: 282 }}
        />
        <TitleLabel isContactUs>We&apos;re here to help.</TitleLabel>
        <ContactUsContent>
          Our support team is available from 8 a.m. to 5 p.m. CST,
        </ContactUsContent>
        <ContactUsContent marginBottom="32px">
          Monday through Friday.
        </ContactUsContent>
        <ContactUsContent marginBottom="182px">
          Email us{' '}
          <MailTo href="mailto:support@rtspro.com">support@rtspro.com</MailTo>{' '}
          or call (888) 874-7053
        </ContactUsContent>
        <GoBackButton isContactUs onClick={handleOnGoBack} variant="contained">
          BACK
        </GoBackButton>
      </ContactUsPaper>
    </ContactUsContainer>
  );
};

export default ContactUs;
