import { useLoadTrackingQueryParams } from '../../hooks/routeHooks';
import LoadTrackingFullDetails from './LoadTrackingFullDetails';
import * as React from 'react';
import LoadTrackingSearch from './LoadTrackingSearch';
import LoadTrackingPartialDetails from './LoadTrackingPartialDetails';
import { Flex } from '../../components/Flex';
import LoadTrackingHeader from './LoadTrackingHeader';

const LoadTracking: React.FC<{}> = () => {
  const {
    queryParams: { trackingId, referenceNumber, city, zip }
  } = useLoadTrackingQueryParams();

  return (
    <Flex height="100%" width="100%" flexDirection="column">
      <LoadTrackingHeader
        referenceNumber={referenceNumber}
        trackingId={trackingId}
        city={city}
        zip={zip}
      />
      {trackingId ? (
        <LoadTrackingFullDetails trackingId={trackingId} />
      ) : referenceNumber ? (
        <LoadTrackingPartialDetails
          referenceNumber={referenceNumber}
          city={city}
          zip={zip}
        />
      ) : (
        <LoadTrackingSearch />
      )}
    </Flex>
  );
};

export default LoadTracking;
