// packages
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

// images
import loginLanding2060 from '../../assets/images/login-landing-2060.png';

export const LoginLandingContainer = styled.div<{ visible?: boolean }>`
  @media (max-width: 600px) {
    display: ${props => (props.visible ? 'block' : 'none !important')}
    align-items: center;
    background-color: #fff;
    background-image: unset;
    background-repeat: unset;
    background-size: unset;
    display: flex;
    min-height: 100vh;
    height: 100vh;
    justify-content: center;
    width: 100%;
  }

  display: ${props => (props.visible ? 'block' : 'none !important')}
  align-items: center;
  background-image: url(${loginLanding2060});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

export const LoginLandingPaper = styled(Paper)<{
  alignItems?: string;
  isContactUs?: boolean;
}>`
  @media (max-width: 600px) {
    box-shadow: unset !important;
    height: auto;
    padding: 0px;
    width: 100%;
  }
  align-items: ${props => (!props.alignItems ? 'center' : props.alignItems)};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 580px;
  padding: ${props => (!props.isContactUs ? '50px 138px' : '50px 60px')};
  box-shadow: 0px 2px 10px 0px #00000029;
  width: 640px;
`;

export const TitleLabel = styled.label<{ isContactUs?: boolean }>`
  font-family: Proxima Nova;
  font-size: ${props => (!props.isContactUs ? '16px' : '18px')};
  font-weight: ${props => (!props.isContactUs ? 400 : 600)};
  line-height: 24px;
  color: #333333;
  padding-bottom: 32px;
  text-align: center;
`;

export const BoldLabel = styled.label`
  font-family: Proxima Nova;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 22px;
  padding-bottom: 8px;
  text-align: center;
`;

export const CreateAccountButton = styled(Button)<{
  isContactUs?: boolean;
}>`
  background-color: #0091ea !important;
  border-radius: 4px;
  font-family: Proxima Nova;
  color: #ffffff !important;
  margin-bottom: 24px !important;
  font-weight: 400, Regular;
  height: ${props => (!props.isContactUs ? '44px' : '40px')};
  width: ${props => (!props.isContactUs ? '175px' : '102px')};
`;

export const LoginButton = styled(Button)`
  background-color: #ffffff !important;
  border-radius: 4px;
  font-family: Proxima Nova;
  color: #0091ea !important;
  font-weight: 400, Regular;
  margin-bottom: 32px !important;
  border: 1px solid #0091ea !important;
  height: 44px;
  width: 175px;
`;

export const SeparatorLine = styled.div`
  border: 1px solid #cccccc;
  margin-bottom: 25px;
  width: 207px;
`;

export const TrackShipmentsLabel = styled.label`
  color: #0091ea;
  cursor: pointer;
  font-family: Proxima Nova;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 19px;
  text-align: center;
`;

export const NoAccountNeededLabel = styled.label`
  color: #666666;
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 17px;
  margin-bottom: 69px;
  text-align: center;
`;

export const QuestionsLabel = styled.label`
  color: #333333;
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 17px;
  text-align: center;
`;
export const ContactUsLabel = styled.a`
  color: #0091ea;
  cursor: pointer;
  font-family: Proxima Nova;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 17px;
  text-align: center;
`;
export const QuestionsAndContactUsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContactUsContent = styled.label<{ marginBottom?: string }>`
  font-family: Proxima Nova;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: ${props => (!props.marginBottom ? '0px' : props.marginBottom)};
`;
