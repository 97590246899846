import Service from './Service';
import { QuoteEmailBody } from '../features/requestQuote/types/requestQuoteTypes';

const Config = require('Config');

class OrderEmailService extends Service {
  constructor() {
    super(Config.serviceUrl);
  }

  async sendRequestQuoteEmail(emailRequestQuoteBody: QuoteEmailBody) {
    try {
      const response = await this.axios.post(`email/sendRequestQuoteEmail`, {
        emailRequestQuoteBody: emailRequestQuoteBody
      });
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }
}

export default OrderEmailService;
