exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);", ""]);

// Module
exports.push([module.id, ".orderTableBody{display:block;overflow-y:auto;height:100%;overflow-x:hidden;min-width:1075px;padding:22px 4px 22px 4px;margin:0 -8px 0 -8px}.orderTableBody .emptyState{display:flex;justify-content:center;align-items:center;font-size:1.5rem;height:100%;color:#ccc}.orderTableBody .row{margin-left:0;margin-right:0}.orderTableFooter{display:flex;justify-content:center}.orderTableDivider{border-color:#ccc}.progressLoader{text-align:center;position:absolute;width:calc(100% - 8.5rem);display:flex;height:4.5rem;align-items:center;justify-content:center}.progressLoader svg{color:#0091ea}", ""]);

// Exports
exports.locals = {
	"ui-blue-01": "#0091ea",
	"ui-blue-02": "#0077b8",
	"ui-blue-03": "#005b8d",
	"ui-gray-01": "#333",
	"ui-gray-02": "#666",
	"ui-gray-03": "#999",
	"ui-gray-04": "#ccc",
	"ui-dropshadow": "rgba(0,0,0,.5)",
	"ui-background": "#f8f8f8",
	"ui-white": "#fff",
	"support-red": "#ef3824",
	"support-orange": "#ff9000",
	"support-green": "#72bf44",
	"support-yellow": "#ffc418"
};