import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router';

const useQueryParams = <T extends {}>() => {
  const { search } = useLocation();
  const { push } = useHistory();

  const addQueryParams = (paramsToAdd: T) => {
    const query = queryString.parse(window.location.search);

    push({
      search: queryString.stringify(
        {
          ...query,
          ...paramsToAdd
        },
        { arrayFormat: 'comma' }
      )
    });
  };

  const clearQueryParams = () => {
    push({
      search: ''
    });
  };

  const setQueryParams = (paramsToAdd: T) => {
    push({
      search: queryString.stringify(
        {
          ...paramsToAdd
        },
        { arrayFormat: 'comma' }
      )
    });
  };

  return {
    addQueryParams,
    clearQueryParams,
    setQueryParams,
    queryParams: queryString.parse(search, { arrayFormat: 'comma' }) as T
  };
};
interface AccountingQueryParams {
  page?: string;
  limit?: string;
  orderBy?: string;
  order?: string;
  search?: string;
  filter?: string | string[];
  invoiceId?: string;
}

export const useAccountingQueryParams = () => {
  return useQueryParams<AccountingQueryParams>();
};

interface OrdersQueryParams {
  orderId: string;
}

export const useOrdersQueryParams = () => {
  return useQueryParams<OrdersQueryParams>();
};

interface LoadTrackingQueryParams {
  trackingId?: string;
  referenceNumber?: string;
  city?: string;
  zip?: string;
}

export const useLoadTrackingQueryParams = () => {
  return useQueryParams<LoadTrackingQueryParams>();
};

interface RateVerificationParams {
  orderId: string;
  mcNumber: string;
}

export const useRateVerificationQueryParams = () => {
  return useQueryParams<RateVerificationParams>();
};
