import React, { FunctionComponent } from 'react';
import './DetailScrollableArea.scss';

interface Props {
  children?: React.ReactNode;
}

const DetailScrollableArea: FunctionComponent<Props> = props => {
  return <div className="detailScrollableArea">{props.children}</div>;
};

export default React.memo(DetailScrollableArea);
