import React, { Component, Fragment } from 'react';
import './OrdersView.scss';
import { Order } from '../../types/orderTypes';
import Paginator from '../paginator/Paginator';
import TermsFooter from '../termsFooter/termsFooter';
import OrdersTable from './OrdersTable';

interface OrderTableProps {
  orders: Order[];
  noOfResults: number;
  pageChange: Function;
  pageChanging: boolean;
  pageIndex: number;
  noOfRows: number;
  rowChange: Function;
  setOrderBy: Function;
  orderBy: {
    column: string;
    isAscending: boolean;
  };
}

class Orders extends Component<OrderTableProps> {
  constructor(props: Readonly<OrderTableProps>) {
    super(props);
    this.pageChange = this.pageChange.bind(this);
  }

  async pageChange(page: number) {
    let { noOfRows, pageChanging } = this.props;
    if (!pageChanging) {
      await this.props.pageChange(page, noOfRows);
    }
  }

  async rowsChange(rows: number) {
    await this.props.rowChange(rows);
  }

  sortHeader = (columnName: string, isAscending: boolean) => {
    this.props.setOrderBy(columnName, isAscending);
  };

  getAnchor(link: string, text: string) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  }

  render() {
    let {
      noOfResults,
      noOfRows,
      rowChange,
      pageIndex,
      pageChanging
    } = this.props;
    return (
      <Fragment>
        <OrdersTable
          orders={this.props.orders}
          orderBy={this.props.orderBy}
          pageChanging={pageChanging}
          setOrderBy={this.props.setOrderBy}
          rowChange={rowChange}
        />
        <hr className="orderTableDivider" />
        <div className="orderTableFooter">
          <div>
            {noOfResults > 0 && (
              <Paginator
                noOfRows={noOfRows}
                noOfResults={noOfResults}
                onPageChange={this.pageChange}
                onRowChange={rowChange}
                pageIndex={pageIndex}
              />
            )}
          </div>
        </div>
        <TermsFooter showBillingEmail={false} />
      </Fragment>
    );
  }
}

export default Orders;
