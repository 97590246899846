// packages
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { NavLink } from 'react-router-dom';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useLocation } from 'react-router';

// components
import { Slide } from '@material-ui/core';
import AppHeader from './components/appHeader/AppHeader';
import Login from './containers/login/Login';
import Logout from './containers/login/Logout';
import Orders from './containers/orders/Orders';
import RequestQuoteButton from './features/requestQuote/RequestQuoteButton';
import RequestQuoteContainer from './features/requestQuote/container/requestQuoteContainer';
import { Accounting } from './features/accounting';
import { LoadTracking } from './features/loadTracking';
import { OptOut } from './features/optOut';
import { Snackbar } from 'shamrock-clover-ui/dist/clover/components/Snackbar/Snackbar';

// services
import { AuthService, UserService } from './services';

// utils
import * as FirebaseUtils from './utilities/firebaseUtils';

const AuthRouteWithHeader: React.FC<{
  Component?: any;
  path: string;
  isAuthenticated: boolean;
}> = ({ path, children, Component, isAuthenticated }) => {
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [requestQuoteBanner, setRequestQuoteBanner] = useState<{
    isVisible: boolean;
    isError: boolean;
    value: string;
  }>({ isVisible: false, isError: false, value: '' });

  return isAuthenticated ? (
    <Route path={path} exact>
      <AppHeader>
        <div className="headerContent">
          <div className="leftHeader">
            <NavLink
              to="/shipments"
              className="navItem"
              activeClassName="active"
            >
              Shipments
            </NavLink>
            <NavLink
              to="/accounting"
              className="navItem"
              activeClassName="active"
            >
              Accounting
            </NavLink>
          </div>
          <RequestQuoteButton onClick={setIsQuoteModalOpen} visible />
        </div>
      </AppHeader>
      <div className="appContentContainer">
        <Snackbar
          open={requestQuoteBanner.isVisible}
          onClose={() => {
            setRequestQuoteBanner({
              isVisible: false,
              isError: false,
              value: ''
            });
          }}
          message={requestQuoteBanner.value}
          variant={requestQuoteBanner.isError ? 'error' : 'success'}
        />
        <Slide
          direction="left"
          in={isQuoteModalOpen}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 400, exit: 400 }}
        >
          <RequestQuoteContainer
            toggleRequestQuoteModal={setIsQuoteModalOpen}
            isRequestQuoteVisible={isQuoteModalOpen}
            setRequestQuoteBanner={setRequestQuoteBanner}
          />
        </Slide>
        {Component ? (
          <Component isQuoteModalOpen={isQuoteModalOpen} />
        ) : (
          children
        )}
      </div>
    </Route>
  ) : (
    <Redirect to="/" />
  );
};

const App: FunctionComponent<{
  authService: AuthService;
}> = ({ authService }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.set({
      page: pathname
    });
    ReactGA.pageview(pathname);
    FirebaseUtils.logFirebaseScreenName(FirebaseUtils.FirebasePages.SHIPMENTS);
    FirebaseUtils.logPageView(FirebaseUtils.FirebasePages.SHIPMENTS);
  }, [pathname]);

  useEffect(() => {
    const getMostCurrentUser = async () => {
      try {
        if (authService.isAuthed) {
          const userService = new UserService();
          await userService.getUser();
        }
      } catch (e) {}
    };
    getMostCurrentUser();
  }, []);

  return (
    <Fragment>
      <Route path="/" component={Login} exact />
      <Route path="/logout" component={Logout} exact />
      <Switch>
        <Route path="/optOut" component={OptOut} exact />
        <Route path="/loadTracking" component={LoadTracking} exact />
        <AuthRouteWithHeader
          Component={Orders}
          isAuthenticated={authService.isAuthed}
          path="/shipments"
        />
        <AuthRouteWithHeader
          Component={false}
          path="/accounting"
          isAuthenticated={authService.isAuthed}
        >
          <Accounting />
        </AuthRouteWithHeader>
        <Redirect from={'*'} to={'/'} />
      </Switch>
    </Fragment>
  );
};

export default App;
