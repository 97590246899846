// packages
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import { CheckBox } from 'shamrock-clover-ui/dist/clover/components/CheckBox/CheckBox';
import { TextInput } from 'shamrock-clover-ui/dist/clover/components/TextInput/TextInput';
import { Icon } from 'shamrock-clover-ui/dist/clover/components/Icon/Icon';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import Grid from '@material-ui/core/Grid';

export const ActionButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 600px), (max-height: 700px) {
    width: auto;
    padding: 10px;
    margin-bottom: 30px;
  }
`;

export const CardContainer = styled.div<{ visible?: boolean }>`
  @media (max-width: 600px), (max-height: 700px) {
    width: 80%;
    display: unset;
    position: fixed;
    bottom: 5%;
    display: ${props => (!props.visible ? 'none' : 'flex')};
    > div {
      height: auto;
    }
  }
  > div {
    background-color: #333333;
    color: #ffffff;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    font-family: Proxima Nova;
    font-size: 16px;
    padding: 15px 11px 14px;
    font-weight: 400;
    justify-content: flex-start;
    text-align: left;
  }
  display: ${props => (!props.visible ? 'none' : 'flex')};
  justify-content: center;
  margin-top: 40px;
  width: 100%;
`;

export const CheckBoxStyled = styled(CheckBox)`
@media (max-width: 600px), (max-height: 700px) {
  div > span {
    color: #333333;
    font-weight: 400;
    line-height: 17px;
  }
  margin-bottom: 40px;
  margin-top 50px;
  width: inherit;
}
  div > span {
    color: #333333;
    font-weight: 400;
    line-height: 17px;
  }
  margin-bottom: 40px;
  margin-top 50px;
  width: 526px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ExpireLabel = styled.label`
  color: #757575;
  font-family: Proxima Nova;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 20px;
`;

export const ExpireLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const IconContainer = styled(Icon)<{ isValid?: boolean }>`
  margin-left: -5px;
  width: 15px;
`;

export const LinkStyled = styled.a`
  cursor: pointer;
  margin-bottom: 15px;
  text-decoration: underline;
`;

export const RegistrationContainer = styled.div`
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const GridContainer = styled(Grid)`
  @media (max-width: 600px), (max-height: 700px) {
    height: 100%;
    overflow: auto;
  }
`;

export const GridPasswordValidationItem = styled(Grid)`
  align-items: flex-end;
  display: flex;
  margin-bottom: -5px;
  flex-direction: row;
  justify-content: start;
`;

export const SpinnerContainer = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  height: 100vh;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

export const PasswordReqContainer = styled.div`
  margin-left: 10px;
`;

export const StepContainerPaper = styled(Paper)<{ height: string }>`
  @media (max-width: 600px), (max-height: 500px) {
    background-color: #fff;
    background-image: unset;
    background-repeat: unset;
    background-size: unset;
    padding: 32px;
    width: 100%;
    display: flex;
    min-height: 100vh;
    height: 100vh;
    justify-content: center;
    overflow: auto;
  }
  @media (min-width: 601px) and (min-height: 501px) and (max-height: 700px) {
    transform: scale(0.8);
  }
  align-items: flex-start;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px #00000029;
  display: flex;
  flex-direction: column;
  height: ${props => props.height};
  padding: 64px;
  width: 640px;
  overflow-y: auto;
`;

export const StepTitleLabel = styled.label<{ fontSize?: string }>`
  font-family: Proxima Nova;
  font-size: ${props => (!props?.fontSize ? '16px' : props.fontSize)};
  font-weight: 700;
  line-height: 24px;
`;

export const StepContentLabel = styled.label<{
  noPadding?: boolean;
  marginBottom?: string;
  noLineHeight?: boolean;
  width?: string;
}>`
  @media (max-width: 600px), (max-height: 700px) {
    width: 100%;
  }
  font-family: Proxima Nova;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: ${props =>
    !props?.marginBottom ? '0px' : props.marginBottom};
  line-height: ${props => (!props?.noLineHeight ? '24px' : '10px')};
  padding-bottom: ${props => (!props?.noPadding ? '40px' : '0px')};
  width: ${props => (!props.width ? '100%' : props.width)};
`;

// TODO: This must be deleted once shamrock-clover-ui has been upgraded.
export const PhoneTextInputStyles = styled.div<{ error?: boolean | string }>`
  .phoneTextInput {
    font-family: inherit;
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    border: 0;
    outline: 0;
    font-size: 16px;
    color: #666666;
    cursor: inherit;
    padding-top: 10px;
    background: transparent;
    transition: border-color 0.2s;
  }
  .phoneTextInput::placeholder {
    color: transparent;
  }
  .phoneTextInput:placeholder-shown ~ .form__label {
    font-size: 16px;
    cursor: text;
    top: 20px;
  }
  .phoneTextInput:focus ~ .form__label {
    pointer-events: none;
    position: absolute;
    top: 5px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: #666666;
  }
  .phoneTextInput:focus:focus ~ .form__label {
    color: ${props => (props.error ? '#EF3824' : '#0091ea')};
  }
`;

export const TextInputStyled = styled(TextInput)<{
  marginBottom?: string;
  marginRight?: string;
  width?: string;
}>`
  @media (max-width: 600px), (max-height: 700px) {
    width: -webkit-fill-available;
    margin-right: 0px;
    padding-left: 10px;
    padding-right: 10px;
    div {
      width: inherit;
    }
  }
  margin-bottom: ${props =>
    !props?.marginBottom ? '15%' : props.marginBottom};
  margin-right: ${props => (!props?.marginRight ? '0px' : props.marginRight)};
  width: ${props => (!props?.width ? '318px' : props.width)};
`;

export const TitleLabel = styled.label`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: #333333;
  padding-bottom: 16px;
  text-align: center;
`;

export const PasswordHelperTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;
export const PasswordValidationText = styled.label`
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
  margin-left: 5px;
`;
