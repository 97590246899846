// packages
import React, { useState } from 'react';

// components
import Registration from '../../components/registration';
import ContactUs from './ContactUs';

// services
import { AuthService } from '../../services';

// styles
import {
  BoldLabel,
  ContactUsLabel,
  CreateAccountButton,
  LoginButton,
  LoginLandingContainer,
  LoginLandingPaper,
  NoAccountNeededLabel,
  QuestionsAndContactUsContainer,
  QuestionsLabel,
  SeparatorLine,
  TitleLabel,
  TrackShipmentsLabel
} from './LoginLandingStyles';

// images
import ShipperPortalBlueLogo from '../../assets/images/shipper-portal-blue.svg';
import LocationIcon from '../../assets/images/Location.svg';

const Config = require('Config');

// constants
export const TRACK_SHIPMENTS_URL = Config.loadTrackingUrl;
const authService: AuthService = new AuthService();

export const LoginLanding: React.FC = () => {
  const [isRegistrationVisible, setIsRegistrationVisible] = useState(false);
  const [isContactUsVisible, setIsContactUsVisible] = useState(false);

  const handleRedirectToLoadTracking = () =>
    window.location.assign(TRACK_SHIPMENTS_URL);

  const toggleRegistrationVisibility = () =>
    setIsRegistrationVisible(!isRegistrationVisible);

  const handleOnLogin = () => authService.redirectToLogin();

  const toggleOnOpenContactUs = () =>
    setIsContactUsVisible(!isContactUsVisible);

  return (
    <>
      <ContactUs
        handleOnGoBack={toggleOnOpenContactUs}
        visible={isContactUsVisible}
      />
      <Registration
        onCancel={toggleRegistrationVisibility}
        visible={isRegistrationVisible}
      />
      <LoginLandingContainer visible={!isRegistrationVisible}>
        <LoginLandingPaper elevation={3}>
          <img
            alt="shipper-portal-blue-logo"
            src={ShipperPortalBlueLogo}
            style={{ height: 48, marginBottom: 32, width: 282 }}
          />
          <TitleLabel>
            Track shipments in real-time, follow invoices, view important
            shipping documents, and more.
          </TitleLabel>
          <BoldLabel>New user?</BoldLabel>
          <CreateAccountButton
            onClick={toggleRegistrationVisibility}
            variant="contained"
          >
            CREATE ACCOUNT
          </CreateAccountButton>
          <LoginButton onClick={handleOnLogin} variant="contained">
            LOG IN
          </LoginButton>
          <SeparatorLine />
          <TrackShipmentsLabel onClick={handleRedirectToLoadTracking}>
            <img
              alt="location-icon"
              src={LocationIcon}
              style={{ marginRight: 4 }}
            />
            TRACK SHIPMENTS
          </TrackShipmentsLabel>
          <NoAccountNeededLabel>No account needed</NoAccountNeededLabel>
          <QuestionsAndContactUsContainer>
            <QuestionsLabel>Questions?&nbsp;</QuestionsLabel>
            <ContactUsLabel href="#" onClick={toggleOnOpenContactUs}>
              Contact Us
            </ContactUsLabel>
          </QuestionsAndContactUsContainer>
        </LoginLandingPaper>
      </LoginLandingContainer>
    </>
  );
};

export default LoginLanding;
