import React, { Fragment, KeyboardEvent } from 'react';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import {
  PICKUP_LOCATION_PLACEHOLDER,
  DELIVERY_LOCATION_PLACEHOLDER
} from '../../utilities/constants';
import {
  performGooglePlacesSearch,
  setOriginFilterString,
  setPlacesSuggestions,
  setDestinationFilterString,
  searchOrders
} from '../../store/orders/ordersActions';
import { Search } from '@material-ui/icons';
import { AppState } from 'store';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

interface OriginDestinationFilterProps {
  clearSuggestions: () => void;
  destinationFilterString?: string;
  isQuoteModalOpen: boolean;
  originFilterString?: string;
  performGooglePlacesSearch: (type: string) => void;
  searchFunction: () => void;
  setDestinationFilterString: Function;
  setOriginFilterString: Function;
  suggestions?: google.maps.places.AutocompletePrediction[];
}

function mapDispatchToProps(
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) {
  return {
    setOriginFilterString: (originFilterString: string) =>
      dispatch(setOriginFilterString(originFilterString)),
    performGooglePlacesSearch: (type: string) =>
      dispatch(performGooglePlacesSearch(type)),
    setDestinationFilterString: (originFilterString: string) =>
      dispatch(setDestinationFilterString(originFilterString)),
    clearSuggestions: () => dispatch(setPlacesSuggestions([])),
    searchFunction: () => dispatch(searchOrders())
  };
}

const mapStateToProps = ({ orders }: AppState) => {
  return {
    originFilterString: orders.originFilterString,
    suggestions: orders.suggestions,
    destinationFilterString: orders.destinationFilterString
  };
};

const theme = {
  uiWhite: '#FFFFFF',
  uiGray3: '#999999',
  uiGray4: '#CCCCCC'
};

const PickupInputContainer = styled.div`
  .react-autosuggest__container {
    width: 200px;
  }

  .react-autosuggest__input {
    border-radius: 1.5rem 0 0 1.5rem;
    border-width: 0px;
    outline: 0;
    height: 2.25rem;
    padding-left: 1rem;
    width: 100%;
    background-color: ${theme.uiWhite};
    ::-webkit-input-placeholder {
      color: ${theme.uiGray3};
    }
  }

  .react-autosuggest__suggestions-container--open {
    background-color: white;
    position: absolute;
    z-index: 1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .react-autosuggest__suggestion {
    display: block;
    width: 200px;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    cursor: pointer;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${theme.uiGray4};
  }

  .react-autosuggest__suggestions-list {
    padding-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;
const DestinationInputContainer = styled.div`
  .react-autosuggest__container {
    width: 200px;
  }

  .react-autosuggest__input {
    border-radius: 0 24px 24px 0;
    border-width: 0px;
    outline: 0;
    height: 36px;
    padding-left: 16px;
    width: 200px;
    background-color: ${theme.uiWhite};
    ::-webkit-input-placeholder {
      color: ${theme.uiGray3};
    }
  }
  .react-autosuggest__suggestions-container--open {
    background-color: white;
    position: absolute;
    z-index: 1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .react-autosuggest__suggestion {
    display: block;
    width: 200px;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    cursor: pointer;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: ${theme.uiGray4};
  }

  .react-autosuggest__suggestions-list {
    padding-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const OriginDestinationFilterContainer = styled.div`
  display: flex;
  padding-right: 8px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  background: ${theme.uiWhite};
`;

const SearchButton = styled.button`
  border-radius: 24px;
  border: 0;
  background-color: ${props => props.theme.blue};
  color: white;
  padding-right: 16px;
  padding-left: 16px;
  height: 36px;
  display: flex;
  padding-top: 1%;
  :focus {
    outline: 0;
  }
  :disabled {
    background-color: #e3e3e3;
    color: ${theme.uiGray3};
  }
  :active {
    background: #0076b6;
    -webkit-box-shadow: inset 0px 0px 2px #c1c1c1;
    -moz-box-shadow: inset 0px 0px 2px #c1c1c1;
    box-shadow: inset 0px 0px 2px #c1c1c1;
    outline: none;
  }
`;

function formatPlaceSuggestion(
  suggestion: google.maps.places.AutocompletePrediction
) {
  var { terms } = suggestion;

  const termsArray = terms.map(term => term.value);

  termsArray.pop();

  return termsArray.join(', ');
}

function renderSuggestion(suggestion: any) {
  return <div>{formatPlaceSuggestion(suggestion)}</div>;
}

function getSuggestionValue(suggestion: any) {
  return formatPlaceSuggestion(suggestion);
}

function canSearch(
  originFilterString?: string,
  destinationFilterString?: string
) {
  if (originFilterString || destinationFilterString) {
    return true;
  }
  return false;
}

export const OriginDestinationFilter: React.FC<OriginDestinationFilterProps> = props => {
  const {
    clearSuggestions,
    destinationFilterString = '',
    isQuoteModalOpen,
    originFilterString = '',
    performGooglePlacesSearch,
    searchFunction,
    setDestinationFilterString,
    setOriginFilterString,
    suggestions = []
  } = props;

  function onInputEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      searchFunction();
    }
  }
  return (
    <Fragment>
      <OriginDestinationFilterContainer>
        <PickupInputContainer>
          <Autosuggest
            inputProps={{
              value: !isQuoteModalOpen ? originFilterString || '' : '',
              onBlur: event => {
                FirebaseUtils.logFirebaseEvent(
                  FirebaseUtils.FirebaseEvents.REFERENCE_SEARCH,
                  FirebaseUtils.FirebaseModules.SHIPPER,
                  FirebaseUtils.FirebasePages.SHIPMENTS,
                  {
                    referenceValue: (event.target as HTMLInputElement).value
                  }
                );
              },
              onChange: (event, { newValue }) => {
                setOriginFilterString(newValue);
              },
              placeholder: PICKUP_LOCATION_PLACEHOLDER,
              onKeyDown: onInputEnter,
              autoComplete: 'new-password'
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={() =>
              performGooglePlacesSearch('ORIGIN')
            }
            onSuggestionsClearRequested={clearSuggestions}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={() => searchFunction()}
          />
        </PickupInputContainer>
        <IconContainer>&gt;</IconContainer>
        <DestinationInputContainer>
          <Autosuggest
            inputProps={{
              value: !isQuoteModalOpen ? destinationFilterString || '' : '',
              onBlur: event => {
                FirebaseUtils.logFirebaseEvent(
                  FirebaseUtils.FirebaseEvents.REFERENCE_SEARCH,
                  FirebaseUtils.FirebaseModules.SHIPPER,
                  FirebaseUtils.FirebasePages.SHIPMENTS,
                  {
                    referenceValue: (event.target as HTMLInputElement).value
                  }
                );
              },
              onChange: (event, { newValue }) => {
                setDestinationFilterString(newValue);
              },
              placeholder: DELIVERY_LOCATION_PLACEHOLDER,
              onKeyDown: onInputEnter,
              autoComplete: 'new-password'
            }}
            suggestions={suggestions}
            onSuggestionsFetchRequested={() =>
              performGooglePlacesSearch('DESTINATION')
            }
            onSuggestionsClearRequested={clearSuggestions}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={() => searchFunction()}
          />
        </DestinationInputContainer>
      </OriginDestinationFilterContainer>
      <SearchButton
        type="button"
        data-testid="SearchButton"
        onClick={() => searchFunction()}
        disabled={!canSearch(originFilterString, destinationFilterString)}
      >
        <Search />
      </SearchButton>
    </Fragment>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OriginDestinationFilter);
