import React, { FunctionComponent } from 'react';
import Table, {
  TableHeader,
  TableBody,
  TableJson,
  TableCellStyles
} from '../../components/table/Table';
import { Order } from '../../types/orderTypes';
import { useSelector } from 'react-redux';
import { getOrdersTableData } from '../../store/orders/ordersTableSelectors';
import trackAnalyticsEvent from '../../utilities/googleAnalyticsUtilities';
import {
  getDetailIsLoading,
  getNotificationSummary
} from '../../store/orders/ordersSelectors';
import { useOrdersQueryParams } from '../../hooks/routeHooks';

import './OrdersTable.scss';
import { getPrimaryText } from '../../utilities/orderUtilities';
import { CircularProgress } from '@material-ui/core';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

const headerData = [
  { label: '', key: 'icon' },
  { label: 'ORDER', key: 'orderId' },
  { label: 'STATUS', key: 'status' },
  { label: 'REFERENCE', key: 'reference' },
  { label: 'PICKUP', key: 'pickup' },
  { label: 'PICKUP DATE', key: 'pickupDate' },
  { label: 'DELIVERY', key: 'delivery' },
  { label: 'DELIVERY DATE', key: 'deliveryDate' }
];

const cellStyles = [
  { currentStyle: { width: '4%' }, columnKey: 'icon' },
  { currentStyle: { width: '7%' }, columnKey: 'orderId' },
  { currentStyle: { width: '11%' }, columnKey: 'status' },
  { currentStyle: { width: '14%' }, columnKey: 'reference' },
  { currentStyle: { width: '18%' }, columnKey: 'pickup' },
  { currentStyle: { width: '14%' }, columnKey: 'pickupDate' },
  { currentStyle: { width: '18%' }, columnKey: 'delivery' },
  { currentStyle: { width: '14%' }, columnKey: 'deliveryDate' }
];

interface OrderTableProps {
  orders: Order[];
  pageChanging: boolean;
  rowChange: Function;
  setOrderBy: Function;
  orderBy: {
    column: string;
    isAscending: boolean;
  };
}

const OrdersTable: FunctionComponent<OrderTableProps> = props => {
  const ordersTableData = useSelector(getOrdersTableData);
  const notificationSummary = useSelector(getNotificationSummary);
  const isLoading = useSelector(getDetailIsLoading);
  const {
    queryParams: { orderId: selectedOrderId },
    addQueryParams
  } = useOrdersQueryParams();
  const ordersTableDataNotifications = ordersTableData.map(order => {
    if (notificationSummary && notificationSummary.globalEnabled) {
      order.notificationsOn = true;
    } else {
      let notificationSetting = notificationSummary?.orders.find(
        o => o.orderId === order.orderId.props.children
      );
      if (notificationSetting) {
        order.notificationsOn = notificationSetting.enabled;
      }
    }
    return order;
  });

  const selectedOrderIndex = props.orders.findIndex(
    x => x.orderId == selectedOrderId
  );

  const onRowClick = (rowData: TableJson, index: number): void => {
    if (isLoading) return;
    const order = props.orders[index];
    if (order) {
      trackAnalyticsEvent(
        'Order',
        'Clicked Order Row',
        `${order.orderId} - ${getPrimaryText(order.rawStops, order.rawCallin)}`
      );

      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.DETAIL_CLICK,
        FirebaseUtils.FirebaseModules.SHIPPER,
        FirebaseUtils.FirebasePages.SHIPMENTS,
        {
          orderNumber: order.orderId,
          currentStatus: getPrimaryText(order.rawStops, order.rawCallin),
          isTracking: order.rawCallin !== null
        }
      );
    }

    addQueryParams({ orderId: order.orderId.trim() });
  };

  const orderByClick = (columnTitle: string, order: 'asc' | 'desc') => {
    props.setOrderBy(columnTitle, order == 'asc');
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.SORT,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.SHIPMENTS,
      { sortType: columnTitle, order: order }
    );
  };

  return (
    <div className="ordersTableContainer" data-testid="orderTableContainer">
      {props.pageChanging && (
        <div className="progressLoader">
          <CircularProgress />
        </div>
      )}
      <Table
        cellStyles={cellStyles}
        isSortable={true}
        headerComponent={(
          cellStyles: TableCellStyles[],
          isSortable: boolean | undefined
        ) => {
          return (
            <TableHeader
              orderByClick={orderByClick}
              isSortable={isSortable}
              columns={headerData}
              cellStyles={cellStyles}
            />
          );
        }}
        bodyComponent={(cellStyles: TableCellStyles[]) => {
          return (
            <TableBody
              currentlySelectedIndex={selectedOrderIndex}
              tableRowData={ordersTableDataNotifications}
              onRowClick={onRowClick}
              cellStyles={cellStyles}
            />
          );
        }}
      />
      {!isLoading && ordersTableData.length === 0 && (
        <div data-testid="NoInvoicesText" className="emptyState">
          No Shipments
        </div>
      )}
      )
    </div>
  );
};

export default OrdersTable;
