import { useQuery } from 'react-query';
import OrderService from '../services/order/OrderService';
import { FILTER_TYPES } from '../utilities/constants';

const useOrdersServiceCount = (filter: string) => {
  return useQuery(
    ['featureFlag', filter],
    () => OrderService.getOrderSummary(1, 1, { filterType: [filter] }),
    {
      staleTime: Infinity
    }
  );
};

export const useOrdersServiceBookedCount = () => {
  let results = useOrdersServiceCount(FILTER_TYPES.BOOKED);
  return { ...results, bookedCount: results.data?.count || 0 };
};

export const useOrdersServiceDeliveredCount = () => {
  let results = useOrdersServiceCount(FILTER_TYPES.DELIVERED);
  return { ...results, deliveredCount: results.data?.count || 0 };
};

export const useOrdersServiceInTransitCount = () => {
  let results = useOrdersServiceCount(FILTER_TYPES.IN_TRANSIT);
  return { ...results, inTransitCount: results.data?.count || 0 };
};
