import * as React from 'react';
import LoadIcon from '../../assets/images/LoadIcon.svg';
import Chevron from '../../assets/images/Chevron.svg';
import './noShipmentView.scss';
import RequestQuoteContainer from '../requestQuote/container/requestQuoteContainer';
import * as FirebaseUtils from '../../utilities/firebaseUtils';
import { Snackbar } from 'shamrock-clover-ui/dist/clover/components/Snackbar/Snackbar';
import { Slide } from '@material-ui/core';

const NoShipmentView: React.FC<{}> = () => {
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);
  const [requestQuoteBanner, setRequestQuoteBanner] = React.useState<{
    isVisible: boolean;
    isError: boolean;
    value: string;
  }>({ isVisible: false, isError: false, value: '' });

  const toggleModalVisibility = () => {
    if (!isQuoteModalOpen) {
      FirebaseUtils.logFirebaseEvent(
        FirebaseUtils.FirebaseEvents.CLICK,
        FirebaseUtils.FirebaseModules.SHIPPER,
        FirebaseUtils.FirebasePages.REQUEST_QUOTE_LINK,
        { description: 'Blank State Request Quote' }
      );
    }
    setIsQuoteModalOpen(!isQuoteModalOpen);
  };
  return (
    <div className="noUserEmptyContainer">
      <Snackbar
        open={requestQuoteBanner.isVisible}
        onClose={() => {
          setRequestQuoteBanner({
            isVisible: false,
            isError: false,
            value: ''
          });
        }}
        message={requestQuoteBanner.value}
        variant={requestQuoteBanner.isError ? 'error' : 'success'}
      />
      <Slide
        direction="left"
        in={isQuoteModalOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 400, exit: 400 }}
      >
        <RequestQuoteContainer
          toggleRequestQuoteModal={setIsQuoteModalOpen}
          isRequestQuoteVisible={isQuoteModalOpen}
          setRequestQuoteBanner={setRequestQuoteBanner}
        />
      </Slide>
      <div className="noShipmentContainer">
        <div className="noShipmentText">No Shipments</div>
      </div>
      <div className="noLoadsContent">
        <div className="iconRequestQuoteContainer">
          <img className="requestQuoteIcon" src={LoadIcon} />
        </div>
        <div className="textRequestQuoteContainer">
          <p className="haveLoadText">Have another load?</p>
          <p onClick={toggleModalVisibility} className="requestQuoteText">
            Request a quote&nbsp; <img className="chevron" src={Chevron} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoShipmentView;
