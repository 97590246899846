import * as React from 'react';
import SpinnerWithWait from '../../components/Spinner';
import NotificationService from '../../services/NotificationService';
import { useLocation } from 'react-router-dom';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import './optOut.scss';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OptOut: React.FunctionComponent<{}> = ({}) => {
  const query = useQuery();
  const email = query.get('email');

  const [isUnsubscribed, setIsUnsubscribed] = React.useState(true);
  const [isLoading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const optOut = async () => {
      if (email) {
        try {
          await NotificationService.optOut(email, true);
          setLoading(false);
        } catch (_error) {
          setLoading(false);
        }
      }
    };
    optOut();
  }, [email]);

  const onClick = async () => {
    if (email) {
      await NotificationService.optOut(email, false);
      setIsUnsubscribed(false);
    }
  };

  return isLoading ? (
    <SpinnerWithWait></SpinnerWithWait>
  ) : (
    <div className="container">
      <div className="textContainer">
        {isUnsubscribed
          ? ` You have successfully unsubscribed from RTS Pro Shipper Portal email
        notifications and will no longer receive them. Please note this does not
        apply to marketing communications. If you unsubscribed in error, you may
        re-subscribe by clicking the button below or by contacting your Ryan
        Transportation representative.`
          : `You have successfully re-subscribed to RTS Pro Shipper Portal email notifications.`}
      </div>
      {isUnsubscribed && (
        <div className="buttonContainer">
          <Button onClick={onClick} width="250px">
            Resubscribe
          </Button>
        </div>
      )}
    </div>
  );
};

export default OptOut;
