import { AppState } from '../';
import OrangeWarning from '../../assets/images/warning-orange-small.png';
import RedWarning from '../../assets/images/warning-red-small.png';
import YellowWarning from '../../assets/images/warning-yellow-small.png';

export const getAccounting = (state: AppState) => {
  return state.accounting;
};

const getTableIcon = (daysOverdue: number) => {
  if (daysOverdue) {
    if (daysOverdue < 0) return undefined;
    if (daysOverdue >= 0 && daysOverdue < 31) {
      return YellowWarning;
    }
    if (daysOverdue < 61) {
      return OrangeWarning;
    }
    if (daysOverdue >= 61) {
      return RedWarning;
    }
  }
};

export const getCardData = (state: AppState) => {
  const zeroToThirtyCount = state.accounting.filterCounts['0-30'];
  const thirtyOneToSixtyOneCount = state.accounting.filterCounts['31-61'];
  const sixtyOnePlusCount = state.accounting.filterCounts['61'];
  const totalBalanceCount = state.accounting.filterCounts['total'];
  const totalCount =
    zeroToThirtyCount.count +
    thirtyOneToSixtyOneCount.count +
    sixtyOnePlusCount.count;
  const totalBalance =
    (zeroToThirtyCount.pastDue || 0) +
    (thirtyOneToSixtyOneCount.pastDue || 0) +
    (sixtyOnePlusCount.pastDue || 0);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  });

  return [
    {
      headerText: 'TOTAL BALANCE',
      bodyText: formatter.format(totalBalanceCount.pastDue || 0),
      footerText: `${totalBalanceCount.count} Invoices`
    },
    {
      headerText: 'TOTAL PAST DUE',
      bodyText: formatter.format(totalBalance),
      footerText: `${totalCount} Invoices`
    },
    {
      headerText: '0 - 30 DAYS PAST DUE',
      headerIcon: 'yellow',
      bodyText: formatter.format(zeroToThirtyCount.pastDue || 0),
      footerText: `${zeroToThirtyCount.count} Invoices`
    },
    {
      headerText: '31 - 61 DAYS PAST DUE',
      headerIcon: 'orange',
      bodyText: formatter.format(thirtyOneToSixtyOneCount.pastDue || 0),
      footerText: `${thirtyOneToSixtyOneCount.count} Invoices`
    },
    {
      headerText: '+61 DAYS PAST DUE',
      headerIcon: 'red',
      bodyText: formatter.format(sixtyOnePlusCount.pastDue || 0),
      footerText: `${sixtyOnePlusCount.count} Invoices`
    }
  ];
};

export const getInvocies = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.invoices;
};

export const getInvoiceTableData = (state: AppState) => {
  return getInvocies(state).map(invoice => {

    let amountToShow = invoice.totalAmount;
    if (invoice.orderCharge?.finalCharge) {
      amountToShow = invoice.orderCharge?.finalCharge
    }

    return {
      icon: getTableIcon(invoice.ageDays),
      invoice_no_string: invoice.id,
      order_id: invoice.orderId,
      blnum: invoice.billOfLadingId,
      invoice_due_date:
        1 >= invoice.ageDays
          ? invoice.ageDays < 0
            ? `${Math.abs(invoice.ageDays)} Days`
            : 'Today'
          : `${invoice.ageDays} Days Past Due`,
      invoice_total: `$${amountToShow.toFixed(2)}`,
      total_balance: `$${invoice.remainingBalance.toFixed(2)}`
    };
  });
};

export const getIsTableLoading = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.isTableLoading;
};

export const getInvoiceCount = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.totalInvoices;
};

export const getInvoiceOrderDetails = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.invoiceOrderDetails;
};

export const getInvoiceFiles = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.invoiceOrderFiles;
};

export const getInvoiceOrderDetailsLoading = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.isDetailsLoading;
};

export const getInvoiceTableLoading = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.isTableLoading;
};

export const getInvoiceById = (invoiceId: string) => (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.invoices.find(x => x.id === invoiceId);
};

export const getAccountingSummaryBilling = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.summaryBilling;
};

export const getIsAccountingTableError = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.isTableError;
};

export const getIsAccountingDetailsError = (state: AppState) => {
  const accounting = getAccounting(state);
  return accounting.isDetailsError;
};
