import React, { FunctionComponent, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from './mapMarker';
import './orderDetailsMapInfo.scss';
import { Marker } from 'store/orders/orderReduxTypes';
import SnackbarError from '../../../components/SnackbarError';

interface Props {
  markers: Marker[];
  isMapsError: boolean;
}

const OrderDetailsMapInfo: FunctionComponent<Props> = ({
  markers,
  isMapsError
}) => {
  const [center] = useState({ lat: 39.015697, lng: -94.565559 });
  const [zoom] = useState(1);

  const getMapBounds = (maps: any, markers: Marker[]) => {
    const bounds = new maps.LatLngBounds();

    markers.forEach(({ lat, lng }) => {
      bounds.extend(new maps.LatLng(lat, lng));
    });

    // Don't zoom in too far on only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const offset = 0.075;
      var extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + offset,
        bounds.getNorthEast().lng() + offset
      );
      var extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - offset,
        bounds.getNorthEast().lng() - offset
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    return bounds;
  };

  const apiIsLoaded = (map: any, maps: any, markers: Marker[]) => {
    if (markers.length === 0) {
      return;
    }

    const bounds = getMapBounds(maps, markers);
    map.fitBounds(bounds, { top: 50, right: 50, bottom: 50, left: 50 });
  };

  return (
    <>
      <SnackbarError
        message="Google Maps data could not be loaded"
        isError={isMapsError}
      />
      <GoogleMapReact
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, markers)}
      >
        {markers.map((marker, index) => (
          <MapMarker
            key={index}
            lat={marker.lat}
            lng={marker.lng}
            type={marker.type}
            primaryText={marker.primaryText}
            secondaryText={marker.secondaryText}
          />
        ))}
      </GoogleMapReact>
    </>
  );
};

export default React.memo(OrderDetailsMapInfo);
