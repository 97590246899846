import React, { Fragment, FunctionComponent } from 'react';
import TablePagination from '@material-ui/core/TablePagination';

interface PaginatorProps {
  noOfResults: number;
  onPageChange: Function;
  onRowChange: Function;
  noOfRows: number;
  pageIndex: number;
}

const Paginator: FunctionComponent<PaginatorProps> = ({
  noOfResults,
  noOfRows,
  onRowChange,
  pageIndex,
  onPageChange
}) => {
  return (
    <Fragment>
      <TablePagination
        component="div"
        count={noOfResults}
        rowsPerPage={noOfRows}
        onChangeRowsPerPage={event => onRowChange(event.target.value)}
        page={pageIndex}
        onChangePage={(_event, page) => {
          onPageChange(page);
        }}
        rowsPerPageOptions={[25, 50, 100]}
      />
    </Fragment>
  );
};

export default Paginator;
