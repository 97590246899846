import React, { Fragment } from 'react';
import { Dialog } from 'shamrock-clover-ui/dist/clover/components/Dialog/Dialog';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import './cancelModal.scss';
import { getShowCancelModal } from '../../../store/orders/ordersSelectors';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import UserService from '../../../services/user/UserService';
import * as FirebaseUtils from '../../../utilities/firebaseUtils';
import AddressBookService from '../../../services/AddressBookService';

interface Props {
  toggleCancelModal: Function;
  toggleLoadEntryModal: Function;
}

const StyledModal = styled(Dialog)`
  padding: 0px !important;
`;

const CancelModal: React.FC<Props> = ({
  toggleCancelModal,
  toggleLoadEntryModal
}) => {
  const dispatch = useDispatch();
  const showModal = useSelector(getShowCancelModal);

  const closeModals = () => {
    const mcleod_customer_id = UserService.getMcleodCustomerId();
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.LOAD_ENTRY_CANCEL,
      {
        mcleodID: mcleod_customer_id,
        description: 'Confirm Cancel Load'
      }
    );
    toggleCancelModal(false);
    toggleLoadEntryModal(false);
    AddressBookService.clearAddressBook(dispatch);
  };

  const continueEditing = () => {
    const mcleod_customer_id = UserService.getMcleodCustomerId();
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.LOAD_ENTRY_CANCEL,
      {
        mcleodID: mcleod_customer_id,
        description: 'Continue Editing'
      }
    );
    toggleCancelModal(false);
  };

  const getModalContent = () => {
    return (
      <>
        <div className="mainText">
          You will lose all of this load{`'`}s details.
        </div>
        <div className="buttonContainer">
          <Button buttonStyle="outlined" onClick={continueEditing}>
            CONTINUE EDITING
          </Button>
          <Button buttonStyle="solid" onClick={closeModals}>
            CANCEL LOAD
          </Button>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <StyledModal
        open={showModal}
        title="Are you sure you want to cancel?"
        content={getModalContent()}
      ></StyledModal>
    </Fragment>
  );
};

export default CancelModal;
