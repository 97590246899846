import React from 'react';
import DeliveredGray from '../../assets/images/DeliveredGray.svg';
import LoadTrackingInputs from './LoadTrackingInputs';
import { Flex } from '../../components/Flex';
import LoadTrackingSignUpButton from './LoadTrackingSignUpButton';
import {
  SearchContainer,
  StatusText,
  SmallIcon,
  Icon,
  SignUp,
  ParentContainer,
  InputContainer
} from './LoadTrackingStyles';

interface Props {
  phone: string;
  email: string;
}

export const LoadTrackingSearch: React.FC<Props> = ({ phone, email }) => {
  return (
    <SearchContainer>
      <ParentContainer flexDirection="column">
        <Flex
          justifyContent="center"
          alignItems="center"
          margin="0px 0px 24px 0px"
        >
          <SmallIcon src={DeliveredGray} />
          <Icon src={DeliveredGray} />
          <SmallIcon src={DeliveredGray} />
        </Flex>
        <StatusText>More than one load found, please contact</StatusText>
        <StatusText>
          <a href={`mailto:${email}`}>{email}</a>&nbsp;or call {phone}
        </StatusText>
        <InputContainer>
          <LoadTrackingInputs />
        </InputContainer>
        <SignUp flexDirection="column" alignItems="center">
          <LoadTrackingSignUpButton text="Are you a Ryan Transportation customer, or don't know what to search by?" />
        </SignUp>
      </ParentContainer>
    </SearchContainer>
  );
};

export default LoadTrackingSearch;
