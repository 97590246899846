import isArray from 'lodash/isArray';

export const isEmpty = (str?: string) => {
  return !str || 0 === str.length;
};

export const statusColorClass = (value: string) => {
  return value.includes('Delayed') ? 'delayed' : '';
};

export const formatStringOrStringArray = (value?: string | string[]) => {
  if (isArray(value)) {
    return value.join(', ');
  }
  return value;
};

export const isZipCode = (value: string) => {
  return /^\d{5}(?:[-\s]\d{4})?$/.test(value);
};

export const isValidEmail = (contact: string) => {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return emailRegex.test(contact);
};

export const isValidPhone = (phoneNumber: string): boolean =>
  Boolean(phoneNumber.length < 11) && Boolean(phoneNumber.match(/^\d{10}$/));
