import { useQuery } from 'react-query';
import FeatureService from '../services/FeatureService';
import UserService from '../services/user/UserService';

const useFeatureFlag = (id: string) => {
  return useQuery(
    ['featureFlag', id],
    () => FeatureService.getFeature(id, UserService.getUserEmail()),
    {
      staleTime: Infinity
    }
  );
};

// RTD-2185 Feature Flag for Export CSV
export const useExportCSVFlag = () => {
  const result = useFeatureFlag('RTD-2185');
  return { isExportCSVEnabled: result.data };
};
