import React from 'react';
import './AccountingTable.scss';
import { useSelector } from 'react-redux';
import {
  getInvoiceTableData,
  getIsTableLoading,
  getAccountingSummaryBilling,
  getIsAccountingTableError
} from '../../store/accounting/accountingSelectors';
import { useAccountingQueryParams } from '../../hooks/routeHooks';
import Table, {
  TableHeader,
  TableBody,
  TableJson,
  TableCellStyles
} from '../../components/table/Table';
import {
  BILLING_EMAIL_LINK,
  BILLING_EMAIL_LINK_TEXT
} from '../../utilities/constants';
import { CircularProgress } from '@material-ui/core';
import trackAnalyticsEvent from '../../utilities/googleAnalyticsUtilities';
import SnackbarError from '../../components/SnackbarError';
import * as FirebaseUtils from '../../utilities/firebaseUtils';

const headerData = [
  { label: '', key: 'icon' },
  { label: 'INVOICE', key: 'invoice_no_string' },
  { label: 'ORDER', key: 'order_id' },
  { label: 'REFERENCE', key: 'blnum' },
  { label: 'DUE IN', key: 'invoice_age_days' },
  { label: 'INVOICE AMOUNT', key: 'invoice_total' },
  { label: 'BALANCE', key: 'total_balance' }
];

const cellStyles = [
  { currentStyle: { width: '4%' }, columnKey: 'icon' },
  { currentStyle: { width: '16%' }, columnKey: 'invoice_no_string' },
  { currentStyle: { width: '16%' }, columnKey: 'order_id' },
  { currentStyle: { width: '16%' }, columnKey: 'blnum' },
  { currentStyle: { width: '16%' }, columnKey: 'invoice_age_days' },
  { currentStyle: { width: '16%' }, columnKey: 'invoice_total' },
  { currentStyle: { width: '16%' }, columnKey: 'total_balance' }
];

const AccountingTable = () => {
  const invoiceTableData = useSelector(getInvoiceTableData);
  const isTableLoading = useSelector(getIsTableLoading);
  const { queryParams, addQueryParams } = useAccountingQueryParams();
  const selectedIndex = invoiceTableData.findIndex(
    x => x.order_id === queryParams.invoiceId
  );
  const summaryBilling = useSelector(getAccountingSummaryBilling);
  const isTableError = useSelector(getIsAccountingTableError);

  if (summaryBilling) {
    trackAnalyticsEvent('Accounting', 'Summary Billing Displayed');
  }

  const onRowClick = (rowData: TableJson): void => {
    trackAnalyticsEvent('Accounting', 'Invoice Click', `${rowData.order_id}`);
    addQueryParams({ invoiceId: rowData.order_id });

    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.DETAIL_CLICK,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.ACCOUNTING,
      {
        orderNumber: rowData.order_id,
        totalBalance: rowData.total_balance,
        invoiceNumber: rowData.blnum
      }
    );
  };

  const orderByClick = (columnTitle: string, order: 'asc' | 'desc') => {
    const columnKey = headerData.find(x => x.label == columnTitle)?.key;
    addQueryParams({ orderBy: columnKey, order });

    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.SORT,
      FirebaseUtils.FirebaseModules.SHIPPER,
      FirebaseUtils.FirebasePages.ACCOUNTING,
      { sortType: columnKey }
    );
  };

  return (
    <div className="tableContainer" data-testid="AccountingTableContainer">
      {isTableLoading && (
        <div className="progressLoader">
          <CircularProgress />
        </div>
      )}
      <SnackbarError
        isError={isTableError}
        message="Oops! Something went wrong. Please refresh."
      />
      {invoiceTableData && invoiceTableData.length > 0 && (
        <Table
          cellStyles={cellStyles}
          isSortable={true}
          headerComponent={(
            cellStyles: TableCellStyles[],
            isSortable: boolean | undefined
          ) => {
            return (
              <TableHeader
                orderByClick={orderByClick}
                isSortable={isSortable}
                columns={headerData}
                cellStyles={cellStyles}
              />
            );
          }}
          bodyComponent={(cellStyles: TableCellStyles[]) => {
            return (
              <TableBody
                currentlySelectedIndex={selectedIndex}
                tableRowData={invoiceTableData}
                onRowClick={onRowClick}
                cellStyles={cellStyles}
              />
            );
          }}
        />
      )}
      {!isTableLoading && invoiceTableData.length === 0 && !summaryBilling && (
        <div data-testid="NoInvoicesText" className="emptyState">
          No Invoices
        </div>
      )}
      {summaryBilling && (
        <div className="emptyState summaryBilling">
          <p>
            Your account is setup on summary billing, which is currently
            unavailable on RTS Pro. <br /> Please contact{' '}
            <a
              href={BILLING_EMAIL_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {BILLING_EMAIL_LINK_TEXT}
            </a>{' '}
            to obtain a copy of your summary bills.
          </p>
        </div>
      )}
    </div>
  );
};

export default AccountingTable;
