import * as React from 'react';
import { useFullDetailsLoadTrackingData } from './useLoadTrackingData';
import SpinnerWithWait from '../../components/Spinner';
import { Flex } from '../../components/Flex';
import OrderDetailsMapInfo from '../../components/orderDetails/orderDetailsMapInfo/orderDetailsMapInfo';
import LoadTrackingCard from './LoadTrackingDetails';
import LoadTrackingNoLoadsFound from './LoadTrackingNoLoadsFound';
import useMobileDetect from '../../hooks/useMobileDetect';
import { Card } from './LoadTrackingStyles';
import { logFullDetailsView } from './loadTrackingAnalytics';

interface Props {
  trackingId: string;
}

const LoadTrackingFullDetails: React.FC<Props> = ({ trackingId }) => {
  const {
    isLoadingFullDetails,
    fullDetailsError,
    fullDetails,
    isLoadingMarkers,
    markers,
    loadStatus
  } = useFullDetailsLoadTrackingData(trackingId);
  const { isDesktop } = useMobileDetect();

  React.useEffect(() => {
    if (!isLoadingFullDetails && !fullDetailsError && fullDetails)
      logFullDetailsView(fullDetails.orderId);
  }, [isLoadingFullDetails]);

  if (isLoadingFullDetails || isLoadingMarkers) {
    return (
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
      >
        <SpinnerWithWait />
      </Flex>
    );
  }

  if (fullDetailsError || !markers || !fullDetails) {
    return <LoadTrackingNoLoadsFound />;
  }

  return isDesktop() ? (
    <Flex height="100%">
      <Flex>
        <LoadTrackingCard details={fullDetails} loadStatus={loadStatus} />
      </Flex>
      <Flex width="50vw" height="100%">
        <OrderDetailsMapInfo markers={markers} isMapsError={false} />
      </Flex>
    </Flex>
  ) : (
    <Flex height="100%" justifyContent="center">
      <Card height="max-content">
        <LoadTrackingCard details={fullDetails} loadStatus={loadStatus} />
      </Card>
    </Flex>
  );
};

export default LoadTrackingFullDetails;
