import { useQuery } from 'react-query';
import { Marker } from '../../store/orders/orderReduxTypes';
import LoadTrackingService from '../../services/LoadTrackingService';
import OrderService from '../../services/order/OrderService';
import { monthDayTimeFormat } from '../../utilities/dateHelper';
import { Order } from '../../types/orderTypes';
import {
  isBooked,
  getStopStatusText,
  isOrderComplete,
  getCurrentStop,
  isDelayed,
  getPrimaryText,
  getSecondaryText
} from '../../utilities/orderUtilities';
import { formatStringOrStringArray } from '../../utilities/stringHelper';
import { LoadTrackingDetails } from './loadTrackingTypes';

function isLoadTrackingDetails(arg: any): arg is LoadTrackingDetails {
  return arg && arg.orderId !== undefined;
}

const getCallinMarker = async (order: Order): Promise<Marker | undefined> => {
  if (order.rawCallin && !isBooked(order) && !isOrderComplete(order)) {
    const { city_name, state, call_date_time } = order.rawCallin;
    const [lat, lng] = await OrderService.getGeolocation(
      `${city_name} ${state}`
    );
    const currentStop = getCurrentStop(order.rawStops);
    const isLate =
      currentStop && isDelayed(currentStop, order.rawStops, order.rawCallin);

    return {
      lat,
      lng,
      type: isLate === false ? 'IN_TRANSIT' : 'IN_TRANSIT_DELAYED',
      primaryText: `Updated: ${monthDayTimeFormat(call_date_time)}`,
      secondaryText: `${city_name}, ${state}`
    };
  }
};

const getStopMarkers = async (order: Order) => {
  return Promise.all(
    order.rawStops.map(
      async (rawStop): Promise<Marker> => {
        const {
          address,
          city,
          state,
          zip_code,
          actual_arrival,
          actual_departure,
          stop_type,
          locationName
        } = rawStop;
        const [lat, lng] = await OrderService.getGeolocation(
          `${address} ${city} ${state} ${zip_code}`
        );
        if (actual_arrival && actual_departure) {
          return {
            lat,
            lng,
            type: 'COMPLETED',
            primaryText: locationName,
            secondaryText:
              stop_type === 'PU' ? 'Pickup: Complete' : 'Delivery: Complete'
          };
        }
        return {
          lat,
          lng,
          type: stop_type === 'PU' ? 'PICKUP' : 'DELIVERY',
          primaryText: locationName,
          secondaryText: `${
            stop_type === 'PU' ? 'Pickup' : 'Delivery'
          }: ${getStopStatusText(rawStop, order.rawStops, order.rawCallin)}`
        };
      }
    )
  );
};

const getMarkers = async (order: Order) => {
  const callinMarker = await getCallinMarker(order);
  const stopMarkers = await getStopMarkers(order);

  if (callinMarker) {
    return [...stopMarkers, callinMarker];
  }

  return stopMarkers;
};

export const useFullDetailsLoadTrackingData = (id?: string) => {
  const fullDetails = useQuery(
    ['loadTrackingId', id],
    () => LoadTrackingService.getFullDetails(id as string),
    {
      staleTime: 1000,
      retry: 0,
      enabled: id !== undefined,
      refetchOnWindowFocus: false
    }
  );

  const markers = useQuery(
    ['mapMarkers', fullDetails.data],
    () => getMarkers(fullDetails.data as Order),
    {
      enabled: fullDetails.data ? true : false
    }
  );

  return {
    fullDetails: fullDetails.data,
    fullDetailsError: fullDetails.isError,
    isLoadingFullDetails: fullDetails.isLoading,
    markers: markers.data,
    isLoadingMarkers: markers.isLoading,
    loadStatus: fullDetails.data
      ? `${getPrimaryText(
          fullDetails.data.rawStops,
          fullDetails.data.rawCallin
        )}. ${getSecondaryText(fullDetails.data.rawStops)}`
      : ''
  };
};

export const usePartialDetailsLoadTrackingData = (
  referenceNumber?: string,
  city?: string | string[],
  zip?: string | string[]
) => {
  const partialDetails = useQuery(
    ['loadTracking', referenceNumber, city, zip],
    () =>
      LoadTrackingService.getPartialDetails(
        referenceNumber as string,
        formatStringOrStringArray(city),
        formatStringOrStringArray(zip)
      ),
    {
      staleTime: 1000,
      retry: 0,
      enabled: referenceNumber !== undefined,
      refetchOnWindowFocus: false
    }
  );

  return {
    partialDetails: partialDetails.data,
    partialDetailsError: partialDetails.error,
    isLoadingPartialDetails: partialDetails.isLoading,
    isLoadTrackingDetails,

    loadStatus:
      partialDetails.data && isLoadTrackingDetails(partialDetails.data)
        ? `${getPrimaryText(
            partialDetails.data.rawStops,
            partialDetails.data.rawCallin
          )}. ${getSecondaryText(partialDetails.data.rawStops)}`
        : ''
  };
};
