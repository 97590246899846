exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".tableContainer thead,tbody tr{display:table;width:100%;table-layout:fixed}.ordersTableContainer thead,tbody tr{display:table;width:98%;margin-left:1%;margin-right:1%;table-layout:fixed}.tableBody{display:block;overflow:scroll;width:calc(100% - 4px);padding-left:2px;padding-right:2px;height:calc(100% - 50px)}.tableView{width:100%;height:100%}.tableRow{height:64px;border-radius:2px;box-shadow:0 2px 4px 0 rgba(0,0,0,.29);margin-bottom:8px;cursor:pointer;width:99%;margin-left:1%}.notificationsOn:before{color:#0091ea;font-size:36px;content:\"•\";position:relative;left:-15px}.empty:before{color:red;font-size:36px;content:\"•\"}.tableRow td{padding-left:22px;text-align:left;height:17px;width:auto;color:rgba(0,0,0,.85);font-family:proxima-nova,arial,sans-serif;font-size:14px;letter-spacing:-0.09px;line-height:17px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.tableHeader{height:56px;width:calc(100% - 1em)}.tableHeader th{height:17px;width:auto;color:#666;font-family:proxima-nova,arial,sans-serif;font-size:14px;font-weight:bold;letter-spacing:-0.09px;line-height:17px;text-align:left;padding-left:22px;text-overflow:ellipsis;white-space:nowrap;position:sticky}.selected{background-color:#eee}.deselected{background-color:#fff}.progressLoader{text-align:center;position:absolute;width:calc(100% - 8.5rem);display:flex;height:4.5rem;align-items:center;justify-content:center}.progressLoader svg{color:#0091ea}", ""]);

// Exports
exports.locals = {
	"ui-blue-01": "#0091ea",
	"ui-blue-02": "#0077b8",
	"ui-blue-03": "#005b8d",
	"ui-gray-01": "#333",
	"ui-gray-02": "#666",
	"ui-gray-03": "#999",
	"ui-gray-04": "#ccc",
	"ui-dropshadow": "rgba(0,0,0,.5)",
	"ui-background": "#f8f8f8",
	"ui-white": "#fff",
	"support-red": "#ef3824",
	"support-orange": "#ff9000",
	"support-green": "#72bf44",
	"support-yellow": "#ffc418"
};