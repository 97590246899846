// packages
import React, { useState } from 'react';
import { Card } from 'shamrock-clover-ui/dist/clover/components/Card/Card';
import { Spinner } from 'shamrock-clover-ui/dist/clover/components/Spinner/Spinner';

// components
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

// styles
import {
  CardContainer,
  RegistrationContainer,
  SpinnerContainer
} from './RegistrationStyles';

// props
interface Props {
  onCancel: any;
  visible: boolean;
}

interface FormData {
  userEmail: string;
  verificationCode: string;
}

export const Registration = ({ onCancel, visible }: Props) => {
  const [currentStep, changeStep] = useState(1);
  const [error, toggleError] = useState<boolean>(false);
  const [customCardMsg, setCustomCardMsg] = useState<string>('');
  const [formData, setFormData] = useState<FormData>({
    userEmail: '',
    verificationCode: ''
  });
  const [isCardVisible, toggleCardVisibility] = useState<boolean>(false);
  const [isLoading, toggleIsLoading] = useState<boolean>(false);

  const handleOnChangeUserEmail = (userEmail: string) =>
    setFormData({ ...formData, userEmail });

  const handleOnChangeVerificationCode = (verificationCode: string) => {
    const verificationCodeRe = new RegExp(/^\d+$/);
    if (verificationCodeRe.test(verificationCode) || verificationCode === '') {
      setFormData({ ...formData, verificationCode });
      return;
    }
  };

  if (!visible) {
    return null;
  }

  return (
    <RegistrationContainer>
      {isLoading ? (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      ) : null}
      <Step1
        onCancel={onCancel}
        onChangeStep={changeStep}
        onChangeUserEmail={handleOnChangeUserEmail}
        step={currentStep}
        toggleIsLoading={toggleIsLoading}
        toggleCardVisibility={toggleCardVisibility}
        toggleError={toggleError}
        userEmail={formData.userEmail}
        setCustomCardMsg={setCustomCardMsg}
        isLoading={isLoading}
        visible={currentStep === 1}
      />
      <Step2
        isLoading={isLoading}
        onChangeStep={changeStep}
        onChangeVerificationCode={handleOnChangeVerificationCode}
        step={currentStep}
        toggleError={toggleError}
        toggleIsLoading={toggleIsLoading}
        userEmail={formData.userEmail}
        verificationCode={formData.verificationCode}
        visible={currentStep === 2}
      />
      <Step3
        onChangeStep={changeStep}
        setCustomCardMsg={setCustomCardMsg}
        step={currentStep}
        toggleCardVisibility={toggleCardVisibility}
        toggleError={toggleError}
        toggleIsLoading={toggleIsLoading}
        userEmail={formData.userEmail}
        visible={currentStep === 3}
      />
      <CardContainer visible={isCardVisible}>
        <Card height="48px" status={!error ? 'green' : 'red'} width="495px">
          <div className="styledFont">{customCardMsg}</div>
        </Card>
      </CardContainer>
    </RegistrationContainer>
  );
};

export default Registration;
