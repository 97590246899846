import { UserService } from '../services';
import UserData from '../services/UserData';

declare const firebase: any;

const cache: CachedItem[] = [];
let isLoaded = false;
let errorLoadingScript = false;
let userLatitude = 0;
let userLongitude = 0;
let userAddress = '';

const { width, height } = window.screen;

export enum FireBaseFunc {
  LOG_FIREBASE_SCREEN_NAME = 'logFirebaseScreenName',
  LOG_FIREBASE_USER = 'logFirebaseUser',
  LOG_PAGE_VIEW = 'logPageView',
  LOG_FIREBASE_EVENT = 'logFirebaseEvent'
}

export const getUserAddress = async () => {
  const address = await UserData.getUserAddress();

  return address;
};

interface CachedItem {
  firebaseFunc: FireBaseFunc;
  params: any[];
}

interface FirebaseConfigProps {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const cacheEvent = (firebaseFunc: FireBaseFunc, params: any[]): boolean => {
  if (isLoaded === false && errorLoadingScript === false) {
    cache.push({ firebaseFunc, params });
    return true;
  }
  // if we fail to load script we do not want
  // to cache or try to log
  if (errorLoadingScript) return true;
  return false;
};

const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.src = src;
    script.addEventListener('load', () => {
      resolve();
    });
    script.addEventListener('error', () => {
      reject();
    });
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  });
};

export enum FirebaseParameters {
  MODULE = 'module',
  PAGE = 'page',
  USER_ID = 'UserID',
  USER_TYPE = 'userType',
  TYPE = 'type',
  DATE = 'date',
  ITEM_LIST_COUNT = 'item_list_count',
  ID = 'id',
  DESTINATION = 'destination',
  NAME = 'name',
  LAT = 'lat',
  LNG = 'lng',
  SEARCH_NAME = 'search_name',
  PRICE = 'price',
  VALUE = 'value',
  SEARCH_TYPE = 'search_type',
  ROUTE = 'route',
  STARTING_LOCATION = 'starting_location',
  ENDING_LOCATION = 'ending_location',
  FEATURED_STATIONS = 'featured_stations',
  TYPES = 'types'
}

export enum FirebasePages {
  SEARCH_LOADS = 'Search Loads',
  POINTE_OF_ENTRY = 'POE',
  LIST = 'List',
  MAP = 'Map',
  DETAILS = 'Details',
  ACCOUNTING = 'Accounting',
  SHIPMENTS = 'shipments',
  LOAD_ENTRY = 'Load Entry',
  LOAD_ENTRY_CANCEL = 'Load Cancel',
  SHIPMENTSDETAILS = 'shipmentDetails',
  GLOBALNOTIFICATIONSETTINGS = 'globalNotificationSettings',
  NOTIFICATIONSETTINGS = 'notificationSettings',
  EASYTRACKFULL = 'EasyTrackFull',
  EASYTRACK = 'EasyTrack',
  VERIFYACCOUNT = 'VerifyAccount',
  REQUEST_QUOTE_LINK = 'RequestQuoteLink',
  REQUEST_QUOTE_MODAL = 'RequestQuoteModal',
  REQUEST_QUOTE_BUTTON = 'RequestQuoteButton',
  ADDRESS_BOOK = 'addressBook',
  ADD_NEW_ADDRESS = 'addNewAddress',
  EDIT_ADDRESS = 'editAddress'
}

export enum FirebaseModules {
  FREIGHT = 'Freight',
  SHIPPER = 'ShipperPortal',
  EASYTRACKFULL = 'EasyTrackFull',
  EASYTRACK = 'EasyTrack'
}

export enum FirebaseEvents {
  SEARCH = 'search',
  EULA_ACCEPTED = 'eula_accepted',
  DETAILS_VIEW = 'details_view',
  SET_FAVORITE = 'set_favorite',
  CLICK = 'click',
  TAKE_PHOTO = 'take_photo',
  CHOOSE_LIBRARY = 'choose_library',
  CHOOSE_PDF = 'choose_pdf',
  TAB_SELECTED = 'tab_selected',
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
  SUBMIT = 'submit',
  SAVE = 'save',
  SORT = 'sort',
  FILTER = 'filter',
  SELECT = 'select',
  ENTER = 'enter',
  EXIT = 'exit',
  RECEIVED = 'received',
  EXPORT = 'export',
  UPLOAD = 'upload',
  LOAD_SEARCH = 'loadboard_search',
  POE_TRAILER = 'poe_trailer',
  MAP_CLICK = 'map_click',
  SEARCH_THIS_AREA = 'search_area',
  CALL = 'call',
  EMAIL = 'email',
  BACKHAUL_CLICK = 'backhaul_click',
  SEARCH_RESULTS = 'loadboardSearch',
  DETAIL_CLICK = 'detail_click',
  PAGE_VIEW = 'page_view',
  CSV_DOWNLOAD = 'agingDownload',
  FILTER_CLICK = 'filterClick',
  REFERENCE_SEARCH = 'referenceSearch',
  CITY_SEARCH = 'citySearch',
  DOCUMENT_DOWNLOAD = 'documentDownload',
  INVOICE_SEARCH = 'invoiceSearch',
  GLOBAL_NOTIFICATION_CLICK = 'globalNotificationClick',
  GLOBAL_NOTIFICATION_ADD = 'globalNotificationAdd',
  GLOBAL_NOTIFICATION_REMOVE = 'globalNotificationRemove',
  NOTIFICATION_CLICK = 'notificationClick',
  NOTIFICATION_ADD = 'notificationAdd',
  NOTIFICATION_REMOVE = 'notificationRemove',
  VIEW_FULL = 'viewFull',
  VIEW_BASIC = 'viewBasic',
  SIGN_UP = 'signUp',
  LOGIN = 'login'
}

export const logFirebaseScreenName = (page: FirebasePages) => {
  if (cacheEvent(FireBaseFunc.LOG_FIREBASE_SCREEN_NAME, [page])) {
    return;
  }
  firebase.analytics().setCurrentScreen(page);
};

export const logFirebaseUser = (
  userId: string | undefined,
  userType?: string | undefined
) => {
  if (cacheEvent(FireBaseFunc.LOG_FIREBASE_USER, [userId, userType])) {
    return;
  }
  firebase
    .analytics()
    .setUserProperties({ [FirebaseParameters.USER_ID]: userId });
  firebase.analytics().setUserId(userId);

  if (userType) {
    firebase
      .analytics()
      .setUserProperties({ [FirebaseParameters.USER_TYPE]: userType });
  }
};
const getDeviceType = () => {
  // not super reliable but works for most scenarios
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'Tablet';
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'Mobile';
  }
  return 'Desktop';
};

export const logFirebaseEvent = (
  event: FirebaseEvents,
  module: FirebaseModules,
  page: FirebasePages,
  customParams: any
) => {
  if (
    cacheEvent(FireBaseFunc.LOG_FIREBASE_EVENT, [
      event,
      module,
      page,
      customParams
    ])
  ) {
    return;
  }

  let mainParams = {
    module: module,
    page: page,
    userLatitude,
    userLongitude,
    resolution: `${width}X${height}`,
    browserType: getDeviceType(),
    userAddress
  };

  let allParams = Object.assign({}, customParams, mainParams);
  const currentUser = UserService.getCurrentUser();
  if (currentUser) {
    logFirebaseUser(currentUser._id, currentUser.user_type);
  }

  firebase.analytics().logEvent(event, allParams);
};

export const logPageView = (page: FirebasePages) => {
  if (cacheEvent(FireBaseFunc.LOG_PAGE_VIEW, [page])) {
    return;
  }
  firebase.analytics().logEvent(FirebaseEvents.PAGE_VIEW, {
    module: 'Load',
    page
  });
};

const loadCachedEvents = () => {
  if (cache.length > 0) {
    cache.forEach(e => {
      switch (e.firebaseFunc) {
        case FireBaseFunc.LOG_FIREBASE_EVENT:
          logFirebaseEvent(e.params[0], e.params[1], e.params[2], e.params[3]);
          break;
        case FireBaseFunc.LOG_FIREBASE_SCREEN_NAME:
          logFirebaseScreenName(e.params[0]);
          break;
        case FireBaseFunc.LOG_FIREBASE_USER:
          logFirebaseUser(e.params[0], e.params[1]);
          break;
        case FireBaseFunc.LOG_PAGE_VIEW:
          logPageView(e.params[0]);
          break;
      }
    });
  }
};

const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        userLatitude = latitude;
        userLongitude = longitude;
      },
      () => {}
    );
  }
};

export const init = async (firebaseConfig: FirebaseConfigProps) => {
  getLocation();

  userAddress = await getUserAddress();

  await loadScript('https://www.gstatic.com/firebasejs/7.24.0/firebase-app.js');
  await loadScript(
    'https://www.gstatic.com/firebasejs/7.24.0/firebase-analytics.js'
  );

  isLoaded = true;
  firebase.initializeApp(firebaseConfig);
  loadCachedEvents();
};
