import React, { useState } from 'react';
import CardFilterIconLight from '../assets/images/CardFilterIconLight.svg';
import CardFilterIconDark from '../assets/images/CardFilterIconDark.svg';
import YellowWarning from '../assets/images/warning-yellow-small.png';
import OrangeWarning from '../assets/images/warning-orange-small.png';
import RedWarning from '../assets/images/warning-red-small.png';

import './Card.scss';

const getIcon = (iconColor: 'yellow' | 'orange' | 'red') => {
  if (iconColor === 'yellow') {
    return YellowWarning;
  }
  if (iconColor === 'orange') {
    return OrangeWarning;
  }
  if (iconColor === 'red') {
    return RedWarning;
  }
};

interface Props {
  style?: React.CSSProperties;
}

interface RenderProps {
  isActive: boolean;
}

interface CardProps extends Props {
  headerComponent: (props: RenderProps) => React.ReactElement;
  bodyComponent: (props: RenderProps) => React.ReactElement;
  footerComponent: (props: RenderProps) => React.ReactElement;
  onClick?: (
    event: React.MouseEvent<HTMLDivElement>,
    isActive: boolean
  ) => void;
}

const Card: React.FunctionComponent<CardProps> = ({
  style,
  headerComponent,
  bodyComponent,
  footerComponent,
  onClick: onClickProp,
  ...props
}) => {
  const [isActive, setActive] = useState(false);
  const renderProps = { isActive };

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const newActiveValue = !isActive;

    setActive(newActiveValue);
    if (onClickProp) {
      onClickProp(event, newActiveValue);
    }
  };

  return (
    <div
      onClick={onClick}
      className="cardContainer"
      style={{
        ...style
      }}
      {...props}
    >
      {headerComponent(renderProps)}
      {bodyComponent(renderProps)}
      {footerComponent(renderProps)}
    </div>
  );
};

interface CardHeaderProps extends Props {
  text: string;
  icon?: 'yellow' | 'orange' | 'red';
}

export const CardHeader: React.FunctionComponent<CardHeaderProps> = ({
  style,
  text,
  icon
}) => {
  return (
    <div
      className="cardHeaderContainer"
      style={{
        ...style
      }}
    >
      <div className="cardText">{text}</div>
      {icon && <img className="icon" src={getIcon(icon)} />}
    </div>
  );
};

interface CardBodyProps extends Props {
  text: string;
}

export const CardBody: React.FunctionComponent<CardBodyProps> = ({
  style,
  text
}) => {
  return <div style={{ ...style }}>{text}</div>;
};

interface CardFooterProps extends Props {
  text?: string;
  filterChipText?: string;
  isActive: boolean;
}

export const CardFooter: React.FunctionComponent<CardFooterProps> = ({
  text,
  filterChipText,
  style,
  isActive
}) => {
  return (
    <div
      className="cardFooterContainer"
      style={{
        ...style
      }}
    >
      {text}
      {filterChipText && (
        <div
          className="cardFooterFilterChip"
          style={{
            backgroundColor: isActive ? '#CCCCCC' : '#EEEEEE'
          }}
        >
          <img
            style={{ paddingLeft: '6px', paddingRight: '6px' }}
            src={isActive ? CardFilterIconDark : CardFilterIconLight}
          />
          <div
            style={{
              color: isActive ? '#333333' : '#888888',
              fontWeight: isActive ? 'bold' : 'normal',
              fontSize: '14px',
              whiteSpace: 'nowrap'
            }}
          >
            {filterChipText}
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
