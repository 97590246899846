import React from 'react';
import { Button } from 'shamrock-clover-ui/dist/clover/components/Button/Button';
import {
  BackDrop,
  Body,
  StyledModal,
  ModalTitle,
  ButtonContainer,
  ModalText
} from './AddressBookStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getUnsavedModalOpen } from '../../store/addressBook/addressBookSelectors';
import {
  setAddressEntryOpen,
  setEditAddressIndex,
  setHoldAddress,
  setUnsavedModalOpen
} from '../../store/addressBook/addressBookActions';

interface Props {
  entryFormBlankOrNoChange: boolean;
}

const UnsavedChangesModal = ({ entryFormBlankOrNoChange }: Props) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(getUnsavedModalOpen);

  const handleDiscard = () => {
    const address = {
      name: '',
      address1: '',
      address2: '',
      city: '',
      stateProvince: '',
      postalCode: '',
      country: '',
      phoneNumber: '',
      appointmentRequired: false,
      isDeleted: false
    };
    dispatch(setHoldAddress(address));
    dispatch(setAddressEntryOpen(false));
    dispatch(setUnsavedModalOpen(false));
    dispatch(setEditAddressIndex(-1));
  };

  const continueEditing = () => {
    dispatch(setUnsavedModalOpen(false));
  };

  if (!isOpen) return <></>;

  if (entryFormBlankOrNoChange) {
    handleDiscard();
  }

  return (
    <BackDrop>
      <StyledModal>
        <Body>
          <ModalTitle>There are unsaved changes.</ModalTitle>
          <ModalText>
            Are you sure you want to leave this page? Changes will not be saved.
          </ModalText>
          <ButtonContainer>
            <Button buttonStyle="outlined" onClick={continueEditing}>
              Continue Editing
            </Button>
            <Button buttonStyle="solid" onClick={handleDiscard}>
              Discard Changes
            </Button>
          </ButtonContainer>
        </Body>
      </StyledModal>
    </BackDrop>
  );
};

export default UnsavedChangesModal;
