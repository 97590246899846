import React, { FunctionComponent } from 'react';
import YellowWarning from '../../assets/images/warning-yellow-large.png';
import OrangeWarning from '../../assets/images/warning-orange-large.png';
import RedWarning from '../../assets/images/warning-red-large.png';

import './InvoiceStatusIcon.scss';

interface Props {
  daysOverdue?: number;
}

const InvoiceStatusIcon: FunctionComponent<Props> = props => {
  const getIconType = () => {
    if (props.daysOverdue) {
      if (props.daysOverdue < 0) return undefined;
      if (props.daysOverdue >= 0 && props.daysOverdue < 31) {
        return YellowWarning;
      }
      if (props.daysOverdue < 61) {
        return OrangeWarning;
      }
      if (props.daysOverdue >= 61) {
        return RedWarning;
      }
    }
  };
  const iconType = getIconType();
  return (
    <>
      {iconType ? (
        <img className="icon" src={iconType} />
      ) : (
        <div className="noIcon" />
      )}
    </>
  );
};

export default InvoiceStatusIcon;
