import React, { Component, Fragment } from 'react';
import { AuthService } from '../../services';

class Logout extends Component<{}> {
  authService: AuthService;

  constructor(props: {}) {
    super(props);
    this.authService = new AuthService();
  }

  componentDidMount() {
    this.authService.logoutRedirect();
  }

  render() {
    return (
      <Fragment>
        <p>You have been logged out. Returning home.</p>
      </Fragment>
    );
  }
}

export default Logout;
