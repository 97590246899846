import { logFirebaseUser } from './firebaseUtils';

const USER_URL = process.env.REACT_APP_USER_URL || '';
const MERCURYGATE_TOKEN_URL = process.env.REACT_APP_MERCURYGATE_TOKEN_URL || '';
const ACTINIUM_URL = process.env.REACT_APP_ACTINIUM_URL || '';
const TOKEN = 'TOKEN';
const USER = 'USER';

export interface UserInfo {
  token: string;
  user: string;
  id: string;
}

export interface CurrentUser {
  company: string;
  createdOn: string;
  crmId: string;
  deviceIds: string[];
  email: string;
  factoring_status: string;
  firstname?: string;
  lastname: string;
  note: string;
  permissions?: { name: string; status: string }[];
  pin: string;
  roles: string[];
  status: string;
  user_type: string;
  _id: string;
  password: string;
  phone?: string;
  mcleod_customer_id: string[];
  bridge_id: string[];
  device_ids: string[];
  mc_number?: string;
}

export const getUserHttp = async (token: string, userName: string) => {
  const response = await fetch(`${USER_URL}user/user/` + userName, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  return (await response.json()) as CurrentUser;
};

export const getUserLocalStorage = () => {
  const user = localStorage.getItem(USER);
  return user ? (JSON.parse(user) as CurrentUser) : null;
};

export const getAuthToken = () => {
  return localStorage.getItem(TOKEN);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN);
};

export const getUserEmail = () => {
  let user = getUserLocalStorage();
  return user && user.email ? user.email : '';
};

export const isAuthenticated = () => {
  const user = getUserLocalStorage();
  const token = getToken();

  if (user && token) {
    logFirebaseUser(user._id, user.user_type);
    return true;
  }
  return false;
};

export const redirectToLogin = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER);
  //TODO No references to this file or function found
  //Actinium-web deprecated use coreweb auth with finaldestination param
  const url = `${ACTINIUM_URL}home?origin=${encodeURIComponent(
    MERCURYGATE_TOKEN_URL
  )}`;
  window.location.replace(url);
};

export const redirectToLogout = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER);
  //TODO No references to this file or function found
  //Actinium-web deprecated use coreweb logout with finaldestination param
  const redirect = encodeURIComponent(MERCURYGATE_TOKEN_URL);
  window.location.assign(`${ACTINIUM_URL}logout?callback=${redirect}`);
};

export enum AUTH0_ERRORS {
  USER_BLOCKED = 'user is blocked'
}
