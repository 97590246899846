import React, { FunctionComponent, useEffect, Fragment } from 'react';
import './AccountingSummary.scss';
import AccountingDetailHeaderSection from './AccountingDetailHeaderSection';
import DetailScrollableArea from '../../../components/detail/DetailScrollableArea';
import DetailDocumentSection from '../../../components/detail/DetailDocumentSection';
import DetailLoadInfo from '../../../components/detail/DetailLoadInfo';
import AccountingLoadInfoText from './AccountingLoadInfoText';
import DetailStop from '../../../components/detail/DetailStop';
import InvoiceInfo from './InvoiceInfo';
import { fetchInvoiceDetails } from '../../../store/accounting/accountingActions';
import { useAccountingQueryParams } from '../../../hooks/routeHooks';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'shamrock-clover-ui/dist/clover/components/Spinner/Spinner';
import SnackbarError from '../../../components/SnackbarError';
import {
  getInvoiceOrderDetails,
  getInvoiceOrderDetailsLoading,
  getInvoiceById,
  getInvoiceTableLoading,
  getInvoiceFiles,
  getIsAccountingDetailsError
} from '../../../store/accounting/accountingSelectors';

const AccountingSummary: FunctionComponent = () => {
  const dispatch = useDispatch();
  const {
    queryParams: { invoiceId }
  } = useAccountingQueryParams();

  const details = useSelector(getInvoiceOrderDetails);
  const files = useSelector(getInvoiceFiles);
  const isLoading = useSelector(getInvoiceOrderDetailsLoading);
  const isDetailError = useSelector(getIsAccountingDetailsError);
  const isTableLoading = useSelector(getInvoiceTableLoading);
  const invoice = useSelector(getInvoiceById(invoiceId ? invoiceId : ''));

  useEffect(() => {
    if (invoiceId) {
      dispatch(fetchInvoiceDetails(invoiceId));
    }
  }, [invoiceId]);

  return isLoading || isTableLoading ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Spinner />
    </div>
  ) : (
    <Fragment>
      <SnackbarError
        isError={isDetailError}
        message="Oops! Something went wrong. Please refresh."
      />
      {details !== null ? (
        <div className="accountingSummary">
          <AccountingDetailHeaderSection
            id={invoice ? invoice.id : undefined}
            balance={invoice ? invoice.remainingBalance : undefined}
            daysPastDue={invoice ? invoice.ageDays : undefined}
            paymentTermDays={invoice ? invoice.billDueDays : undefined}
          />
          <DetailScrollableArea>
            <DetailDocumentSection fileList={files} id={details.orderId} />
            <InvoiceInfo invoice={invoice} order={details as any} />
            <DetailLoadInfo large={true}>
              <AccountingLoadInfoText order={details as any} />
            </DetailLoadInfo>
            {details.rawStops.map((stop, index) => {
              return (
                <DetailStop key={index} stop={stop} stops={details.rawStops} />
              );
            })}
          </DetailScrollableArea>
        </div>
      ) : null}
    </Fragment>
  );
};

export default AccountingSummary;
