import { Order } from 'types/orderTypes';
import { FILTER_TYPES } from '../../utilities/constants';
import OrderService from '../../services/order/OrderService';
import NotificationService from '../../services/NotificationService';
import GoogleMapsService from '../../services/googleMaps/GoogleMapsService';
import {} from 'googlemaps';
import {
  Marker,
  FileItem,
  OrderState,
  OrderActionTypes,
  NotificationSummary
} from './orderReduxTypes';
import {
  isBooked,
  getStopStatusText,
  isOrderComplete,
  getCurrentStop,
  isDelayed,
  isValidStops
} from '../../utilities/orderUtilities';
import { monthDayTimeFormat } from '../../utilities/dateHelper';
import to from 'await-to-js';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { AppState } from 'store';
import isEmpty from 'lodash/isEmpty';

export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDERS_ERROR = 'SET_ORDERS_ERROR';
export const SET_NUMBER_OF_ORDER_RESULTS = 'SET_NUMBER_OF_ORDER_RESULTS';
export const TOGGLE_PAGE_CHANGING = 'TOGGLE_PAGE_CHANGING';
export const SET_PAGE_INDEX = 'SET_PAGE_INDEX';
export const SET_NO_OF_ROWS = 'SET_NO_OF_ROWS';
export const SET_ORDERS_CSV = 'SET_ORDERS_CSV';
export const SET_ORDERS_CSV_LOADING = 'SET_ORDERS_CSV_LOADING';
export const TOGGLE_FILTER_BOOKED = 'TOGGLE_FILTER_BOOKED';
export const TOGGLE_FILTER_IN_TRANSIT = 'TOGGLE_FILTER_IN_TRANSIT';
export const TOGGLE_FILTER_DELIVERED = 'TOGGLE_FILTER_DELIVERED';
export const SET_REFERENCE_FILTER_STRING = 'SET_REFERENCE_FILTER_STRING';
export const SET_REFERENCE_TOGGLE_STRING = 'SET_REFERENCE_TOGGLE_STRING';
export const SET_ORIGIN_FILTER_STRING = 'SET_ORIGIN_FILTER_STRING';
export const SET_DESTINATION_FILTER_STRING = 'SET_DESTINATION_FILTER_STRING';
export const SET_PLACES_SUGGESTIONS = 'SET_PLACES_SUGGESTIONS';
export const SET_ORDER_BY = 'SET_ORDER_BY';
export const SET_DETAIL_PAGE = 'SET_DETAIL_PAGE';
export const TOGGLE_DETAIL_VISIBILITY = 'TOGGLE_DETAIL_VISIBILITY';
export const TOGGLE_DETAIL_LOADING = 'TOGGLE_DETAIL_LOADING';
export const TOGGLE_MARKERS_LOADING = 'TOGGLE_MARKERS_LOADING';
export const SET_DETAIL_ERROR = 'SET_DETAIL_ERROR';
export const SET_DETAIL_MARKERS = 'SET_DETAIL_MARKERS';
export const TOGGLE_MAPS_ERROR = 'TOGGLE_MAPS_ERROR';
export const TOGGLE_NOTIFICATIONS_MODAL = 'TOGGLE_NOTIFICATIONS_MODAL';
export const SET_NOTIFICATION_SUMMARY = 'SET_NOTIFICATION_SUMMARY';
export const TOGGLE_LOAD_ENTRY_MODAL = 'TOGGLE_LOAD_ENTRY_MODAL';
export const TOGGLE_CANCEL_MODAL = 'TOGGLE_CANCEL_MODAL';
export const SET_LOAD_ENTRY_ERROR = 'SET_LOAD_ENTRY_ERROR';
export const TOGGLE_NOTIFICATIONS_MODAL_PENDING_STATE =
  'TOGGLE_NOTIFICATIONS_MODAL_PENDING_STATE';

export function setOrders(orders: Order[]): OrderActionTypes {
  return { type: SET_ORDERS, orders };
}

export function setOrdersError(isError: boolean): OrderActionTypes {
  return { type: SET_ORDERS_ERROR, isError };
}

export function setNumberOfOrderResults(orderCount: number): OrderActionTypes {
  return { type: SET_NUMBER_OF_ORDER_RESULTS, orderCount };
}

export function togglePageChanging(pageChanging: boolean): OrderActionTypes {
  return { type: TOGGLE_PAGE_CHANGING, pageChanging };
}

export function setPageIndex(page: number): OrderActionTypes {
  return { type: SET_PAGE_INDEX, page };
}

export function setNoOfRows(noOfRows: number): OrderActionTypes {
  return { type: SET_NO_OF_ROWS, noOfRows };
}

export function toggleFilterBooked(filterBooked: boolean): OrderActionTypes {
  return { type: TOGGLE_FILTER_BOOKED, filterBooked };
}

export function setOrdersCSV(ordersCSV: string): OrderActionTypes {
  return { type: SET_ORDERS_CSV, ordersCSV };
}

export function setOrdersCSVLoading(
  ordersCSVLoading: boolean
): OrderActionTypes {
  return { type: SET_ORDERS_CSV_LOADING, ordersCSVLoading };
}

export function toggleFilterInTransit(
  filterInTransit: boolean
): OrderActionTypes {
  return { type: TOGGLE_FILTER_IN_TRANSIT, filterInTransit };
}

export function toggleFilterDelivered(
  filterDelivered: boolean
): OrderActionTypes {
  return { type: TOGGLE_FILTER_DELIVERED, filterDelivered };
}

export function setReferenceFilterString(
  referenceFilterString: string
): OrderActionTypes {
  return { type: SET_REFERENCE_FILTER_STRING, referenceFilterString };
}

export function setReferenceToggleString(
  referenceToggleString: string
): OrderActionTypes {
  return { type: SET_REFERENCE_TOGGLE_STRING, referenceToggleString };
}

export function getFilters(state: OrderState): string[] {
  const { filterBooked, filterInTransit, filterDelivered } = state;
  const filters: string[] = [];

  filterInTransit && filters.push(FILTER_TYPES.IN_TRANSIT);
  filterBooked && filters.push(FILTER_TYPES.BOOKED);
  filterDelivered && filters.push(FILTER_TYPES.DELIVERED);

  return filters;
}

export function setPageDetail({
  order,
  markers = [],
  fileItems = [],
  error = undefined
}: {
  order: Order;
  markers?: Marker[];
  fileItems?: FileItem[];
  error?: string;
}): OrderActionTypes {
  return {
    type: SET_DETAIL_PAGE,
    detail: {
      order,
      markers,
      fileItems,
      error
    }
  };
}

export function toggleDetailLoading(value: boolean) {
  return {
    type: TOGGLE_DETAIL_LOADING,
    value
  };
}

export function toggleMarkersLoading(value: boolean): OrderActionTypes {
  return {
    type: TOGGLE_MARKERS_LOADING,
    value
  };
}

export function setDetailError(value: string): OrderActionTypes {
  return {
    type: SET_DETAIL_ERROR,
    value
  };
}

export function setDetailMarkers(value: Marker[]): OrderActionTypes {
  return {
    type: SET_DETAIL_MARKERS,
    value
  };
}

export function toggleMapsError(value: boolean): OrderActionTypes {
  return {
    type: TOGGLE_MAPS_ERROR,
    value
  };
}

export function toggleNotificationsModal(isVisible: boolean) {
  return {
    type: TOGGLE_NOTIFICATIONS_MODAL,
    isVisible
  };
}

export function toggleLoadEntryModal(isVisible: boolean) {
  return {
    type: TOGGLE_LOAD_ENTRY_MODAL,
    isVisible
  };
}

export function toggleCancelModal(isVisible: boolean) {
  return {
    type: TOGGLE_CANCEL_MODAL,
    isVisible
  };
}

export function toggleNotificationModalInPendingState(value: boolean) {
  return {
    type: TOGGLE_NOTIFICATIONS_MODAL_PENDING_STATE,
    value
  };
}

export function setLoadEntryError(
  isVisible: boolean,
  isError: boolean,
  value: string
): OrderActionTypes {
  return {
    type: SET_LOAD_ENTRY_ERROR,
    isVisible,
    isError,
    value
  };
}

export function retrieveGoogleMapsData(
  order: Order
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch) {
    dispatch(toggleMarkersLoading(true));
    dispatch(toggleMapsError(false));
    let markers: Marker[] = [];
    try {
      if (order.rawCallin && !isBooked(order) && !isOrderComplete(order)) {
        const { city_name, state, call_date_time } = order.rawCallin;
        const [lat, lng] = await OrderService.getGeolocation(
          `${city_name} ${state}`
        );
        const currentStop = getCurrentStop(order.rawStops);
        const isLate =
          currentStop &&
          isDelayed(currentStop, order.rawStops, order.rawCallin);

        markers.push({
          lat,
          lng,
          type: isLate === false ? 'IN_TRANSIT' : 'IN_TRANSIT_DELAYED',
          primaryText: `Updated: ${monthDayTimeFormat(call_date_time)}`,
          secondaryText: `${city_name}, ${state}`
        });
      }

      const rawStopsPromises = order.rawStops.map(
        async (rawStop): Promise<void> => {
          const {
            address,
            city,
            state,
            zip_code,
            actual_arrival,
            actual_departure,
            stop_type,
            locationName
          } = rawStop;
          const [lat, lng] = await OrderService.getGeolocation(
            `${address} ${city} ${state} ${zip_code}`
          );
          if (actual_arrival && actual_departure) {
            markers.push({
              lat,
              lng,
              type: 'COMPLETED',
              primaryText: locationName,
              secondaryText:
                stop_type === 'PU' ? 'Pickup: Complete' : 'Delivery: Complete'
            });
            return;
          }
          markers.push({
            lat,
            lng,
            type: stop_type === 'PU' ? 'PICKUP' : 'DELIVERY',
            primaryText: locationName,
            secondaryText: `${
              stop_type === 'PU' ? 'Pickup' : 'Delivery'
            }: ${getStopStatusText(rawStop, order.rawStops, order.rawCallin)}`
          });
        }
      );
      await Promise.all(rawStopsPromises);
    } catch (err) {
      markers = [];
      dispatch(toggleMapsError(true));
    }
    dispatch(setDetailMarkers(markers));
    dispatch(toggleMarkersLoading(false));
  };
}

export function retrieveOrderData(
  orderId: string
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch) {
    dispatch(toggleDetailLoading(true));

    const [orderDetailsError, orderDetail] = await to(
      OrderService.getOrderDetails(orderId)
    );
    if (orderDetailsError || orderDetail === undefined) {
      dispatch(
        setDetailError(orderDetailsError ? orderDetailsError.toString() : '')
      );
      dispatch(toggleDetailLoading(false));
      return;
    }

    const [filesError, files] = await to(
      OrderService.getFilesForOrder(orderId)
    );
    if (filesError) {
      // Sentry.withScope((scope): void => {
      //   scope.setExtras({ orderId });
      //   const eventId = Sentry.captureException(filesError);
      //   this.setState({ eventId });
      // });
    }

    dispatch(retrieveGoogleMapsData(orderDetail));

    dispatch(
      setPageDetail({
        fileItems: files,
        order: orderDetail
      })
    );
    dispatch(toggleDetailLoading(false));
  };
}

export function retrieveOrderCsv(): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> {
  return async function(dispatch, getState): Promise<any> {
    const ordersState = getState().orders;
    const {
      originFilterString,
      destinationFilterString,
      referenceFilterString,
      orderBy
    } = ordersState;
    try {
      dispatch(setOrdersCSVLoading(true));
      let ordersResult = await OrderService.getOrdersCSV({
        filterType: getFilters(ordersState),
        searchString: referenceFilterString,
        originString: originFilterString,
        destinationString: destinationFilterString,
        orderBy
      });
      dispatch(setOrdersCSV(ordersResult.csv));
      dispatch(setOrdersCSVLoading(false));
    } catch (e) {
      console.log('Error: ' + e);
    }
  };
}

export function retrievePageOfData(
  page: number,
  numberOfRows?: number
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch, getState): Promise<any> {
    const ordersState = getState().orders;
    const {
      noOfRows,
      originFilterString,
      destinationFilterString,
      referenceFilterString,
      orderBy
    } = ordersState;
    try {
      dispatch(togglePageChanging(true));
      let ordersResult = await OrderService.getOrderSummary(
        page + 1,
        numberOfRows ? numberOfRows : noOfRows,
        {
          filterType: getFilters(ordersState),
          searchString: referenceFilterString,
          originString: originFilterString,
          destinationString: destinationFilterString,
          orderBy
        }
      );
      let validOrders = ordersResult.orders.filter(o =>
        isValidStops(o.rawStops, o.orderId)
      );
      dispatch(setOrders(validOrders));
      dispatch(setNumberOfOrderResults(ordersResult.count));
      dispatch(togglePageChanging(false));
      dispatch(setPageIndex(page));
      dispatch(retrieveOrderCsv());
    } catch (e) {
      dispatch(togglePageChanging(false));
      dispatch(setOrdersError(true));
    }
  };
}

export function toggleFilter(
  filterToBeToggled: string
): ThunkAction<void, AppState, null, Action<string>> {
  return function(dispatch, getState): void {
    const {
      filterBooked,
      filterInTransit,
      filterDelivered
    } = getState().orders;
    switch (filterToBeToggled) {
      case FILTER_TYPES.BOOKED: {
        dispatch(toggleFilterBooked(!filterBooked));
        break;
      }
      case FILTER_TYPES.IN_TRANSIT: {
        dispatch(toggleFilterInTransit(!filterInTransit));
        break;
      }
      case FILTER_TYPES.DELIVERED: {
        dispatch(toggleFilterDelivered(!filterDelivered));
        break;
      }
    }
  };
}

export function searchOrders(): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> {
  return function(dispatch): void {
    dispatch(toggleFilterBooked(false));
    dispatch(toggleFilterDelivered(false));
    dispatch(toggleFilterInTransit(false));
    dispatch(retrievePageOfData(0));
  };
}

export function setOriginFilterString(
  originFilterString: string
): OrderActionTypes {
  return { type: SET_ORIGIN_FILTER_STRING, originFilterString };
}

export function setDestinationFilterString(
  destinationFilterString: string
): OrderActionTypes {
  return { type: SET_DESTINATION_FILTER_STRING, destinationFilterString };
}

export function setPlacesSuggestions(
  placesSuggestions: google.maps.places.AutocompletePrediction[]
): OrderActionTypes {
  return { type: SET_PLACES_SUGGESTIONS, placesSuggestions };
}

export function performGooglePlacesSearch(
  type: string
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch, getState): Promise<any> {
    const { originFilterString, destinationFilterString } = getState().orders;
    const filterValue =
      type === 'ORIGIN' ? originFilterString : destinationFilterString;
    try {
      let places = await GoogleMapsService.getPlaceSuggestions(filterValue);
      dispatch(setPlacesSuggestions(places));
    } catch (e) {}
  };
}

export function setOrderBy(
  column: string,
  isAscending: boolean
): OrderActionTypes {
  return {
    type: SET_ORDER_BY,
    orderBy: {
      column: column,
      isAscending: isAscending
    }
  };
}

export function toggleOrderBy(
  column: string,
  isAscending: boolean
): ThunkAction<void, AppState, null, Action<string>> {
  return function(dispatch): void {
    dispatch(setOrderBy(column, isAscending));
    dispatch(retrievePageOfData(0));
  };
}

export function setNotificationSummary(
  summary: NotificationSummary | null
): OrderActionTypes {
  return {
    type: SET_NOTIFICATION_SUMMARY,
    notificationSummary: summary
  };
}

export function getNotificationSummary(): ThunkAction<
  void,
  AppState,
  null,
  Action<string>
> {
  return async function(dispatch): Promise<any> {
    try {
      dispatch(toggleNotificationModalInPendingState(true));
      let result = await NotificationService.getNotificationSummary();
      if (!isEmpty(result)) {
        dispatch(setNotificationSummary(result));
      } else {
        dispatch(setNotificationSummary(null));
      }
      dispatch(toggleNotificationModalInPendingState(false));
    } catch (err) {}
  };
}

export function toggleOrderNotificationsEnabled(
  orderId: string,
  notificationsOn: boolean
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch): Promise<any> {
    try {
      dispatch(toggleNotificationModalInPendingState(true));
      let result = await NotificationService.updateOrderNotificationsEnabled(
        orderId,
        notificationsOn
      );
      dispatch(setNotificationSummary(result));
      dispatch(toggleNotificationModalInPendingState(false));
    } catch (err) {}
  };
}

export function toggleGlobalNotificationsEnabled(
  notificationsOn: boolean
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch): Promise<any> {
    try {
      dispatch(toggleNotificationModalInPendingState(true));
      let result = await NotificationService.updateGlobalNotificationsEnabled(
        notificationsOn
      );
      dispatch(setNotificationSummary(result));
      dispatch(toggleNotificationModalInPendingState(false));
    } catch (err) {}
  };
}

export function updateOrderNotificationUsers(
  orderId: string,
  contacts: string[]
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch): Promise<any> {
    try {
      dispatch(toggleNotificationModalInPendingState(true));
      let result = await NotificationService.updateOrderUsers(
        orderId,
        contacts
      );
      dispatch(setNotificationSummary(result));
      dispatch(toggleNotificationModalInPendingState(false));
    } catch (err) {}
  };
}

export function updateGlobalNotificationUsers(
  contacts: string[]
): ThunkAction<void, AppState, null, Action<string>> {
  return async function(dispatch): Promise<any> {
    try {
      dispatch(toggleNotificationModalInPendingState(true));
      let result = await NotificationService.updateGlobalUsers(contacts);
      dispatch(setNotificationSummary(result));
      dispatch(toggleNotificationModalInPendingState(false));
    } catch (err) {}
  };
}
